// @import url('https://fonts.cdnfonts.com/css/general-sans');
body {
  margin: 0;
  // font-family: "Nexa Regular";
  font-family: Generalsans, sans-serif;
  background-color: $dark;
  overflow-x: hidden;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(97, 209, 128, 0.2);
  }
}
// common css starts
.mh-100 {
  min-height: 100vh;
}
.mh-89 {
  min-height: 89vh;
}
a {
  text-decoration: $none;
}
.commonRadius8 {
  border-radius: 8px;
}
.commonRadius10 {
  border-radius: 10px;
}
.release {
  text-align: $right;
  @include ipad-landscape {
    margin-bottom: 20px !important;
    order: 1;
  }
  @include ipad {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    order: 2;
  }
  @include mobile {
    margin-bottom: 20px !important;
    margin-top: 0 !important;
    text-align: $right;
    order: 1;
    width: 100%;
  }
  p {
    font-size: 18px;
    span {
      background-color: $grayTint1;
      padding: 12px 20px;
      color: $white;
      font-weight: $semibold;
      display: $inline-block;
      @include mobile {
        padding: 10px;
        display: $inline-block;
        font-size: 12px;
      }
    }
  }
}

// common form control starts
.form-control {
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background-color: $grayTint1;
  height: 50px;
  color: $whiteO2;
  box-shadow: $none;
  &::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: $whiteO2;
  }
  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $whiteO2;
  }
  &:focus {
    background-color: $grayTint1;
    box-shadow: none;
    border: 1px solid rgba(97, 209, 128, 0.16);
  }
}
// common form control ends

.stepProgressBar {
  margin: 30px 0 30px 0;
  .progress {
    background-color: $grayTint8;
    height: 8px;
    .progress-bar {
      background-color: $green;
      border-radius: 8px;
    }
  }
  p {
    margin: 20px 0 0 0;
    display: $flex;
    justify-content: space-between;
    color: $white;
    font-size: 18px;
    @include mobile {
      font-size: 16px;
    }
    span {
    }
  }
}
.green {
  color: $green;
}
.white {
  color: $white;
}
.red {
  color: $red;
}
.commonHeading {
  margin-bottom: 30px;
  * {
    font-size: clamp(1.5rem, 4vw, 2.5rem);
    color: $white;
    font-weight: $bold;
    margin: 0;
  }
}

.commonBtn {
  background: $commonGradient;
  color: $white;
  font-weight: $semibold;
  border: none;
  font-size: 18px;
  border-radius: 8px;
  padding: 10px 20px;
  &:hover {
    color: $white;
  }
}
.commonCard {
  background-color: $grayTint3;
  border: 1px solid rgba(97, 209, 128, 0.16);
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
  &:hover {
    border-color: $green;
    box-shadow: 0px 3.5px 5.5px 0px rgba(97, 209, 128, 0.4);
  }
  a {
    display: $flex;
    padding: 25px;
    align-items: $center;
    gap: 20px;
    h4 {
      font-size: 30px;
      color: $white;
      @include medium-desktop {
        font-size: 24px;
      }
    }
    span {
      display: $inline-flex;
      background-color: $dark;
      border-radius: 8px;
      align-items: $center;
      justify-content: $center;
      width: 120px;
      height: 120px;
      @include large-ipad {
        width: 110px;
        height: 90px;
      }
      img {
        width: 100%;
        max-width: 70px;
        @include laptop {
          max-width: 60px;
        }
        @include large-ipad {
          max-width: 50px;
        }
      }
    }
  }
}

.btnSection {
  text-align: $right;
  @include mobile {
    text-align: $center;
  }
  .btn {
    padding: 10px;
    border-radius: 8px;
    min-width: 180px;
    max-width: 180px;
    height: 50px;
    border: 0;
    margin: 0 5px;
    font-weight: $semibold;
    @include mobile {
      min-width: 160px;
      max-width: 160px;
      margin-bottom: 5px;
    }
    &.btn-cancel {
      background-color: $grayTint7;
      color: $whiteO3;
    }
    &.btn-save {
      background: $commonGradient;
      color: $white;
    }
    &.btn-share {
      background-color: $grayTint1;
      border: 1px solid rgba(97, 209, 128, 0.1);
      color: $white;
    }
  }
}
.photoUploadSection {
  text-align: $left;
  label {
    color: $whiteO2;
    margin-bottom: 10px;
  }
}
.dragDrop {
  background-color: $grayTint3;
  border: 1px dashed rgba(97, 209, 128, 0.16);
  border-radius: 8px;
  text-align: $center;
  padding: 40px;
  position: $relative;
  input {
    position: $absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;
    opacity: 0;
  }
  p {
    color: $whiteO4;
    .green {
      color: $green;
    }
    .white {
      color: $white;
    }
  }
}

.sectionPadding {
  padding: 50px 0;
}

.commonBg-animation {
  -webkit-animation: moving 70s infinite;
  animation: moving 70s infinite;
}
.commonLoader {
  background-color: rgba(13, 13, 13, 0.9);
  position: $fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  .inner {
    display: $flex;
    width: 100%;
    height: 100%;
    align-items: $center;
    justify-content: $center;
    animation: taadaa 3s infinite;
    img {
      width: 100%;
      max-width: 150px;
    }
  }
}
.react-datepicker-wrapper {
  width: 100%;
  .react-datepicker__input-container {
    input {
      border-radius: 8px;
      border: 1px solid rgba(97, 209, 128, 0.16);
      background-color: $grayTint1;
      height: 50px;
      color: $whiteO8;
      box-shadow: $none;
      width: 100%;
      padding: 0.375rem 0.75rem;
      outline: none;
    }
  }
}
.react-datepicker__month-wrapper {
  .react-datepicker__month-text {
    color: $whiteO8;
    &:hover {
      background-color: rgba(97, 209, 128, 0.16);
      color: $green;
    }
  }
}
.react-datepicker-popper {
  .react-datepicker {
    background-color: $grayTint1;
    border-color: $grayTint8;
    .react-datepicker__triangle {
      &::after {
        border-bottom-color: $grayTint1 !important;
      }
    }
    .react-datepicker__header {
      background-color: $grayTint1;
      border-color: $grayTint8;
      color: $white;
      > div {
        justify-content: space-between !important;
        color: $whiteO8;
        button {
          border: 1px solid rgba(97, 209, 128, 0.16);
          background-color: $grayTint6;
          border-radius: 5px;
        }
        select {
          border: 1px solid rgba(97, 209, 128, 0.16);
          background-color: $grayTint6;
          border-radius: 5px;
          outline: $none;
          cursor: pointer;
        }
      }
      .react-datepicker__day-names {
        .react-datepicker__day-name {
          color: $whiteO8;
        }
      }
    }
    .react-datepicker__month {
      .react-datepicker__day {
        color: $whiteO8;
        &.react-datepicker__day--selected {
          background-color: $grayTint6;
        }
        &:hover {
          background-color: $grayTint6;
        }
      }
    }
    .react-datepicker__year-dropdown {
      background-color: $grayTint1;
      border-color: $grayTint8;
      overflow-x: hidden;
      height: 170px;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(97, 209, 128, 0.2);
      }
      .react-datepicker__year-option {
        .react-datepicker__navigation {
          display: none !important;
        }
      }
    }
  }
}
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  left: -20px !important;
}
.Toastify__toast {
  background-color: #242c32 !important;
  border-radius: 8px !important;
  padding: 0 !important;
  > div {
    font-size: 20px;
    color: #fff;
  }
  .toastHead {
    font-size: 17px;
    font-weight: $semibold;
    font-family: "Poppins", sans-serif;
    margin: 10px 0 5px 0;
  }
  .toastText {
    font-size: 13px;
    color: #c8c5c5;
    font-family: "Poppins", sans-serif;
    margin-bottom: 0;
    padding-bottom: 5px;
  }
  .Toastify__close-button,
  .Toastify__toast-icon {
    display: $none;
  }
  .toastOuter {
    display: $flex;
    gap: 15px;
    align-items: $center;
    .toastImg {
      // padding-top: 5px;
    }
  }
  // .Toastify__toast {
  //   padding: 0;
  // }
  // &--success {
  //   background-color: $green !important;
  //   border: 1px solid $green;
  //   svg {
  //     path {
  //       fill: $white;
  //     }
  //   }
  //   > div {
  //     color: $white;
  //     font-weight: $semibold;
  //   }
  // }
  &--error {
    // background-color: $red !important;
    // border: 1px solid $red;
    .Toastify__toast-body {
      // box-shadow: 0 0 10px rgb(240, 66, 72, 0.2);
      background-image: url(../imgs/redShadow.svg);
      background-repeat: no-repeat;
    }
    svg {
      // box-shadow: 0 0 10px rgb(240, 66, 72, 0.2);
      // path {
      //   fill: $white;
      // }
    }
    // > div {
    //   color: $white;
    //   font-weight: $semibold;
    // }
  }
  &--success {
    .Toastify__toast-body {
      background-image: url(../imgs/greenShandow.svg);
      background-repeat: no-repeat;
    }
  }
  &--warning {
    .Toastify__toast-body {
      background-image: url(../imgs/toastWarningYellow.svg);
      background-repeat: no-repeat;
    }
  }
}
.pac-container {
  z-index: 1111;
  background-color: $grayTint3;
  border: none;
  .pac-item {
    color: $white;
    border-color: rgba(97, 209, 128, 0.16);
    .pac-item-query {
      color: $white;
    }
    &:hover {
      background-color: $dark;
    }
  }
}
.errorListingRed {
  padding: 0;
  margin: -10px 0 15px 0;
  li {
    list-style-type: none;
    color: $reddish3;
    display: $flex;
    align-items: $center;
    font-size: 15px;
    font-weight: $regular;
    gap: 5px;
    img {
      width: 100%;
      max-width: 18px;
    }
  }
}

// common css ends

// welcome starts
// .bg-slide-accent {
//   position: fixed;
//   background-color: $grayTint3;
//   background-image: url(../imgs/patterenImg.svg);
//   width: 0;
//   height: 100vh;
//   z-index: 10;
//   left: 0;
//   animation: bgslideLeft 2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
//   @include ipad {
//     height: 100%;
//   }
// }

// .bg-slide {
//   position: fixed;
//   background-color: $grayTint3;
//   background-image: url(../imgs/patterenImg.svg);
//   width: 0;
//   height: 100vh;
//   z-index: 11;
//   right: 0;
//   animation: bgslideRight 2s cubic-bezier(0.785, 0.135, 0.15, 0.86);
//   @include ipad {
//     height: 100%;
//   }
// }
.welcome {
  text-align: $center;
  height: 100vh;
  display: $flex;
  align-items: $center;
  justify-content: $center;
  position: $relative;
  // animation: fadeIn ease 0.2s;
  // -webkit-animation: fadeIn ease 0.2s;
  // -moz-animation: fadeIn ease 0.2s;
  // -o-animation: fadeIn ease 0.2s;
  // -ms-animation: fadeIn ease 0.2s;
  @include ipad {
    overflow: hidden;
  }
  &::after {
    position: $absolute;
    content: " ";
    width: 100%;
    height: 100%;
    background-image: url(../imgs/welcomePatteren.png);
    background-size: 100%;
    background-position: $center;
    background-repeat: no-repeat;
    // -webkit-animation: slidein 70s;
    // animation: slidein 70s;
    // -webkit-animation-fill-mode: forwards;
    // animation-fill-mode: forwards;
    // -webkit-animation-iteration-count: infinite;
    // animation-iteration-count: infinite;
    // -webkit-animation-direction: alternate;
    // animation-direction: alternate;
    @include ipad {
      background-size: cover;
      // animation: zoom-in-zoom-out 30s ease-out infinite;
    }
  }
  &__Logo {
    position: relative;
    z-index: 11;
    img {
      width: 100%;
      max-width: 25%;
      @include ipad {
        max-width: 60%;
      }
      @include mobile {
        max-width: 80%;
      }
    }
  }
}
// welcome ends

// landing page starts
.landingOuter {
  background-image: url(../imgs/landingMainBg.png);
  background-size: cover;
  @include mobile {
    background-size: 100%;
  }
}
.heroSection {
  padding: 60px 60px 100px;
  // background-image: url(../imgs/heroPatteren.svg);
  // background-image: url(../imgs/jobOfferBg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  -webkit-animation: moving 70s infinite;
  animation: moving 70s infinite;
  @include laptop {
    padding: 60px 60px 60px;
  }
  @include ipad {
    padding: 40px 60px 40px;
  }
  @include mobile {
    padding: 40px 20px;
  }
  &__Content {
    .capsule {
      color: $whiteO7;
      border-radius: 100px;
      border: 1px solid #fafafa;
      background: rgba(29, 29, 29, 0);
      padding: 7px 20px;
      display: $inline-block;
      font-weight: $regular;
      min-width: 220px;
      text-align: center;
      font-size: 20px;

      @include laptop {
        font-size: 18px;
        min-width: 190px;
      }
      @include mobile {
        font-size: 16px;
        min-width: 170px;
      }
    }
    h1 {
      // font-size: 90px;
      // margin: 80px 0;
      // background: linear-gradient(180deg, #61D180 0%, rgba(255, 255, 255, 0.70) 100%);
      // -webkit-background-clip: text;
      // -webkit-text-stroke: 4px transparent;
      // color: $dark;
      color: $grayTint11;
      margin: 40px 0px;
      font-size: 112px;
      font-weight: 700;
      @include desktop {
        font-size: 80px;
        margin: 60px 0px;
      }
      @include medium-desktop {
        font-size: 70px;
      }
      @include laptop {
        font-size: 60px;
      }
      @include small-laptop {
        font-size: 50px;
      }
      @include mobile {
        font-size: 27px;
        line-height: 37px;
        margin: 40px 0 30px;
      }
      br {
        @include mobile {
          display: none;
        }
      }
    }
    p {
      // font-size: 40px;
      // font-weight: $semibold;
      // background: linear-gradient(180deg, #61D180 0%, rgba(255, 255, 255, 0.70) 100%);
      // background-clip: text;
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      color: rgba(97, 209, 128, 0.7);

      font-size: 28px;

      font-weight: 500;

      @include desktop {
        font-size: 30px;
      }
      @include laptop {
        font-size: 27px;
      }
      @include small-laptop {
        font-size: 25px;
      }
      @include mobile {
        font-size: 15px;
      }
      br {
        @include mobile {
          display: none;
        }
      }
    }
    small {
      font-size: 14px;
      font-weight: $semibold;
      color: rgba(97, 209, 128, 0.2);
    }
    button {
      background: transparent;
      border: 0;
      .mouseImg {
        width: 100%;
        max-width: 150px;
        cursor: pointer;
        -webkit-animation: mover 1s infinite alternate;
        animation: mover 1s infinite alternate;
      }
    }
  }
}

.noJobSec {
  // padding: 120px 0 0;
  // background-image: url(../imgs/jobOfferBg.svg);
  // background-color: $dark;
  background-size: cover;
  background-repeat: no-repeat;
  // position: $absolute;
  // width: 100%;
  // height: 100%;
  top: 50%;
  @include ipad {
    padding: 40px 0 70px;
  }
  @include mobile {
    padding: 40px 0 80px;
  }
  &__Content {
    text-align: $center;
    h2 {
      font-size: 50px;
      font-weight: $semibold;
      @include medium-desktop {
        font-size: 40px;
      }
      @include mobile {
        font-size: 30px;
      }
    }
    ul {
      margin: 50px 0;
      // @include mobile {
      //   text-align: $left;
      // }
      li {
        position: $relative;
        color: $white;
        font-size: 20px;
        list-style-type: none;
        display: $inline-block;
        margin-right: 50px;
        @include laptop {
          font-size: 18px;
        }
        @include mobile {
          margin: 0 0 10px 0;
          display: $block;
        }
        img {
          width: 100%;
          max-width: 20px;
          margin-right: 5px;
        }
        // &::after {
        //   position: $absolute;
        //   content: " ";
        //   background-image: url(../imgs/tickGreendark.svg);
        //   width: 20px;
        //   height: 20px;
        //   background-size: 20px;
        //   background-repeat: no-repeat;
        //   left: -25px;
        //   top: 5px;
        // }
        // &:first-child {
        //   &::after {
        //     @include mobile {
        //       left: 0;
        //     }
        //   }
        // }
        // &:nth-child(2) {
        //   &::after {
        //     @include mobile {
        //       left: 45px;
        //     }
        //   }
        // }
        // &:last-child {
        //   &::after {
        //     @include mobile {
        //       left: 20px;
        //     }
        //   }
        // }
      }
    }
    p {
      color: $white;
      font-size: 22px;
      @include laptop {
        font-size: 18px;
      }
      @include mobile {
        font-size: 16px;
      }
      br {
        @include mobile {
          display: $none;
        }
      }
      // @include small-laptop {
      //   font-size: 18px;
      // }
    }
    a {
      display: $inline-block;
      margin-top: 50px;
      width: 500px;
      border: 1px solid $green;
      font-weight: $regular;
      color: $green;
      font-size: 22px;
      text-decoration: $none;
      padding: 15px 0;
      transition: all 0.6s ease-in-out;
      @include laptop {
        font-size: 18px;
        width: 400px;
      }
      @include mobile {
        width: 70%;
      }
      &:hover {
        // background-color: $green;
        color: $white;
        transform: scale(1.01);
        box-shadow: inset 600px 0 0 0 $green;
      }
    }
  }
}

.laptop {
  // background-image: url(../imgs/laptopBg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  // padding-bottom: 100px;
  position: $relative;
  // max-height: 800px;
  // min-height: 650px;
  // margin-top: -50px;
  // @include mobile {
  //   min-height: 350px;
  // }
  // @include fold {
  //   margin-top: 0;
  //   min-height: 260px;
  // }
  &__Img {
    margin-top: 50px;
    // position: $absolute;
    // top: -700px;
    // transform: translate(-50%, 0%) translate3d(0px, 707.107px, -707.107px) rotateX(-45deg);
    // opacity: 0;
    // transition: transform 1s ease-in-out, opacity 1s ease-in-out;
    z-index: 5;
    // position: $absolute;
    position: $relative;
    left: 50%;
    opacity: 0;
    overflow: hidden;
    will-change: transform;
    display: grid;
    place-items: center;
    width: 1000px;
    // width: 1228px;
    min-height: 0px;
    max-height: 800px;
    // padding: 10px;
    transform: rotateX(-45deg) translate(-50%, 0px);
    // border-radius: 14px;
    // border: 1px solid rgba(193, 197, 208, 0.35);
    // background: rgba(255, 255, 255, 0.4);
    // box-shadow: rgba(0, 0, 0, 0.03) 0px 24px 24px 0px;
    // backdrop-filter: blur(15px);
    @include ipad {
      width: 650px;
      margin-top: 0;
    }
    @include mobile {
      width: 350px;
    }
    // @include mobile {
    //   width: 260px;
    // }
    img {
      width: 100%;
    }
  }
}

.features {
  // background-image: url(../imgs/featuresBg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 0 0 100px;
  .sectionHeading {
    text-align: $center;
    margin-bottom: 80px;
    h4 {
      font-size: 30px;
      margin-bottom: 20px;
      @include mobile {
        font-size: 22px;
      }
    }
    h2 {
      font-size: 48px;
      font-weight: $semibold;
      @include medium-desktop {
        font-size: 40px;
      }
      @include mobile {
        font-size: 30px;
      }
    }
  }
  &__Content {
    border: 1px solid rgba(97, 209, 128, 0.16);
    background: $dark;
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    padding: 30px 30px;
    height: 330px;
    transition: all 0.3s ease-in-out;
    * {
      transition: all 0.3s ease-in-out;
    }
    @include laptop {
      height: 400px;
    }
    @include small-laptop {
      margin-bottom: 20px;
      height: 400px;
    }
    @include mobile {
      height: auto;
    }
    img {
      margin-bottom: 35px;
      transition: all 0.5s ease-in-out;
    }
    h5 {
      color: $white;
      font-size: 20px;
      margin-bottom: 20px;
    }
    p {
      color: $whiteO7;
      margin-bottom: 0;
      transition: all 0.5s ease-in-out;
    }
    &:hover {
      box-shadow: 0px 0px 70px 0px rgba(97, 209, 128, 0.1);
      h5,
      p {
        transform: translate(0, -10px);
      }
      img {
        transform: rotateY(360deg);
      }
    }
  }
  &__Marque {
    margin-top: 100px;
    text-align: $center;
    h4 {
      font-size: 30px;
      margin-bottom: 50px;
      @include mobile {
        font-size: 24px;
      }
    }
    .marqueeImgs {
      span {
        display: $inline-block;
        min-width: 150px;
        max-width: 300px;
        margin: 0 50px;
        cursor: pointer;
        @include mobile {
          margin: 0 20px;
        }
        img {
          width: 170px;
          height: 50px;
          object-fit: contain;
          opacity: 0.4;
        }
      }
    }
  }
}

.messaging {
  // background-image: url(../imgs/featuresBg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  &__Content {
    background-color: $grayTint5;
    padding: 50px;

    h3 {
      font-size: 40px;
      font-weight: $light;
      text-align: center;
      margin-bottom: 40px;
      @include laptop {
        font-size: 30px;
      }
      @include mobile {
        font-size: 18px;
      }
      @include fold {
        font-size: 14px;
      }
    }
    ul {
      padding: 0;
      margin: 0;
      display: $flex;
      justify-content: center;
      @include mobile {
        flex-direction: column;
      }
      li {
        list-style-type: none;
        position: $relative;
        color: $white;
        font-size: 20px;
        font-weight: $normal;
        margin: 0 50px 0 0;
        @include mobile {
          font-size: 16px;
          margin-bottom: 10px;
        }
        &:last-of-type {
          margin: 0;
        }
        &::before {
          position: $absolute;
          content: " ";
          width: 20px;
          height: 20px;
          background-image: url(../imgs/tickGreendark.svg);
          background-size: 20px;
          background-repeat: no-repeat;
          left: -25px;
          top: 5px;
          @include mobile {
            background-size: 18px;
          }
        }
      }
    }
    .messageCarousel {
      margin-bottom: 50px;
      .carousel {
        .carousel-item {
          img {
            width: 80%;
            margin: 0 auto;
            border: 2px solid $green;
            border-radius: 10px;
          }
        }
        .carousel-control-prev {
          width: 10%;
          opacity: 1;
          @include mobile {
            width: 15%;
            left: -30px;
          }
          .carousel-control-prev-icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-image: url(../imgs/arrowLeft.svg);
            background-size: 25px;
            background-color: $whiteO2;
            border: 1px solid rgba(255, 255, 255, 0.05);
            box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.2);
            transition: all 0.3s ease-in-out;
            @include mobile {
              background-size: 18px;
              width: 35px;
              height: 35px;
            }
            @include fold {
              width: 25px;
              height: 25px;
              background-size: 10px;
            }
            &:hover {
              background-color: $green;
              box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.3);
              background-image: url(../imgs/arrowLeftWhite.svg);
            }
          }
        }
        .carousel-control-next {
          width: 10%;
          opacity: 1;
          @include mobile {
            width: 15%;
            right: -30px;
          }
          .carousel-control-next-icon {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-image: url(../imgs/arrowRight.svg);
            background-size: 25px;
            background-color: $whiteO2;
            border: 1px solid rgba(255, 255, 255, 0.05);
            box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.2);
            transition: all 0.3s ease-in-out;
            @include mobile {
              background-size: 18px;
              width: 35px;
              height: 35px;
            }
            @include fold {
              width: 25px;
              height: 25px;
              background-size: 10px;
            }
            &:hover {
              background-color: $green;
              box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.3);
              background-image: url(../imgs/arrowRightWhite.svg);
            }
          }
        }
      }
    }
  }
}

.compatibility {
  // background-image: url(../imgs/capibilityBg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
  @include mobile {
    padding: 100px 0 50px;
  }
  &__Content {
    text-align: $center;
    p {
      font-size: 20px;
      color: $whiteTint1;
      font-weight: $light;
      @include laptop {
        font-size: 16px;
      }
    }
    a {
      display: $inline-block;
      margin-top: 20px;
      width: 500px;
      border: 1px solid $green;
      font-weight: 500;
      color: $green;
      font-size: 22px;
      text-decoration: $none;
      padding: 15px 0;
      transition: all 0.6s ease-in-out;
      @include laptop {
        font-size: 18px;
        width: 400px;
      }
      @include mobile {
        width: 70%;
      }
      &:hover {
        // background-color: $green;
        color: $white;
        box-shadow: inset 600px 0 0 0 $green;
      }
    }
    .capBottom {
      display: $flex;
      justify-content: $center;
      margin: 120px auto 0;
      align-items: $center;
      background-color: $grayTint3;
      border-radius: 110px 30px 30px 110px;
      width: 540px;
      padding: 0 40px 0 0;
      @include laptop {
        width: 455px;
      }
      @include mobile {
        width: 100%;
        flex-direction: column;
        border-radius: 30px 30px 30px 30px;
        padding: 30px 20px;
        margin-top: 170px;
      }
      .outer {
        width: 150px;
        height: 150px;
        background-color: $green;
        border-radius: 50%;
        display: flex;
        align-items: $center;
        justify-content: $center;
        border: 3px solid $white;
        margin-right: 30px;
        position: $relative;
        @include laptop {
          width: 130px;
          height: 130px;
        }
        @include mobile {
          position: relative;
          margin: -90px 0 20px 0;
        }
        &::after {
          position: $absolute;
          content: " ";
          width: 200px;
          height: 200px;
          border: 3px solid $green;
          border-radius: 50%;
          z-index: -1;
          animation: pulse-animation 2s infinite;
          @include laptop {
            width: 180px;
            height: 180px;
          }
          @keyframes pulse-animation {
            0% {
              box-shadow: 0 0 0 0px rgba(97, 209, 128, 0.2);
            }
            100% {
              box-shadow: 0 0 0 30px rgba(97, 209, 128, 0);
            }
          }
        }
        .inner {
          width: 100px;
          height: 100px;
          border-radius: 50%;
          display: $flex;
          align-items: $center;
          justify-content: $center;
          border: 3px solid $white;
          @include laptop {
            width: 80px;
            height: 80px;
          }
          img {
            width: 100%;
            max-width: 50px;
            @include laptop {
              max-width: 35px;
            }
          }
        }
      }
      p {
        color: $white;
        font-weight: $semibold;
        text-align: $left;
        margin: 0;
        span {
          color: $whiteO7;
        }
        @include mobile {
          text-align: $center;
        }
      }
    }
  }
}

.chooseSection {
  // background-image: url(../imgs/chooseBg.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 0 70px;
  @include mobile {
    padding: 50px 0 50px;
  }
  .sectionHeading {
    text-align: $center;
    h2 {
      font-size: 60px;
      margin-bottom: 30px;
      font-weight: $semibold;
      @include desktop {
        font-size: 50px;
      }
      @include medium-desktop {
        font-size: 40px;
      }
      @include mobile {
        font-size: 21px;
        font-weight: $semibold;
      }
    }
    p {
      font-size: 20px;
      margin-bottom: 60px;
      @include mobile {
        font-size: 14px;
      }
    }
  }
  &__Content {
    border-radius: 8px;
    border: 1px solid rgba(97, 209, 128, 0.16);
    background: $grayTint5;
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    padding: 30px;
    transition: all 0.3s ease-in-out;
    @include ipad {
      margin-bottom: 20px;
    }
    img {
      width: 100%;
      height: 250px;
      object-fit: cover;
      object-position: $center;
      transition: all 0.5s ease-in-out;
      @include ipad {
        height: auto;
      }
      &.icons {
        width: 80px;
        height: 80px;
        margin-bottom: 30px;
      }
    }
    h4 {
      transition: all 0.3s ease-in-out;
      @include small-laptop {
        font-size: 21px;
      }
    }
    p {
      font-size: 18px;
      color: $whiteO7;
      margin: 20px 0 50px 0;
      height: 100px;
      transition: all 0.5s ease-in-out;
      @include laptop {
        font-size: 16px;
      }
    }
    &:hover {
      box-shadow: 0px 0px 70px 0px rgba(97, 209, 128, 0.1);
      img.icons {
        transform: rotateY(360deg);
      }
      .bottomImg {
        transform: translate(0, -10px);
      }
      p,
      h4 {
        transform: translate(0, -10px);
      }
    }
  }
}

.waiting {
  // background-color: $grayTint4;
  padding: 0 0 60px;
  &__Content {
    text-align: $center;
    h3 {
      font-size: 60px;
      font-weight: $semibold;
      margin-bottom: 30px;
      @include desktop {
        font-size: 50px;
      }
      @include medium-desktop {
        font-size: 40px;
      }
      @include mobile {
        font-size: 30px;
      }
    }
    p {
      font-size: 18px;
      @include mobile {
        font-size: 16px;
      }
    }
    ul {
      padding: 0;
      margin: 30px 0 0 0;
      @include mobile {
        padding-left: 30px;
      }
      li {
        position: $relative;
        list-style-type: $none;
        display: $inline-block;
        margin: 0 100px 0 0;
        color: $white;
        text-align: $left;
        vertical-align: middle;
        font-size: 18px;
        @include mobile {
          display: block;
          font-size: 16px;
          margin-bottom: 10px;
        }
        &:last-of-type {
          margin: 0;
        }
        &::after {
          position: $absolute;
          content: " ";
          width: 20px;
          height: 20px;
          background-image: url(../imgs/tickGreendark.svg);
          background-size: 20px;
          background-repeat: no-repeat;
          top: 50%;
          left: -24px;
          transform: translate(-20%, -50%);
        }
        br {
          @include mobile {
            display: none;
          }
        }
      }
    }
    a {
      background: $commonGradient;
      color: $white !important;
      font-size: 18px;
      display: $inline-block;
      margin-top: 50px;
      padding: 13px 40px;
      border-radius: 100px;
      font-weight: $semibold;
      transition: all 0.3s ease-in-out;
      &:hover {
        transform: translateY(-5px);
      }
    }
  }
}

.newFooter {
  padding: 20px 100px;
  // background-color: $dark;
  @include desktop {
    padding: 20px 40px;
  }
  @include medium-desktop {
    padding: 20px 10px;
  }
  &__Content {
    @include ipad {
      text-align: $center;
    }
    ul {
      padding: 0;
      margin: 0;
      li {
        list-style-type: none;
        display: $inline-block;
        margin-right: 10px;
        a {
          color: $whiteO7;
          font-size: 16px;
          transition: all 0.3s ease-in-out;
          @include desktop {
            font-size: 14px;
          }
          &:hover {
            color: $green;
            text-shadow: 0 0 10px rgba(97, 209, 128, 0.6);
          }
          @include medium-desktop {
            font-size: 14px;
          }
        }
      }
      &.socialIcon {
        text-align: $right;
        @include ipad {
          text-align: $center;
        }
        li {
          a {
            display: $inline-block;
            transition: 0.2s ease-in-out;
            &:hover {
              transform: translateY(-5px);
            }
          }
        }
      }
    }
    p {
      text-align: $center;
      color: $whiteO7;
      margin: 0;
      @include ipad {
        margin: 10px 0;
      }
    }
  }
}

// landing page ends

.topHeader {
  background-color: $black;
  &__content {
    a {
      img {
        width: 100%;
        max-width: 150px;
      }
    }
  }
}
.topbarInner {
  background-color: $grayTint3;
  padding: 10px 15px;
  // position: fixed;
  // width: 100%;
  // z-index: 11;
  .topBarLogoSection {
    @include ipad {
      display: $flex;
      height: 100%;
      align-items: center;
    }
    .logo {
      width: 100%;
      max-width: 170px;
      margin-top: 10px;
      @include ipad {
        max-width: 130px;
        margin-top: 0;
      }
    }
  }
  .menuIcon {
    margin: 15px 10px 0 0;
    cursor: pointer;
    display: none;
    @include ipad {
      display: block;
      margin: 5px 20px 0 0;
    }
    @include mobile {
      margin-top: 5px;
    }
    span {
      width: 20px;
      height: 2px;
      display: $block;
      background-color: $white;
      margin: 5px 0;
      &:nth-child(2) {
        width: 15px;
      }
    }
  }
  .form-group {
    position: $relative;
    width: 100%;
    margin: 10px 0 0 20px;
    .form-control {
      height: 55px;
      color: $white;
      padding-left: 40px;
      // background-color: $grayTint1;
      // border: 1px solid rgba(97, 209, 128, 0.16);
      // box-shadow: none;
      // border-radius: 8px;
      // &::-webkit-input-placeholder {
      //   color: $whiteO2;
      // }
      // &::-moz-placeholder {
      //   color: $whiteO2;
      // }
    }
    span {
      position: $absolute;
      top: 15px;
      left: 10px;
      img {
        width: 100%;
        max-width: 25px;
      }
    }
  }
  &__Content {
    display: $flex;
    align-items: $center;
    justify-content: flex-end;
    gap: 10px;
    @include mobile-landscape {
      justify-content: flex-end;
      margin-top: 10px;
    }
    .dropdown {
      .dropdown-toggle {
        background-color: transparent;
        border: 0;
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          object-fit: cover;
          background-color: $white;
        }
        &::after {
          background-image: url(../imgs/chevron-down.svg);
          border: 0;
          background-size: 20px;
          width: 15px;
          height: 15px;
        }
      }
      .dropdown-menu {
        background-color: $grayTint3;
        border: 1px solid rgba(97, 209, 128, 0.16);
        padding: 0;
        overflow: hidden;
        box-shadow: 0px 0px 10px 0px rgba(97, 209, 128, 0.2);
        li {
          a {
            color: $white;
            font-size: 14px;
            border-bottom: 1px solid $whiteO2;
            padding: 10px;
            font-weight: $regular;
            img {
              width: 24px;
              height: 24px;
              border-radius: 50%;
              object-fit: cover;
              margin-right: 5px;
            }
            &:hover {
              background-color: $grayTint4;
            }
          }
          &:last-child {
            a {
              border-bottom: 0;
            }
          }
        }
      }
      &.userDropdown {
        .dropdown-toggle {
          display: $flex;
          text-align: left;
          gap: 10px;
          align-items: $center;
          position: $relative;
          box-shadow: none;
          img {
            width: 60px;
            height: 60px;
            border-radius: 8px;
            border: 1px solid $white;
            transition: all 0.3s ease-in-out;
            @include mobile {
              width: 45px;
              height: 45px;
            }
          }
          span {
            font-weight: $regular;
            transition: all 0.3s ease-in-out;
            @include ipad {
              font-size: 14px;
            }
            &.online {
              display: $inline-block;
              width: 14px;
              height: 14px;
              background-color: $green;
              border-radius: 50%;
              position: $absolute;
              bottom: 5px;
              left: 60px;
              @include mobile {
                width: 12px;
                height: 12px;
                bottom: 5px;
                left: 48px;
              }
            }
            span {
              font-size: 14px;
              color: rgba(255, 255, 255, 0.6);
              display: $block;
              @include ipad {
                font-size: 12px;
              }
            }
          }
          &:hover {
            img {
              border-color: $green;
              box-shadow: 0 0 20px rgba(97, 209, 128, 0.5);
            }
            span {
              color: $green;
              span {
                color: rgba(255, 255, 255, 0.6);
              }
            }
          }
        }
      }
    }
  }
}

.sidebar {
  background-color: $grayTint3;
  // border-right: 1px solid rgba(97, 209, 128, 0.16);
  width: 300px;
  height: 100%;
  position: $fixed;
  top: 0;
  left: 0;
  padding: 10px 15px;
  overflow: auto;
  transition: all 0.2s ease-in-out;
  @include ipad {
    transform: translate(-300px, 0);
  }
  &.toggle {
    @include ipad {
      transform: translate(0, 0);
      z-index: 1;
    }
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(97, 209, 128, 0.1);
  }
  .crossIcon {
    display: $none;
    position: $absolute;
    right: 10px;
    top: 10px;
    @include ipad {
      display: $inline-block;
    }
  }
  a {
    display: $block;
    text-align: $center;
    margin: 20px 0 40px;
    outline: none;
    img {
      width: 100%;
      max-width: 170px;
    }
  }
  &__Inner {
    ul {
      padding: 0;
      li {
        display: $block;
        list-style-type: none;
        a {
          text-decoration: none;
          color: $white;
          font-weight: $regular;
          text-align: $left;
          padding: 18px 15px;
          display: $flex;
          align-items: $center;
          border-radius: 8px;
          transition: all 0.6s ease-in-out;
          margin: 0 0 2px 0;
          img {
            width: 100%;
            max-width: 32px;
            margin-right: 20px;
          }
          &:hover {
            // background: $commonGradient;
            box-shadow: inset 600px 0 0 0 #61d180;
          }
        }
        &.disabled {
          opacity: 0.1;
          a {
            &:hover {
              background: none;
              color: $white;
              // cursor: not-allowed;
            }
          }
        }
        &.active {
          a {
            background: $commonGradient;
          }
        }
      }
    }
  }
  &__Progress {
    margin: 20px 0 40px;
    p {
      margin: 0 0 10px 0;
      color: $white;
      text-align: center;
      font-size: 18px;
      font-weight: $regular;
      &:last-child {
        color: $grayTint6;
        margin-top: 10px;
      }
    }
    .progress {
      height: 8px;
      .progress-bar {
        background-color: $green;
      }
    }
  }
  .upgradeSection {
    background: linear-gradient(172deg, #61d180 8.92%, #023020 110.84%);
    border-radius: 8px;
    text-align: center;
    padding: 15px;
    margin: 0 20px;
    img {
      width: 100%;
      max-width: 60%;
    }
    p {
      color: $white;
      font-weight: $light;
      margin: 15px 0;
      span {
        color: $yellow;
        font-weight: $semibold;
      }
    }
    .btn {
      width: 100%;
      background-color: $white;
      // color: $green;
      color: $dark;
      padding: 8px 10px;
      font-weight: $semibold;
    }
  }
  .sidebarEntries {
    border: 8px solid $green;
    display: $flex;
    align-items: $center;
    justify-content: $center;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 30px;
    p {
      color: $white;
      font-weight: $semibold;
      margin-bottom: 0;
      font-size: 20px;
    }
  }
  &.active {
    transform: translate(0);
    z-index: 1;
  }
  .close {
    width: 20px;
    height: 20px;
    background-image: url(../imgs/crossIcon.svg);
    background-size: 16px;
    background-repeat: no-repeat;
    position: absolute;
    right: 10px;
    top: 20px;
    filter: invert(1);
    display: none;
    @include ipad {
      display: $block;
    }
  }
}
.formSection {
  // background: url(../imgs/linePatteren.svg) $black;
  background-color: $dark;
  background-size: 90%;
  background-position: $center;
  background-repeat: no-repeat;
  padding: 50px 0;
  min-height: calc(100vh - 145px);
  &__Img {
    display: flex;
    height: 100%;
    align-items: $center;
    justify-content: $center;
    @include ipad {
      display: $none;
    }
    img {
      width: 100%;
      &.forgotImg {
        width: 80%;
      }
      @include small-laptop {
        width: 100%;
      }
    }
  }
  &__content {
    .logoImg {
      width: 100%;
      max-width: 120px;
    }
    form {
      border-radius: 8px;
      border: 1px solid $grayTint1;
      box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
      padding: 50px 50px;
      background-color: $grayTint3;
      @include mobile {
        padding: 30px 20px;
      }
      h2 {
        color: $white;
        font-size: clamp(1.562rem, 4vw, 2.95rem);
        font-weight: $light;
        text-align: $center;
        margin-bottom: 0;
        @include laptop {
          font-size: clamp(1.562rem, 4vw, 2rem);
        }
      }
      p {
        color: $whiteO7;
        text-align: $center;
      }
      .form-group {
        margin-bottom: 20px;
        position: $relative;
        .form-control {
          //   border-radius: 8px;
          //   border: 1px solid rgba(97, 209, 128, 0.16);
          //   background-color: $grayTint1;
          //   height: 50px;
          color: $whiteO7;
          padding-left: 55px;
          //   color: $white;
          //   box-shadow: $none;
          //   &::-webkit-input-placeholder {
          //     /* Chrome/Opera/Safari */
          //     color: $whiteO2;
          //   }
          //   &::-moz-placeholder {
          //     /* Firefox 19+ */
          //     color: $whiteO2;
          //   }
          // &:focus {
          //   color: $white;
          // }
        }
        .toggle-password {
          position: $absolute;
          top: 17px;
          right: 15px;
          cursor: pointer;
        }
        span {
          position: $absolute;
          color: $whiteO2;
          font-size: 18px;
          width: 40px;
          height: 50px;
          top: 0;
          left: 5px;
          padding: 0 10px;
          border-right: 1px solid rgba(74, 74, 74, 0.5);
          display: $flex;
          align-items: $center;
          justify-content: $center;
        }
      }
      .passwordRemember {
        display: $flex;
        justify-content: space-between;
        a {
          font-size: 14px;
          color: $green;
        }
        .form-check {
          display: flex;
          justify-content: $center;
          gap: 5px;
          .form-check-input {
            width: 14px;
            height: 14px;
            box-shadow: $none;
            &:checked {
              background-color: $green;
              border-color: $green;
            }
          }
          .form-check-label {
            color: $whiteO7;
            font-size: 14px;
            // margin-top: 3px;
          }
        }
      }
      .btn {
        // background-color: $green;
        background-image: $commonGradient;
        border: $none;
        color: $white;
        width: 100%;
        font-size: 18px;
        font-weight: $semibold;
        border-radius: 8px;
        margin-top: 30px;
        height: 50px;
        transition: all 0.2s ease-in-out;
        &:hover {
          box-shadow: 0 0 20px rgba(97, 209, 128, 0.2);
          transform: scale(1.02);
        }
      }
      p {
        color: $whiteO7;
        margin: 0 0 40px 0;
        font-weight: $normal;
        a {
          color: $green;
        }
      }
      .googleLogin {
        .btn {
          background-color: $reddish;
          background-image: $none;
          border: 1px solid $reddish;
          font-weight: $bold;
          border-radius: 8px;
          margin-top: 0;
          font-size: 16px;
          img {
            width: 100%;
            max-width: 20px;
          }
          &:active {
            color: $white;
          }
          &.accountGoogleBtn {
            display: $flex;
            justify-content: $center;
            align-items: $center;
            gap: 6px;
          }
        }
      }
      .divider {
        position: $relative;
        border-top: 1px solid rgba(61, 58, 39, 0.5);
        margin: 30px 0;
        p {
          position: $absolute;
          color: $whiteO7;
          background-color: $grayTint3;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          padding: 0 10px;
          @include mobile {
            width: 70%;
          }
        }
      }
      &.roleForm {
        padding: 40px 100px;
        @include mobile-landscape {
          padding: 40px 50px;
        }
      }
    }
    .roleSelector {
      display: $flex;
      gap: 20px;
      @include mobile {
        display: $block;
      }
      .btn {
        height: auto;
        background-color: $grayTint2;
        border: 1px solid $grayTint2;
        background-image: $none;
        padding: 30px;
        font-weight: $regular;
        color: $whiteO7;
        svg {
          path {
            fill: $whiteO7;
          }
        }
        &:hover {
          border-color: $green;
          color: $green;
          svg {
            path {
              fill: $green;
            }
          }
        }
        @include mobile {
          word-break: break-all;
        }
      }
    }
  }
  &.roleSelectorMain {
    min-height: 100vh;
    display: $flex;
    align-items: $center;
  }
  .accountInner {
    border-radius: 8px;
    border: 1px solid $grayTint1;
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    padding: 50px 50px;
    background-color: $grayTint3;
    h2 {
      color: $white;
      font-size: clamp(1.562rem, 4vw, 2.95rem);
      font-weight: $light;
      text-align: $center;
      margin-bottom: 0;
      @include laptop {
        font-size: clamp(1.562rem, 4vw, 2rem);
      }
    }
    p {
      color: $whiteO7;
      text-align: $center;
    }
    .googleLogin {
      .btn {
        background-color: $reddish;
        background-image: $none;
        border: 1px solid $reddish;
        font-weight: $bold;
        border-radius: 8px;
        margin-top: 20px;
        font-size: 16px;
        height: 50px;
        width: 100%;
        color: $white;
        transition: all 0.2s ease-in-out;
        img {
          width: 100%;
          max-width: 20px;
        }
        &:hover {
          box-shadow: 0 0 20px rgba(97, 209, 128, 0.2);
          transform: scale(1.02);
        }
      }
    }
    form {
      border: $none;
      box-shadow: $none;
      padding: 0;
    }
  }
}
.footer {
  background-color: $black;
  padding: 20px 0;
  &__Content {
    @include ipad {
      text-align: $center;
    }
    p {
      color: $whiteO7;
      margin: 20px 0 0 0;
    }
    ul {
      padding: 0;
      text-align: $right;
      margin: 0;
      @include ipad {
        text-align: $center;
        margin-top: 10px;
      }
      li {
        display: $inline-block;
        margin: 0 0 0 5px;
        a {
          display: $inline-flex;
          justify-content: $center;
          align-items: $center;
          width: 48px;
          height: 48px;
          border: 2px solid $grayTint1;
          color: $white;
          border-radius: 50%;
          font-size: 20px;
          background-image: url(../imgs/patterenImg.svg);
        }
      }
    }
  }
}
.innerFooter {
  background-color: $grayTint4;
  padding: 20px 10px;
  &__Content {
    @include ipad {
      text-align: $center;
    }
    .logoImg {
      width: 100%;
      max-width: 120px;
    }
    ul {
      padding: 0;
      margin: 10px 0 0 0;
      text-align: $center;
      @include ipad {
        margin: 10px 0 10px 0;
      }
      li {
        display: $inline-block;
        margin: 0 5px;
        a {
          color: $whiteO7;
          font-size: 18px;
          transition: all 0.2s ease-in-out;
          // font-weight: $regular;
          @include medium-desktop {
            font-size: 16px;
          }
          @include small-laptop {
            font-size: 14px;
          }
          &:hover {
            color: $green;
            text-shadow: 0 0 10px rgba(97, 209, 128, 0.6);
          }
          img {
            @include small-laptop {
              width: 100%;
              max-width: 30px;
            }
          }
        }
        &.disabled {
          a {
            opacity: 0.1;
            &:hover {
              color: rgba(255, 255, 255, 0.7);
              cursor: not-allowed;
            }
          }
        }
      }
      &.socialIcons {
        text-align: $right;
        @include ipad {
          text-align: $center;
        }
        li {
          a {
            display: $inline-block;
            border-radius: 50%;
            &:hover {
              box-shadow: 0 0 10px rgba(97, 209, 128, 0.6);
              transform: translateY(-5px);
            }
          }
        }
      }
    }
  }
}
.bottonFooter {
  background: $grayTint3;
  padding: 20px 0;
  p {
    color: $whiteO7;
    font-size: 18px;
    text-align: $center;
    margin: 0;
    font-weight: $regular;
  }
}

.mainWrapper {
  padding-left: 300px;
  @include ipad {
    padding-left: 0;
  }
}

.hirebirdplans {
  background-color: $dark;
  .plansmidsection {
    margin: 73px 0px;
    .planpricingsection {
      margin-top: 94px;
    }
    h1 {
      color: $white;
      text-align: $center;
      font-size: clamp(2.5rem, 3vw, 3.125rem);
      font-weight: $bold;
      margin: 0px auto;
      span {
        color: $green;
      }
    }
    > p {
      color: $whiteO7;
      text-align: $center;
      font-size: 18px;
      font-weight: $normal;
      margin: 22px auto;
      span {
        color: $whiteO7;

        font-size: 18px;

        font-weight: $bold;
      }
    }
    .plansmidleft {
      height: 100%;
      border: 1px solid #1d1d1d;
      background: #000;
      box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
      padding: 40px;
      min-height: 600px;
      transition: all 0.3s ease-in-out;
      &:hover {
        border-radius: 8px;
        border: 1px solid $green;
        background: #000;
        box-shadow: 0px 0px 70px 0px rgba(97, 209, 128, 0.4);
      }
      @include laptop {
        padding: 40px 30px;
      }
      @include large-ipad {
        padding: 40px 10px;
      }
      @include ipad {
        height: 680px;
        margin-bottom: 20px;
      }
      @include mobile-landscape {
        height: auto;
      }
      @include mobile {
        margin: 0px 0px 40px 0px;
      }
      .imgcontain {
        margin: 42px 0px;
      }
      .planlowerleft {
        h3 {
          font-size: 32px;
          font-weight: $semibold;
          text-align: $center;
          color: $green;
          margin-bottom: 30px;
          span {
            color: $white;
            font-size: 16px;
            font-weight: $light;
            margin-left: 10px;
          }
        }
        p {
          color: $green;
          font-size: 24px;
          font-weight: $semibold;
          text-align: $center;
          margin: 0 0 30px 0;
          span {
            color: rgba(255, 255, 255, 0.5);
            font-size: 18px;
            font-weight: $normal;
          }
        }
        a {
          display: block;
          color: $white;
          text-align: $center;
          font-size: 18px;
          margin: 0px auto;
          font-weight: $bold;
          background: $commonGradient;
          padding: 13px 0px;
          max-width: 253px;
          // margin-bottom: 35px;
        }
      }
      h4 {
        color: $white;
        font-size: 36px;
        font-weight: $bold;
        text-align: $center;
        @include laptop {
          font-size: 32px;
        }
        span {
          color: $white;
        }
        .green {
          color: $green;
        }
      }
      .planDetail {
        height: 340px;
        margin: 100px 0 0;
        @include mobile-landscape {
          height: auto;
          min-height: auto;
          margin: 70px 0;
        }
        p {
          color: $white;
        }
        ul {
          padding-left: 30px;
          li {
            margin-bottom: 15px;
            color: $whiteO7;
            position: $relative;
            list-style-type: none;
            &:last-of-type {
              margin-bottom: 0;
            }
            &::after {
              position: $absolute;
              content: " ";
              width: 18px;
              height: 18px;
              background-image: url(../imgs/greentick.svg);
              background-size: 18px;
              background-repeat: no-repeat;
              left: -25px;
              top: 5px;
            }
          }
        }
        &.candidateDetail {
          margin: 100px 0;
          h3 {
            color: $green;
            text-align: $center;
            font-weight: $semibold;
          }
          ul {
            margin-bottom: 0;
          }
          .col-xl-8 {
            border-left: 2px solid #3d3a27;
          }
        }
      }
      .planleftitems {
        display: flex;
        align-items: start;
        margin: 9px 0px;
        img {
          margin: 4px 8px 0px 8px;
        }
        p {
          color: $whiteO7;
          font-size: 16px;
          font-weight: $normal;
          margin-bottom: 0px;
        }
      }
      &.advancePlan {
        &:hover {
          box-shadow: 0px 0px 70px rgba(253, 197, 0, 0.42);
          border-color: $yellow;
        }
        h4 {
          .green {
            color: $yellow;
          }
        }
        ul {
          li {
            &::after {
              background-image: url(../imgs/yellowTick.svg);
            }
          }
        }
        h3 {
          color: $yellow;
        }
        .candidateDetail {
          h3 {
            color: $yellow;
          }
        }
      }
    }
  }
}

.hirebirdnavbar {
  background-color: $dark;
  ul {
    li {
      a {
        margin: 0px 18px;
        color: $white;
        font-size: 18px;
        font-weight: $normal;
        &:hover {
          color: $green;
        }
      }
    }
  }
}

// .foot {
//     div {
//         span {
//             color: rgba(255, 255, 255, 0.7);

//             font-size: 18px;

//             font-weight: 400;
//         }
//         a {
//             color: #fff;
//             font-size: 18px;
//             font-weight: 400;
//         }
//         .greenlink {
//             color: #61d180;
//             text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

//             font-size: 18px;

//             font-weight: 400;
//         }
//         .footericons {
//             padding: 11px 14px;
//             border: 1px solid grey;
//             border-radius: 100%;
//         }
//     }
//     .footerrow {
//         justify-content: end;
//         @include ipad {
//             justify-content: center;
//         }
//     }
//     .footerrowleft {
//         justify-content: left;
//         @include ipad {
//             justify-content: center;
//         }
//     }
//     .righticonscontainer {
//         text-align: right;
//         margin: 14px 0px;
//         @include ipad {
//             text-align: center;
//         }
//     }
//     .lefticonscontainer {
//         text-align: left;
//         margin: 14px 0px;
//         @include ipad {
//             text-align: center;
//         }
//     }
// }

.foot {
  display: $flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: $center;
  @include tablet {
    justify-content: $center;
  }
  @include mobile {
    display: $block;
    text-align: $center;
  }
  .footinside1 {
    display: $flex;
    flex-wrap: wrap;
    @include mobile {
      display: $block;
      text-align: $center;
    }
  }
  .footinside2 {
    display: $flex;
    flex-wrap: wrap;
    margin: 8px 0px;
    @include mobile {
      display: $block;
      text-align: $center;
      margin-top: 25px;
    }
  }
  span {
    color: $whiteO7;
    font-size: 18px;
    font-weight: $normal;
  }
  .footerlinks {
    color: $white;
    font-size: 18px;
    font-weight: $normal;
    margin: 0px 20px;
    @include mobile {
      display: $block;
      text-align: $center;
      margin: 10px 0px;
    }
  }
  .footicons {
    padding: 11px 16px;
    border: 1px solid grey;
    border-radius: 100%;
    color: $white;
    font-size: 18px;
    font-weight: $normal;
    margin: 0px 15px;
  }
}

.plansmid {
  @include mobile-landscape {
    margin-top: 20px !important;
  }
}

.plansmidrightothersection {
  @include ipad {
    margin-top: 42px !important;
  }
}

.successpasswordouter {
  width: 100%;
  height: 83vh;
  display: $flex;
  justify-content: $center;
  align-items: $center;
  .successpasswordsection {
    display: $flex;
    justify-content: $center;
    align-items: $center;
    text-align: $center;
    max-width: 738px;
    height: 504px;
    border-radius: 8px;
    border: 1px solid #1d1d1d;
    background: #000;
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    margin: auto;
    padding: 0 30px;
    .topImg {
      width: 100%;
      max-width: 100px;
    }
    h1 {
      color: $white;
      text-align: $center;
      font-size: clamp(2.5rem, 3vw, 3.125rem);
      font-weight: $light;
    }
    p {
      color: $whiteO7;
      text-align: $center;
      margin: 20px auto;
      font-size: 18px;

      font-weight: $normal;
      max-width: 532px;
    }
    a {
      color: $white;

      font-size: 18px;

      font-weight: $bold;
      display: $block;
      border-radius: 8px;
      background: $green;
      max-width: 494px;
      padding: 13px 0px;
      text-align: $center;
      margin: 20px auto;
    }
  }
}
.candidateOuter {
  .outerHeading {
    color: $white;
    font-size: 30px;
    font-weight: $semibold;
    margin: 20px 0;
  }
}
.sectionInvite {
  background-image: url(../imgs/inviteBg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 20px;
  margin-top: 40px;
  &__Content {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    h4 {
      font-size: 40px;
      color: $white;
      margin-bottom: 15px;
      font-weight: $light;
      @include laptop {
        font-size: 36px;
      }
      @include large-ipad {
        font-size: 30px;
      }
      @include mobile {
        font-size: 20px;
      }
      span {
        color: $yellow;
        font-weight: $bold;
      }
      br {
        @include desktop2 {
          display: none;
        }
        @include medium-desktop {
          display: none;
        }
      }
    }
    .btn {
      background-color: $darkGreen;
      color: $green;
      font-weight: $semibold;
      padding: 12px 15px;
      -webkit-transition: ease-out 0.4s;
      -moz-transition: ease-out 0.4s;
      transition: ease-out 0.4s;
      &:hover {
        // background-color: $green;
        color: $white;
        box-shadow: 0 10px 20px rgba(97, 209, 128, 0.2);
        // box-shadow: inset 400px 0 0 0 $green;
        // border-color: $green;
      }
      @include mobile {
        padding: 6px 15px;
      }
    }
  }
  &__Img {
    text-align: $center;
    img {
      width: 100%;
      max-width: 330px;
      @include mobile {
        max-width: 180px;
        margin-top: 15px;
      }
    }
  }
}
.candidateuser {
  // display: $flex;
  // justify-content: space-between;
  // flex-wrap: wrap;
  // align-items: $center;
  margin: 94px 0px;
  background-color: $grayTint3;
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  padding: 30px 30px;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  .candidateuserleft {
    display: $flex;
    align-items: flex-start;
    @include mobile {
      flex-wrap: wrap;
    }
    .candidateImg {
      img {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        object-fit: cover;
        object-position: $center;
        -o-object-position: $center;
        @include mobile {
          width: 80px;
          height: 80px;
          margin-right: 10px;
        }
      }
    }
    .candidateuserleft2 {
      margin-left: 18px;
      @include ipad {
        margin-left: 0px;
        margin-top: 20px;
      }
      div {
        display: $flex;
        align-items: center;
        flex-wrap: wrap;
      }
      // p {
      //     max-width: 603px;
      // }
    }
    h2 {
      color: $white;
      font-size: clamp(2rem, 3vw, 2.25rem);
      font-weight: $bold;
      @include mobile {
        font-size: 22px;
      }
      span {
        color: $green;
        font-size: 14px;
        font-weight: $light;
        margin-left: 8px;
        @include medium-desktop {
          display: $block;
        }
        @include mobile {
          margin-left: 0;
        }
      }
    }
    h4 {
      color: $green;
      font-size: 14px;
      font-weight: $light;
      margin-left: 8px;
      @include laptop {
        margin-left: 0;
      }
      img {
        // margin-bottom: 10px;
      }
      @include mobile {
        margin-left: 0px;
      }
    }
    p {
      color: $whiteO7;
      font-size: 14px;
      font-weight: $normal;
      span {
        font-weight: $semibold;
      }
    }
  }
  .candidateuserright {
    .btn {
      background: $commonGradient;
      color: $white;
      border: 0;
      border-radius: 8px;
      padding: 15px 30px;
      font-weight: $semibold;
      font-size: 18px;
      @include laptop {
        padding: 15px 10px;
      }
      @include ipad {
        margin-top: 20px;
      }
    }
  }
  .profileCompletion {
    text-align: $right;
    position: $relative;
    @include mobile-landscape {
      text-align: $center;
    }
    .CircularProgressbar {
      width: 137px;
      height: 137px;
      @include mobile {
        width: 117px;
        height: 117px;
        margin-top: 20px;
      }
      .CircularProgressbar-path {
        stroke: $green;
      }
      .CircularProgressbar-trail {
        stroke: rgba(97, 209, 128, 0.1);
      }
      .CircularProgressbar-text {
        fill: $green;
        font-size: 15px;
        font-weight: 700;
        transform: translate(0, -3px);
      }
    }
    p {
      color: $green;
      font-size: 15px;
      font-weight: 700;
      position: $absolute;
      top: 53%;
      right: 26px;
      @include small-laptop {
        right: 13px;
      }
      @include large-ipad {
        right: 30px;
      }
      @include ipad {
        right: 26px;
      }
      @include mobile-landscape {
        right: 50%;
        top: 60%;
        transform: translate(50%, 0);
        font-size: 13px;
      }
    }
  }
}
.jobsHead {
  display: $flex;
  justify-content: space-between;
  align-items: $center;
  button {
    background-color: transparent;
    border: 0;
    img {
      @include mobile {
        width: 100%;
        max-width: 30px;
      }
    }
  }
}

.candidatemidleft {
  // margin: 0px 10px;
  background-color: $grayTint3;
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  padding: 30px 30px;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  margin-bottom: 30px;
  @include mobile {
    padding: 30px 20px;
  }
  .jobActions {
    position: $absolute;
    right: 5px;
    top: 10px;
    @include ipad {
      right: 0px;
      top: 5px;
    }
    @include mobile {
      display: flex;
      gap: 5px;
      right: 5px;
    }
    button {
      background: transparent;
      border: none;
      @include mobile {
        padding: 0;
      }
      img {
        @include ipad {
          width: 100%;
          max-width: 30px;
        }
      }
    }
  }
  .candidatemidabout {
    margin: 0 0px;
    p {
      color: $whiteO7;
      font-size: 14px;
      font-weight: $normal;
    }
    .candidatemidabout1 {
      display: flex;
      align-items: $center;
      margin: 0 0 20px;
      h3 {
        color: $white;
        font-size: 30px;
        font-weight: $bold;
        display: $flex;
        align-items: $center;
        gap: 10px;
        @include medium-desktop {
          font-size: 26px;
        }
        @include mobile {
          font-size: 22px;
          align-items: flex-start;
        }
      }
    }
    .eduDetail {
      img {
        width: 100%;
        height: 95px;
        border-radius: 8px;
        object-fit: cover;
        object-position: $center;
        -o-object-position: $center;
        margin-bottom: 20px;
        @include mobile {
          height: 180px;
        }
      }
      .eduDyImg {
        display: $inline-block;
        @include mobile {
          display: $block;
        }
        img {
          width: 85px;
          height: 85px;
          @include ipad {
            width: 95px;
            height: 95px;
          }
          @include mobile {
            width: 100%;
            height: 150px;
          }
        }
      }
      h3 {
        color: $white;
        font-weight: $normal;
      }
      p {
        font-weight: $normal;
        &.experienceHead {
          font-size: 20px;
          margin-bottom: 30px;
          font-weight: $regular;
          color: $white;
        }
        &.colourGreen {
          color: $green;
          margin: 10px 0 !important;
        }
        &.description {
          font-size: 16px;
        }
      }
      .eduDyText {
        padding-left: 15px;
        @include desktop {
          padding-left: 30px;
        }
        @include medium-desktop {
          padding-left: 35px;
        }
        @include laptop {
          padding-left: 0;
        }
        @include large-ipad {
          padding-left: 35px;
        }
        @include ipad {
          padding-left: 0;
        }
        h3 {
          font-weight: $regular;
        }
      }
    }
    .divider {
      border: 1px solid rgba(97, 209, 128, 0.1);
      margin: 30px 0;
    }
    .progressOuter {
      margin-bottom: 15px;
      p {
        display: $flex;
        justify-content: space-between;
        font-size: 18px;
        margin-bottom: 10px;
      }
      .progress {
        height: 3px;
        .progress-bar {
          background-color: $green;
        }
      }
    }
    .tags {
      word-break: break-all;
      span {
        display: $inline-block;
        border-radius: 8px;
        background: rgba(97, 209, 128, 0.1);
        padding: 5px 10px;
        color: $green;
        margin: 0 5px 5px 0;
      }
    }
  }
  .candidatereviews {
    h3 {
      color: $white;
      display: $flex;
      align-items: $center;
      gap: 10px;
      margin-bottom: 30px;
      font-weight: $bold;
      @include mobile {
        align-items: flex-start;
      }
    }
    .reviewOuter {
      display: flex;
      align-items: flex-start;
      gap: 10px;
      @include mobile {
        align-items: center;
      }
      .reviewImg {
        img {
          width: 50px;
          height: 50px;
          border-radius: 50%;
          border: 2px solid $white;
          object-fit: cover;
          object-position: $center;
          -o-object-position: $center;
        }
      }
      .reviewName {
        h4 {
          color: $white;
          font-size: 20px;
          span {
            font-weight: $normal;
            font-size: 14px;
            color: $whiteO7;
          }
        }
      }
    }
    .rating {
      text-align: $right;
    }
    .reviewContent {
      margin-top: 25px;
      position: $relative;
      p {
        color: $whiteO7;
      }
    }
    .divider {
      border: 1px solid rgba(97, 209, 128, 0.1);
      margin: 30px 0;
    }
    .addTestimonialBtn {
      position: $absolute;
      top: 30px;
      right: 15px;
      @include mobile {
        position: $static;
        margin-bottom: 30px;
        text-align: right;
      }
      .btn {
        background: $none;
        border: $none;
        padding: 0;
        margin-left: 5px;
        img {
          @include mobile {
            width: 100%;
            max-width: 30px;
          }
        }
      }
    }
    .editTestimonialBtn {
      position: $absolute;
      background: $none;
      border: $none;
      top: -45px;
      right: -15px;
      @include large-ipad {
        top: 0;
      }
      @include mobile {
        top: -25px;
      }
      img {
        @include mobile {
          width: 100%;
          max-width: 25px;
        }
      }
    }
  }
  .detailText {
    * {
      color: $whiteO7;
    }
    ul,
    ol {
      margin-bottom: 30px;
      li {
        color: $whiteO7;
        margin-bottom: 10px;
        list-style-type: none;
        position: relative;
        font-weight: 500;
        &::after {
          position: absolute;
          content: " ";
          width: 20px;
          height: 20px;
          background-image: url(../imgs/tickGreendark.svg);
          background-size: 18px;
          background-repeat: no-repeat;
          left: -30px;
          top: 3px;
        }
      }
    }
  }
  &.experienceSection {
    .candidatemidabout {
      .candidatemidabout1 {
        margin-bottom: 0;
        h3 {
          margin: 0;
        }
        .actionBtns {
          @include mobile {
            top: 20px !important;
          }
        }
      }
      .divider {
        &:last-child {
          display: none;
        }
      }
      .eduDetail {
        margin-top: 20px;
        p {
          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
  }
  &.customPromptSection {
    .candidatemidabout {
      .candidatemidabout1 {
        margin-bottom: 0;
        h3 {
          margin: 0;
        }
      }
      .eduDetail {
        margin: 20px 0 0 0;
        .promtHead {
          font-size: 20px;
          font-weight: $semibold;
          color: $green;
        }
        p {
          margin: 0;
          &.promptAnswer {
            font-weight: $regular;
          }
        }
      }
    }
  }
  &.aboutSection {
    .candidatemidabout {
      .candidatemidabout1 {
        margin-bottom: 0;
        h3 {
          margin: 0;
        }
      }
      p {
        margin-top: 20px;
      }
    }
  }
  &.reviewsSection {
    .candidatereviews {
      .divider {
        &:last-child {
          display: $none;
        }
      }
      .reviewContent {
        p {
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &.empty {
    border: 1px solid rgba(97, 209, 128, 0.16);
    background: rgba(97, 209, 128, 0.1);
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  }
}
.setupProfile {
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: rgba(97, 209, 128, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 30px;
  display: $flex;
  justify-content: space-between;
  position: $relative;
  @include mobile {
    padding: 20px;
  }
  h3 {
    font-size: 30px;
    color: $white;
    margin-bottom: 0;
    display: $flex;
    align-items: $center;
    gap: 10px;
    font-weight: $bold;
    @include mobile {
      font-size: 20px;
    }
    @include mobile-small {
      font-size: 18px;
    }
  }
  button {
    background-color: transparent;
    border: 0;
    position: $absolute;
    right: 15px;
    top: 30px;
    @include mobile {
      top: 20px;
    }
    img {
      @include medium-desktop {
        max-width: 35px;
      }
      @include mobile {
        max-width: 30px;
      }
    }
  }
}
.candidateProfileRight {
  background-color: $grayTint3;
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  padding: 30px 30px;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  margin-bottom: 30px;
  .candidatemidright1 {
    h3 {
      display: flex;
      justify-content: space-between;
      color: $white;
      font-size: 24px;
      font-weight: $bold;
      border-bottom: 1px solid rgba(97, 209, 128, 0.16);
      padding: 0 0 20px 0;
      @include medium-desktop {
        font-size: 20px;
      }
      &.contactHeading {
        justify-content: flex-start;
        gap: 10px;
      }
      .fa {
        font-size: 18px;
        @include medium-desktop {
          font-size: 14px;
        }
      }
    }
  }
  .candidatemidright2 {
    p {
      color: $white;
      font-size: 20px;
      margin: 30px 0 0 0;
      span {
        color: $green;
        font-weight: $bold;
        padding-left: 30px;
        padding-top: 5px;
        display: $inline-block;
      }
      &.contactAddress {
        color: $whiteO7;
        font-size: 16px;
        $display: flex;
        align-items: flex-start;
      }
    }
    .sendmessage {
      background: $commonGradient;
      color: $white;
      padding: 10px 20px;
      font-weight: $semibold;
      display: $inline-block;
      border-radius: 8px;
      margin-top: 20px;
    }
    .input-group {
      gap: 10px;
      margin-top: 20px;
      .form-control {
        background-color: rgba(71, 72, 75, 0.5);
        border-radius: 8px !important;
        border-color: rgba(71, 72, 75, 0.5);
        box-shadow: none;
        color: $white;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $whiteO2;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: $whiteO2;
        }
      }
      .btn {
        background-color: $green;
        border-radius: 8px !important;
      }
    }
  }
}
.candidateFullImg {
  img {
    width: 100%;
    border-radius: 8px;
  }
}
.candidatemidright {
  // margin: 0px 15px;
  .candidatemidright1 {
    // margin: 24px 0px;
    // display: $flex;
    // align-items: $center;
    // justify-content: space-between;
    // flex-wrap: wrap;
    h3 {
      color: $white;
      font-size: 24px;
      font-weight: $bold;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(97, 209, 128, 0.16);
      padding: 0 0 20px 0;
      > div {
        span {
          font-size: 16px;
        }
      }
    }
  }
  .candidatemidright2 {
    // margin: 45px 0px;
    display: $flex;
    align-items: flex-start;
    div {
      margin: 0px 13px;
      p {
        color: $white;
        font-size: 20px;
        font-weight: $normal;
        margin-bottom: 0px;
      }
      span {
        color: $green;
        font-size: clamp(1rem, 4vw, 1.125rem);
        // font-weight: $bold;
        font-weight: $semibold;
        a {
          color: $green;
          word-break: break-all;
        }
      }
    }
    img {
      margin-top: 5px;
    }
  }
  .candidatemidright3 {
    h3 {
      color: $white;
      font-size: 24px;
      font-weight: $bold;
      border-bottom: 1px solid rgba(97, 209, 128, 0.16);
      padding: 0 0 20px 0;
    }
  }
  .candidatemidright4 {
    display: $flex;
    align-items: flex-start;
    // margin: 50px 0px;
    p {
      color: $whiteO7;
      font-size: 16px;
      font-weight: $normal;
      margin: 0px 13px;
    }
  }
  .sendmessage {
    display: $block;
    color: $white;
    font-size: 18px;
    font-weight: $bold;
    border-radius: 8px;
    background: $green;
    max-width: 251px;
    text-align: $center;
    padding: 17px 0px;
    margin: 74px 0px;
  }
  .maskimgcontainer {
    margin: 40px 0px;
    background-color: #f2f6fd;
    border-radius: 8px;
    background-image: url("../imgs/mask.png");
    background-position: $center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 460px;
    padding: 20px;
    img {
      border-radius: 8px;
    }
    h3 {
      color: #000;
      font-size: 30px;
      font-weight: $bold;
    }
    p {
      color: rgba(0, 0, 0, 0.5);
      font-size: 14px;
      font-weight: $normal;
    }
    a {
      display: $block;
      color: $white;
      font-size: 16px;
      font-weight: $bold;
      border-radius: 8px;
      background: $green;
      max-width: 118px;
      text-align: $center;
      padding: 14px 0px;
    }
  }
  .maskimg {
    margin: 40px 0px;
    border-radius: 8px;
  }
}
.companyHiring {
  background-image: url(../imgs/hiringBg.png);
  background-size: cover;
  background-position: $center;
  background-repeat: no-repeat;
  padding: 40px 20px;
  height: 400px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
  h3 {
    color: $white;
    font-size: 30px;
  }
  p {
    color: $white;
    font-weight: $regular;
  }
  a {
    background-color: $black;
    color: $white;
    font-weight: $semibold;
    padding: 10px 30px;
    display: $inline-block;
    border-radius: 8px;
  }
}

.fa {
  color: grey;
}
.checked {
  color: orange;
}

.jobssection {
  > p {
    color: $whiteO7;
    font-size: 16px;
    font-weight: $normal;
  }
  .jobssectiontop {
    display: $flex;
    align-items: $center;
    justify-content: space-between;
    flex-wrap: wrap;

    .jobssectiontop1 {
      display: $flex;
      align-items: $center;
      @include mobile {
        align-items: flex-start;
        flex-direction: column;
      }
      div {
        margin-left: 10px;
        // margin-top: 7px;
        @include mobile {
          margin: 10px 0 0 0;
        }
        h4,
        .postTime {
          @include mobile {
            margin: 0 !important;
          }
        }
        h4 {
          color: $white;
          font-size: 20px;
          font-weight: $bold;
          margin: 0px 13px;
          @include mobile {
            font-size: 15px;
          }
          span {
            font-weight: $normal;
            font-size: 14px;
            color: $green;
            margin-left: 10px;
            @include mobile {
              display: $block;
              margin-left: 0;
            }
            img {
              margin-right: 5px;
            }
          }
        }
        .postTime {
          color: $whiteO7;
          font-size: 14px;
          margin: 0 13px;
        }
        p {
          color: $whiteO7;
          font-size: 14px;
          font-weight: $normal;
          margin: 7px 13px;
        }
      }
      .jobImgs {
        width: 60px;
        height: 60px;
        border-radius: 8px;
        object-fit: cover;
        object-position: center;
      }
    }
    .jobssectiontop2 {
      display: $flex;
      align-items: $center;

      span {
        color: $green;
        font-size: 14px;
        font-weight: $bold;
        margin: 7px 20px;
      }
    }
  }
  .pricingplans {
    margin: 15px 0px;
    color: $green;
    font-size: 20px;
    font-weight: $bold;

    span {
      color: rgba(255, 255, 255, 0.5);
      font-size: 16px;
      font-weight: $normal;
    }
  }
  .jobssectionbottom {
    display: $flex;
    align-items: $center;
    justify-content: space-between;
    flex-wrap: wrap;
    .tags {
      display: $flex;
      align-items: $center;
      flex-wrap: wrap;
      gap: 10px;
      span {
        color: $green;
        font-size: 14px;
        font-weight: $normal;
        border-radius: 8px;
        background: rgba(97, 209, 128, 0.1);
        padding: 10px 20px;
      }
    }
    a {
      color: $white;
      font-size: 18px;
      font-weight: $bold;
      border-radius: 8px;
      background: $commonGradient;
      width: 186px;
      padding: 13px 0px;
      text-align: $center;
      @include ipad {
        margin-top: 16px;
      }
    }
  }
}

.cppagination {
  margin: 40px 0px;
  display: $flex;
  justify-content: $center;
  align-items: $center;
  flex-wrap: wrap;
  a {
    color: $green;
    display: $inline-flex;
    align-items: $center;
    justify-content: $center;
    width: 46px;
    height: 40px;
    background: rgba(97, 209, 128, 0.1);
    color: $green;
    margin-right: 10px;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    &:hover {
      background-color: $green;
      color: $white;
    }
    @include mobile {
      font-size: 12px;
      width: 36px;
      height: 35px;
    }
    &.paginationarrowright {
      margin-right: 0;
    }
    &.active {
      background-color: $green;
      color: $white;
      font-weight: $regular;
    }
  }
}

.jobListing {
  min-height: 100vh;
  background-color: $dark;
  padding: 30px 0 0;
  h3 {
    color: $white;
    font-size: 40px;
    font-weight: $regular;
    &.preferencesHead {
      font-size: 26px;
      .preferencesText {
        font-size: 17px;
        font-weight: $regular;
        color: $whiteO7;
      }
    }
  }
  &__form {
    form {
      border-radius: 8px;
      border: 1px solid $whiteO2;
      background: $grayTint5;
      box-shadow: 8px 8px 20px 0px rgba(113, 113, 113, 0.15);
      .form-group {
        display: $flex;
        position: $relative;
        label {
          color: $white;
          white-space: nowrap;
          padding: 20px 0 0 20px;
          color: $whiteO7;
          @include laptop {
            padding: 15px 0 0 20px;
          }
        }
        .form-control {
          height: 65px;
          background-color: transparent;
          border: $none;
          color: $white;
          box-shadow: none;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: $whiteO2;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: $whiteO2;
          }
          @include laptop {
            height: 55px;
          }
        }
        span {
          position: $absolute;
          cursor: pointer;
          top: 20px;
          right: 0;
          i {
            color: $whiteO2;
          }
          @include laptop {
            top: 15px;
          }
          @include ipad {
            right: 10px;
          }
        }
        &.first {
          &::after {
            position: $absolute;
            content: " ";
            width: 1px;
            height: 40px;
            background-color: rgba(255, 255, 255, 0.8);
            right: -22px;
            top: 14px;
            @include laptop {
              top: 9px;
            }
            @include ipad {
              display: none;
            }
          }
        }
      }
      .btn-search {
        background: $commonGradient;
        color: $white;
        font-weight: $semibold;
        border: 0;
        width: 100%;
        height: 65px;
        border-radius: 8px;
        @include laptop {
          height: 55px;
        }
      }
    }
    .filters {
      display: $flex;
      // justify-content: space-between;
      margin-top: 10px;
      gap: 5px;
      @include small-laptop {
        flex-wrap: wrap;
      }
      select {
        background-color: $whiteO2;
        color: $whiteO5;
        border-radius: 8px;
        font-size: 14px;
        padding: 5px 7px;
        border-color: transparent;
        outline: none;
        cursor: pointer;
      }
      .btn-clear {
        background-color: $green;
        color: $white;
        border-radius: 8px;
        border-color: $green;
        padding: 0px 10px;
      }
    }
  }
  .candidateTags {
    margin-top: 20px;
    span {
      color: $green;
      border-radius: 8px;
      background: rgba(97, 209, 128, 0.1);
      font-size: 14px;
      display: inline-block;
      padding: 5px 10px;
      margin-bottom: 8px;
      border: 1px solid rgba(97, 209, 128, 0.1);
      cursor: pointer;
      margin: 0 3px;
      &:hover {
        border-color: $green;
      }
      @include medium-desktop {
        padding: 5px 4px;
        font-size: 13px;
      }
    }
  }
  &__List {
    margin-top: 30px;
    h3 {
      color: $white;
      display: $flex;
      justify-content: space-between;
      margin-bottom: 40px;
      a {
        transition: all 0.3s ease-in-out;
        display: $inline-flex;
        width: 42px;
        height: 38px;
        border: 1px solid $green;
        align-items: $center;
        justify-content: center;
        border-radius: 4px;
        margin: 0 5px;
        svg {
          path {
            fill: $whiteO2;
          }
        }
        &.active,
        &:hover {
          background-color: $green;
          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }
  }
  &__Result {
    background-color: $grayTint3;
    border: 1px solid rgba(97, 209, 128, 0.16);
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    .resultImg {
      img {
        width: 100%;
        @include mobile {
          max-width: 80px;
          margin-bottom: 10px;
        }
      }
    }
    .resultText {
      h3 {
        color: $white;
        font-size: 24px;
        font-weight: $bold;
        display: $flex;
        justify-content: space-between;
        @include mobile {
          font-size: 19px;
        }
        span {
          display: $flex;
          align-items: $center;
          .dropdown {
            .dropdown-toggle {
              background-color: transparent;
              border: 0;
              padding: 0;
              &::after {
                display: none;
              }
            }
            .dropdown-menu {
              padding: 0;
              background-color: $grayTint5;
              li {
                margin: 0;
                a {
                  color: $white;
                  padding: 15px 15px;
                  border-bottom: 1px solid $whiteO2;
                  &:hover {
                    background-color: transparent;
                  }
                  @include mobile {
                    font-size: 14px;
                  }
                  img {
                    margin-right: 5px;
                  }
                }
                &:last-child {
                  a {
                    border: 0;
                  }
                }
              }
            }
          }
          img {
            &.premiumLogo {
              width: 100%;
              max-width: 50px;
              margin-left: 20px;
              @include mobile {
                max-width: 45px;
                margin-left: 0px;
              }
            }
          }
        }
      }
      p {
        color: $whiteO7;
        span {
          display: $inline-flex;
          align-items: center;
          margin-right: 10px;
          // opacity: 0.3;
          gap: 3px;
          img {
            width: 100%;
            max-width: 20px;
          }
        }
      }
      ul {
        padding-left: 20px;
        li {
          color: $whiteO7;
          margin-bottom: 10px;
        }
      }
      .hourlyRate {
        display: $flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: $center;
        margin-top: 40px;
        h4 {
          color: $green;
          font-size: 18px;
          margin: 0;
          span {
            color: $whiteO5;
            font-size: 14px;
          }
          @include mobile {
            margin-bottom: 10px;
          }
        }
        .btn {
          background: $commonGradient;
          color: $white;
          font-size: 18px;
          font-weight: $semibold;
          border: 0;
          padding: 10px 20px;
        }
      }
    }
    &.premium {
      background-color: none;
      background-image: url(../imgs/primium.png);
      border-color: $yellow;
      .resultText {
        .hourlyRate {
          h4 {
            color: $yellow;
          }
        }
      }
    }
  }
  &__Candidate {
    background-color: $grayTint3;
    border: 1px solid rgba(97, 209, 128, 0.5);
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    padding: 25px;
    border-radius: 8px;
    margin-bottom: 30px;
    .candidate__preference {
      display: $flex;
      gap: 5px;
      span {
        color: $green;
        border-radius: 8px;
        background-color: rgba(97, 209, 128, 0.16);
        display: $inline-flex;
        align-items: $center;
        padding: 5px 15px;
        font-size: 12px;
        min-height: 23px;
      }
    }
    .candidateAboutText {
      color: $whiteO7;
      margin-top: 20px;
      font-size: 14px;
      a {
        font-weight: $semibold;
        color: $whiteO7;
      }
    }
    .hourlyRate {
      display: $flex;
      height: 100%;
      align-items: $center;
      h4 {
        color: $green;
        font-size: 18px;
        margin: 0;
        span {
          color: $whiteO5;
          font-size: 14px;
        }
      }
    }
    .candidate__Btns {
      text-align: $right;
      a {
        border-radius: 8px;
        color: $white;
        padding: 7px 20px;
        display: $inline-block;
        font-size: 14px;
        &.viewProfile {
          background-color: $grayTint7;
          font-weight: $normal;
        }
        &.inviteBtn {
          font-weight: $semibold;
          background: $commonGradient;
          margin-left: 10px;
        }
        @include mobile {
          width: 100%;
          text-align: $center;
          margin: 10px 0 0 0 !important;
        }
      }
    }
    .candidateDetail {
      display: $flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: $center;
      img {
        width: 65px;
        height: 65px;
        object-fit: cover;
        border-radius: 8px;
        border: 1px solid $white;
      }
      h3 {
        color: $grayTint6;
        font-size: 28px;
        font-weight: $semibold;
      }
      .currentCompany {
        display: $flex;
        gap: 10px;
        img {
          width: 32px;
          height: 32px;
        }
        p {
          font-size: 10px;
          color: $white;
          span {
            color: $whiteO7;
            display: $block;
          }
        }
      }
    }
    .chartSection {
      text-align: $center;
      margin-bottom: 30px;
      position: $relative;
      @include mobile-landscape {
        // margin-bottom: 10px;
        text-align: $left;
      }
      @include mobile {
        text-align: $center;
      }
      svg {
        width: 120px;
        height: 120px;
        text {
          fill: $green;
          font-size: 5px;
          font-weight: $regular;
        }
      }
      .matchPercentage {
        position: $absolute;
        color: $green;
        text-align: $center;
        font-weight: $regular;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &.Candidategrid {
      .candidateDetail {
        img {
          @include laptop {
            width: 50px;
            height: 50px;
          }
        }
        h3 {
          margin: 0;
          @include laptop {
            font-size: 25px;
          }
          @include small-laptop {
            font-size: 23px;
          }
        }
      }
      .currentCompanyImg {
        height: 100%;
        display: flex;
        justify-content: end;
        align-items: center;
        img {
          width: 40px;
          height: 40px;
          border: 0;
          @include laptop {
            width: 32px;
            height: 32px;
          }
        }
        .dropdown {
          .dropdown-toggle {
            background-color: transparent;
            border: 0;
            padding: 0;
            img {
              width: 26px;
              height: 26px;
            }
            &::after {
              display: none;
            }
          }
          .dropdown-menu {
            padding: 0;
            background-color: $grayTint5;
            li {
              margin: 0;
              a {
                color: $white;
                padding: 15px 15px;
                border-bottom: 1px solid $whiteO2;
                &:hover {
                  background-color: transparent;
                }
                @include mobile {
                  font-size: 14px;
                }
                img {
                  height: auto;
                  width: 16px;
                  margin-right: 5px;
                }
              }
            }
          }
        }
      }
      .candidate__preference {
        margin: 20px 0;
      }
      .candidate__preference {
        span {
          @include laptop {
            padding: 5px 10px;
            font-size: 11px;
          }
        }
      }
      .aboutCandidate {
        font-size: 14px;
        color: $whiteO7;
        margin: 0 0 20px 0;
        a {
          font-weight: 600;
          color: $whiteO7;
        }
      }
      .chartSection {
        text-align: $left;
        margin: 0;
        @include mobile {
          text-align: $center;
        }
        svg {
          width: 90px;
          height: 90px;
          @include medium-desktop {
            width: 70px;
            height: 70px;
          }
          @include small-laptop {
            width: 60px;
            height: 60px;
          }
          text {
            font-size: 6px;
          }
        }
      }
      .candidate__Btns {
        height: 100%;
        display: $flex;
        align-items: $center;
        justify-content: end;
        @include mobile {
          display: $block;
        }
        a {
          padding: 14px 25px;
          @include desktop {
            padding: 12px 19px;
          }
          @include medium-desktop {
            padding: 10px 10px;
          }
          @include laptop {
            padding: 10px 7px;
            font-size: 13px;
          }
          @include small-laptop {
            padding: 10px 6px;
            font-size: 12px;
          }
        }
      }
      .resultPreference {
        color: $whiteO7;
        font-size: 14px;
        margin-top: 20px;
        span {
          display: $inline-flex;
          align-items: $center;
          margin-right: 30px;
          gap: 3px;
          @include medium-desktop {
            margin-right: 10px;
          }
        }
      }
      ul {
        padding-left: 20px;
        li {
          color: $whiteO7;
        }
      }
    }
  }
  .sectionActivate {
    background-color: $grayTint3;
    border: 1px solid rgba(97, 209, 128, 0.16);
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    border-radius: 8px;
    padding: 30px;
    h3 {
      color: $white;
      font-size: 18px;
    }
    .form-group {
      position: $relative;
      margin: 20px 0 30px 0;
      .form-control {
        background-color: $grayTint1;
        border-color: rgba(97, 209, 128, 0.16);
        box-shadow: none;
        height: 50px;
        color: $white;
        padding-left: 60px;
        &::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: $whiteO2;
        }
        &::-moz-placeholder {
          /* Firefox 19+ */
          color: $whiteO2;
        }
      }
      span {
        position: $absolute;
        top: 0;
        width: 50px;
        height: 50px;
        display: $inline-flex;
        justify-content: $center;
        align-items: $center;
        border-right: 1px solid rgba(67, 67, 67, 0.5);
      }
    }
    p {
      color: $whiteO7;
      font-size: 14px;
      font-weight: $regular;
      margin: 0;
    }
    .btn {
      background: $commonGradient;
      border: 0;
      color: $white;
      font-weight: $bold;
      padding: 10px 30px;
      margin-bottom: 20px;
    }
  }
  .searchableTags {
    margin-top: 30px;
    h3 {
      color: $white;
      font-size: 30px;
      font-weight: $bold;
      @include mobile {
        font-size: 24px;
      }
    }
    .tags {
      margin-top: 30px;
      span {
        color: $green;
        background: rgba(97, 209, 128, 0.1);
        font-size: 14px;
        padding: 7px 10px;
        display: $inline-block;
        margin: 0 5px 10px 0;
        border-radius: 8px;
      }
    }
  }
  .premiumCandidate {
    background-image: url(../imgs/premium-candidate.png);
    background-size: cover;
    border-color: $yellow;
    .candidateAvatar {
      position: $relative;
      span {
        position: $absolute;
        top: -10px;
        right: -10px;
        img {
          border: none;
          width: 100%;
          max-width: 23px;
          height: auto;
        }
      }
    }
    .chartSection {
      svg {
        path {
          stroke: $yellow;
        }
      }
      .matchPercentage {
        color: $yellow;
      }
    }
    .hourlyRate {
      h4 {
        color: $yellow;
      }
    }
  }
  &__noResult {
    text-align: $center;
    padding: 70px 0 0;
    img {
      width: 100%;
      max-width: 25%;
      margin-bottom: 20px;
    }
    h4 {
      color: $white;
      font-size: 36px;
    }
    p {
      color: $whiteO7;
      font-size: 18px;
    }
  }
  .jobDetails {
    background-color: $grayTint3;
    border: 1px solid rgba(97, 209, 128, 0.16);
    border-radius: 8px;
    padding: 30px 30px;
    margin-bottom: 30px;
    &__Header {
      display: $flex;
      gap: 15px;
      .jobImg {
        img {
          width: 80px;
          height: 80px;
          border-radius: 8px;
          object-fit: cover;
          object-position: $center;
          @include mobile {
            width: 50px;
            height: 50px;
          }
        }
      }
      .heading {
        h2 {
          color: $white;
          font-weight: $semibold;
          @include laptop {
            font-size: 30px;
          }
          @include mobile {
            font-size: 20px;
          }
        }
        h4 {
          color: $green;
          font-weight: $semibold;
          span {
            font-size: 14px;
            color: $whiteO5;
            font-weight: $light;
          }
        }
        p {
          color: $whiteO7;
          &.preference {
            font-weight: $light;
          }
          &:last-of-type {
            margin: 0;
          }
        }
      }
    }
    .jobBtns {
      text-align: $right;
      .btn {
        padding: 10px;
        border-radius: 8px;
        min-width: 180px;
        max-width: 180px;
        height: 50px;
        border: 0;
        margin: 0 5px;
        font-weight: $semibold;
        @include medium-desktop {
          min-width: 120px;
          max-width: 120px;
        }
        @include mobile {
          margin-bottom: 10px;
        }
        &.btn-cancel {
          background-color: $grayTint7;
          color: $whiteO3;
          max-width: none;
          min-width: auto;
        }
        &.btn-save {
          background: $commonGradient;
          color: $white;
        }
      }
    }
    hr {
      border-color: rgba(97, 209, 128, 0.16);
      box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    }
    .detailsContent {
      .detail {
        font-size: 30px;
      }
      p {
        font-weight: $light;
        color: $whiteO7;
      }
      h5 {
        font-size: 16px;
        font-weight: $light;
        color: $whiteO7;
        margin: 30px 0 15px 0;
      }
      ul {
        li {
          color: $whiteO7;
          margin-bottom: 10px;
          list-style-type: none;
          position: $relative;
          &::before {
            position: $absolute;
            content: " ";
            width: 20px;
            height: 20px;
            background-image: url(../imgs/greentick.svg);
            background-size: 15px;
            background-repeat: no-repeat;
            left: -25px;
            top: 5px;
          }
        }
      }
      .btns {
        .btn {
          border-radius: 8px;
          background: rgba(61, 61, 61, 0.5);
          color: $whiteO2;
          padding: 10px 15px;
          margin: 0 5px;
          font-size: 18px;
          img {
            max-width: 25px;
          }
        }
      }
    }
    &.premium {
      .jobDetailsInner {
        background-image: url(../imgs/premium-candidate.png);
        background-size: cover;
        background-repeat: no-repeat;
        padding: 20px 20px;
        border-radius: 8px;
        position: $relative;
        margin-bottom: 20px;
        .premiumIcon {
          position: $absolute;
          top: 20px;
          right: 20px;
          @include mobile {
            top: -10px;
            right: -10px;
          }
          img {
            @include mobile {
              width: 100%;
              max-width: 40px;
            }
          }
        }
        .jobDetails__Header {
          .heading {
            h2 {
              width: 80%;
              @include mobile {
                width: 100%;
              }
            }
          }
        }
      }
      .video {
        position: $relative;
        border-radius: 8px;
        overflow: hidden;
        .thumbnail {
          img {
            width: 100%;
            height: 350px;
            object-fit: cover;
            object-position: $center;
            @include mobile {
              height: 250px;
            }
          }
        }
        .playBtn {
          position: $absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          img {
            @include mobile {
              width: 100%;
              max-width: 60px;
            }
          }
        }
      }
    }
  }
}

// common modal starts
.commonModal {
  .modal-dialog {
    // max-width: 30%;
    @include medium-desktop {
      max-width: 40%;
    }
    @include ipad-landscape {
      max-width: 60%;
    }
    @include mobile-landscape {
      max-width: 90%;
      margin: 0 auto;
    }
    .modal-content {
      border-radius: 8px;
      overflow: hidden;
      border: 1px solid rgba(97, 209, 128, 0.16);
      box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.1);
      .modal-header {
        background-color: $grayTint3;
        border: 0;
        .btn-close {
          background-image: url(../imgs/crossBlack.svg);
          background-size: 20px;
          background-repeat: no-repeat;
          opacity: 1;
          box-shadow: none;
        }
      }
      .modal-body {
        background-color: $grayTint3;
        .commonModalContent {
          text-align: $center;
          padding: 0 30px;
          @include desktop {
            padding: 0 20px;
          }
          .ModalHeading {
            font-size: 30px;
            color: $white;
            margin-bottom: 50px;
            @include mobile {
              font-size: 22px;
            }
          }
          h5 {
            font-size: 36px;
            color: $white;
            font-weight: $light;
          }
          h4.inviteSubText {
            color: $whiteO7;
            font-weight: $light;
            span {
              color: $yellow;
              text-align: $center;
              font-weight: $regular;
            }
          }
          p {
            color: $white;
            font-size: 19px;
            font-weight: $regular;
            margin: 30px 0 30px 0;
            @include mobile {
              font-size: 14px;
            }
            &.experienceText {
              font-size: 13px;
              color: $whiteO4;
              font-weight: $normal;
              margin-top: 0;
            }
            &.confirmText {
              font-size: 16px;
              color: $whiteO7;
              font-weight: $light;
            }
            br {
              @include large-ipad {
                display: none;
              }
            }
          }
          a {
            border-radius: 8px;
            width: 190px;
            height: 48px;
            font-weight: $semibold;
            display: $inline-flex;
            align-items: $center;
            justify-content: $center;
            margin-bottom: 20px;
            @include large-ipad {
              width: 170px;
              font-size: 14px;
            }
            &.cancelBtn {
              background: $grayTint7;
              color: rgba(255, 255, 255, 0.3);
              margin-right: 10px;
            }
            &.inviteBtn {
              background: $commonGradient;
              color: $white;
            }
          }
          form {
            .form-group {
              margin-bottom: 20px;
              position: $relative;
              .form-control {
                // border-radius: 8px;
                // border: 1px solid rgba(97, 209, 128, 0.16);
                // background-color: $grayTint1;
                // height: 50px;
                // box-shadow: none;
                color: $whiteO8;
                &::-webkit-input-placeholder {
                  color: $whiteO4;
                }
                &::-moz-placeholder {
                  color: $whiteO4;
                }
                &:disabled {
                  background-color: $grayTint4;
                }
              }
              ul {
                padding: 0;
                background-color: $grayTint1;
                border: 1px solid rgba(97, 209, 128, 0.16);
                max-height: 200px;
                overflow-x: auto;
                border-radius: 8px;
                position: $absolute;
                width: 100%;
                z-index: 11;
                &::-webkit-scrollbar {
                  width: 6px;
                }

                &::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }

                &::-webkit-scrollbar-thumb {
                  background-color: rgba(97, 209, 128, 0.1);
                }
                li {
                  list-style-type: none;
                  text-align: left;
                  color: $whiteO7;
                  padding: 8px 10px;
                  transition: all 0.2s ease-in-out;
                }
              }
              select {
                background-image: url(../imgs/arrowBottom.svg);
                background-size: 20px;
                background-repeat: no-repeat;
                background-position: 98% center;
              }
              textarea {
                min-height: 150px;
                resize: none;
                &::-webkit-scrollbar {
                  width: 6px;
                }
                &::-webkit-scrollbar-track {
                  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
                }
                &::-webkit-scrollbar-thumb {
                  background-color: rgba(97, 209, 128, 0.2);
                }
              }
              .appentBtn {
                border-radius: 8px;
                border: 1px solid rgba(97, 209, 128, 0.1);
                background: $dark;
                margin-top: 15px;
                color: $whiteO3;
                font-weight: $regular;
              }
              .iconSkillDelete {
                margin: 0 0 0 15px;
              }
              #half-stars-example {
                .rating-group {
                  label {
                    .star {
                      width: 20px;
                      height: 20px;
                    }
                    .activeStar {
                      width: 20px;
                      height: 20px;
                      filter: invert(80%) sepia(59%) saturate(2087%)
                        hue-rotate(352deg) brightness(397%) contrast(89%);
                    }
                  }
                }
              }
            }
            .form-check {
              .form-check-label {
                color: $whiteO8;
                font-weight: $regular;
                cursor: pointer;
              }
              .form-check-input {
                background-color: $grayTint3;
                border-color: rgba(97, 209, 128, 0.16);
                box-shadow: none;
                cursor: pointer;
                &:checked {
                  background-color: $green;
                }
              }
            }
            .tagSuggesion {
              text-align: $left;
              p {
                color: $whiteO4;
                margin: 0 0 10px 0;
                font-size: 18px;
                font-weight: $regular;
              }
              span {
                border-radius: 8px;
                background: rgba(97, 209, 128, 0.1);
                color: $green;
                display: $inline-block;
                font-size: 14px;
                padding: 5px 10px;
                margin: 0 5px 5px 0;
                cursor: pointer;
              }
            }
          }
          .formBtns {
            margin-top: 70px;
            .btn {
              border-radius: 8px;
              width: 190px;
              height: 48px;
              font-weight: $semibold;
              display: $inline-flex;
              align-items: $center;
              justify-content: $center;
              margin-bottom: 20px;
              border: 0;
              @include large-ipad {
                width: 170px;
                font-size: 14px;
              }
              @include mobile {
                width: 160px;
              }
              &.cancelBtn {
                background: $grayTint7;
                color: rgba(255, 255, 255, 0.3);
                margin-right: 10px;
                @include mobile {
                  margin-left: 5px;
                }
                &:hover {
                  box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
                  transform: scale(1.02);
                }
              }
              &.inviteBtn {
                background: $commonGradient;
                color: $white;
                transition: all 0.2s ease-in-out;
                &:hover {
                  box-shadow: 0 0 20px rgba(97, 209, 128, 0.2);
                  transform: scale(1.02);
                }
              }
              &.AppStatusBtn {
                width: 260px;
              }
            }
          }
          .boostContent {
            p {
              font-weight: $regular;
            }
            .inviteBtn {
              width: auto;
            }
            .formBtns {
              button {
                margin: 0;
                @include ipad {
                  margin-bottom: 10px;
                }
                @include ipad-landscape {
                  width: 100%;
                }
              }
              .tooltipOuter {
                cursor: pointer;
                display: $inline-block;
                margin-left: 10px;
                position: $relative;
                .tooltipText {
                  opacity: 0;
                  position: $absolute;
                  background-color: $green;
                  color: $white;
                  font-size: 15px;
                  width: 260px;
                  top: -130px;
                  left: -210px;
                  padding: 10px 10px;
                  border-radius: 8px;
                  transition: all 0.1s ease-in-out;
                  font-weight: $regular;
                  @include mobile {
                    left: -113px;
                    display: none;
                  }
                  &::after {
                    position: absolute;
                    content: " ";
                    width: 0;
                    height: 0;
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 10px solid $green;
                    bottom: -10px;
                    right: 30px;
                    @include mobile {
                      right: 50%;
                    }
                  }
                }
                &:hover {
                  .tooltipText {
                    opacity: 1;
                    @include mobile {
                      display: inline-block;
                    }
                  }
                }
              }
            }
          }
          .stepProgressBar {
            p {
              display: $none;
            }
          }
          .suggesion {
            text-align: $left;
            h3 {
              font-size: 18px;
              color: $whiteO4;
              font-weight: $regular;
              margin-bottom: 15px;
            }
            span {
              border-radius: 8px;
              background: rgba(97, 209, 128, 0.1);
              color: $green;
              padding: 3px 10px;
              display: $inline-block;
              font-size: 14px;
              margin-right: 5px;
            }
          }
          .candidatemidabout {
            .divider {
              &:last-of-type {
                display: none;
              }
            }
          }
          form {
            .form-group {
              &.delereRow {
                margin: 6px 0 0 0;
                text-align: left;
              }
            }
          }
          .waitImg {
            width: 100%;
            max-width: 50%;
          }
          .waitText {
            color: #fff;
            font-weight: $regular;
            font-size: 23px;
            @include medium-desktop {
              font-size: 22px;
            }
            @include mobile {
              font-size: 15px;
            }
            span {
              color: $green;
            }
          }
          .deleteJobImg {
            width: 100%;
            max-width: 130px;
          }
          .jobPosted__List {
            background-color: $grayTint1;
            .postHeader {
              .postImg {
                .img {
                  position: $absolute;
                  right: -10px;
                  top: -20px;
                  img {
                    width: 45px;
                    height: 45px;
                  }
                }
              }
            }
            .postBodyContent {
              p {
                font-size: 12px;
                color: $whiteO7;
              }
              h4 {
                font-size: 15px;
              }
            }
          }
          .postSignup {
            .btns {
              margin-top: 30px;
              .btn {
                border: 0;
                border-radius: 8px;
                padding: 0;
                height: 44px;
                &.googleBtn {
                  background-color: $reddish;
                  color: $white;
                  padding: 0 30px;
                  font-weight: $semibold;
                  margin-right: 10px;
                  @include mobile {
                    margin-bottom: 10px;
                  }
                }
                &.linkedBtn {
                  img {
                    height: 44px;
                  }
                }
              }
              .linkedApplyBtn {
                margin-bottom: 0;
                height: 44px;
                width: auto;
                display: $inline-block;
                img {
                  width: 100%;
                  height: 44px;
                  border-radius: 8px;
                }
              }
            }
            p {
              width: 70%;
              position: $relative;
              border-top: 1px solid rgba(97, 209, 128, 0.1);
              margin: 40px auto;
              @include laptop {
                font-size: 14px;
              }
              @include ipad {
                width: 80%;
              }
              @include mobile {
                width: 100%;
              }
              span {
                position: absolute;
                background-color: $grayTint3;
                left: 50%;
                transform: translate(-50%, -50%);
                color: $whiteO7;
                padding: 0 10px;
              }
            }
            .form-group {
              position: $relative;
              width: 70%;
              margin: 0 auto 30px;
              @include ipad {
                width: 80%;
              }
              @include mobile {
                width: 100%;
              }
              .form-control {
                padding-left: 55px;
                color: $whiteO5;
                height: 50px;
              }
              span {
                position: $absolute;
                color: $whiteO2;
                font-size: 18px;
                width: 40px;
                height: 50px;
                top: 0;
                left: 5px;
                padding: 0 10px;
                border-right: 1px solid rgba(74, 74, 74, 0.5);
                display: $flex;
                align-items: $center;
                justify-content: $center;
              }
              &.signinEmail {
                display: flex;
                gap: 10px;
                @include mobile {
                  flex-wrap: wrap;
                }
                .btn {
                  background: $commonGradient;
                  color: $white;
                  width: 120px;
                  border: 0;
                  font-weight: $semibold;
                }
              }
            }
          }
          .checkSection {
            text-align: $left;
            .form-check {
              display: $flex;
              align-items: $center;
              margin-bottom: 10px;
              .form-check-label {
                color: $whiteO7;
                font-size: 18px;
                font-weight: $regular;
                margin-left: 10px;
              }
              .form-check-input {
                width: 30px;
                height: 30px;
                background-color: $grayTint1;
                border: 2px solid rgb(113, 113, 113, 0.1);
                box-shadow: none;
                overflow: visible;
                &:checked {
                  background-image: url(../imgs/checkMarkLarge.svg);
                  background-size: 20px;
                }
              }
            }
          }
          .shareContent {
            .input-group {
              .input-group-text {
                border: 1px solid $green;
                background-color: rgba(97, 209, 128, 0.1);
              }
              .form-control {
                color: $white;
                &:disabled {
                  background-color: rgba(97, 209, 128, 0.1);
                  color: $whiteO3;
                }
              }
            }
            .shareBtns {
              display: $flex;
              justify-content: $center;
              gap: 20px;
              margin: 30px 0;
              @include mobile {
                flex-direction: column;
              }
              > div {
                display: $flex;
                .btn {
                  background-color: $grayTint1;
                  color: $whiteO7;
                  padding: 10px 40px;
                  font-weight: $regular;
                }
              }
            }
          }
          .teamInfo {
            p {
              margin: 0 0 20px 0;
              font-size: 16px;
              color: $green;
              span {
                width: 150px;
                display: $inline-block;
                font-weight: $normal;
                color: $whiteO8;
              }
              &.description {
                color: $white;
                font-size: 14px;
                margin: 0;
              }
              &.customPrompt {
                vertical-align: top;
              }
            }
            .ActEduDtls {
              display: flex;
              margin-bottom: 5px;
              &:last-of-type {
                margin-bottom: 0;
              }
              .eduDyImg {
                margin-right: 10px;
                img {
                  width: 60px;
                  height: 60px;
                  border-radius: 8px;
                  object-fit: cover;
                  object-position: $center;
                  -o-object-position: $center;
                }
              }
              .eduDyText {
                h3 {
                  font-size: 16px;
                  color: $green;
                }
                p {
                  font-size: 13px;
                  color: $whiteO7;
                  margin: 0;
                }
              }
            }
            .eduDetail {
              .experienceHead {
                font-size: 16px;
                color: $green;
              }
              p {
                margin-bottom: 0;
                color: $whiteO7;
                font-size: 14px;
                &.description {
                  margin-bottom: 10px;
                }
              }
            }
            .divider {
              height: 1px;
              background-color: rgba(97, 209, 128, 0.1);
              margin: 10px 0;
              &:last-of-type {
                display: none;
              }
            }
            .tags {
              span {
                background-color: rgba(97, 209, 128, 0.3);
                color: $white;
                padding: 3px 3px;
                border-radius: 8px;
                font-size: 13px;
                margin: 0 5px 5px 0;
                min-width: 20px;
                text-align: $center;
                display: $inline-block;
              }
            }
            .skillListing {
              padding: 0;
              li {
                background-color: rgba(97, 209, 128, 0.3);
                color: $white;
                padding: 3px 3px;
                border-radius: 8px;
                font-size: 13px;
                margin: 0 5px 5px 0;
                min-width: 20px;
                text-align: $center;
                display: $inline-block;
              }
            }
          }
        }
        .jobProgress {
          .stepProgressBar {
            margin-top: 0;
            p {
              display: $none;
            }
          }
        }
      }
    }
  }
  &.editEducationModal {
    .candidatemidleft {
      text-align: left;
      background-color: transparent;
      border: 0;
      padding: 0;
      box-shadow: none;
      .eduDetail {
        position: $relative;
        img {
          @include medium-desktop {
            height: 65px;
          }
          @include mobile {
            height: 157px;
            object-fit: cover;
            object-position: center;
          }
        }
        h3 {
          font-size: 20px;
        }
        p {
          font-size: 14px !important;
          margin-bottom: 0 !important;
          margin-top: 10px !important;
          color: $whiteO7 !important;
        }
        .actionBtns {
          position: $absolute;
          right: 0;
          top: 0;
          .btn {
            img {
              width: 100%;
              max-width: 50px;
              height: auto;
            }
          }
        }
        &.reviewDetail {
          .userIcon {
            border-radius: 50%;
            width: 100px;
            height: 100px;
          }
          .rating {
            width: 100%;
            max-width: 100px;
            height: auto;
          }
          .divider {
            &:last-child {
              display: none;
            }
          }
          .starRating {
            label {
              .star {
                width: 20px;
                height: 20px;
              }
              .activeStar {
                width: 20px;
                height: 20px;
                filter: invert(80%) sepia(59%) saturate(2087%)
                  hue-rotate(352deg) brightness(397%) contrast(89%);
              }
            }
          }
        }
      }
    }
  }
  &.boostModal {
    .modal-dialog {
      @include medium-desktop {
        max-width: 50%;
      }
      @include mobile-landscape {
        max-width: 100%;
      }
    }
  }
  &.uploadImageModal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .commonModalContent {
            .dragDrop {
              p {
                color: $whiteO4;
                margin-bottom: 0;
                &:last-of-type {
                  margin-top: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
  &.confirmModal {
    .modal-dialog {
      max-width: 30%;
      @include desktop {
        max-width: 40%;
      }
      @include ipad {
        max-width: 60%;
      }
      @include mobile {
        max-width: 100%;
      }
    }
  }
  &.profileModal {
    .modal-dialog {
      max-width: 50%;
      @include desktop {
        max-width: 50%;
      }
      @include ipad {
        max-width: 80%;
      }
      @include mobile {
        max-width: 90%;
      }
    }
  }
  &.companyProfileImg {
    .commonModalContent {
      .dragDrop {
        p {
          color: $whiteO4 !important;
          font-size: 16px !important;
        }
      }
    }
  }
  &.candidateOnboarding {
    .modal-dialog {
      .modal-content {
        border: none;
        box-shadow: none;
        background-color: transparent;
        border-radius: 4px;
        .modal-header {
          padding: 0;
          text-align: $center;
          background-color: transparent;
          margin-bottom: 20px;
          .logo {
            width: 100%;
            max-width: 100px;
          }
        }
        .modal-body {
          position: $relative;
          border: 1px solid $grayTint1;
          box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.1);
          border-radius: 4px;
          .commonModalContent {
            .ModalHeading {
              margin-top: 50px;
            }
            form {
              padding: 0 25px;
              .previewUpload {
                display: inline-block;
                margin-top: 20px;
                position: $relative;
                width: 100px;
                height: 100px;
                border-radius: 100%;
                border: 3px solid $white;
                // overflow: hidden;
                img {
                  width: 100px;
                  height: 100px;
                  border-radius: 100%;
                  object-fit: cover;
                  object-position: center;
                }
                .crossIcon {
                  position: $absolute;
                  cursor: pointer;
                  top: 0;
                  right: 0;
                  img {
                    max-width: 23px;
                    height: auto;
                  }
                }
              }
            }
            .stepProgressBar {
              margin-top: 0;
              box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
              p {
                display: $none;
              }
              .progress {
                background-color: $grayTint1;
              }
            }
          }
          .btn-close {
            position: $absolute;
            top: 20px;
            right: 20px;
            background-image: url(../imgs/crossBlack.svg);
            background-size: 20px;
            background-repeat: no-repeat;
          }
        }
      }
    }
  }
  &.postingModal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .commonModalContent {
            p {
              color: $whiteO7;
              font-size: 16px;
            }
            h4.inviteSubText {
              span {
                small {
                  color: rgba(253, 197, 0, 0.34);
                  font-size: 16px;
                }
              }
            }
            .questionsList {
              padding: 0;
              height: 500px;
              overflow-y: auto;
              &::-webkit-scrollbar {
                width: 6px;
              }
              &::-webkit-scrollbar-track {
                box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
              }
              &::-webkit-scrollbar-thumb {
                background-color: rgba(97, 209, 128, 0.2);
              }
              li {
                list-style-type: none;
                text-align: $left;
                h3 {
                  font-size: 20px;
                  color: $white;
                  font-weight: $light;
                  display: $flex;
                  justify-content: space-between;
                  margin: 0;
                }
                p {
                  margin-top: 0;
                  font-weight: $light;
                }
                button {
                  background: transparent;
                  border: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  &#educationhistoryModal,
  &#experiencehistoryModal,
  &#oneTimeEducationhistoryModal {
    .modal-body {
      .commonModalContent {
        form {
          .row {
            margin-bottom: 40px;
            position: $relative;
            .deleteEduBtn {
              position: $absolute;
              right: 0;
              width: 50px;
              height: 40px;
              top: -50px;
              background-color: rgba(255, 27, 27, 0.2);
              color: $red;
              border: none;
              border-radius: 8px;
              @include mobile {
                width: 40px;
                height: 36px;
                top: -45px;
              }
              i {
                font-size: 18px;
                @include mobile {
                  font-size: 15px;
                }
              }
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
  &#questionModal {
    .modal-dialog {
      .modal-content {
        .modal-body {
          .commonModalContent {
            .questionsList {
              li {
                p {
                  margin-bottom: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}
// common modal ends

// common tabs starts
.commonTabs {
  nav {
    .nav-tabs {
      justify-content: space-between;
      border-bottom: 5px solid $grayTint8;
      .nav-link {
        font-size: 20px;
        font-weight: $bold;
        color: $white;
        width: 20%;
        padding: 0 0 20px;
        border: 0;
        border-bottom: 5px solid transparent;
        margin-bottom: -5px;
        &.active,
        &:hover {
          background-color: transparent;
          border-color: $green;
        }
        @include laptop {
          font-size: 18px;
        }
        @include small-laptop {
          font-size: 16px;
        }
        @include large-ipad {
          width: auto;
        }
        @include mobile {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }
  .tab-content {
    .innerTabContent {
      padding: 50px 0;
      h4 {
        color: $white;
        font-size: clamp(1.2rem, 4vw, 1.625rem);
        font-weight: $bold;
      }
      p {
        color: $whiteO7;
        font-size: clamp(14px, 4vw, 18px);
      }
      hr {
        border-color: $whiteO2;
      }
      .update {
        height: 100%;
        display: $flex;
        align-items: $center;
        justify-content: $center;
        @include mobile {
          justify-content: flex-start;
        }
        a {
          color: $green;
          font-weight: $regular;
        }
        .form-check-input {
          width: 45px;
          height: 25px;
          box-shadow: none;
          cursor: pointer;
          &:checked {
            background-color: $green;
            border-color: $green;
          }
        }
      }
      .commonForm {
        .form-group {
          label {
            color: $whiteO7;
            font-size: clamp(15px, 4vw, 19px);
            font-weight: $semibold;
            margin-bottom: 20px;
          }
          .form-control {
            // border-radius: 8px;
            // border: 1px solid rgba(97, 209, 128, 0.16);
            // background: $grayTint1;
            // height: 50px;
            // box-shadow: none;
            color: $whiteO8;
            @include large-ipad {
              margin-bottom: 10px;
            }
            // &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
            //   color: $whiteO5;
            // }
            // &::-moz-placeholder { /* Firefox 19+ */
            //   color: $whiteO5;
            // }
          }
        }
        hr {
          margin: 40px 0 30px;
        }
        // .btnSection {
        //   text-align: $right;
        //   .btn {
        //     padding: 10px;
        //     border-radius: 8px;
        //     min-width: 180px;
        //     max-width: 180px;
        //     height: 50px;
        //     border: 0;
        //     margin: 0 5px;
        //     font-weight: $semibold;
        //     &.btn-cancel {
        //       background-color: $grayTint7;
        //       color: $whiteO3;
        //     }
        //     &.btn-save {
        //       background: $commonGradient;
        //       color: $white;
        //     }
        //   }
        // }
        .billingCard {
          width: 320px;
          height: 230px;
          display: $flex;
          align-items: $center;
          justify-content: $center;
          border-radius: 8px;
          border: 1px solid rgba(97, 209, 128, 0.1);
          margin: 0 0 25px;
          background: $grayTint1;
          position: $relative;
          @include desktop {
            width: auto;
          }
          @include mobile {
            height: 180px;
          }
          .form-check-label {
            img {
              margin-bottom: 10px;
              @include medium-desktop {
                width: 100%;
                max-width: 80px;
              }
            }
            p {
              margin: 0;
            }
          }
          .form-check-input {
            position: $absolute;
            width: 21px;
            height: 21px;
            top: 20px;
            right: 20px;
            border-radius: 50%;
            cursor: pointer;
            background-color: $grayTint1;
            border-color: $whiteO2;
            box-shadow: none;
            &:checked {
              background-color: $green;
              border-color: $green;
            }
          }
        }
        .thirdPatyPayment {
          border-radius: 8px;
          border: 1px solid rgba(97, 209, 128, 0.16);
          background: $grayTint1;
          display: $flex;
          align-items: $center;
          justify-content: space-between;
          padding: 10px;
          margin-bottom: 20px;
          .form-check {
            .form-check-input {
              border-radius: 50%;
              background-color: transparent;
              box-shadow: none;
              &:checked {
                background-color: $green;
                border-color: $green;
              }
            }
            .form-check-label {
              color: $white;
              font-weight: $regular;
            }
          }
        }
      }
      .integrationCard {
        background-color: $grayTint3;
        border: 1px solid rgba(97, 209, 128, 0.16);
        border-radius: 8px;
        box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
        margin-top: 30px;
        &__Body {
          padding: 20px;
          height: 210px;
          @include medium-desktop {
            height: 240px;
          }
          @include mobile-landscape {
            height: auto;
          }
          .name {
            display: $flex;
            align-items: $center;
            margin-bottom: 20px;
            img {
              width: 65px;
              height: 65px;
              object-fit: cover;
              -o-object-position: $center;
              object-position: $center;
              border-radius: 8px;
            }
            h3 {
              color: $white;
              margin: 0;
              padding-left: 20px;
              font-size: 20px;
            }
          }
          p {
            margin: 0;
            color: $whiteO7;
            font-size: 15px;
            @include laptop {
              font-size: 14px;
            }
          }
          .form-check {
            display: $flex;
            justify-content: end;
            .form-check-input {
              width: 45px;
              height: 25px;
              box-shadow: none;
              cursor: pointer;
              &:checked {
                background-color: $green;
                border-color: $green;
              }
            }
          }
        }
        &__Footer {
          padding: 20px;
          text-align: $right;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          a {
            color: $green;
            font-size: 18px;
          }
        }
      }
    }
  }
}
// common tabs ends

// logout modal starts
.logoutModal {
  .modal-dialog {
    max-width: 600px;
    .modal-content {
      background-color: $grayTint3;
      padding: 20px 0;
      border-radius: 8px;
      .modal-header {
        border: 0;
        .btn-close {
          background-image: url(../imgs/crossBlack.svg);
          background-size: 20px;
          background-repeat: no-repeat;
          opacity: 1;
          box-shadow: none;
        }
      }
      .modal-body {
        text-align: $center;
        p {
          color: $white;
          font-size: 18px;
          span {
            font-weight: $regular;
          }
        }
      }
      .modal-footer {
        border: 0;
        justify-content: $center;
        .btn {
          padding: 10px;
          border-radius: 8px;
          min-width: 180px;
          max-width: 180px;
          height: 50px;
          border: 0;
          margin: 0 5px;
          font-weight: $semibold;
          @include mobile {
            margin-bottom: 10px;
          }
          &.btn-secondary {
            background-color: $grayTint7;
            color: $whiteO3;
          }
          &.btn-primary {
            background: $commonGradient;
            color: $white;
          }
        }
      }
    }
  }
}
// logout modal ends

// dashboard page starts
.dashboard {
  .text-end {
    .btn {
      @include mobile {
        margin-bottom: 10px;
        font-size: 14px;
      }
    }
  }
  .commonCard {
    margin-bottom: 30px;
    @include ipad-landscape {
      margin-bottom: 10px;
    }
  }
  .card {
    border: 0;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 30px;
    .card-header {
      background-color: $grayTint1;
      border-bottom: $grayTint1;
      display: flex;
      justify-content: space-between;
      h3 {
        font-size: 30px;
        color: $white;
      }
      .dropdown {
        .dropdown-toggle {
          background-color: $whiteO2;
          border: 8px;
          &::after {
            display: none;
          }
        }
        .dropdown-menu {
          padding: 0;
          background-color: $grayTint1;
          width: 250px;
          margin-top: 15px !important;
          box-shadow: 0px 0px 10px 0px rgba(97, 209, 128, 0.2);
          border: 1px solid rgba(255, 255, 255, 0.2);
          &::after {
            position: $absolute;
            content: " ";
            width: 15px;
            height: 15px;
            background-image: url(../imgs/UpArrowBlack.svg);
            background-size: 15px;
            background-repeat: no-repeat;
            // width: 0px;
            // height: 0px;
            // border-left: 8px solid transparent;
            // border-right: 8px solid transparent;
            // border-bottom: 13px solid #141414;
            top: -13px;
            right: 10px;
          }
          li {
            color: $white;
            padding: 10px 10px;
            border-bottom: 1px solid $whiteO2;
            cursor: pointer;
            &:last-of-type {
              border: 0;
            }
            .form-check {
              .form-check-label {
                font-size: 14px;
              }
              .form-check-input {
                background-color: $grayTint1;
                width: 18px;
                height: 18px;
                box-shadow: none;
                &:checked {
                  border-color: $white;
                  box-shadow: none;
                }
              }
            }
          }
        }
      }
    }
    .card-body {
      background-color: $grayTint1;
      padding: 0;
      &.dashboardCardBody {
        height: 480px;
        overflow: auto;
        &::-webkit-scrollbar {
          width: 6px;
        }

        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }

        &::-webkit-scrollbar-thumb {
          background-color: rgba(97, 209, 128, 0.1);
        }
      }
      .table {
        margin: 0;
        tr {
          th {
            background-color: $grayTint5;
            border-bottom: 1px solid rgba(97, 209, 128, 0.16);
            color: $white;
            padding: 15px 15px;
            font-weight: 500;
            @include medium-desktop {
              padding: 15px 20px;
            }
            @include medium-desktop {
              white-space: nowrap;
            }
          }
        }
        tbody {
          border: 1px solid rgba(97, 209, 128, 0.16);
          tr {
            td {
              background-color: $grayTint3;
              color: $whiteO7;
              border-bottom: 1px solid rgba(97, 209, 128, 0.16);
              padding: 15px 15px;
              vertical-align: middle;
              @include medium-desktop {
                white-space: nowrap;
                padding: 15px 20px;
              }
              @include laptop {
                font-size: 14px;
              }
              span {
                display: $inline-block;
                margin-right: 10px;
                @include medium-desktop {
                  margin-right: 10px;
                }
                img {
                  width: 100%;
                  max-width: 35px;
                  height: 35px;
                  border-radius: 50%;
                  object-fit: cover;
                  object-position: $center;
                  border: 1px solid $white;
                }
              }
              button {
                background-color: transparent;
                border: 0;
              }
              .btns {
                @include medium-desktop {
                  white-space: nowrap;
                }
                a {
                  border-radius: 8px;
                  background: rgba(97, 209, 128, 0.1);
                  color: $green;
                  display: $inline-block;
                  padding: 6px 20px;
                  font-size: 14px;
                  margin-right: 10px;
                  &:last-of-type {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
      }
      ul {
        padding: 0;
        li {
          list-style-type: none;
          a {
            text-decoration: none;
            display: flex;
            color: $whiteO7;
            border-bottom: 1px solid rgba(97, 209, 128, 0.16);
            padding: 10px 0;
            @include medium-desktop {
              display: $grid;
              grid-template-columns: 15% 85%;
            }
            @include large-ipad {
              grid-template-columns: 20% 80%;
            }
            @include ipad {
              display: $flex;
            }
            .userImg {
              width: 35px;
              height: 35px;
              background-color: $whiteO2;
              border-radius: 50%;
              display: $inline-flex;
              align-items: $center;
              justify-content: $center;
              margin-right: 10px;
            }
            p {
              font-size: 12px;
              margin: 0;
              .yellow {
                color: $yellow;
              }
              .green {
                color: $green;
              }
            }
          }
        }
      }
    }
    &.activityCard {
      .card-body {
        padding: 0 20px;
      }
    }
  }
  .messageSection {
    border-radius: 8px;
    border: 1px solid rgba(97, 209, 128, 0.16);
    background: $grayTint3;
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    padding: 20px;
    h3 {
      color: $white;
      @include laptop {
        font-size: 24px;
      }
      @include small-laptop {
        font-size: 21px;
      }
    }
    .messages {
      border-bottom: 1px solid rgba(97, 209, 128, 0.16);
      padding: 15px 10px;
      .messageInner {
        display: $flex;
        align-items: $center;
        .img {
          display: $inline-block;
          margin-right: 10px;
          img {
            width: 35px;
            height: 35px;
            border-radius: 50%;
            border: 1px solid $white;
          }
        }
        .listIcons {
          background-color: $whiteO2;
          border-radius: 4px;
          width: 35px;
          height: 35px;
          display: $inline-flex;
          align-items: $center;
          justify-content: $center;
          margin-right: 10px;
          &.text {
            display: $block;
            text-align: $center;
            color: $white;
            font-size: 12px;
            line-height: 13px;
            padding: 5px 0;
            .green {
              color: $green;
              font-weight: $regular;
            }
          }
        }
        p {
          color: $whiteO7;
          font-size: 14px;
          margin: 0;
          span {
            font-weight: $regular;
          }
        }
      }
      p {
        font-size: 14px;
        &.purple {
          color: $purple;
        }
        &.green {
          color: $green;
        }
        &.yellow {
          color: $yellow;
        }
        &.red {
          color: $reddish2;
        }
      }
    }
  }
}
// dashboard page ends

// profile page starts
.profile {
  padding: 30px 10px 50px 10px;
  background: linear-gradient(
    90deg,
    #202020 0.14%,
    #212121 17.54%,
    #31523e 50.97%,
    #232a26 81.51%,
    #212121 99.9%
  );
  backdrop-filter: blur(2px);
  .candidateHeading {
    display: $flex;
    justify-content: space-between;
    align-items: $center;
    @include ipad-landscape {
      flex-wrap: wrap;
    }
    @include mobile {
      flex-direction: column;
      align-items: start;
    }
    .release {
      p {
        span {
          font-size: 16px;
          @include mobile {
            font-size: 14px;
          }
        }
      }
    }
    h2 {
      @include small-laptop {
        font-size: 2.2rem;
      }
      @include large-ipad {
        font-size: 2rem;
        order: 2;
      }
      @include ipad {
        order: 1;
      }
      @include mobile {
        font-size: 1.5rem;
        order: 2;
      }
    }
  }
  .btn-add {
    background: $commonGradient;
    color: $white;
    font-weight: $regular;
    border: 0;
    border-radius: 8px;
    padding: 12px 20px;
    img {
      width: 100%;
      max-width: 20px;
      margin-right: 5px;
    }
  }
  .commonTabs {
    nav {
      .nav-tabs {
        justify-content: flex-start;
        .nav-link {
          width: 15%;
        }
      }
    }
  }
  .tab-content {
    .candidateuser {
      .candidateuserleft {
        .candidateImg {
          position: $relative;
          @include ipad {
            margin-right: 10px;
          }
          .btn {
            position: $absolute;
            bottom: -5px;
            border: 0;
            right: 0;
            &:focus,
            &:active {
              outline: none;
            }
            img {
              width: 100%;
              max-width: 30px;
              height: auto;
            }
            input {
              position: $absolute;
              left: 12px;
              width: 30px;
              height: 30px;
              opacity: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
    .candidatemidleft {
      position: $relative;
      .candidatemidabout {
        .candidatemidabout1 {
          .actionBtns {
            position: $absolute;
            right: 20px;
            top: 30px;
            @include mobile {
              top: 30px;
            }
            .btn {
              padding: 0;
              margin-right: 10px;
              border: 0;
              transition: all 0.2s ease-in-out;
              &:last-of-type {
                margin: 0;
              }
              img {
                @include medium-desktop {
                  max-width: 35px;
                }
                @include mobile {
                  width: 100%;
                  max-width: 30px;
                }
              }
              &:hover {
                box-shadow: 0 0 20px rgba(97, 209, 128, 0.2);
                transform: scale(1.03);
              }
            }
          }
        }
      }
      .eduDetail {
        .form-control {
          background-color: rgba(97, 209, 128, 0.16);
          color: $green;
          font-size: 14px;
          background-image: url(../imgs/arrowBottomGreen.svg);
          background-size: 20px;
          background-repeat: no-repeat;
          background-position: 99% center;
          border: none;
          padding: 10px 10px;
          width: 50%;
          margin-bottom: 30px;
          box-shadow: none;
          cursor: pointer;
          @include laptop {
            width: 100%;
          }
        }
        select {
          option {
            background-color: $grayTint3;
            color: $whiteO8;
          }
        }
      }
    }
  }
  .profileRightOuter {
    margin-top: 40px;
    @include large-ipad {
      margin-top: 0;
    }
    .profileSkills {
      .candidatemidabout {
        .candidatemidabout1 {
          margin-bottom: 0;
          h3 {
            margin: 0;
            .actionBtns {
              .btn {
                img {
                  @include medium-desktop {
                    width: 100%;
                    max-width: 35px;
                  }
                }
              }
            }
          }
          .actionBtns {
            @include medium-desktop {
              top: 26px;
            }
          }
        }
      }
    }
  }
  .photoUploadSection {
    margin-bottom: 30px;
    p {
      color: $whiteO7;
    }
    .dragDrop {
      background-color: $grayTint3;
      border: 1px dashed rgba(97, 209, 128, 0.16);
      border-radius: 8px;
      text-align: $center;
      padding: 40px;
      position: $relative;
      input {
        position: $absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        cursor: pointer;
        opacity: 0;
      }
      p {
        color: $whiteO4;
        .green {
          color: $green;
        }
        .white {
          color: $white;
        }
      }
    }
    .uploadPreview {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      display: $inline-block;
      margin-top: 20px;
      position: $relative;
      img {
        border: 3px solid #fff;
      }
      .crossIcon {
        position: $absolute;
        cursor: pointer;
        border-radius: 50%;
        border: 0;
        max-width: 23px;
        right: 10px;
      }
    }
  }
  .profileForm {
    margin-top: 40px;
    form {
      .form-group {
        margin-bottom: 15px;
        label {
          font-size: 18px;
          color: $whiteO7;
          margin-bottom: 8px;
        }
        .form-control {
          // border-radius: 8px;
          // border: 1px solid rgba(97, 209, 128, 0.16);
          // background-color: $grayTint1;
          // height: 50px;
          // box-shadow: none;
          color: $whiteO8;
          // &::-webkit-input-placeholder {
          //   color: $whiteO4;
          // }
          // &::-moz-placeholder {
          //   color: $whiteO4;
          // }
        }
        textarea {
          resize: $none;
          &.description {
            height: 100px !important;
          }
          &.about {
            height: 150px !important;
          }
        }
      }
      .preview {
        width: 100px;
        margin-top: 50px;
        position: $relative;
        @include ipad {
          margin-top: 20px;
        }
        .uploadImg {
          width: 100px;
          height: 100px;
          object-fit: cover;
          object-position: $center;
          border-radius: 50%;
        }
        span {
          position: $absolute;
          right: 0;
          cursor: pointer;
          img {
            width: 25px;
          }
        }
      }
    }
  }
  .hiringAlert {
    background-color: $grayTint3;
    border-radius: 8px;
    border: 1px solid rgba(97, 209, 128, 0.16);
    padding: 30px 30px;
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    h4 {
      color: $white;
      font-size: 22px;
      font-weight: $semibold;
      border-bottom: 1px solid rgba(97, 209, 128, 0.1);
      padding: 0 0 15px 0;
    }
    a {
      background: $commonGradient;
      color: $white;
      margin-top: 20px;
      padding: 10px 30px;
      font-weight: $bold;
      border-radius: 8px;
      display: $inline-block;
      @include medium-desktop {
        padding: 10px 20px;
      }
      &:hover {
        box-shadow: 0 10px 20px rgba(97, 209, 128, 0.2);
      }
    }
  }
  .btnSection {
    .btn {
      min-width: 150px;
      max-width: 150px;
    }
  }
}
// profile page ends

// create job starts
.createJob {
  padding: 30px 10px 50px 10px;
  background: linear-gradient(
    90deg,
    #202020 0.14%,
    #212121 17.54%,
    #31523e 50.97%,
    #232a26 81.51%,
    #212121 99.9%
  );
  backdrop-filter: blur(2px);
  &__form {
    border-radius: 8px;
    border: 1px solid rgba(97, 209, 128, 0.16);
    background: $grayTint3;
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    padding: 30px;
    form {
      .form-group {
        margin-bottom: 20px;
        &.monthlySelector {
          > div {
            > div {
              background-color: $grayTint1;
              border: 1px solid rgba(97, 209, 128, 0.1);
              height: 50px;
              border-radius: 8px;
              box-shadow: $none;
              > div {
                > div {
                  color: $white;
                  padding: 8px 10px;
                }
              }
            }
            > div:nth-child(4) {
              background-color: $grayTint1;
              border: 0;
              height: auto;
            }
            .css-1u9des2-indicatorSeparator {
              background-color: rgba(97, 209, 128, 0.1);
            }
          }
          .css-1nmdiq5-menu {
            background-color: $grayTint1 !important;
            color: $whiteO8;
            > div {
              background-color: $grayTint1;
              > div {
                &:hover {
                  background-color: $grayTint5;
                }
              }
            }
          }
          .col-lg-5 {
            border: 0;
            background-color: transparent;
          }
        }

        .editorOuter {
          .ck-editor {
            .ck-editor__top {
              .ck-reset_all {
                background: $grayTint1;
              }
            }
          }
          .ck-toolbar {
            background-color: $grayTint1;
            border-color: rgba(97, 209, 128, 0.16);
            border-radius: 8px 8px 0 0 !important;
            &__items {
              .ck-button {
                color: $white;
                box-shadow: none;
                &:hover,
                &:focus {
                  background: $grayTint1;
                  color: $white;
                }
              }
              .ck-dropdown__panel {
                ul {
                  background: $grayTint1;
                }
              }
            }
          }
          .ck-content {
            background-color: $grayTint1;
            min-height: 300px;
            color: $white;
            border-color: rgba(97, 209, 128, 0.16);
            border-radius: 0 0 8px 8px;
            &.ck-focused {
              border-color: rgba(97, 209, 128, 0.16);
            }
          }
        }
        .form-control {
          border-radius: 8px;
          border: 1px solid rgba(97, 209, 128, 0.16);
          background-color: $grayTint1;
          height: 50px;
          box-shadow: none;
          color: $whiteO8;
          &::-webkit-input-placeholder {
            /* Chrome/Opera/Safari */
            color: $whiteO4;
          }
          &::-moz-placeholder {
            /* Firefox 19+ */
            color: $whiteO4;
          }
        }
        textarea {
          min-height: 150px;
        }
        select {
          background-image: url(../imgs/arrowBottom.svg);
          background-size: 20px;
          background-repeat: no-repeat;
          background-position: 99% center;
        }
      }
    }
    .salaryRange {
      border-radius: 100px;
      border: 1px solid rgba(97, 209, 128, 0.16);
      background: linear-gradient(
        92deg,
        rgba(97, 209, 128, 0.1) 0.24%,
        rgba(2, 48, 32, 0.1) 139.73%
      );
      padding: 15px;
      text-align: $center;
      p {
        color: $white;
        margin: 0;
        .green {
          color: $green;
        }
      }
    }
    .selectJob {
      margin-top: 50px;
      p {
        font-weight: $light;
        color: $white;
        font-size: 20px;
      }
      .selectJobTiles {
        display: $flex;
        gap: 50px;
        @include ipad {
          flex-wrap: wrap;
          justify-content: center;
        }
        @include mobile {
          gap: 10px;
        }
        .tile {
          background-color: $grayTint1;
          border: 1px solid rgba(97, 209, 128, 0.1);
          border-radius: 8px;
          width: 250px;
          height: 220px;
          display: $flex;
          flex-direction: column;
          align-items: $center;
          justify-content: $center;
          cursor: pointer;
          position: $relative;
          transition: all 0.2s ease-in-out;
          @include mobile {
            width: 130px;
            height: 130px;
          }
          p {
            color: $whiteO7;
            margin: 0;
            font-size: 18px;
            transition: all 0.2s ease-in-out;
          }
          svg {
            transition: all 0.2s ease-in-out;
            @include mobile {
              width: 50px;
              height: 50px;
            }
          }
          .checkMark {
            opacity: 0;
            position: absolute;
            top: 10px;
            right: 10px;
            transition: all 0.2s ease-in-out;
          }
          &:hover,
          &.active {
            border-color: $green;
            p {
              color: $green;
            }
            svg {
              path {
                fill: $green;
              }
            }
            .checkMark {
              opacity: 1;
            }
          }
        }
      }
      .departmentSelector {
        > div {
          > div {
            background-color: $grayTint1;
            border: 1px solid rgba(97, 209, 128, 0.1);
            height: 50px;
            border-radius: 8px;
            &:nth-child(2) {
              border: 0;
            }
            &:hover,
            &:focus {
              box-shadow: none;
            }
            > div {
              > div {
                color: $white;
                &.css-d7l1ni-option {
                  background-color: $grayTint1;
                }
              }
            }
          }
          > div:nth-child(4) {
            background-color: transparent;
            border: 0;
            height: auto;
          }
          > div {
            > div {
              background-color: $grayTint1;
            }
          }
          .css-1u9des2-indicatorSeparator {
            background-color: rgba(97, 209, 128, 0.1);
          }
        }
        .css-1nmdiq5-menu {
          background-color: $grayTint1 !important;
          color: $whiteO8;
          > div {
            background-color: $grayTint1;
            > div {
              &:hover {
                background-color: $grayTint5;
              }
            }
          }
        }
        .otherOptions {
          display: flex;
          margin-top: 15px;
          button {
            border: 0;
            background: none;
            margin-left: 10px;
          }
          .form-control {
            color: $whiteO8;
          }
        }
      }
    }
    .uploader {
      p {
        font-weight: $light;
        font-size: 18px;
        color: $white;
      }
      .dragDrop {
        text-align: $center;
        border: 1px dashed $yellow;
        border-radius: 8px;
        padding: 50px;
        background-color: $grayTint1;
        position: $relative;
        margin-bottom: 30px;
        .premium {
          position: absolute;
          right: 10px;
          top: 10px;
          img {
            width: 100%;
            max-width: 60px;
          }
        }
        input {
          position: $absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          opacity: 0;
          cursor: pointer;
        }
        p {
          color: $whiteO4;
          .white {
            color: $white;
          }
          .green {
            color: $green;
          }
        }
      }
    }
    .skillsGrid {
      // display: $flex;
      // align-items: $center;
      // justify-content: space-between;
      // @include mobile {
      //   flex-wrap: wrap;
      // }

      position: $relative;
      .form-group {
        width: 100%;
        margin: 0;
        @include mobile {
          width: 100%;
          margin-bottom: 5px;
        }
      }
      button {
        // background: $commonGradient;
        // color: $white;
        // border: 0;
        // padding: 12px 15px;
        // border-radius: 8px;
        // font-weight: $regular;
        background: transparent;
        border: none;
        position: $absolute;
        top: 6px;
        right: 3px;
      }
    }
    .skillListing {
      padding: 0;
      margin: 15px 0;
      li {
        list-style-type: $none;
        display: $inline-block;
        color: $green;
        border-radius: 8px;
        background: rgba(97, 209, 128, 0.1);
        font-size: 14px;
        padding: 5px 15px;
        margin: 0 10px 10px 0;
        text-transform: capitalize;
      }
      .crossIcon {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
      }
      &li:hover .crossIcon {
        opacity: 1;
        cursor: pointer;
      }
    }
  }
}
// create job ends

// common table starts
.commonTable {
  border-radius: 8px;
  overflow: hidden;
  .searchBar {
    position: $relative;
    .form-control {
      // border-radius: 8px;
      // border: 1px solid rgba(97, 209, 128, 0.16);
      // background-color: $grayTint1;
      // height: 50px;
      // box-shadow: none;
      // color: $whiteO8;
      padding-left: 40px;
      &::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: $whiteO4;
      }
      &::-moz-placeholder {
        /* Firefox 19+ */
        color: $whiteO4;
      }
    }
    span {
      position: $absolute;
      top: 12px;
      left: 10px;
    }
  }
  .tableOuter {
    border: 1px solid $grayTint1;
    border-radius: 8px;
    overflow: hidden;
    .table {
      margin-bottom: 0;
      thead {
        tr {
          th {
            background-color: $dark;
            color: $white;
            border-color: $dark;
            padding: 15px 10px;
            font-weight: $regular;
          }
        }
      }
      tbody {
        tr {
          td {
            background-color: $grayTint3;
            color: $whiteO7;
            border-color: rgba(113, 113, 113, 0.1);
            vertical-align: middle;
            padding: 8px 10px;
            .compatibe {
              border-radius: 8px;
              padding: 8px 10px;
              font-size: 14px;
              &.green {
                color: $green;
                background: rgba(97, 209, 128, 0.1);
              }
              &.orange {
                color: $orange;
                background: rgba(202, 97, 0, 0.23);
              }
              &.yellow {
                color: $yellow;
                background: rgba(253, 197, 0, 0.16);
              }
            }
            .status {
              font-size: 14px;
              border-radius: 8px;
              padding: 8px 10px;
              min-width: 130px;
              max-width: 130px;
              display: $inline-block;
              text-align: $center;
              &.hire {
                color: $green;
                background-color: $darkGreen;
              }
              &.message {
                color: $green;
                background-color: rgba(97, 209, 128, 0.2);
              }
              &.sentOffer {
                color: $green;
                background-color: rgba(97, 209, 128, 0.05);
              }
            }
          }
        }
      }
    }
    .pagination {
      padding: 20px;
      .enteries {
        p {
          color: $white;
          margin: 10px 0 0 0;
        }
      }
      .cppagination {
        margin: 0;
        justify-content: end;
      }
    }
  }
}
// common table ends

// budget page starts
.budget {
  &__Content {
    background-color: $grayTint3;
    border: 1px solid rgba(97, 209, 128, 0.16);
    border-radius: 8px;
    padding: 20px;
    h3 {
      font-size: 30px;
      font-weight: $light;
      color: $white;
    }
    p {
      color: $white;
      font-weight: $light;
      margin-bottom: 30px;
    }
    .rangeSection {
      margin-bottom: 30px;
      h5 {
        font-size: 20px;
        font-weight: $light;
        color: $white;
      }
      p {
        text-align: $center;
        margin-top: 20px;
        color: $whiteO4;
      }
      .rc-slider {
        .rc-slider-rail {
          background: rgba(97, 209, 128, 0.16);
          box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
          height: 8px;
        }
        .rc-slider-track {
          background-color: $green;
          height: 8px;
        }
        .rc-slider-handle {
          width: 30px;
          height: 30px;
          background-color: $green;
          border-color: $green;
          opacity: 1;
          box-shadow: none;
          top: 0;
          &.rc-slider-handle-dragging {
            border-color: $green;
            box-shadow: none;
          }
        }
      }
    }
  }
}
// budget page ends

// for you starts
.forYou {
  &__Content {
    background-color: $grayTint3;
    border: 1px solid $grayTint1;
    padding: 20px;
    border-radius: 8px;
    .forYouCover {
      background-image: url(../imgs/primium.png);
      background-size: cover;
      background-repeat: no-repeat;
      background-position: $center;
      padding: 40px 20px;
      border-radius: 8px;
      .userImg {
        text-align: $center;
        img {
          width: 100%;
          max-width: 90px;
          height: 90px;
          border-radius: 50%;
          object-fit: cover;
          object-position: $center;
          -o-object-position: $center;
          @include laptop {
            max-width: 80px;
            height: 80px;
          }
          @include small-laptop {
            max-width: 70px;
            height: 70px;
          }
        }
      }
      h3 {
        color: $white;
        font-size: 30px;
      }
      p {
        color: $white;
        font-size: 14px;
      }
      h5 {
        font-size: 24px;
        color: $green;
        font-weight: $regular;
        span {
          color: $whiteO5;
          font-size: 14px;
        }
      }
    }
    .forYouDetail {
      margin-top: 30px;
      h3 {
        color: $green;
        font-size: 32px;
        font-weight: $semibold;
      }
      h5 {
        font-weight: $light;
        font-size: 22px;
        color: $white;
      }
      p {
        color: $white;
        font-weight: $semibold;
        font-size: 14px;
      }
      h4 {
        color: $white;
        font-size: 22px;
        margin: 30px 0 15px 0;
        font-weight: $semibold;
      }
      ul {
        li {
          list-style-type: none;
          color: $whiteO7;
          position: $relative;
          margin-bottom: 10px;
          &::after {
            position: $absolute;
            content: " ";
            background-image: url(../imgs/greentick.svg);
            background-size: 15px;
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
            left: -25px;
            top: 5px;
          }
        }
      }
      .donut {
        position: $relative;
        text-align: $center;
        svg {
          height: 120px;
          width: 120px;
        }
        p {
          color: $green;
          position: $absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
    .forYouBtn {
      display: $flex;
      justify-content: space-between;
      margin: 30px 0;
      .btn {
        padding: 0;
        img {
          width: 100%;
          max-width: 55px;
        }
      }
    }
  }
}
// for you ends

// messages starts
.messages {
  .listSection {
    padding-right: 0;
  }
  &__Contact {
    border-right: 1px solid rgba(97, 209, 128, 0.16);
    .searchContact {
      display: $flex;
      justify-content: space-between;
      align-items: $center;
      background-color: $grayTint3;
      padding: 20px;
      h4 {
        color: $white;
        margin: 0;
      }
      .form-group {
        width: 65%;
        position: $relative;
        .form-control {
          border-radius: 100px;
          color: $whiteO4;
          padding-left: 40px;
        }
        span {
          position: $absolute;
          top: 0;
          color: $green;
          top: 13px;
          left: 13px;
        }
      }
    }
    .contactOuter {
      height: 69vh;
      overflow: auto;
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(97, 209, 128, 0.2);
      }
      .contactList {
        padding: 20px 20px;
        background-color: $grayTint10;
        border-bottom: 1px solid rgba(97, 209, 128, 0.16);
        cursor: pointer;
        .col-xl-9 {
          padding-left: 0;
        }
        .contactImg {
          text-align: $center;
          position: $relative;
          @include mobile {
            text-align: $left;
          }
          span {
            width: 16px;
            height: 16px;
            border-radius: 50%;
            border: 4px solid $white;
            position: $absolute;
            background-color: $grayTint9;
            top: 0;
            left: 10px;
            &.active {
              background-color: $green;
            }
            @include ipad {
              left: 0;
            }
          }
          img {
            width: 100%;
            max-width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
            object-position: $center;
            @include large-ipad {
              width: 40px;
              height: 40px;
            }
          }
        }
        .contactName {
          position: $relative;
          h4 {
            color: $white;
            font-size: 16px;
            display: $flex;
            align-items: $center;
            justify-content: space-between;
            @include ipad {
              display: $block;
            }
            span {
              font-size: 12px;
              &.lastSeen {
                font-size: 10px;
                color: $white;
                @include ipad {
                  display: $block;
                  text-align: $right;
                }
              }
            }
          }
          p {
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 14px;
            color: $white;
            width: 80%;
            margin: 0;
          }
          .messageCounter {
            background-color: $green;
            color: $white;
            font-size: 12px;
            position: $absolute;
            bottom: 0;
            right: 0;
            width: 20px;
            height: 20px;
            display: $flex;
            align-items: $center;
            justify-content: $center;
            border-radius: 50%;
          }
        }
        &.active {
          background: $commonGradient;
          .messageCounter {
            display: none;
          }
        }
      }
    }
  }
  .chatSection {
    padding-left: 0;
  }
  &__Chat {
    height: 100%;
    @include mobile {
      margin-top: 10px;
    }
    .chatOuter {
      background-color: $grayTint3;
      padding: 17px;
      border-bottom: 1px solid rgba(112, 124, 151, 0.1);
      .NameImg {
        display: $flex;
        align-items: $center;
        .imgSection {
          margin-right: 20px;
          img {
            width: 55px;
            height: 55px;
            border: 1px solid $white;
            border-radius: 50%;
            object-fit: cover;
            object-position: $center;
          }
        }
        .nameSection {
          h4 {
            color: $white;
            font-size: 20px;
            margin-bottom: 0;
          }
          p {
            color: $green;
            margin-bottom: 0;
            font-size: 15px;
          }
        }
      }
      .sideBtns {
        text-align: $right;
        .btn {
          padding: 15px;
          border: 0;
          @include mobile {
            padding: 10px;
          }
          &.sendOffer {
            color: $white;
            font-weight: $regular;
            background: $commonGradient;
            margin-right: 20px;
            @include mobile {
              margin-right: 10px;
            }
          }
          &.deleteBtn {
            background-color: $whiteO2;
            padding: 14px;
            @include mobile {
              padding: 9px;
            }
          }
        }
      }
    }
    .chatInner {
      padding: 15px;
      // height: calc(100% - 300px);
      height: 60vh;
      overflow: auto;
      &::-webkit-scrollbar {
        // width: 6px;
        display: $none;
      }
      // &::-webkit-scrollbar-track {
      //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      // }
      // &::-webkit-scrollbar-thumb {
      //   background-color: rgba(97, 209, 128, 0.2);
      // }
      @include desktop {
        height: 52vh;
      }
      .myChat {
        display: $flex;
        align-items: $center;
        justify-content: flex-end;
        margin-bottom: 20px;
        .messages {
          max-width: 40%;
          background-color: $grayTint3;
          border: 1px solid rgba(97, 209, 128, 0.16);
          border-radius: 0px 10px 10px 10px;
          padding: 10px 10px;
          margin-left: 10px;
          @include mobile {
            max-width: 100%;
          }
          p {
            color: $whiteO7;
            margin: 0;
            @include large-ipad {
              font-size: 14px;
            }
          }
        }
        span {
          cursor: pointer;
        }
      }
      .otherChat {
        display: $flex;
        align-items: flex-start;
        justify-content: flex-start;
        margin-bottom: 20px;
        .messageOuter {
          max-width: 40%;
          position: $relative;
          @include mobile {
            max-width: 100%;
          }
          .messages {
            border-radius: 10px 10px 0 10px;
            background: $commonGradient;
            box-shadow: 10px 10px 25px rgba(97, 209, 128, 0.1);
            padding: 10px 10px;
            margin-left: 10px;
            cursor: pointer;
            @include large-ipad {
              font-size: 14px;
            }
            p {
              color: $whiteO7;
              margin: 0;
            }
          }
          .messageTimer {
            margin: 0;
            text-align: $right;
            font-size: 14px;
            color: $whiteO5;
          }
          span {
            margin-left: 10px;
            position: $absolute;
            top: 20%;
            right: -30px;
            @include mobile {
              top: auto;
              right: auto;
              bottom: 0;
              left: 0;
            }
          }
        }
      }
    }
    .messageWrite {
      position: $relative;
      textarea {
        border-radius: 0;
        background-color: transparent;
        border: 0;
        border-top: 2px solid rgba(112, 124, 151, 0.15);
        padding: 10px 130px 10px 70px;
        color: $whiteO5;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
        }
        &::-webkit-scrollbar-thumb {
          background-color: rgba(97, 209, 128, 0.2);
        }
      }
      .attachIcon {
        position: $absolute;
        top: 10px;
        left: 10px;
        cursor: pointer;
        img {
          width: 100%;
          max-width: 40px;
        }
        .attachments {
          position: $absolute;
          top: -190px;
          display: $none;
          span {
            width: 40px;
            height: 40px;
            background-color: $green;
            border-radius: 50%;
            display: $flex;
            align-items: $center;
            justify-content: $center;
            margin-bottom: 5px;
            img {
              width: 100%;
              max-width: 25px;
            }
          }
        }
        &:hover {
          .attachments {
            display: $block;
          }
        }
      }
      .sideIcon {
        position: $absolute;
        top: 5px;
        right: 0;
        .emoji {
          margin-right: 5px;
        }
        .btn {
          background: $green;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: inline-flex;
          align-items: $center;
          justify-content: $center;
          margin: 5px;
        }
      }
    }
  }
}
// messages ends

// jobs posted starts
.jobPosted {
  min-height: 80vh;
  padding: 30px 10px 50px 10px;
  &__List {
    background-color: $grayTint3;
    border: 1px solid rgba(97, 209, 128, 0.16);
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    border-radius: 8px;
    padding: 20px;
    margin-bottom: 20px;
    &:last-of-type {
      margin-bottom: 0;
    }
    .postHeader {
      display: $flex;
      gap: 15px;
      align-items: $center;
      position: $relative;
      .headBtns {
        position: $absolute;
        right: 0;
        top: 0;
        .btn {
          padding: 0;
          margin-right: 10px;
          &:last-of-type {
            margin: 0;
          }
        }
      }
      .postImg {
        width: 70px;
        position: $relative;
        img {
          width: 65px;
          height: 65px;
          object-fit: cover;
          object-position: $center;
          border-radius: 8px;
        }
      }
      .postText {
        h4 {
          font-size: 20px;
          margin-bottom: 0;
          font-weight: $semibold;
          span {
            font-size: 14px;
            font-weight: $light;
            margin-left: 5px;
            img {
              width: 100%;
              max-width: 10px;
              margin-right: 5px;
            }
          }
        }
        .postTime {
          color: $whiteO7;
          font-size: 14px;
        }
        .jobLoc {
          display: $flex;
          align-items: $center;
          margin-top: 10px;
          @include mobile {
            flex-wrap: wrap;
          }
          span {
            margin-right: 10px;
            display: $flex;
            align-items: $center;
            @include mobile {
              margin-right: 7px;
            }
            img {
              margin-right: 5px;
              width: 100%;
              max-width: 20px;
            }
          }
        }
        p {
          font-size: 14px;
          color: $whiteO7;
          margin-bottom: 0;
        }
      }
      .applyBtns {
        position: $absolute;
        right: 0;
        top: 0;
        @include mobile {
          position: $static;
          margin-top: 10px;
        }
        .btn {
          height: 44px;
          &.btn-apply {
            background: $commonGradient;
            color: $white;
            border: 0;
            padding: 10px 30px;
            font-weight: $semibold;
            margin-right: 10px;
          }
          &.btn-save {
            background-color: $whiteO2;
            padding: 10px 15px;
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
    .postBodyContent {
      margin-top: 20px;
      h4 {
        color: $green;
        span {
          color: $whiteO7;
          font-weight: $normal;
          font-size: 16px;
        }
      }
      p {
        color: $whiteO7;
        margin-top: 10px;
      }
      .btnSeemore {
        font-style: italic;
        text-decoration: underline;
        font-size: 14px;
        padding: 0;
        color: $green;
      }
      .detailText {
        margin-top: 20px;
        h2,
        h3,
        h4,
        h5,
        h6 {
          color: $white;
          font-size: 18px;
          strong {
            font-weight: $semibold;
          }
        }
        ul,
        ol {
          li {
            color: $whiteO7;
            margin-bottom: 10px;
            position: $relative;
            list-style-type: $none;
            &::before {
              position: $absolute;
              content: " ";
              background-image: url(../imgs/checkMarkIcon.svg);
              width: 18px;
              height: 18px;
              background-size: 17px;
              background-repeat: no-repeat;
              top: 5px;
              left: -25px;
            }
          }
        }
      }
    }
    .postFooter {
      margin-top: 20px;
      .tags {
        span {
          color: $green;
          border-radius: 8px;
          background: rgba(97, 209, 128, 0.1);
          font-size: 14px;
          padding: 5px 10px;
          margin-right: 10px;
          display: $inline-block;
          text-transform: capitalize;
          @include mobile {
            margin-bottom: 10px;
          }
        }
      }
      .btnSection {
        a {
          padding: 12px;
          display: $inline-block;
          min-width: none;
          &.btn-share {
            min-width: 200px;
            max-width: 200px;
          }
        }
      }
    }
  }
}
// jobs posted ends

// analytics starts
.analytics {
  background: linear-gradient(
    90deg,
    #202020 0.14%,
    #212121 17.54%,
    #31523e 50.97%,
    #232a26 81.51%,
    #212121 99.9%
  );
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 50px 30px;
  &__Filter {
    display: $flex;
    align-items: $center;
    justify-content: flex-end;
    gap: 10px;
    .btn-group {
      .btn {
        background-color: $whiteO1;
        border: 1px solid $whiteO2;
        color: $white;
        padding: 10px 20px;
        font-weight: $regular;
        transition: all 0.2s ease-in-out;
        &:hover {
          background: $commonGradient;
        }
      }
    }
    .dropdown {
      .dropdown-toggle {
        background: $commonGradient;
        color: $white;
        padding: 11px 20px;
        font-weight: $regular;
        width: 300px;
        position: $relative;
        border: 0;
        &::after {
          position: $absolute;
          content: " ";
          width: 20px;
          height: 20px;
          background-image: url(../imgs/filterGreen.svg);
          background-size: 20px;
          right: 10px;
          border: 0;
        }
      }
    }
  }
  .card {
    background-color: $grayTint3;
    border: 1px solid $grayTint1;
    box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    .card-header {
      display: flex;
      justify-content: space-between;
      background-color: $grayTint1;
      padding: 20px;
      @include laptop {
        align-items: $center;
      }
      @include mobile {
        flex-direction: column;
      }
      h3 {
        color: $white;
        font-size: 30px;
        font-weight: $semibold;
        margin: 0;
        @include medium-desktop {
          font-size: 20px;
        }
        @include small-laptop {
          font-size: 20px;
        }
      }
      .dropdown {
        .dropdown-toggle {
          background-image: $commonGradient;
          border: 1px solid $green;
          color: $white;
          font-weight: $regular;
          padding: 9px 30px;
          border-radius: 8px;
          @include medium-desktop {
            padding: 9px 25px 9px 10px;
          }
          &::after {
            position: $absolute;
            content: " ";
            background-image: url(../imgs/curveShape.svg);
            background-repeat: no-repeat;
            width: 18px;
            height: 18px;
            background-size: 18px;
            background-position-y: center;
            border: 0;
          }
        }
        .dropdown-menu {
          background-color: $grayTint1;
          padding: 0;
          overflow: hidden;
          li {
            .dropdown-item {
              color: $white;
              &:hover {
                background-color: rgba(97, 209, 128, 0.3);
              }
            }
          }
        }
        &.newUserDropdown {
          text-align: $right;
        }
      }
      .filterOuter {
        display: $flex;
        justify-content: end;
        @include mobile {
          flex-wrap: wrap;
          flex-direction: column-reverse;
        }
        .customDate {
          display: $flex;
          margin-right: 10px;
          @include mobile {
            flex-wrap: wrap;
            margin-top: 10px;
          }
          .react-datepicker-wrapper {
            width: 150px;
            margin-right: 10px;
            @include mobile {
              width: 110px;
            }
            input {
              height: 44px;
            }
          }
          .btn {
            background: $commonGradient;
            color: $white;
            border: 1px solid $green;
            padding: 10px 40px;
            font-weight: $regular;
            height: 44px;
            border-radius: 8px;
          }
        }
      }
    }
    .card-body {
      .chart-container {
        .apexcharts-toolbar {
          display: $none;
        }
        svg {
          text {
            fill: $white;
          }
        }
        .recharts-wrapper {
          // width: 100% !important;
          // height: 600px !important;
          svg {
            width: 100%;
          }
          .recharts-legend-wrapper {
            display: $none;
          }
        }
      }
    }
  }
  .ticketsCard-body {
    background-color: $grayTint1;
    padding: 10px 20px;
    height: 625px;
    overflow: auto;
    @include large-ipad {
      height: auto;
    }
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(97, 209, 128, 0.2);
    }
    .ticketList {
      display: $flex;
      background-color: $lightGray2;
      border-radius: 8px;
      padding: 10px 15px;
      margin-bottom: 10px;
      .candidateImg {
        margin-right: 10px;
        img {
          width: 32px;
          height: 32px;
          object-fit: cover;
          -o-object-position: center;
          object-position: center;
          border-radius: 50%;
        }
      }
      .candidateuserleft2 {
        h2 {
          font-size: 16px;
          color: $white;
          display: $flex;
          align-items: $center;
          justify-content: space-between;
        }
        p {
          color: $white;
          font-size: 14px;
          margin-bottom: 0;
        }
      }
    }
  }
  .invitation {
    // border: 1px solid rgba(97, 209, 128, 0.16);
    // background: $grayTint3;
    // box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    // padding: 15px;
    &.px-5 {
      @include large-ipad {
        padding: 0 !important;
        margin-bottom: 10px;
      }
    }
    h3 {
      margin-bottom: 30px;
    }
    .invitationCard {
      background-color: $grayTint1;
      padding: 20px;
      border-radius: 8px;
      height: 180px;
      @include medium-desktop {
        padding: 20px;
      }
      @include large-ipad {
        margin-bottom: 10px;
      }
      .inviteHeader {
        display: $flex;
        gap: 10px;
        align-items: $center;
        span {
          background-color: $green;
          display: $inline-flex;
          width: 30px;
          height: 30px;
          align-items: $center;
          justify-content: $center;
          border-radius: 6px;
          &.activeAcount {
            background-color: transparent;
          }
        }
        p {
          color: $white;
          font-size: 24px;
          font-weight: $semibold;
          margin: 0;
          @include medium-desktop {
            font-size: 21px;
          }
          @include small-laptop {
            font-size: 20px;
          }
        }
        h3 {
          color: $white;
          font-size: 24px;
          font-weight: $semibold;
          margin: 0 0 20px 0;
          @include laptop {
            font-size: 22px;
          }
          @include small-laptop {
            font-size: 20px;
          }
        }
        &.employerHeader {
          justify-content: space-between;
          align-items: start;
          h3 {
            margin: 0;
          }
          .dropdown {
            .btn {
              padding: 6px 20px;
              border-radius: 8px;
              &.viewAllButton {
                &:hover {
                  background-color: $green;
                  color: $white;
                  border: 1px solid $green;
                }
              }
              &.dropdown-toggle {
                background: $commonGradient;
                color: $white;
                border: 1px solid $green;
                padding: 6px 30px;
                &::after {
                  position: $absolute;
                  content: " ";
                  background-image: url(../imgs/curveShape.svg);
                  background-repeat: no-repeat;
                  width: 18px;
                  height: 18px;
                  background-size: 18px;
                  background-position-y: center;
                  border: 0;
                }
              }
            }
            .dropdown-menu {
              background-color: $grayTint1;
              padding: 0;
              overflow: hidden;
              li {
                .dropdown-item {
                  color: $white;
                  &:hover {
                    background-color: rgba(97, 209, 128, 0.3);
                  }
                }
              }
            }
          }
        }
      }
      .inviteBody {
        h4 {
          font-size: 26px;
          font-weight: $semibold;
          margin: 10px 0;
          padding-left: 40px;
        }
        p {
          color: $whiteO5;
          font-size: 12px;
          padding-left: 35px;
          font-weight: $semibold;
          margin-bottom: 0;
          span {
            color: $white;
            margin-left: 20px;
            font-size: 20px;
            font-weight: $light;
            position: $relative;
            svg {
              height: 10px;
              position: $absolute;
              top: 1px;
              // right: 0;
            }
          }
        }
        .manageTeamImage {
          @include medium-desktop {
            margin-bottom: 5px;
            width: 50px;
            height: 50px;
          }
          @include laptop {
            width: 45px;
            height: 45px;
          }
        }
        .employeeCardOuter {
          display: $flex;
          overflow: auto;
          margin-top: 10px;
          @include small-laptop {
            white-space: nowrap;
          }
          .newEmployeeCard {
            display: flex;
            background-color: $lightGray2;
            padding: 10px;
            border-radius: 8px;
            width: 310px;
            margin-right: 10px;
            .candidateImg {
              margin-right: 10px;
              img {
                width: 32px;
                height: 32px;
                object-fit: cover;
                object-position: center;
                -o-object-position: center;
                border-radius: 50%;
              }
            }
            .candidateuserleft2 {
              h2 {
                font-size: 14px;
                color: $white;
                display: $flex;
                align-items: $center;
                justify-content: space-between;
              }
              p {
                font-size: 12px;
                color: $white;
                padding: 0;
                font-weight: $regular;
                margin-bottom: 0;
                white-space: normal;
                word-break: break-all;
              }
            }
          }
        }
        .manageTeamImage {
          width: 48px;
          height: 48px;
        }
      }
    }
    .card {
      .card-header {
        .searchSection {
          display: flex;
          .btn {
            background: linear-gradient(
              to right,
              rgba(97, 209, 128, 0.2),
              rgba(2, 48, 32, 0.2)
            );
            color: $whiteO7;
            border-color: rgba(97, 209, 128, 0.3);
            transition: all 0.5s ease-in-out;
            &:hover {
              box-shadow: inset 600px 0 0 0 rgba(97, 209, 128, 0.8);
              color: $white;
            }
          }
          .searchBar {
            position: $relative;
            display: $flex;
            .form-control {
              color: $whiteO7;
              background-color: $grayTint5;
              padding-left: 30px;
              border-color: $whiteO2;
            }
            span {
              position: $absolute;
              top: 10px;
              left: 5px;
            }
          }
        }
        .customerSearch {
          display: $flex;
          @include mobile {
            margin-top: 10px;
          }
          .searchBar {
            position: $relative;
            .form-control {
              border-color: $whiteO5;
              color: $white;
              padding-left: 30px;
              height: 44px;
            }
            span {
              position: $absolute;
              top: 9px;
              left: 5px;
            }
          }
          .dropdown {
            .dropdown-toggle {
              margin-left: 10px;
              border-color: $whiteO5;
              background: transparent;
              &::after {
                background-image: url(../imgs/arrowBottomWhite.svg);
                top: 15px;
              }
              span {
                color: $whiteO7;
              }
            }
          }
        }
      }
      .card-body {
        background: $grayTint3;
        .editableTeamMember {
          background-color: $grayTint8;
          align-items: $center;
          .leadRight {
            .btn {
              width: 35px;
              height: 35px;
              background-color: rgba(97, 209, 128, 0.3);
              border-radius: 50%;
              display: $inline-flex;
              align-items: $center;
              justify-content: $center;
              padding: 0;
              margin-right: 10px;
              &:last-of-type {
                margin: 0;
              }
              img {
                width: 15px;
              }
            }
          }
        }
        .table-responsive {
          .customerTable {
            thead {
              tr {
                th {
                  color: $green;
                  font-weight: $regular;
                  background-color: $grayTint3;
                  border-color: $whiteO7;
                  padding: 10px 10px;
                }
              }
            }
            tbody {
              tr {
                td {
                  background-color: $grayTint3;
                  color: $whiteO8;
                  padding: 15px 10px;
                  .status {
                    padding: 7px 20px;
                    border-radius: 4px;
                    font-size: 14px;
                    font-weight: $regular;
                    display: $inline-block;
                    text-align: $center;
                    &.status-won {
                      color: $green;
                      background-color: $darkGreen;
                      border: 1px solid $darkGreen2;
                    }
                    &.status-lost {
                      background-color: $redTint;
                      border: 1px solid $lightRed;
                      color: $lightRed;
                    }
                    &.status-inactive {
                      background-color: $lightRed2;
                      color: $redTint;
                      border: 1px solid $lightRed2;
                    }
                  }
                }
              }
            }
          }
          .tableEntry {
            display: $flex;
            align-items: $center;
            justify-content: space-between;
            p {
              margin: 0;
              color: $whiteO5;
              font-weight: $regular;
            }
          }
        }
        .activitySection {
          .activityContent {
            display: $flex;
            justify-content: space-between;
            background-color: $lightGray2;
            border-radius: 8px;
            padding: 10px;
            margin-bottom: 10px;
            &:last-of-type {
              margin-bottom: 0;
            }
            .leftContent {
              display: flex;
              align-items: $center;
              img {
                width: 50px;
                height: 50px;
                border-radius: 50%;
                object-fit: cover;
                object-position: $center;
                -o-object-position: $center;
                margin-right: 10px;
              }
              p {
                color: $white;
                font-weight: $regular;
                margin: 0;
                font-size: 14px;
                span {
                  font-weight: $light;
                }
              }
            }
            .rightContent {
              p {
                color: $white;
                font-size: 13px;
                font-weight: $regular;
              }
            }
          }
        }
      }
    }
    .productionCard {
      background-color: $grayTint1;
      border-radius: 8px;
      padding: 15px;
      margin-bottom: 20px;
      h3 {
        color: $white;
        font-size: 24px;
        margin-bottom: 0;
      }
      h4 {
        padding-left: 50px;
        margin: 10px 0;
      }
      p {
        font-size: 12px;
        color: $whiteO5;
        padding-left: 50px;
        span {
          color: $white;
          font-size: 16px;
          padding-left: 20px;
          img {
            margin-top: -15px;
          }
        }
      }
    }
    &.teamDirectory {
      display: $flex;
      flex-direction: column;
      height: 100%;
      @include ipad {
        margin-top: 20px;
      }
      .card {
        display: $flex;
        height: 100%;
        flex-direction: column;
      }
    }
    .listing {
      display: $flex;
      align-items: $center;
      justify-content: space-between;
      border: 1px solid rgba(97, 209, 128, 0.16);
      background: $grayTint1;
      box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
      padding: 10px 10px;
      margin-bottom: 15px;
      > div {
        display: $flex;
        align-items: $center;
        gap: 10px;
      }
      span {
        width: 44px;
        height: 44px;
        display: $inline-flex;
        align-items: $center;
        justify-content: $center;
        border-radius: 4px;
        &.green {
          background-color: rgba(97, 209, 128, 0.1);
        }
        &.red {
          background-color: rgba(255, 27, 27, 0.1);
        }
      }
      h5 {
        font-size: 20px;
        color: $white;
        font-weight: $bold;
        margin: 0;
      }
      p {
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }
  .ticketOuter {
    padding-left: 30px;
    .table-resposnive {
      .table {
        tr {
          th {
            span {
              img {
                margin-left: 5px;
              }
            }
          }
        }
      }
    }
  }
  &.teamView {
    min-height: 100vh;
  }
}
// analytics ends

// users starts
.users {
  background: linear-gradient(
    90deg,
    #202020 0.14%,
    #212121 17.54%,
    #31523e 50.97%,
    #232a26 81.51%,
    #212121 99.9%
  );
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 50px 30px;
  border-radius: 0;
  .card {
    background-color: $grayTint3;
    margin-left: 20px;
    @include ipad {
      margin-left: 0;
    }
    .card-header {
      background-color: $grayTint1;
      padding: 20px;
      h2 {
        color: $white;
        font-size: 30px;
        font-weight: $semibold;
      }
      .exportBtn {
        display: $flex;
        height: 100%;
        align-items: $center;
        a {
          background: $commonGradient;
          color: $white;
          border: 1px solid $green;
          padding: 9px 20px;
          border-radius: 6px;
          font-weight: $regular;
        }
        p {
          color: $white;
          margin-bottom: 0;
          margin-right: 10px;
          font-weight: $regular;
        }
      }
      .dropdown {
        text-align: $right;
        @include mobile {
          margin-top: 10px;
          text-align: $left;
        }
        .userButton {
          position: $relative;
          &::after {
            position: $absolute;
            content: " ";
            background-image: url(../imgs/curveShape.svg);
            width: 18px;
            height: 18px;
            background-size: 16px;
            background-repeat: no-repeat;
            background-position-y: center;
            border: 0;
            top: 14px;
            right: 8px;
          }
          &:hover,
          &:focus {
            color: $white;
            font-weight: $regular;
            border: 1px solid $green;
          }
        }
        .dropdown-menu {
          background-color: $grayTint1;
          padding: 0;
          overflow: hidden;
          li {
            .dropdown-item {
              color: $white;
              cursor: pointer;
              &:hover {
                background-color: rgba(97, 209, 128, 0.3);
                color: $white;
              }
            }
          }
        }
      }
    }
    .card-body {
      padding: 30px 50px;
      .tableOuter {
        .table {
          tr {
            th {
              background-color: $grayTint1;
              border-color: rgba(113, 113, 113, 0.1);
              .form-check {
                .form-check-input {
                  box-shadow: none;
                  border: 1px solid $green;
                  background-color: rgba(97, 209, 128, 0.3);
                }
              }
            }
            td {
              border-color: rgba(113, 113, 113, 0.1);
              .form-check {
                .form-check-input {
                  box-shadow: none;
                  border: 1px solid $green;
                  background-color: rgba(97, 209, 128, 0.3);
                }
              }
              .skillListing {
                padding: 0;
                li {
                  display: $inline-block;
                  background-color: rgba(97, 209, 128, 0.3);
                  color: $white;
                  padding: 3px 3px;
                  border-radius: 8px;
                  font-size: 13px;
                  margin: 0 5px 5px 0;
                  min-width: 20px;
                  text-align: $center;
                }
              }
              .actionBtns {
                display: $flex;
                .btn {
                  padding: 0;
                  margin-right: 5px;
                  &:last-of-type {
                    margin: 0;
                  }
                }
              }
            }
          }
        }
        .pagination {
          margin-top: 30px;
        }
      }
    }
  }
}
// user ends

// affilate url starts
.affilate {
  padding: 50px 10px;
  .commonHeading {
    * {
      margin-bottom: 15px;
    }
  }
  .sectionSubheading {
    h3 {
      font-weight: $semibold;
    }
    p {
      color: $whiteO7;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);
      padding-bottom: 15px;
    }
  }
  .commonHeading {
    hr {
      border-top: 2px solid $whiteO2;
      box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
    }
  }
  &__Content {
    // width: 80%;
    margin: 0 auto;
    background-color: $grayTint3;
    border: 1px solid rgba(97, 209, 128, 0.16);
    padding: 30px 15px;
    text-align: center;
    @include mobile {
      width: 100%;
    }
    h4 {
      color: $white;
    }
    p {
      color: $white;
      font-size: 20px;
      font-weight: $semibold;
      margin-bottom: 50px;
      span {
        color: $yellow;
      }
    }
    .qrCode__Outer {
      display: $flex;
      align-items: $center;
      justify-content: $center;
      gap: 50px;
      border: 2px solid $green;
      border-radius: 8px;
      width: 35%;
      margin: 0 auto;
      padding: 35px 30px;
      @include desktop {
        width: 45%;
      }
      @include medium-desktop {
        width: 50%;
      }
      @include laptop {
        width: 55%;
      }
      @include laptop {
        width: 60%;
      }
      @include ipad {
        width: 75%;
      }
      @include mobile {
        width: 100%;
        flex-wrap: wrap;
      }
    }
    .qrCode {
      width: 200px;
      height: 200px;
      // background-image: url(../imgs/qrOuter.svg);
      // background-size: 98%;
      // background-repeat: no-repeat;
      // background-position: $center;
      // padding: 20px 6px 0 0;
      border-radius: 8px;
      border: 1px solid rgba(97, 209, 128, 0.16);
      padding: 5px;
      text-align: center;
      // margin: 0 auto;
      display: $flex;
      align-items: $center;
      justify-content: $center;
      img {
        width: 100%;
        max-width: 75%;
        border: 1px solid rgba(97, 209, 128, 0.16);
        border-radius: 8px;
        background-color: $grayTint3;
        padding: 5px;
      }
      canvas {
        background-color: $white;
        padding: 6px;
        width: 170px !important;
        height: 170px !important;
      }
    }
    .shareBtns {
      margin: 50px 0 20px;
      button {
        background-color: transparent;
        border: 0;
      }
    }
    .shareBtn {
      color: $white;
      background: $commonGradient;
      border-radius: 8px;
      border: 0;
      margin-top: 30px;
      width: 280px;
      padding: 10px 0;
      font-weight: $regular;
      @include mobile {
        width: 100%;
      }
    }
    .divider {
      border: 1px solid rgba(255, 255, 255, 0.04);
      background: rgba(0, 0, 0, 0.1);
      box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
      margin: 60px 0 50px;
    }
    h4 {
      font-size: 26px;
      font-weight: $semibold;
      width: 85%;
      margin: 0 auto 50px;
      @include ipad {
        width: 100%;
      }
      span {
        color: $yellow;
      }
    }
    .form-section {
      height: 100%;
      display: $flex;
      flex-direction: column;
      justify-content: center;
      width: 35%;
      margin: 50px auto 0;
      @include desktop {
        width: 45%;
      }
      @include medium-desktop {
        width: 50%;
      }
      @include laptop {
        width: 55%;
      }
      @include laptop {
        width: 60%;
      }
      @include ipad {
        width: 75%;
      }
      @include mobile {
        width: 100%;
      }
      .form-group {
        position: $relative;
        span {
          position: absolute;
          top: 10px;
          right: 10px;
        }
        .form-control {
          color: $whiteO7;
          padding-right: 30px;
        }
      }
    }
    .entryCounter {
      h3 {
        font-size: 26px;
        margin-bottom: 50px;
      }
      &__Content {
        position: $relative;
        svg {
          position: $relative;
          width: 190px;
          height: 190px;
          z-index: 1000;
          box-shadow: 0px 0px 24px rgba(255, 255, 255, 0.2);
          border-radius: 100%;
          circle {
            width: 100%;
            height: 100%;
            fill: none;
            box-shadow: 0px 0px 24px rgba(255, 255, 255, 0.2);
            stroke: #191919;
            stroke-width: 10;
            stroke-linecap: round;
            transform: translate(5px, 5px);
            &:nth-child(2) {
              stroke-dashoffset: 570;
              stroke: $green;
            }
          }
        }
        .number {
          position: $absolute;
          top: 5px;
          left: 50%;
          transform: translate(-50%, 70%);
          @include mobile {
            transform: translate(-50%, 100%);
          }
          h2 {
            color: $white;
            font-weight: $semibold;
            font-size: 60px;
            line-height: 25px;
            span {
              font-size: 20px;
            }
          }
        }
        ul {
          display: $flex;
          height: 100%;
          flex-direction: column;
          justify-content: $center;
          margin-top: 30px;
          li {
            position: $relative;
            text-align: $left;
            list-style-type: none;
            color: $white;
            margin-bottom: 15px;
            font-size: 18px;
            &::after {
              position: $absolute;
              content: " ";
              width: 20px;
              height: 20px;
              background-image: url(../imgs/tickGreendark.svg);
              background-size: 18px;
              background-repeat: no-repeat;
              left: -25px;
              top: 5px;
            }
          }
        }
      }
    }
  }
}
// affilate url ends

// terms page starts
.terms {
  &__Content {
    h3 {
      color: $white;
      margin: 30px 0 15px;
      font-weight: $semibold;
    }
    h5 {
      color: $white;
      font-weight: $semibold;
      margin-top: 30px;
    }
    p {
      color: $whiteO7;
      a {
        color: $green;
      }
      @include mobile {
        word-wrap: break-all;
      }
    }
    ul {
      li {
        color: $whiteO7;
        margin-bottom: 8px;
        list-style-type: none;
        position: $relative;
        &::after {
          position: $absolute;
          content: " ";
          width: 20px;
          height: 20px;
          background-image: url(../imgs/tickGreendark.svg);
          background-size: 18px;
          background-repeat: no-repeat;
          left: -25px;
          top: 3px;
        }
        a {
          color: $green;
        }
      }
    }
    .table-responsive {
      .table {
        tr {
          th {
            background-color: $grayTint1;
            color: $white;
            padding: 15px;
            font-size: 14px;
            border-color: $grayTint8;
          }
          td {
            background-color: $grayTint3;
            color: $white;
            padding: 15px;
            font-size: 14px;
            border-color: $grayTint8;
            a {
              color: $green;
            }
          }
        }
      }
    }
  }
}
// terms page ends

#half-stars-example {
  position: relative;

  /* use display:inline-flex to prevent whitespace issues. alternatively, you can put all the children of .rating-group on a single line */
  .mainLabel {
    display: $block;
    color: $whiteO5;
    text-align: $left;
    font-weight: $regular;
    font-size: 14px;
  }
  .rating-group {
    // display: inline-flex;
    text-align: $left;
  }

  /* make hover effect work properly in IE */
  .rating__icon {
    pointer-events: none;
  }

  /* hide radio inputs */
  .rating__input {
    position: absolute !important;
    left: -9999px !important;
  }

  /* set icon padding and size */
  .rating__label {
    cursor: pointer;
    /* if you change the left/right padding, update the margin-right property of .rating__label--half as well. */
    padding: 0 0.1em;
    font-size: 1.2rem;
  }

  /* add padding and positioning to half star labels */
  .rating__label--half {
    padding-right: 0;
    margin-right: -0.6em;
    z-index: 2;
    position: absolute;
  }

  /* set default star color */
  .rating__icon--star {
    color: orange;
  }

  /* set color of none icon when unchecked */
  .rating__icon--none {
    color: #eee;
  }

  /* if none icon is checked, make it red */
  .rating__input--none:checked + .rating__label .rating__icon--none {
    color: red;
  }

  /* if any input is checked, make its following siblings grey */
  .rating__input:checked ~ .rating__label .rating__icon--star {
    color: #ddd;
  }

  /* make all stars orange on rating group hover */
  .rating-group:hover .rating__label .rating__icon--star,
  .rating-group:hover .rating__label--half .rating__icon--star {
    color: orange;
  }

  /* make hovered input's following siblings grey on hover */
  .rating__input:hover ~ .rating__label .rating__icon--star,
  .rating__input:hover ~ .rating__label--half .rating__icon--star {
    color: #ddd;
  }

  /* make none icon grey on rating group hover */
  .rating-group:hover
    .rating__input--none:not(:hover)
    + .rating__label
    .rating__icon--none {
    color: #eee;
  }

  /* make none icon red on hover */
  .rating__input--none:hover + .rating__label .rating__icon--none {
    color: red;
  }
}

// contact us starts
.contactSection {
  padding: 110px 0;
  &__Img {
    @include ipad {
      display: none;
    }
    img {
      width: 80%;
      @include large-ipad {
        width: 100%;
      }
    }
  }
  &__Form {
    form {
      background-color: $grayTint3;
      padding: 20px;
      border-radius: 8px;
      h3 {
        color: $white;
        font-size: 40px;
        font-weight: $semibold;
        text-align: $center;
        margin-bottom: 30px;
      }
      .form-group {
        margin-bottom: 20px;
        .form-control {
          color: $whiteO8;
        }
        textarea {
          height: 150px;
          resize: none;
        }
        .btn-send {
          background: $commonGradient;
          color: $white;
          border: $none;
          font-weight: $regular;
          font-size: 18px;
          padding: 10px 30px;
          margin-top: 30px;
          width: 100%;
        }
      }
    }
  }
}
// contact us ends

.publicQr {
  text-align: $center;
  min-height: 100vh;
  display: $flex;
  align-items: center;
  justify-content: center;
  background-image: url(../imgs/featuresBg.svg);
  background-size: cover;
  &__Content {
    margin-bottom: 50px;
    h3 {
      font-size: 40px;
      color: $white;
    }
    img {
      width: 100%;
      max-width: 120px;
    }
  }
  &__Img {
    width: 200px;
    height: 200px;
    background-image: url(../imgs/qrOuter.svg);
    background-size: 98%;
    background-repeat: no-repeat;
    background-position: $center;
    // padding: 20px 6px 0 0;
    text-align: center;
    margin: 0 auto;
    display: $flex;
    align-items: $center;
    justify-content: $center;
    canvas {
      background-color: #fff;
      padding: 3px;
    }
  }
}

.order2 {
  @include mobile {
    order: 2;
  }
}
.order1 {
  @include mobile {
    order: 1;
    margin-bottom: 20px;
  }
}

// error page starts
.errorPage {
  min-height: 90vh;
  display: $flex;
  flex-direction: column;
  justify-content: $center;
  align-items: $center;
  &__Content {
    text-align: $center;
    h3 {
      color: $white;
      font-weight: $bold;
      font-size: 48px;
      @include ipad {
        font-size: 36px;
      }
      @include mobile {
        font-size: 30px;
      }
    }
    h1 {
      color: $green;
      font-size: 250px;
      font-weight: $semibold;
      @include mobile {
        font-size: 120px;
      }
    }
    p {
      color: #fff;
      font-size: 24px;
      font-weight: $semibold;
      @include ipad {
        font-size: 20px;
      }
      @include mobile {
        font-size: 16px;
      }
    }
  }
}
// error page ends
.imageUploaderSection {
  .fixed {
    // > div {
    //   padding: 0 !important;
    // }
  }
  .uploaderInner {
    .crop-container {
      // background: linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 0,
      // linear-gradient(to right, #cbd4e1 8px, transparent 8px) 0 100%,
      // linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 0,
      // linear-gradient(to left, #cbd4e1 8px, transparent 8px) 100% 100%,
      // linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 0 0,
      // linear-gradient(to bottom, #cbd4e1 8px, transparent 8px) 100% 0,
      // linear-gradient(to top, transparent 8px, transparent 8px) 0 100%,
      // linear-gradient(to top, #cbd4e1 8px, transparent 8px) 100% 100%;
      background: none;
      background-image: url(../imgs/qrOuter.svg);
      background-repeat: no-repeat;
      background-size: cover;
      height: 242px;
    }
    .uploaderFileSection {
      display: flex;
      justify-content: $center;
      @include large-ipad {
        flex-direction: column;
        align-items: center;
      }
      @include mobile-landscape {
        margin-top: 20px;
      }
      p {
        margin: 0 !important;
        border: 1px solid $grayTint8;
        border-radius: 8px;
        padding: 7px 10px;
        text-align: $left;
        position: $relative;
        width: 60%;
        @include medium-desktop {
          font-size: 14px !important;
        }
        @include mobile {
          width: 90%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-direction: row-reverse;
        }
        img {
          width: 100%;
          max-width: 30px;
          float: right;
        }
        input {
          position: $absolute;
          width: 100%;
          height: 100%;
          left: 0;
          top: 0;
          opacity: 0;
          cursor: pointer;
        }
      }
      button {
        background: $commonGradient;
        border: none;
        color: $white;
        font-weight: $semibold;
        border-radius: 8px;
        margin-left: 20px;
        @include medium-desktop {
          font-size: 14px;
          padding: 0 10px !important;
        }
        @include large-ipad {
          padding: 5px 10px !important;
          margin-top: 20px;
        }
      }
    }
  }
  .cropperArea {
    height: 280px;
    width: 280px;
    margin: 0 auto;
    position: $relative;
    h6 {
      font-size: 16px;
      color: $whiteO5;
      text-align: left;
    }
    .reactEasyCrop_Container {
      top: 30px;
      left: 0;
    }
  }
  .cropperBtns {
    margin: 30px 0;
    button {
      background-color: rgba(97, 209, 128, 0.2);
      border: 0;
      padding: 10px 12px;
      border-radius: 8px;
      display: $inline-flex;
      align-items: $center;
      justify-content: $center;
      margin: 0 3px;
      img {
        width: 100%;
        max-width: 14px;
      }
      &.first {
        img {
          transform: rotateY(180deg);
        }
      }
    }
    input[type="range"] {
      -webkit-appearance: none;
      height: 10px;
      border-radius: 5px;
      background: #d3d3d3; /* Set the background color */
      outline: none;
      margin: 10px 0; /* Optional: Add some spacing */
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: $green; /* Set the thumb color */
        cursor: pointer;
        margin-top: -5px; /* Center the thumb vertically */
      }
      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 10px;
        border-radius: 5px;
        background: #d3d3d3; /* Set the track color */
      }
    }
  }
}
// Admin styling

.adminTopRowSetting {
  // margin: 45px 0px;
  // background-color: #000;
  display: $flex;
  align-items: flex-start;
  div {
    margin: 0px 13px;
    p {
      color: $white;
      font-size: 20px;
      font-weight: $normal;
      margin-bottom: 0px;
    }
    span {
      color: $green;
      // font-size: clamp(1rem, 4vw, 1.125rem);
      &.newUserData {
        font-size: 40px;
        font-weight: $semibold;
        font-family: manrope;
      }
      a {
        color: $green;
        word-break: break-all;
      }
    }
  }
  img {
    margin-top: 5px;
    size: 40px;
  }
  .todayData {
    font-weight: 10;
    // color: red;
  }
  .topArrow {
    height: 25px;
    width: 25px;
    // size: 22px;
  }
}
.manageTeamImage {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  object-fit: cover;
  object-position: center;
  margin-left: 5px;
  margin-right: 5px;
}
.imageGroupTeamMember {
  display: block;
}
.newUserData {
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: #191a1e;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 15px;
}
.viewAllButton {
  border: 1px solid #61d180;
  color: white;
  font-weight: 500;
  padding: 9px 30px;
  background-color: #1d1d1d;
  margin-right: 12px;
}

.hiringStatisticsBody {
  font-size: 27px;
  margin-left: 30px;
  margin-right: 30px;
  .divider {
    background-color: rgba(97, 209, 128, 0.15);
    height: 2px;
    margin: 25px 0;
  }
}
.hiringStatistics {
  // padding: 38px;
  margin-top: 20px;
  display: $flex;
  justify-content: space-between;
  align-items: $center;
  margin-bottom: 15px;
  .hiringStatisticsInner {
    display: flex;
    justify-content: space-between;
    align-items: $center;
    img {
      margin-right: 10px;
    }
    p {
      font-size: 16px;
      color: $white;
      margin: 0;
    }
  }
  .count {
    font-weight: $semibold;
    font-size: 20px;
    color: $white;
    margin-bottom: 0;
  }
}
.ticketMain {
  display: flex;
  padding: 34px;
}
.ticketBox {
  border-radius: 10px;
  // height: 200px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: #191a1e;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 30px 40px;
  margin: 0 0 15px 0;
  .ticketBoxBody {
    // display: flex;
    // justify-content: space-around;
    // margin-top: 42px;
  }
  .ticketData {
    h3 {
      font-size: 28px;
      color: #fff;
      font-weight: $semibold;
    }
    p {
      color: #c9c9c9;
      font-size: 15px;
      font-weight: $regular;
    }
  }
}
.userButton {
  background-image: linear-gradient(91deg, #61d180 0.07%, #023020 112.02%);
  border: 1px solid #61d180;
  color: #fff;
  font-weight: 500;
  padding: 9px 30px;
  &::after {
    position: $absolute;
    content: " ";
    background-image: url(../imgs/arrowBottomWhite.svg);
    width: 18px;
    height: 18px;
    background-image: 18px;
    border: 0;
  }
}
.adminButtonGroup {
  // margin-left: 32px;
  .adminTeamButton {
    background: linear-gradient(91deg, #202825 0.07%, #023020 112.02%);
    border: 1px solid $green;
    color: #fff;
    font-weight: 500;
    padding: 12px 30px;
    margin: 10px 0;
    border-radius: 8px;
    width: 160px;
    transition: all 0.3s ease-in-out;
    @include large-ipad {
      width: 100%;
    }
    @include ipad {
      width: 160px;
    }
    &.activeButton,
    &:hover {
      background: $commonGradient;
    }
  }
}
.TopPerformers {
  margin-bottom: 12px;
  padding: 10px 10px;
  justify-content: space-between;
  display: flex;
  color: $white;
  border-radius: 8px;
  background-color: $grayTint1;
  @include mobile {
    flex-direction: column;
  }
  &.firstPosition {
    background-color: rgba(253, 197, 0, 0.38);
  }
  &.secondPosition {
    background-color: $lightGray2;
  }
  &.thirdPosition {
    background-color: rgba(61, 45, 14, 0.8);
  }
  .leadLeft {
    display: flex;
    align-items: center;
    gap: 10px;
    .leadPosition {
      font-size: 20px;
      margin: 0;
    }
    .leadHeading {
      font-size: 14px;
      font-weight: $semibold;
      margin: 0;
    }
    .leadImage {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      object-fit: cover;
      object-position: $center;
      -o-object-position: $center;
    }
    p {
      color: $white;
      font-size: 12px;
      margin: 0;
    }
  }
  .leadRight {
    display: flex;
    justify-content: space-around;
    @include mobile {
      margin: 20px 0 0;
    }
    .leadCounter {
      text-align: $center;
      background-color: rgb(255, 255, 255, 0.4);
      border-radius: 8px;
      padding: 7px;
      width: 80px;
      margin-right: 10px;
      &:last-of-type {
        margin: 0;
      }
      h4 {
        font-size: 24px;
        margin: 0;
      }
      p {
        font-size: 10px;
        margin: 0;
      }
    }
  }
}
.sectionShareJob {
  padding: 50px 0;
  .jobPosted__List {
    .postHeader {
      @include mobile {
        flex-direction: column;
      }
    }
    .postBodyContent {
      h4 {
        @include mobile {
          word-break: break-all;
        }
      }
    }
  }
}
// .dashboardtimeperiod {
//   color: rgba(255, 255, 255, 0.7);
//   font-size: 14px;
//   margin: 0 13px;
// }
