//Responsive media mixins
@mixin desktop {
  @media (max-width: #{$desktop}) {
    @content;
  }
}
@mixin desktop2 {
  @media (max-width: #{$desktop2}) {
    @content;
  }
}
@mixin medium-desktop {
  @media (max-width: #{$medium-desktop}) {
    @content;
  }
}
@mixin laptop {
  @media (max-width: #{$laptop}) {
    @content;
  }
}
@mixin small-laptop {
  @media (max-width: #{$small-laptop}) {
    @content;
  }
}
@mixin large-ipad {
  @media (max-width: #{$large-ipad}) {
    @content;
  }
}
@mixin ipad-landscape {
  @media (max-width: #{$ipad-landscape}) {
    @content;
  }
}
@mixin ipad {
  @media (max-width: #{$ipad}) {
    @content;
  }
}
@mixin tablet {
  @media (max-width: #{$tablet}) {
    @content;
  }
}
@mixin mobile-landscape {
  @media (max-width: #{$mobile-landscape}) {
    @content;
  }
}
@mixin mobile {
  @media (max-width: #{$mobile}) {
    @content;
  }
}

@mixin mobile-small {
  @media (max-width: #{$mobile-small}) {
    @content;
  }
}

@mixin fold {
  @media (max-width: #{$fold}) {
    @content;
  }
}