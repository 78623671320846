/* overlay translate animation starts */

@keyframes bgslideLeft {
  0% {
    width: 50%;
    left: 0;
  }
  50% {
    width: 50%;
    left: -100vw;
  }
  100% {
    left: 0;
    width: 0;
  }
}
@keyframes bgslideRight {
  0% {
    width: 50%;
    right: 0;
  }
  50% {
    width: 50%;
    right: -100vw;
  }
  100% {
    right: 0;
    width: 0;
  }
}

/* overlay translate animation ends */

/* fade in animation starts */
@keyframes fadeIn{
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
}
  
  @-moz-keyframes fadeIn {
    0% {
      opacity:0;
    }
    100% {
      opacity:1;
    }
  }
  
@-webkit-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
  
@-o-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}

@-ms-keyframes fadeIn {
    0% {
        opacity:0;
    }
    100% {
        opacity:1;
    }
}
/* fade in animation ends */


/* Bgmoving animation starts */
@-webkit-keyframes slidein {
  from {background-position: top; background-size:100%px; }
  to {background-position: -100px 0px;background-size:130%;}
}
    
@keyframes slidein {
  from {background-position: top;background-size:100%; }
  to {background-position: -100px 0px;background-size:130%;}
}

@-webkit-keyframes moving {
  from {background-position: top; background-size:100%px; }
  to {background-position: 0 0;background-size:130%;}
}
    
@keyframes moving {
  from {background-position: top;background-size:100%; }
  to {background-position: 0 0;background-size:130%;}
}
/* Bgmoving animation starts */


@-webkit-keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}
@keyframes mover {
    0% { transform: translateY(0); }
    100% { transform: translateY(-10px); }
}


@keyframes zoom-in-zoom-out {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(1.4, 1.4);
  }
  100% {
    transform: scale(1, 1);
  }
}


/* Tada animation */
@keyframes taadaa { 
  0% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}