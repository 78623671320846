.navbar {
    // background-color: $grayTint5;
    padding: 15px 50px;
    @include mobile {
        padding: 15px 0px;
    }
    .navbar-brand {
        img {
            width: 100%;
            max-width: 130px;
            margin-left: 20px;
            @include large-ipad {
                margin: 0;
            }
            @include mobile {
                max-width: 90px;
            }
        }
    }
    .navbar-brand-center {
        @include ipad {
            display: none;
        }
    }
    .navbar-toggler {
        box-shadow: none;
        padding: 0;
        border: 0;
        .navbar-toggler-icon {
            background-image: url(../imgs/menuicon.svg);
        }
    }
    .navbar-collapse {
        justify-content: space-between;
    }
    .navbar-nav {
        .nav-item {
            .nav-link {
                color: $white;
                font-size: 18px;
                margin-right: 20px;
                transition: all 0.3s ease-in-out;
                &:hover {
                    color: $green;
                    text-shadow: 0 0 40px rgba(97, 209, 128, 0.7);
                }
                @include ipad {
                    margin: 0 0 0 15px;
                    font-size: 16px;
                }
            }
            &:last-of-type {
                .nav-link {
                    @include ipad {
                        margin: 0 0 0 15px;
                    }
                }
            }
            &.active {
                .nav-link {
                    color: $green;
                    text-shadow: 0 0 40px rgba(97, 209, 128, 0.7);
                }
            }
        }
        &:last-of-type {
            text-align: $right;
            @include ipad {
                text-align: $left;
            }
        }
        .btn-createAcount {
            border: 1px solid $green;
            color: $green;
            font-weight: $regular;
            padding: 10px 30px;
            margin-left: 20px;
            transition: all 0.6s ease-in-out;
            @include ipad {
                margin: 20px 0 0 15px;
                width: 210px;
            }
            &:hover {
                // background-color: $green;
                color: $white;
                box-shadow: inset 400px 0 0 0 #61D180;
                // box-shadow: 0 0 10px rgba(97, 209, 128, 0.7);
                transform: scale(1.01);
            }
        }
    }
}