// colours
$white: #fff;
$black: #000;
$dark: #0D0D0D;
$whiteO1: rgba(255, 255, 255, 0.10);
$whiteO2: rgba(255, 255, 255, 0.2);
$whiteO3: rgba(255, 255, 255, 0.30);
$whiteO4: rgba(255, 255, 255, 0.40);
$whiteO5: rgba(255, 255, 255, 0.50);
$whiteO7: rgba(255, 255, 255, 0.7);
$whiteO8: rgba(255, 255, 255, 0.8);
$whiteTint1: #fafafa;
$green: #61D180;
$darkGreen: #023020;
$darkGreen2: #094e36;
$red: #FF1B1B;
$reddish: #EA4335;
$lightRed: #C48787;
$lightRed2: #FFC5C5;
$redTint: #BB1B16;
$redTint2: #DF0404;
$yellow: #FDC500;
$purple: #A9ACFF;
$reddish2: #FF5C5C;
$reddish3: #DE5753;
$orange: #F28900;
$grayTint1: #1D1D1D;
$grayTint2: #060606;
$grayTint3: #191A1E;
$grayTint4: #131313;
$grayTint5: #141414;
$grayTint6: #979797;
$grayTint7: #3D3D3D;
$grayTint8: #47484B;
$grayTint9: #cccccc;
$grayTint10: #1F1D1F;
$grayTint11:#d6d6d6;
$lightGray: #3D3A27;
$lightGray2: #3E4240;
$commonGradient: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);

// alignments
$center: center;
$right: right;
$left: left;

// display
$inline-block: inline-block;
$block: block;
$flex: flex;
$grid: grid;
$inline-flex: inline-flex;
$none: none;

// font weight
$light: 300;
$normal: 400;
$regular: 500;
$semibold: 600;
$bold: 700;

// positions
$static: static;
$relative: relative;
$absolute: absolute;
$fixed: fixed;


// breakpoints
$desktop: 1600px;
$desktop2: 1500px;
$medium-desktop: 1440px;
$laptop: 1366px;
$small-laptop: 1280px;
$large-ipad: 1199px;
$ipad-landscape: 1024px;
$ipad: 991px;
$tablet: 768px;
$mobile-landscape: 767px;
$mobile: 575px;
$mobile-small: 360px;
$fold: 280px;
