/* @font-face {
    font-family: NexaLight;
    src: url(../webFonts/NexaLight.otf);
    font-family: 'Nexa Light';
}
@font-face {
    font-family: NexaRegular;
    src: url(../webFonts/NexaRegular.otf);
    font-family: 'Nexa Regular';
}
@font-face {
    font-family: NexaBold;
    src: url(../webFonts/NexaBold.otf);
    font-family: 'Nexa Bold';
} */
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400&display=swap");

@font-face {
  font-family: "Generalsans";
  /* src: url('https://uploads-ssl.webflow.com/654d16187729d564109251d4/654d16197729d56410925279_GeneralSans-Bold.ttf') format('truetype'); */
  src: url("../fonts/Generalsans700/654d16197729d56410925279_GeneralSans-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Generalsans";
  /* src: url('https://uploads-ssl.webflow.com/654d16187729d564109251d4/654d16197729d5641092527c_GeneralSans-Semibold.ttf') format('truetype'); */
  src: url("../fonts/Generalsans600/654d16197729d5641092527c_GeneralSans-Semibold.ttf")
    format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Generalsans";
  /* src: url('https://uploads-ssl.webflow.com/654d16187729d564109251d4/654d16197729d56410925284_GeneralSans-Regular.ttf') format('truetype'); */
  src: url("../fonts/Generalsans400/654d16197729d56410925284_GeneralSans-Regular.ttf")
    format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Generalsans";
  /* src: url('https://uploads-ssl.webflow.com/654d16187729d564109251d4/654d16197729d564109252a0_GeneralSans-Medium.ttf') format('truetype'); */
  src: url("../fonts/Generalsans500/654d16197729d564109252a0_GeneralSans-Medium.ttf")
    format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
