@import 'fonts.css';
@import 'bootstrap.min.css';
@import 'animation.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.4.2/css/all.min.css';
@import 'https://cdnjs.cloudflare.com/ajax/libs/bootstrap-select/1.14.0-beta2/css/bootstrap-select.min.css';
.navbar {
  padding: 15px 50px;
}
@media (max-width: 575px) {
  .navbar {
    padding: 15px 0px;
  }
}
.navbar .navbar-brand img {
  width: 100%;
  max-width: 130px;
  margin-left: 20px;
}
@media (max-width: 1199px) {
  .navbar .navbar-brand img {
    margin: 0;
  }
}
@media (max-width: 575px) {
  .navbar .navbar-brand img {
    max-width: 90px;
  }
}
@media (max-width: 991px) {
  .navbar .navbar-brand-center {
    display: none;
  }
}
.navbar .navbar-toggler {
  box-shadow: none;
  padding: 0;
  border: 0;
}
.navbar .navbar-toggler .navbar-toggler-icon {
  background-image: url(../imgs/menuicon.svg);
}
.navbar .navbar-collapse {
  justify-content: space-between;
}
.navbar .navbar-nav .nav-item .nav-link {
  color: #fff;
  font-size: 18px;
  margin-right: 20px;
  transition: all 0.3s ease-in-out;
}
.navbar .navbar-nav .nav-item .nav-link:hover {
  color: #61D180;
  text-shadow: 0 0 40px rgba(97, 209, 128, 0.7);
}
@media (max-width: 991px) {
  .navbar .navbar-nav .nav-item .nav-link {
    margin: 0 0 0 15px;
    font-size: 16px;
  }
}
@media (max-width: 991px) {
  .navbar .navbar-nav .nav-item:last-of-type .nav-link {
    margin: 0 0 0 15px;
  }
}
.navbar .navbar-nav .nav-item.active .nav-link {
  color: #61D180;
  text-shadow: 0 0 40px rgba(97, 209, 128, 0.7);
}
.navbar .navbar-nav:last-of-type {
  text-align: right;
}
@media (max-width: 991px) {
  .navbar .navbar-nav:last-of-type {
    text-align: left;
  }
}
.navbar .navbar-nav .btn-createAcount {
  border: 1px solid #61D180;
  color: #61D180;
  font-weight: 500;
  padding: 10px 30px;
  margin-left: 20px;
  transition: all 0.6s ease-in-out;
}
@media (max-width: 991px) {
  .navbar .navbar-nav .btn-createAcount {
    margin: 20px 0 0 15px;
    width: 210px;
  }
}
.navbar .navbar-nav .btn-createAcount:hover {
  color: #fff;
  box-shadow: inset 400px 0 0 0 #61D180;
  transform: scale(1.01);
}

body {
  margin: 0;
  font-family: Generalsans, sans-serif;
  background-color: #0D0D0D;
  overflow-x: hidden;
}
body::-webkit-scrollbar {
  width: 6px;
}
body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
body::-webkit-scrollbar-thumb {
  background-color: rgba(97, 209, 128, 0.2);
}

.mh-100 {
  min-height: 100vh;
}

.mh-89 {
  min-height: 89vh;
}

a {
  text-decoration: none;
}

.commonRadius8 {
  border-radius: 8px;
}

.commonRadius10 {
  border-radius: 10px;
}

.release {
  text-align: right;
}
@media (max-width: 1024px) {
  .release {
    margin-bottom: 20px !important;
    order: 1;
  }
}
@media (max-width: 991px) {
  .release {
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    order: 2;
  }
}
@media (max-width: 575px) {
  .release {
    margin-bottom: 20px !important;
    margin-top: 0 !important;
    text-align: right;
    order: 1;
    width: 100%;
  }
}
.release p {
  font-size: 18px;
}
.release p span {
  background-color: #1D1D1D;
  padding: 12px 20px;
  color: #fff;
  font-weight: 600;
  display: inline-block;
}
@media (max-width: 575px) {
  .release p span {
    padding: 10px;
    display: inline-block;
    font-size: 12px;
  }
}

.form-control {
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background-color: #1D1D1D;
  height: 50px;
  color: rgba(255, 255, 255, 0.2);
  box-shadow: none;
}
.form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.2);
}
.form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.2);
}
.form-control:focus {
  background-color: #1D1D1D;
  box-shadow: none;
  border: 1px solid rgba(97, 209, 128, 0.16);
}

.stepProgressBar {
  margin: 30px 0 30px 0;
}
.stepProgressBar .progress {
  background-color: #47484B;
  height: 8px;
}
.stepProgressBar .progress .progress-bar {
  background-color: #61D180;
  border-radius: 8px;
}
.stepProgressBar p {
  margin: 20px 0 0 0;
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 18px;
}
@media (max-width: 575px) {
  .stepProgressBar p {
    font-size: 16px;
  }
}
.green {
  color: #61D180;
}

.white {
  color: #fff;
}

.red {
  color: #FF1B1B;
}

.commonHeading {
  margin-bottom: 30px;
}
.commonHeading * {
  font-size: clamp(1.5rem, 4vw, 2.5rem);
  color: #fff;
  font-weight: 700;
  margin: 0;
}

.commonBtn {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  font-weight: 600;
  border: none;
  font-size: 18px;
  border-radius: 8px;
  padding: 10px 20px;
}
.commonBtn:hover {
  color: #fff;
}

.commonCard {
  background-color: #191A1E;
  border: 1px solid rgba(97, 209, 128, 0.16);
  box-shadow: 0px 3.5px 5.5px 0px rgba(0, 0, 0, 0.02);
  border-radius: 15px;
  transition: all 0.3s ease-in-out;
}
.commonCard:hover {
  border-color: #61D180;
  box-shadow: 0px 3.5px 5.5px 0px rgba(97, 209, 128, 0.4);
}
.commonCard a {
  display: flex;
  padding: 25px;
  align-items: center;
  gap: 20px;
}
.commonCard a h4 {
  font-size: 30px;
  color: #fff;
}
@media (max-width: 1440px) {
  .commonCard a h4 {
    font-size: 24px;
  }
}
.commonCard a span {
  display: inline-flex;
  background-color: #0D0D0D;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
}
@media (max-width: 1199px) {
  .commonCard a span {
    width: 110px;
    height: 90px;
  }
}
.commonCard a span img {
  width: 100%;
  max-width: 70px;
}
@media (max-width: 1366px) {
  .commonCard a span img {
    max-width: 60px;
  }
}
@media (max-width: 1199px) {
  .commonCard a span img {
    max-width: 50px;
  }
}

.btnSection {
  text-align: right;
}
@media (max-width: 575px) {
  .btnSection {
    text-align: center;
  }
}
.btnSection .btn {
  padding: 10px;
  border-radius: 8px;
  min-width: 180px;
  max-width: 180px;
  height: 50px;
  border: 0;
  margin: 0 5px;
  font-weight: 600;
}
@media (max-width: 575px) {
  .btnSection .btn {
    min-width: 160px;
    max-width: 160px;
    margin-bottom: 5px;
  }
}
.btnSection .btn.btn-cancel {
  background-color: #3D3D3D;
  color: rgba(255, 255, 255, 0.3);
}
.btnSection .btn.btn-save {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
}
.btnSection .btn.btn-share {
  background-color: #1D1D1D;
  border: 1px solid rgba(97, 209, 128, 0.1);
  color: #fff;
}

.photoUploadSection {
  text-align: left;
}
.photoUploadSection label {
  color: rgba(255, 255, 255, 0.2);
  margin-bottom: 10px;
}

.dragDrop {
  background-color: #191A1E;
  border: 1px dashed rgba(97, 209, 128, 0.16);
  border-radius: 8px;
  text-align: center;
  padding: 40px;
  position: relative;
}
.dragDrop input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
}
.dragDrop p {
  color: rgba(255, 255, 255, 0.4);
}
.dragDrop p .green {
  color: #61D180;
}
.dragDrop p .white {
  color: #fff;
}

.sectionPadding {
  padding: 50px 0;
}

.commonBg-animation {
  -webkit-animation: moving 70s infinite;
  animation: moving 70s infinite;
}

.commonLoader {
  background-color: rgba(13, 13, 13, 0.9);
  position: fixed;
  z-index: 1;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}
.commonLoader .inner {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  animation: taadaa 3s infinite;
}
.commonLoader .inner img {
  width: 100%;
  max-width: 150px;
}

.react-datepicker-wrapper {
  width: 100%;
}
.react-datepicker-wrapper .react-datepicker__input-container input {
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background-color: #1D1D1D;
  height: 50px;
  color: rgba(255, 255, 255, 0.8);
  box-shadow: none;
  width: 100%;
  padding: 0.375rem 0.75rem;
  outline: none;
}

.react-datepicker__month-wrapper .react-datepicker__month-text {
  color: rgba(255, 255, 255, 0.8);
}
.react-datepicker__month-wrapper .react-datepicker__month-text:hover {
  background-color: rgba(97, 209, 128, 0.16);
  color: #61D180;
}

.react-datepicker-popper .react-datepicker {
  background-color: #1D1D1D;
  border-color: #47484B;
}
.react-datepicker-popper .react-datepicker .react-datepicker__triangle::after {
  border-bottom-color: #1D1D1D !important;
}
.react-datepicker-popper .react-datepicker .react-datepicker__header {
  background-color: #1D1D1D;
  border-color: #47484B;
  color: #fff;
}
.react-datepicker-popper .react-datepicker .react-datepicker__header > div {
  justify-content: space-between !important;
  color: rgba(255, 255, 255, 0.8);
}
.react-datepicker-popper .react-datepicker .react-datepicker__header > div button {
  border: 1px solid rgba(97, 209, 128, 0.16);
  background-color: #979797;
  border-radius: 5px;
}
.react-datepicker-popper .react-datepicker .react-datepicker__header > div select {
  border: 1px solid rgba(97, 209, 128, 0.16);
  background-color: #979797;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}
.react-datepicker-popper .react-datepicker .react-datepicker__header .react-datepicker__day-names .react-datepicker__day-name {
  color: rgba(255, 255, 255, 0.8);
}
.react-datepicker-popper .react-datepicker .react-datepicker__month .react-datepicker__day {
  color: rgba(255, 255, 255, 0.8);
}
.react-datepicker-popper .react-datepicker .react-datepicker__month .react-datepicker__day.react-datepicker__day--selected {
  background-color: #979797;
}
.react-datepicker-popper .react-datepicker .react-datepicker__month .react-datepicker__day:hover {
  background-color: #979797;
}
.react-datepicker-popper .react-datepicker .react-datepicker__year-dropdown {
  background-color: #1D1D1D;
  border-color: #47484B;
  overflow-x: hidden;
  height: 170px;
}
.react-datepicker-popper .react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar {
  width: 6px;
}
.react-datepicker-popper .react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.react-datepicker-popper .react-datepicker .react-datepicker__year-dropdown::-webkit-scrollbar-thumb {
  background-color: rgba(97, 209, 128, 0.2);
}
.react-datepicker-popper .react-datepicker .react-datepicker__year-dropdown .react-datepicker__year-option .react-datepicker__navigation {
  display: none !important;
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after {
  left: -20px !important;
}

.Toastify__toast {
  background-color: #242c32 !important;
  border-radius: 8px !important;
  padding: 0 !important;
}
.Toastify__toast > div {
  font-size: 20px;
  color: #fff;
}
.Toastify__toast .toastHead {
  font-size: 17px;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  margin: 10px 0 5px 0;
}
.Toastify__toast .toastText {
  font-size: 13px;
  color: #c8c5c5;
  font-family: "Poppins", sans-serif;
  margin-bottom: 0;
  padding-bottom: 5px;
}
.Toastify__toast .Toastify__close-button,
.Toastify__toast .Toastify__toast-icon {
  display: none;
}
.Toastify__toast .toastOuter {
  display: flex;
  gap: 15px;
  align-items: center;
}
.Toastify__toast--error .Toastify__toast-body {
  background-image: url(../imgs/redShadow.svg);
  background-repeat: no-repeat;
}
.Toastify__toast--success .Toastify__toast-body {
  background-image: url(../imgs/greenShandow.svg);
  background-repeat: no-repeat;
}
.Toastify__toast--warning .Toastify__toast-body {
  background-image: url(../imgs/toastWarningYellow.svg);
  background-repeat: no-repeat;
}

.pac-container {
  z-index: 1111;
  background-color: #191A1E;
  border: none;
}
.pac-container .pac-item {
  color: #fff;
  border-color: rgba(97, 209, 128, 0.16);
}
.pac-container .pac-item .pac-item-query {
  color: #fff;
}
.pac-container .pac-item:hover {
  background-color: #0D0D0D;
}

.errorListingRed {
  padding: 0;
  margin: -10px 0 15px 0;
}
.errorListingRed li {
  list-style-type: none;
  color: #DE5753;
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  gap: 5px;
}
.errorListingRed li img {
  width: 100%;
  max-width: 18px;
}

.welcome {
  text-align: center;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
@media (max-width: 991px) {
  .welcome {
    overflow: hidden;
  }
}
.welcome::after {
  position: absolute;
  content: " ";
  width: 100%;
  height: 100%;
  background-image: url(../imgs/welcomePatteren.png);
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
}
@media (max-width: 991px) {
  .welcome::after {
    background-size: cover;
  }
}
.welcome__Logo {
  position: relative;
  z-index: 11;
}
.welcome__Logo img {
  width: 100%;
  max-width: 25%;
}
@media (max-width: 991px) {
  .welcome__Logo img {
    max-width: 60%;
  }
}
@media (max-width: 575px) {
  .welcome__Logo img {
    max-width: 80%;
  }
}

.landingOuter {
  background-image: url(../imgs/landingMainBg.png);
  background-size: cover;
}
@media (max-width: 575px) {
  .landingOuter {
    background-size: 100%;
  }
}

.heroSection {
  padding: 60px 60px 100px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center bottom;
  -webkit-animation: moving 70s infinite;
  animation: moving 70s infinite;
}
@media (max-width: 1366px) {
  .heroSection {
    padding: 60px 60px 60px;
  }
}
@media (max-width: 991px) {
  .heroSection {
    padding: 40px 60px 40px;
  }
}
@media (max-width: 575px) {
  .heroSection {
    padding: 40px 20px;
  }
}
.heroSection__Content .capsule {
  color: rgba(255, 255, 255, 0.7);
  border-radius: 100px;
  border: 1px solid #fafafa;
  background: rgba(29, 29, 29, 0);
  padding: 7px 20px;
  display: inline-block;
  font-weight: 500;
  min-width: 220px;
  text-align: center;
  font-size: 20px;
}
@media (max-width: 1366px) {
  .heroSection__Content .capsule {
    font-size: 18px;
    min-width: 190px;
  }
}
@media (max-width: 575px) {
  .heroSection__Content .capsule {
    font-size: 16px;
    min-width: 170px;
  }
}
.heroSection__Content h1 {
  color: #d6d6d6;
  margin: 40px 0px;
  font-size: 112px;
  font-weight: 700;
}
@media (max-width: 1600px) {
  .heroSection__Content h1 {
    font-size: 80px;
    margin: 60px 0px;
  }
}
@media (max-width: 1440px) {
  .heroSection__Content h1 {
    font-size: 70px;
  }
}
@media (max-width: 1366px) {
  .heroSection__Content h1 {
    font-size: 60px;
  }
}
@media (max-width: 1280px) {
  .heroSection__Content h1 {
    font-size: 50px;
  }
}
@media (max-width: 575px) {
  .heroSection__Content h1 {
    font-size: 27px;
    line-height: 37px;
    margin: 40px 0 30px;
  }
}
@media (max-width: 575px) {
  .heroSection__Content h1 br {
    display: none;
  }
}
.heroSection__Content p {
  color: rgba(97, 209, 128, 0.7);
  font-size: 28px;
  font-weight: 500;
}
@media (max-width: 1600px) {
  .heroSection__Content p {
    font-size: 30px;
  }
}
@media (max-width: 1366px) {
  .heroSection__Content p {
    font-size: 27px;
  }
}
@media (max-width: 1280px) {
  .heroSection__Content p {
    font-size: 25px;
  }
}
@media (max-width: 575px) {
  .heroSection__Content p {
    font-size: 15px;
  }
}
@media (max-width: 575px) {
  .heroSection__Content p br {
    display: none;
  }
}
.heroSection__Content small {
  font-size: 14px;
  font-weight: 600;
  color: rgba(97, 209, 128, 0.2);
}
.heroSection__Content button {
  background: transparent;
  border: 0;
}
.heroSection__Content button .mouseImg {
  width: 100%;
  max-width: 150px;
  cursor: pointer;
  -webkit-animation: mover 1s infinite alternate;
  animation: mover 1s infinite alternate;
}

.noJobSec {
  background-size: cover;
  background-repeat: no-repeat;
  top: 50%;
}
@media (max-width: 991px) {
  .noJobSec {
    padding: 40px 0 70px;
  }
}
@media (max-width: 575px) {
  .noJobSec {
    padding: 40px 0 80px;
  }
}
.noJobSec__Content {
  text-align: center;
}
.noJobSec__Content h2 {
  font-size: 50px;
  font-weight: 600;
}
@media (max-width: 1440px) {
  .noJobSec__Content h2 {
    font-size: 40px;
  }
}
@media (max-width: 575px) {
  .noJobSec__Content h2 {
    font-size: 30px;
  }
}
.noJobSec__Content ul {
  margin: 50px 0;
}
.noJobSec__Content ul li {
  position: relative;
  color: #fff;
  font-size: 20px;
  list-style-type: none;
  display: inline-block;
  margin-right: 50px;
}
@media (max-width: 1366px) {
  .noJobSec__Content ul li {
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .noJobSec__Content ul li {
    margin: 0 0 10px 0;
    display: block;
  }
}
.noJobSec__Content ul li img {
  width: 100%;
  max-width: 20px;
  margin-right: 5px;
}
.noJobSec__Content p {
  color: #fff;
  font-size: 22px;
}
@media (max-width: 1366px) {
  .noJobSec__Content p {
    font-size: 18px;
  }
}
@media (max-width: 575px) {
  .noJobSec__Content p {
    font-size: 16px;
  }
}
@media (max-width: 575px) {
  .noJobSec__Content p br {
    display: none;
  }
}
.noJobSec__Content a {
  display: inline-block;
  margin-top: 50px;
  width: 500px;
  border: 1px solid #61D180;
  font-weight: 500;
  color: #61D180;
  font-size: 22px;
  text-decoration: none;
  padding: 15px 0;
  transition: all 0.6s ease-in-out;
}
@media (max-width: 1366px) {
  .noJobSec__Content a {
    font-size: 18px;
    width: 400px;
  }
}
@media (max-width: 575px) {
  .noJobSec__Content a {
    width: 70%;
  }
}
.noJobSec__Content a:hover {
  color: #fff;
  transform: scale(1.01);
  box-shadow: inset 600px 0 0 0 #61D180;
}

.laptop {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
}
.laptop__Img {
  margin-top: 50px;
  z-index: 5;
  position: relative;
  left: 50%;
  opacity: 0;
  overflow: hidden;
  will-change: transform;
  display: grid;
  place-items: center;
  width: 1000px;
  min-height: 0px;
  max-height: 800px;
  transform: rotateX(-45deg) translate(-50%, 0px);
}
@media (max-width: 991px) {
  .laptop__Img {
    width: 650px;
    margin-top: 0;
  }
}
@media (max-width: 575px) {
  .laptop__Img {
    width: 350px;
  }
}
.laptop__Img img {
  width: 100%;
}

.features {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  padding: 0 0 100px;
}
.features .sectionHeading {
  text-align: center;
  margin-bottom: 80px;
}
.features .sectionHeading h4 {
  font-size: 30px;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .features .sectionHeading h4 {
    font-size: 22px;
  }
}
.features .sectionHeading h2 {
  font-size: 48px;
  font-weight: 600;
}
@media (max-width: 1440px) {
  .features .sectionHeading h2 {
    font-size: 40px;
  }
}
@media (max-width: 575px) {
  .features .sectionHeading h2 {
    font-size: 30px;
  }
}
.features__Content {
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: #0D0D0D;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 30px 30px;
  height: 330px;
  transition: all 0.3s ease-in-out;
}
.features__Content * {
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1366px) {
  .features__Content {
    height: 400px;
  }
}
@media (max-width: 1280px) {
  .features__Content {
    margin-bottom: 20px;
    height: 400px;
  }
}
@media (max-width: 575px) {
  .features__Content {
    height: auto;
  }
}
.features__Content img {
  margin-bottom: 35px;
  transition: all 0.5s ease-in-out;
}
.features__Content h5 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 20px;
}
.features__Content p {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
  transition: all 0.5s ease-in-out;
}
.features__Content:hover {
  box-shadow: 0px 0px 70px 0px rgba(97, 209, 128, 0.1);
}
.features__Content:hover h5,
.features__Content:hover p {
  transform: translate(0, -10px);
}
.features__Content:hover img {
  transform: rotateY(360deg);
}
.features__Marque {
  margin-top: 100px;
  text-align: center;
}
.features__Marque h4 {
  font-size: 30px;
  margin-bottom: 50px;
}
@media (max-width: 575px) {
  .features__Marque h4 {
    font-size: 24px;
  }
}
.features__Marque .marqueeImgs span {
  display: inline-block;
  min-width: 150px;
  max-width: 300px;
  margin: 0 50px;
  cursor: pointer;
}
@media (max-width: 575px) {
  .features__Marque .marqueeImgs span {
    margin: 0 20px;
  }
}
.features__Marque .marqueeImgs span img {
  width: 170px;
  height: 50px;
  object-fit: contain;
  opacity: 0.4;
}

.messaging {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
}
.messaging__Content {
  background-color: #141414;
  padding: 50px;
}
.messaging__Content h3 {
  font-size: 40px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 40px;
}
@media (max-width: 1366px) {
  .messaging__Content h3 {
    font-size: 30px;
  }
}
@media (max-width: 575px) {
  .messaging__Content h3 {
    font-size: 18px;
  }
}
@media (max-width: 280px) {
  .messaging__Content h3 {
    font-size: 14px;
  }
}
.messaging__Content ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}
@media (max-width: 575px) {
  .messaging__Content ul {
    flex-direction: column;
  }
}
.messaging__Content ul li {
  list-style-type: none;
  position: relative;
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin: 0 50px 0 0;
}
@media (max-width: 575px) {
  .messaging__Content ul li {
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.messaging__Content ul li:last-of-type {
  margin: 0;
}
.messaging__Content ul li::before {
  position: absolute;
  content: " ";
  width: 20px;
  height: 20px;
  background-image: url(../imgs/tickGreendark.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  left: -25px;
  top: 5px;
}
@media (max-width: 575px) {
  .messaging__Content ul li::before {
    background-size: 18px;
  }
}
.messaging__Content .messageCarousel {
  margin-bottom: 50px;
}
.messaging__Content .messageCarousel .carousel .carousel-item img {
  width: 80%;
  margin: 0 auto;
  border: 2px solid #61D180;
  border-radius: 10px;
}
.messaging__Content .messageCarousel .carousel .carousel-control-prev {
  width: 10%;
  opacity: 1;
}
@media (max-width: 575px) {
  .messaging__Content .messageCarousel .carousel .carousel-control-prev {
    width: 15%;
    left: -30px;
  }
}
.messaging__Content .messageCarousel .carousel .carousel-control-prev .carousel-control-prev-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url(../imgs/arrowLeft.svg);
  background-size: 25px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 575px) {
  .messaging__Content .messageCarousel .carousel .carousel-control-prev .carousel-control-prev-icon {
    background-size: 18px;
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 280px) {
  .messaging__Content .messageCarousel .carousel .carousel-control-prev .carousel-control-prev-icon {
    width: 25px;
    height: 25px;
    background-size: 10px;
  }
}
.messaging__Content .messageCarousel .carousel .carousel-control-prev .carousel-control-prev-icon:hover {
  background-color: #61D180;
  box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.3);
  background-image: url(../imgs/arrowLeftWhite.svg);
}
.messaging__Content .messageCarousel .carousel .carousel-control-next {
  width: 10%;
  opacity: 1;
}
@media (max-width: 575px) {
  .messaging__Content .messageCarousel .carousel .carousel-control-next {
    width: 15%;
    right: -30px;
  }
}
.messaging__Content .messageCarousel .carousel .carousel-control-next .carousel-control-next-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-image: url(../imgs/arrowRight.svg);
  background-size: 25px;
  background-color: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.2);
  transition: all 0.3s ease-in-out;
}
@media (max-width: 575px) {
  .messaging__Content .messageCarousel .carousel .carousel-control-next .carousel-control-next-icon {
    background-size: 18px;
    width: 35px;
    height: 35px;
  }
}
@media (max-width: 280px) {
  .messaging__Content .messageCarousel .carousel .carousel-control-next .carousel-control-next-icon {
    width: 25px;
    height: 25px;
    background-size: 10px;
  }
}
.messaging__Content .messageCarousel .carousel .carousel-control-next .carousel-control-next-icon:hover {
  background-color: #61D180;
  box-shadow: 0px 0px 14px rgba(255, 255, 255, 0.3);
  background-image: url(../imgs/arrowRightWhite.svg);
}

.compatibility {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 100px 0;
}
@media (max-width: 575px) {
  .compatibility {
    padding: 100px 0 50px;
  }
}
.compatibility__Content {
  text-align: center;
}
.compatibility__Content p {
  font-size: 20px;
  color: #fafafa;
  font-weight: 300;
}
@media (max-width: 1366px) {
  .compatibility__Content p {
    font-size: 16px;
  }
}
.compatibility__Content a {
  display: inline-block;
  margin-top: 20px;
  width: 500px;
  border: 1px solid #61D180;
  font-weight: 500;
  color: #61D180;
  font-size: 22px;
  text-decoration: none;
  padding: 15px 0;
  transition: all 0.6s ease-in-out;
}
@media (max-width: 1366px) {
  .compatibility__Content a {
    font-size: 18px;
    width: 400px;
  }
}
@media (max-width: 575px) {
  .compatibility__Content a {
    width: 70%;
  }
}
.compatibility__Content a:hover {
  color: #fff;
  box-shadow: inset 600px 0 0 0 #61D180;
}
.compatibility__Content .capBottom {
  display: flex;
  justify-content: center;
  margin: 120px auto 0;
  align-items: center;
  background-color: #191A1E;
  border-radius: 110px 30px 30px 110px;
  width: 540px;
  padding: 0 40px 0 0;
}
@media (max-width: 1366px) {
  .compatibility__Content .capBottom {
    width: 455px;
  }
}
@media (max-width: 575px) {
  .compatibility__Content .capBottom {
    width: 100%;
    flex-direction: column;
    border-radius: 30px 30px 30px 30px;
    padding: 30px 20px;
    margin-top: 170px;
  }
}
.compatibility__Content .capBottom .outer {
  width: 150px;
  height: 150px;
  background-color: #61D180;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #fff;
  margin-right: 30px;
  position: relative;
}
@media (max-width: 1366px) {
  .compatibility__Content .capBottom .outer {
    width: 130px;
    height: 130px;
  }
}
@media (max-width: 575px) {
  .compatibility__Content .capBottom .outer {
    position: relative;
    margin: -90px 0 20px 0;
  }
}
.compatibility__Content .capBottom .outer::after {
  position: absolute;
  content: " ";
  width: 200px;
  height: 200px;
  border: 3px solid #61D180;
  border-radius: 50%;
  z-index: -1;
  animation: pulse-animation 2s infinite;
}
@media (max-width: 1366px) {
  .compatibility__Content .capBottom .outer::after {
    width: 180px;
    height: 180px;
  }
}
@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(97, 209, 128, 0.2);
  }
  100% {
    box-shadow: 0 0 0 30px rgba(97, 209, 128, 0);
  }
}
.compatibility__Content .capBottom .outer .inner {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #fff;
}
@media (max-width: 1366px) {
  .compatibility__Content .capBottom .outer .inner {
    width: 80px;
    height: 80px;
  }
}
.compatibility__Content .capBottom .outer .inner img {
  width: 100%;
  max-width: 50px;
}
@media (max-width: 1366px) {
  .compatibility__Content .capBottom .outer .inner img {
    max-width: 35px;
  }
}
.compatibility__Content .capBottom p {
  color: #fff;
  font-weight: 600;
  text-align: left;
  margin: 0;
}
.compatibility__Content .capBottom p span {
  color: rgba(255, 255, 255, 0.7);
}
@media (max-width: 575px) {
  .compatibility__Content .capBottom p {
    text-align: center;
  }
}

.chooseSection {
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 0 70px;
}
@media (max-width: 575px) {
  .chooseSection {
    padding: 50px 0 50px;
  }
}
.chooseSection .sectionHeading {
  text-align: center;
}
.chooseSection .sectionHeading h2 {
  font-size: 60px;
  margin-bottom: 30px;
  font-weight: 600;
}
@media (max-width: 1600px) {
  .chooseSection .sectionHeading h2 {
    font-size: 50px;
  }
}
@media (max-width: 1440px) {
  .chooseSection .sectionHeading h2 {
    font-size: 40px;
  }
}
@media (max-width: 575px) {
  .chooseSection .sectionHeading h2 {
    font-size: 21px;
    font-weight: 600;
  }
}
.chooseSection .sectionHeading p {
  font-size: 20px;
  margin-bottom: 60px;
}
@media (max-width: 575px) {
  .chooseSection .sectionHeading p {
    font-size: 14px;
  }
}
.chooseSection__Content {
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: #141414;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 30px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .chooseSection__Content {
    margin-bottom: 20px;
  }
}
.chooseSection__Content img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 991px) {
  .chooseSection__Content img {
    height: auto;
  }
}
.chooseSection__Content img.icons {
  width: 80px;
  height: 80px;
  margin-bottom: 30px;
}
.chooseSection__Content h4 {
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1280px) {
  .chooseSection__Content h4 {
    font-size: 21px;
  }
}
.chooseSection__Content p {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
  margin: 20px 0 50px 0;
  height: 100px;
  transition: all 0.5s ease-in-out;
}
@media (max-width: 1366px) {
  .chooseSection__Content p {
    font-size: 16px;
  }
}
.chooseSection__Content:hover {
  box-shadow: 0px 0px 70px 0px rgba(97, 209, 128, 0.1);
}
.chooseSection__Content:hover img.icons {
  transform: rotateY(360deg);
}
.chooseSection__Content:hover .bottomImg {
  transform: translate(0, -10px);
}
.chooseSection__Content:hover p,
.chooseSection__Content:hover h4 {
  transform: translate(0, -10px);
}

.waiting {
  padding: 0 0 60px;
}
.waiting__Content {
  text-align: center;
}
.waiting__Content h3 {
  font-size: 60px;
  font-weight: 600;
  margin-bottom: 30px;
}
@media (max-width: 1600px) {
  .waiting__Content h3 {
    font-size: 50px;
  }
}
@media (max-width: 1440px) {
  .waiting__Content h3 {
    font-size: 40px;
  }
}
@media (max-width: 575px) {
  .waiting__Content h3 {
    font-size: 30px;
  }
}
.waiting__Content p {
  font-size: 18px;
}
@media (max-width: 575px) {
  .waiting__Content p {
    font-size: 16px;
  }
}
.waiting__Content ul {
  padding: 0;
  margin: 30px 0 0 0;
}
@media (max-width: 575px) {
  .waiting__Content ul {
    padding-left: 30px;
  }
}
.waiting__Content ul li {
  position: relative;
  list-style-type: none;
  display: inline-block;
  margin: 0 100px 0 0;
  color: #fff;
  text-align: left;
  vertical-align: middle;
  font-size: 18px;
}
@media (max-width: 575px) {
  .waiting__Content ul li {
    display: block;
    font-size: 16px;
    margin-bottom: 10px;
  }
}
.waiting__Content ul li:last-of-type {
  margin: 0;
}
.waiting__Content ul li::after {
  position: absolute;
  content: " ";
  width: 20px;
  height: 20px;
  background-image: url(../imgs/tickGreendark.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  top: 50%;
  left: -24px;
  transform: translate(-20%, -50%);
}
@media (max-width: 575px) {
  .waiting__Content ul li br {
    display: none;
  }
}
.waiting__Content a {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff !important;
  font-size: 18px;
  display: inline-block;
  margin-top: 50px;
  padding: 13px 40px;
  border-radius: 100px;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}
.waiting__Content a:hover {
  transform: translateY(-5px);
}

.newFooter {
  padding: 20px 100px;
}
@media (max-width: 1600px) {
  .newFooter {
    padding: 20px 40px;
  }
}
@media (max-width: 1440px) {
  .newFooter {
    padding: 20px 10px;
  }
}
@media (max-width: 991px) {
  .newFooter__Content {
    text-align: center;
  }
}
.newFooter__Content ul {
  padding: 0;
  margin: 0;
}
.newFooter__Content ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 10px;
}
.newFooter__Content ul li a {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1600px) {
  .newFooter__Content ul li a {
    font-size: 14px;
  }
}
.newFooter__Content ul li a:hover {
  color: #61D180;
  text-shadow: 0 0 10px rgba(97, 209, 128, 0.6);
}
@media (max-width: 1440px) {
  .newFooter__Content ul li a {
    font-size: 14px;
  }
}
.newFooter__Content ul.socialIcon {
  text-align: right;
}
@media (max-width: 991px) {
  .newFooter__Content ul.socialIcon {
    text-align: center;
  }
}
.newFooter__Content ul.socialIcon li a {
  display: inline-block;
  transition: 0.2s ease-in-out;
}
.newFooter__Content ul.socialIcon li a:hover {
  transform: translateY(-5px);
}
.newFooter__Content p {
  text-align: center;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}
@media (max-width: 991px) {
  .newFooter__Content p {
    margin: 10px 0;
  }
}

.topHeader {
  background-color: #000;
}
.topHeader__content a img {
  width: 100%;
  max-width: 150px;
}

.topbarInner {
  background-color: #191A1E;
  padding: 10px 15px;
}
@media (max-width: 991px) {
  .topbarInner .topBarLogoSection {
    display: flex;
    height: 100%;
    align-items: center;
  }
}
.topbarInner .topBarLogoSection .logo {
  width: 100%;
  max-width: 170px;
  margin-top: 10px;
}
@media (max-width: 991px) {
  .topbarInner .topBarLogoSection .logo {
    max-width: 130px;
    margin-top: 0;
  }
}
.topbarInner .menuIcon {
  margin: 15px 10px 0 0;
  cursor: pointer;
  display: none;
}
@media (max-width: 991px) {
  .topbarInner .menuIcon {
    display: block;
    margin: 5px 20px 0 0;
  }
}
@media (max-width: 575px) {
  .topbarInner .menuIcon {
    margin-top: 5px;
  }
}
.topbarInner .menuIcon span {
  width: 20px;
  height: 2px;
  display: block;
  background-color: #fff;
  margin: 5px 0;
}
.topbarInner .menuIcon span:nth-child(2) {
  width: 15px;
}
.topbarInner .form-group {
  position: relative;
  width: 100%;
  margin: 10px 0 0 20px;
}
.topbarInner .form-group .form-control {
  height: 55px;
  color: #fff;
  padding-left: 40px;
}
.topbarInner .form-group span {
  position: absolute;
  top: 15px;
  left: 10px;
}
.topbarInner .form-group span img {
  width: 100%;
  max-width: 25px;
}
.topbarInner__Content {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
@media (max-width: 767px) {
  .topbarInner__Content {
    justify-content: flex-end;
    margin-top: 10px;
  }
}
.topbarInner__Content .dropdown .dropdown-toggle {
  background-color: transparent;
  border: 0;
}
.topbarInner__Content .dropdown .dropdown-toggle img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  background-color: #fff;
}
.topbarInner__Content .dropdown .dropdown-toggle::after {
  background-image: url(../imgs/chevron-down.svg);
  border: 0;
  background-size: 20px;
  width: 15px;
  height: 15px;
}
.topbarInner__Content .dropdown .dropdown-menu {
  background-color: #191A1E;
  border: 1px solid rgba(97, 209, 128, 0.16);
  padding: 0;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(97, 209, 128, 0.2);
}
.topbarInner__Content .dropdown .dropdown-menu li a {
  color: #fff;
  font-size: 14px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px;
  font-weight: 500;
}
.topbarInner__Content .dropdown .dropdown-menu li a img {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 5px;
}
.topbarInner__Content .dropdown .dropdown-menu li a:hover {
  background-color: #131313;
}
.topbarInner__Content .dropdown .dropdown-menu li:last-child a {
  border-bottom: 0;
}
.topbarInner__Content .dropdown.userDropdown .dropdown-toggle {
  display: flex;
  text-align: left;
  gap: 10px;
  align-items: center;
  position: relative;
  box-shadow: none;
}
.topbarInner__Content .dropdown.userDropdown .dropdown-toggle img {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid #fff;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 575px) {
  .topbarInner__Content .dropdown.userDropdown .dropdown-toggle img {
    width: 45px;
    height: 45px;
  }
}
.topbarInner__Content .dropdown.userDropdown .dropdown-toggle span {
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 991px) {
  .topbarInner__Content .dropdown.userDropdown .dropdown-toggle span {
    font-size: 14px;
  }
}
.topbarInner__Content .dropdown.userDropdown .dropdown-toggle span.online {
  display: inline-block;
  width: 14px;
  height: 14px;
  background-color: #61D180;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  left: 60px;
}
@media (max-width: 575px) {
  .topbarInner__Content .dropdown.userDropdown .dropdown-toggle span.online {
    width: 12px;
    height: 12px;
    bottom: 5px;
    left: 48px;
  }
}
.topbarInner__Content .dropdown.userDropdown .dropdown-toggle span span {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  display: block;
}
@media (max-width: 991px) {
  .topbarInner__Content .dropdown.userDropdown .dropdown-toggle span span {
    font-size: 12px;
  }
}
.topbarInner__Content .dropdown.userDropdown .dropdown-toggle:hover img {
  border-color: #61D180;
  box-shadow: 0 0 20px rgba(97, 209, 128, 0.5);
}
.topbarInner__Content .dropdown.userDropdown .dropdown-toggle:hover span {
  color: #61D180;
}
.topbarInner__Content .dropdown.userDropdown .dropdown-toggle:hover span span {
  color: rgba(255, 255, 255, 0.6);
}

.sidebar {
  background-color: #191A1E;
  width: 300px;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  padding: 10px 15px;
  overflow: auto;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 991px) {
  .sidebar {
    transform: translate(-300px, 0);
  }
}
@media (max-width: 991px) {
  .sidebar.toggle {
    transform: translate(0, 0);
    z-index: 1;
  }
}
.sidebar::-webkit-scrollbar {
  width: 5px;
}
.sidebar::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.sidebar::-webkit-scrollbar-thumb {
  background-color: rgba(97, 209, 128, 0.1);
}
.sidebar .crossIcon {
  display: none;
  position: absolute;
  right: 10px;
  top: 10px;
}
@media (max-width: 991px) {
  .sidebar .crossIcon {
    display: inline-block;
  }
}
.sidebar a {
  display: block;
  text-align: center;
  margin: 20px 0 40px;
  outline: none;
}
.sidebar a img {
  width: 100%;
  max-width: 170px;
}
.sidebar__Inner ul {
  padding: 0;
}
.sidebar__Inner ul li {
  display: block;
  list-style-type: none;
}
.sidebar__Inner ul li a {
  text-decoration: none;
  color: #fff;
  font-weight: 500;
  text-align: left;
  padding: 18px 15px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  transition: all 0.6s ease-in-out;
  margin: 0 0 2px 0;
}
.sidebar__Inner ul li a img {
  width: 100%;
  max-width: 32px;
  margin-right: 20px;
}
.sidebar__Inner ul li a:hover {
  box-shadow: inset 600px 0 0 0 #61d180;
}
.sidebar__Inner ul li.disabled {
  opacity: 0.1;
}
.sidebar__Inner ul li.disabled a:hover {
  background: none;
  color: #fff;
}
.sidebar__Inner ul li.active a {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
}
.sidebar__Progress {
  margin: 20px 0 40px;
}
.sidebar__Progress p {
  margin: 0 0 10px 0;
  color: #fff;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
}
.sidebar__Progress p:last-child {
  color: #979797;
  margin-top: 10px;
}
.sidebar__Progress .progress {
  height: 8px;
}
.sidebar__Progress .progress .progress-bar {
  background-color: #61D180;
}
.sidebar .upgradeSection {
  background: linear-gradient(172deg, #61d180 8.92%, #023020 110.84%);
  border-radius: 8px;
  text-align: center;
  padding: 15px;
  margin: 0 20px;
}
.sidebar .upgradeSection img {
  width: 100%;
  max-width: 60%;
}
.sidebar .upgradeSection p {
  color: #fff;
  font-weight: 300;
  margin: 15px 0;
}
.sidebar .upgradeSection p span {
  color: #FDC500;
  font-weight: 600;
}
.sidebar .upgradeSection .btn {
  width: 100%;
  background-color: #fff;
  color: #0D0D0D;
  padding: 8px 10px;
  font-weight: 600;
}
.sidebar .sidebarEntries {
  border: 8px solid #61D180;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.sidebar .sidebarEntries p {
  color: #fff;
  font-weight: 600;
  margin-bottom: 0;
  font-size: 20px;
}
.sidebar.active {
  transform: translate(0);
  z-index: 1;
}
.sidebar .close {
  width: 20px;
  height: 20px;
  background-image: url(../imgs/crossIcon.svg);
  background-size: 16px;
  background-repeat: no-repeat;
  position: absolute;
  right: 10px;
  top: 20px;
  filter: invert(1);
  display: none;
}
@media (max-width: 991px) {
  .sidebar .close {
    display: block;
  }
}

.formSection {
  background-color: #0D0D0D;
  background-size: 90%;
  background-position: center;
  background-repeat: no-repeat;
  padding: 50px 0;
  min-height: calc(100vh - 145px);
}
.formSection__Img {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
}
@media (max-width: 991px) {
  .formSection__Img {
    display: none;
  }
}
.formSection__Img img {
  width: 100%;
}
.formSection__Img img.forgotImg {
  width: 80%;
}
@media (max-width: 1280px) {
  .formSection__Img img {
    width: 100%;
  }
}
.formSection__content .logoImg {
  width: 100%;
  max-width: 120px;
}
.formSection__content form {
  border-radius: 8px;
  border: 1px solid #1D1D1D;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 50px 50px;
  background-color: #191A1E;
}
@media (max-width: 575px) {
  .formSection__content form {
    padding: 30px 20px;
  }
}
.formSection__content form h2 {
  color: #fff;
  font-size: clamp(1.562rem, 4vw, 2.95rem);
  font-weight: 300;
  text-align: center;
  margin-bottom: 0;
}
@media (max-width: 1366px) {
  .formSection__content form h2 {
    font-size: clamp(1.562rem, 4vw, 2rem);
  }
}
.formSection__content form p {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}
.formSection__content form .form-group {
  margin-bottom: 20px;
  position: relative;
}
.formSection__content form .form-group .form-control {
  color: rgba(255, 255, 255, 0.7);
  padding-left: 55px;
}
.formSection__content form .form-group .toggle-password {
  position: absolute;
  top: 17px;
  right: 15px;
  cursor: pointer;
}
.formSection__content form .form-group span {
  position: absolute;
  color: rgba(255, 255, 255, 0.2);
  font-size: 18px;
  width: 40px;
  height: 50px;
  top: 0;
  left: 5px;
  padding: 0 10px;
  border-right: 1px solid rgba(74, 74, 74, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.formSection__content form .passwordRemember {
  display: flex;
  justify-content: space-between;
}
.formSection__content form .passwordRemember a {
  font-size: 14px;
  color: #61D180;
}
.formSection__content form .passwordRemember .form-check {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.formSection__content form .passwordRemember .form-check .form-check-input {
  width: 14px;
  height: 14px;
  box-shadow: none;
}
.formSection__content form .passwordRemember .form-check .form-check-input:checked {
  background-color: #61D180;
  border-color: #61D180;
}
.formSection__content form .passwordRemember .form-check .form-check-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}
.formSection__content form .btn {
  background-image: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  border: none;
  color: #fff;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  border-radius: 8px;
  margin-top: 30px;
  height: 50px;
  transition: all 0.2s ease-in-out;
}
.formSection__content form .btn:hover {
  box-shadow: 0 0 20px rgba(97, 209, 128, 0.2);
  transform: scale(1.02);
}
.formSection__content form p {
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 40px 0;
  font-weight: 400;
}
.formSection__content form p a {
  color: #61D180;
}
.formSection__content form .googleLogin .btn {
  background-color: #EA4335;
  background-image: none;
  border: 1px solid #EA4335;
  font-weight: 700;
  border-radius: 8px;
  margin-top: 0;
  font-size: 16px;
}
.formSection__content form .googleLogin .btn img {
  width: 100%;
  max-width: 20px;
}
.formSection__content form .googleLogin .btn:active {
  color: #fff;
}
.formSection__content form .googleLogin .btn.accountGoogleBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
}
.formSection__content form .divider {
  position: relative;
  border-top: 1px solid rgba(61, 58, 39, 0.5);
  margin: 30px 0;
}
.formSection__content form .divider p {
  position: absolute;
  color: rgba(255, 255, 255, 0.7);
  background-color: #191A1E;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
}
@media (max-width: 575px) {
  .formSection__content form .divider p {
    width: 70%;
  }
}
.formSection__content form.roleForm {
  padding: 40px 100px;
}
@media (max-width: 767px) {
  .formSection__content form.roleForm {
    padding: 40px 50px;
  }
}
.formSection__content .roleSelector {
  display: flex;
  gap: 20px;
}
@media (max-width: 575px) {
  .formSection__content .roleSelector {
    display: block;
  }
}
.formSection__content .roleSelector .btn {
  height: auto;
  background-color: #060606;
  border: 1px solid #060606;
  background-image: none;
  padding: 30px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.formSection__content .roleSelector .btn svg path {
  fill: rgba(255, 255, 255, 0.7);
}
.formSection__content .roleSelector .btn:hover {
  border-color: #61D180;
  color: #61D180;
}
.formSection__content .roleSelector .btn:hover svg path {
  fill: #61D180;
}
@media (max-width: 575px) {
  .formSection__content .roleSelector .btn {
    word-break: break-all;
  }
}
.formSection.roleSelectorMain {
  min-height: 100vh;
  display: flex;
  align-items: center;
}
.formSection .accountInner {
  border-radius: 8px;
  border: 1px solid #1D1D1D;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 50px 50px;
  background-color: #191A1E;
}
.formSection .accountInner h2 {
  color: #fff;
  font-size: clamp(1.562rem, 4vw, 2.95rem);
  font-weight: 300;
  text-align: center;
  margin-bottom: 0;
}
@media (max-width: 1366px) {
  .formSection .accountInner h2 {
    font-size: clamp(1.562rem, 4vw, 2rem);
  }
}
.formSection .accountInner p {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
}
.formSection .accountInner .googleLogin .btn {
  background-color: #EA4335;
  background-image: none;
  border: 1px solid #EA4335;
  font-weight: 700;
  border-radius: 8px;
  margin-top: 20px;
  font-size: 16px;
  height: 50px;
  width: 100%;
  color: #fff;
  transition: all 0.2s ease-in-out;
}
.formSection .accountInner .googleLogin .btn img {
  width: 100%;
  max-width: 20px;
}
.formSection .accountInner .googleLogin .btn:hover {
  box-shadow: 0 0 20px rgba(97, 209, 128, 0.2);
  transform: scale(1.02);
}
.formSection .accountInner form {
  border: none;
  box-shadow: none;
  padding: 0;
}

.footer {
  background-color: #000;
  padding: 20px 0;
}
@media (max-width: 991px) {
  .footer__Content {
    text-align: center;
  }
}
.footer__Content p {
  color: rgba(255, 255, 255, 0.7);
  margin: 20px 0 0 0;
}
.footer__Content ul {
  padding: 0;
  text-align: right;
  margin: 0;
}
@media (max-width: 991px) {
  .footer__Content ul {
    text-align: center;
    margin-top: 10px;
  }
}
.footer__Content ul li {
  display: inline-block;
  margin: 0 0 0 5px;
}
.footer__Content ul li a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  border: 2px solid #1D1D1D;
  color: #fff;
  border-radius: 50%;
  font-size: 20px;
  background-image: url(../imgs/patterenImg.svg);
}

.innerFooter {
  background-color: #131313;
  padding: 20px 10px;
}
@media (max-width: 991px) {
  .innerFooter__Content {
    text-align: center;
  }
}
.innerFooter__Content .logoImg {
  width: 100%;
  max-width: 120px;
}
.innerFooter__Content ul {
  padding: 0;
  margin: 10px 0 0 0;
  text-align: center;
}
@media (max-width: 991px) {
  .innerFooter__Content ul {
    margin: 10px 0 10px 0;
  }
}
.innerFooter__Content ul li {
  display: inline-block;
  margin: 0 5px;
}
.innerFooter__Content ul li a {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 1440px) {
  .innerFooter__Content ul li a {
    font-size: 16px;
  }
}
@media (max-width: 1280px) {
  .innerFooter__Content ul li a {
    font-size: 14px;
  }
}
.innerFooter__Content ul li a:hover {
  color: #61D180;
  text-shadow: 0 0 10px rgba(97, 209, 128, 0.6);
}
@media (max-width: 1280px) {
  .innerFooter__Content ul li a img {
    width: 100%;
    max-width: 30px;
  }
}
.innerFooter__Content ul li.disabled a {
  opacity: 0.1;
}
.innerFooter__Content ul li.disabled a:hover {
  color: rgba(255, 255, 255, 0.7);
  cursor: not-allowed;
}
.innerFooter__Content ul.socialIcons {
  text-align: right;
}
@media (max-width: 991px) {
  .innerFooter__Content ul.socialIcons {
    text-align: center;
  }
}
.innerFooter__Content ul.socialIcons li a {
  display: inline-block;
  border-radius: 50%;
}
.innerFooter__Content ul.socialIcons li a:hover {
  box-shadow: 0 0 10px rgba(97, 209, 128, 0.6);
  transform: translateY(-5px);
}

.bottonFooter {
  background: #191A1E;
  padding: 20px 0;
}
.bottonFooter p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  text-align: center;
  margin: 0;
  font-weight: 500;
}

.mainWrapper {
  padding-left: 300px;
}
@media (max-width: 991px) {
  .mainWrapper {
    padding-left: 0;
  }
}

.hirebirdplans {
  background-color: #0D0D0D;
}
.hirebirdplans .plansmidsection {
  margin: 73px 0px;
}
.hirebirdplans .plansmidsection .planpricingsection {
  margin-top: 94px;
}
.hirebirdplans .plansmidsection h1 {
  color: #fff;
  text-align: center;
  font-size: clamp(2.5rem, 3vw, 3.125rem);
  font-weight: 700;
  margin: 0px auto;
}
.hirebirdplans .plansmidsection h1 span {
  color: #61D180;
}
.hirebirdplans .plansmidsection > p {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  margin: 22px auto;
}
.hirebirdplans .plansmidsection > p span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-weight: 700;
}
.hirebirdplans .plansmidsection .plansmidleft {
  height: 100%;
  border: 1px solid #1d1d1d;
  background: #000;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 40px;
  min-height: 600px;
  transition: all 0.3s ease-in-out;
}
.hirebirdplans .plansmidsection .plansmidleft:hover {
  border-radius: 8px;
  border: 1px solid #61D180;
  background: #000;
  box-shadow: 0px 0px 70px 0px rgba(97, 209, 128, 0.4);
}
@media (max-width: 1366px) {
  .hirebirdplans .plansmidsection .plansmidleft {
    padding: 40px 30px;
  }
}
@media (max-width: 1199px) {
  .hirebirdplans .plansmidsection .plansmidleft {
    padding: 40px 10px;
  }
}
@media (max-width: 991px) {
  .hirebirdplans .plansmidsection .plansmidleft {
    height: 680px;
    margin-bottom: 20px;
  }
}
@media (max-width: 767px) {
  .hirebirdplans .plansmidsection .plansmidleft {
    height: auto;
  }
}
@media (max-width: 575px) {
  .hirebirdplans .plansmidsection .plansmidleft {
    margin: 0px 0px 40px 0px;
  }
}
.hirebirdplans .plansmidsection .plansmidleft .imgcontain {
  margin: 42px 0px;
}
.hirebirdplans .plansmidsection .plansmidleft .planlowerleft h3 {
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  color: #61D180;
  margin-bottom: 30px;
}
.hirebirdplans .plansmidsection .plansmidleft .planlowerleft h3 span {
  color: #fff;
  font-size: 16px;
  font-weight: 300;
  margin-left: 10px;
}
.hirebirdplans .plansmidsection .plansmidleft .planlowerleft p {
  color: #61D180;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  margin: 0 0 30px 0;
}
.hirebirdplans .plansmidsection .plansmidleft .planlowerleft p span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 18px;
  font-weight: 400;
}
.hirebirdplans .plansmidsection .plansmidleft .planlowerleft a {
  display: block;
  color: #fff;
  text-align: center;
  font-size: 18px;
  margin: 0px auto;
  font-weight: 700;
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  padding: 13px 0px;
  max-width: 253px;
}
.hirebirdplans .plansmidsection .plansmidleft h4 {
  color: #fff;
  font-size: 36px;
  font-weight: 700;
  text-align: center;
}
@media (max-width: 1366px) {
  .hirebirdplans .plansmidsection .plansmidleft h4 {
    font-size: 32px;
  }
}
.hirebirdplans .plansmidsection .plansmidleft h4 span {
  color: #fff;
}
.hirebirdplans .plansmidsection .plansmidleft h4 .green {
  color: #61D180;
}
.hirebirdplans .plansmidsection .plansmidleft .planDetail {
  height: 340px;
  margin: 100px 0 0;
}
@media (max-width: 767px) {
  .hirebirdplans .plansmidsection .plansmidleft .planDetail {
    height: auto;
    min-height: auto;
    margin: 70px 0;
  }
}
.hirebirdplans .plansmidsection .plansmidleft .planDetail p {
  color: #fff;
}
.hirebirdplans .plansmidsection .plansmidleft .planDetail ul {
  padding-left: 30px;
}
.hirebirdplans .plansmidsection .plansmidleft .planDetail ul li {
  margin-bottom: 15px;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  list-style-type: none;
}
.hirebirdplans .plansmidsection .plansmidleft .planDetail ul li:last-of-type {
  margin-bottom: 0;
}
.hirebirdplans .plansmidsection .plansmidleft .planDetail ul li::after {
  position: absolute;
  content: " ";
  width: 18px;
  height: 18px;
  background-image: url(../imgs/greentick.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  left: -25px;
  top: 5px;
}
.hirebirdplans .plansmidsection .plansmidleft .planDetail.candidateDetail {
  margin: 100px 0;
}
.hirebirdplans .plansmidsection .plansmidleft .planDetail.candidateDetail h3 {
  color: #61D180;
  text-align: center;
  font-weight: 600;
}
.hirebirdplans .plansmidsection .plansmidleft .planDetail.candidateDetail ul {
  margin-bottom: 0;
}
.hirebirdplans .plansmidsection .plansmidleft .planDetail.candidateDetail .col-xl-8 {
  border-left: 2px solid #3d3a27;
}
.hirebirdplans .plansmidsection .plansmidleft .planleftitems {
  display: flex;
  align-items: start;
  margin: 9px 0px;
}
.hirebirdplans .plansmidsection .plansmidleft .planleftitems img {
  margin: 4px 8px 0px 8px;
}
.hirebirdplans .plansmidsection .plansmidleft .planleftitems p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}
.hirebirdplans .plansmidsection .plansmidleft.advancePlan:hover {
  box-shadow: 0px 0px 70px rgba(253, 197, 0, 0.42);
  border-color: #FDC500;
}
.hirebirdplans .plansmidsection .plansmidleft.advancePlan h4 .green {
  color: #FDC500;
}
.hirebirdplans .plansmidsection .plansmidleft.advancePlan ul li::after {
  background-image: url(../imgs/yellowTick.svg);
}
.hirebirdplans .plansmidsection .plansmidleft.advancePlan h3 {
  color: #FDC500;
}
.hirebirdplans .plansmidsection .plansmidleft.advancePlan .candidateDetail h3 {
  color: #FDC500;
}

.hirebirdnavbar {
  background-color: #0D0D0D;
}
.hirebirdnavbar ul li a {
  margin: 0px 18px;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
}
.hirebirdnavbar ul li a:hover {
  color: #61D180;
}

.foot {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
@media (max-width: 768px) {
  .foot {
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .foot {
    display: block;
    text-align: center;
  }
}
.foot .footinside1 {
  display: flex;
  flex-wrap: wrap;
}
@media (max-width: 575px) {
  .foot .footinside1 {
    display: block;
    text-align: center;
  }
}
.foot .footinside2 {
  display: flex;
  flex-wrap: wrap;
  margin: 8px 0px;
}
@media (max-width: 575px) {
  .foot .footinside2 {
    display: block;
    text-align: center;
    margin-top: 25px;
  }
}
.foot span {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-weight: 400;
}
.foot .footerlinks {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin: 0px 20px;
}
@media (max-width: 575px) {
  .foot .footerlinks {
    display: block;
    text-align: center;
    margin: 10px 0px;
  }
}
.foot .footicons {
  padding: 11px 16px;
  border: 1px solid grey;
  border-radius: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  margin: 0px 15px;
}

@media (max-width: 767px) {
  .plansmid {
    margin-top: 20px !important;
  }
}

@media (max-width: 991px) {
  .plansmidrightothersection {
    margin-top: 42px !important;
  }
}

.successpasswordouter {
  width: 100%;
  height: 83vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.successpasswordouter .successpasswordsection {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 738px;
  height: 504px;
  border-radius: 8px;
  border: 1px solid #1d1d1d;
  background: #000;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  margin: auto;
  padding: 0 30px;
}
.successpasswordouter .successpasswordsection .topImg {
  width: 100%;
  max-width: 100px;
}
.successpasswordouter .successpasswordsection h1 {
  color: #fff;
  text-align: center;
  font-size: clamp(2.5rem, 3vw, 3.125rem);
  font-weight: 300;
}
.successpasswordouter .successpasswordsection p {
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  margin: 20px auto;
  font-size: 18px;
  font-weight: 400;
  max-width: 532px;
}
.successpasswordouter .successpasswordsection a {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  display: block;
  border-radius: 8px;
  background: #61D180;
  max-width: 494px;
  padding: 13px 0px;
  text-align: center;
  margin: 20px auto;
}

.candidateOuter .outerHeading {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  margin: 20px 0;
}

.sectionInvite {
  background-image: url(../imgs/inviteBg.svg);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 40px 20px;
  margin-top: 40px;
}
.sectionInvite__Content {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.sectionInvite__Content h4 {
  font-size: 40px;
  color: #fff;
  margin-bottom: 15px;
  font-weight: 300;
}
@media (max-width: 1366px) {
  .sectionInvite__Content h4 {
    font-size: 36px;
  }
}
@media (max-width: 1199px) {
  .sectionInvite__Content h4 {
    font-size: 30px;
  }
}
@media (max-width: 575px) {
  .sectionInvite__Content h4 {
    font-size: 20px;
  }
}
.sectionInvite__Content h4 span {
  color: #FDC500;
  font-weight: 700;
}
@media (max-width: 1500px) {
  .sectionInvite__Content h4 br {
    display: none;
  }
}
@media (max-width: 1440px) {
  .sectionInvite__Content h4 br {
    display: none;
  }
}
.sectionInvite__Content .btn {
  background-color: #023020;
  color: #61D180;
  font-weight: 600;
  padding: 12px 15px;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
}
.sectionInvite__Content .btn:hover {
  color: #fff;
  box-shadow: 0 10px 20px rgba(97, 209, 128, 0.2);
}
@media (max-width: 575px) {
  .sectionInvite__Content .btn {
    padding: 6px 15px;
  }
}
.sectionInvite__Img {
  text-align: center;
}
.sectionInvite__Img img {
  width: 100%;
  max-width: 330px;
}
@media (max-width: 575px) {
  .sectionInvite__Img img {
    max-width: 180px;
    margin-top: 15px;
  }
}

.candidateuser {
  margin: 94px 0px;
  background-color: #191A1E;
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  padding: 30px 30px;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
}
.candidateuser .candidateuserleft {
  display: flex;
  align-items: flex-start;
}
@media (max-width: 575px) {
  .candidateuser .candidateuserleft {
    flex-wrap: wrap;
  }
}
.candidateuser .candidateuserleft .candidateImg img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  -o-object-position: center;
}
@media (max-width: 575px) {
  .candidateuser .candidateuserleft .candidateImg img {
    width: 80px;
    height: 80px;
    margin-right: 10px;
  }
}
.candidateuser .candidateuserleft .candidateuserleft2 {
  margin-left: 18px;
}
@media (max-width: 991px) {
  .candidateuser .candidateuserleft .candidateuserleft2 {
    margin-left: 0px;
    margin-top: 20px;
  }
}
.candidateuser .candidateuserleft .candidateuserleft2 div {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.candidateuser .candidateuserleft h2 {
  color: #fff;
  font-size: clamp(2rem, 3vw, 2.25rem);
  font-weight: 700;
}
@media (max-width: 575px) {
  .candidateuser .candidateuserleft h2 {
    font-size: 22px;
  }
}
.candidateuser .candidateuserleft h2 span {
  color: #61D180;
  font-size: 14px;
  font-weight: 300;
  margin-left: 8px;
}
@media (max-width: 1440px) {
  .candidateuser .candidateuserleft h2 span {
    display: block;
  }
}
@media (max-width: 575px) {
  .candidateuser .candidateuserleft h2 span {
    margin-left: 0;
  }
}
.candidateuser .candidateuserleft h4 {
  color: #61D180;
  font-size: 14px;
  font-weight: 300;
  margin-left: 8px;
}
@media (max-width: 1366px) {
  .candidateuser .candidateuserleft h4 {
    margin-left: 0;
  }
}
@media (max-width: 575px) {
  .candidateuser .candidateuserleft h4 {
    margin-left: 0px;
  }
}
.candidateuser .candidateuserleft p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}
.candidateuser .candidateuserleft p span {
  font-weight: 600;
}
.candidateuser .candidateuserright .btn {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  border: 0;
  border-radius: 8px;
  padding: 15px 30px;
  font-weight: 600;
  font-size: 18px;
}
@media (max-width: 1366px) {
  .candidateuser .candidateuserright .btn {
    padding: 15px 10px;
  }
}
@media (max-width: 991px) {
  .candidateuser .candidateuserright .btn {
    margin-top: 20px;
  }
}
.candidateuser .profileCompletion {
  text-align: right;
  position: relative;
}
@media (max-width: 767px) {
  .candidateuser .profileCompletion {
    text-align: center;
  }
}
.candidateuser .profileCompletion .CircularProgressbar {
  width: 137px;
  height: 137px;
}
@media (max-width: 575px) {
  .candidateuser .profileCompletion .CircularProgressbar {
    width: 117px;
    height: 117px;
    margin-top: 20px;
  }
}
.candidateuser .profileCompletion .CircularProgressbar .CircularProgressbar-path {
  stroke: #61D180;
}
.candidateuser .profileCompletion .CircularProgressbar .CircularProgressbar-trail {
  stroke: rgba(97, 209, 128, 0.1);
}
.candidateuser .profileCompletion .CircularProgressbar .CircularProgressbar-text {
  fill: #61D180;
  font-size: 15px;
  font-weight: 700;
  transform: translate(0, -3px);
}
.candidateuser .profileCompletion p {
  color: #61D180;
  font-size: 15px;
  font-weight: 700;
  position: absolute;
  top: 53%;
  right: 26px;
}
@media (max-width: 1280px) {
  .candidateuser .profileCompletion p {
    right: 13px;
  }
}
@media (max-width: 1199px) {
  .candidateuser .profileCompletion p {
    right: 30px;
  }
}
@media (max-width: 991px) {
  .candidateuser .profileCompletion p {
    right: 26px;
  }
}
@media (max-width: 767px) {
  .candidateuser .profileCompletion p {
    right: 50%;
    top: 60%;
    transform: translate(50%, 0);
    font-size: 13px;
  }
}

.jobsHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.jobsHead button {
  background-color: transparent;
  border: 0;
}
@media (max-width: 575px) {
  .jobsHead button img {
    width: 100%;
    max-width: 30px;
  }
}

.candidatemidleft {
  background-color: #191A1E;
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  padding: 30px 30px;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  margin-bottom: 30px;
}
@media (max-width: 575px) {
  .candidatemidleft {
    padding: 30px 20px;
  }
}
.candidatemidleft .jobActions {
  position: absolute;
  right: 5px;
  top: 10px;
}
@media (max-width: 991px) {
  .candidatemidleft .jobActions {
    right: 0px;
    top: 5px;
  }
}
@media (max-width: 575px) {
  .candidatemidleft .jobActions {
    display: flex;
    gap: 5px;
    right: 5px;
  }
}
.candidatemidleft .jobActions button {
  background: transparent;
  border: none;
}
@media (max-width: 575px) {
  .candidatemidleft .jobActions button {
    padding: 0;
  }
}
@media (max-width: 991px) {
  .candidatemidleft .jobActions button img {
    width: 100%;
    max-width: 30px;
  }
}
.candidatemidleft .candidatemidabout {
  margin: 0 0px;
}
.candidatemidleft .candidatemidabout p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}
.candidatemidleft .candidatemidabout .candidatemidabout1 {
  display: flex;
  align-items: center;
  margin: 0 0 20px;
}
.candidatemidleft .candidatemidabout .candidatemidabout1 h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: 10px;
}
@media (max-width: 1440px) {
  .candidatemidleft .candidatemidabout .candidatemidabout1 h3 {
    font-size: 26px;
  }
}
@media (max-width: 575px) {
  .candidatemidleft .candidatemidabout .candidatemidabout1 h3 {
    font-size: 22px;
    align-items: flex-start;
  }
}
.candidatemidleft .candidatemidabout .eduDetail img {
  width: 100%;
  height: 95px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
  -o-object-position: center;
  margin-bottom: 20px;
}
@media (max-width: 575px) {
  .candidatemidleft .candidatemidabout .eduDetail img {
    height: 180px;
  }
}
.candidatemidleft .candidatemidabout .eduDetail .eduDyImg {
  display: inline-block;
}
@media (max-width: 575px) {
  .candidatemidleft .candidatemidabout .eduDetail .eduDyImg {
    display: block;
  }
}
.candidatemidleft .candidatemidabout .eduDetail .eduDyImg img {
  width: 85px;
  height: 85px;
}
@media (max-width: 991px) {
  .candidatemidleft .candidatemidabout .eduDetail .eduDyImg img {
    width: 95px;
    height: 95px;
  }
}
@media (max-width: 575px) {
  .candidatemidleft .candidatemidabout .eduDetail .eduDyImg img {
    width: 100%;
    height: 150px;
  }
}
.candidatemidleft .candidatemidabout .eduDetail h3 {
  color: #fff;
  font-weight: 400;
}
.candidatemidleft .candidatemidabout .eduDetail p {
  font-weight: 400;
}
.candidatemidleft .candidatemidabout .eduDetail p.experienceHead {
  font-size: 20px;
  margin-bottom: 30px;
  font-weight: 500;
  color: #fff;
}
.candidatemidleft .candidatemidabout .eduDetail p.colourGreen {
  color: #61D180;
  margin: 10px 0 !important;
}
.candidatemidleft .candidatemidabout .eduDetail p.description {
  font-size: 16px;
}
.candidatemidleft .candidatemidabout .eduDetail .eduDyText {
  padding-left: 15px;
}
@media (max-width: 1600px) {
  .candidatemidleft .candidatemidabout .eduDetail .eduDyText {
    padding-left: 30px;
  }
}
@media (max-width: 1440px) {
  .candidatemidleft .candidatemidabout .eduDetail .eduDyText {
    padding-left: 35px;
  }
}
@media (max-width: 1366px) {
  .candidatemidleft .candidatemidabout .eduDetail .eduDyText {
    padding-left: 0;
  }
}
@media (max-width: 1199px) {
  .candidatemidleft .candidatemidabout .eduDetail .eduDyText {
    padding-left: 35px;
  }
}
@media (max-width: 991px) {
  .candidatemidleft .candidatemidabout .eduDetail .eduDyText {
    padding-left: 0;
  }
}
.candidatemidleft .candidatemidabout .eduDetail .eduDyText h3 {
  font-weight: 500;
}
.candidatemidleft .candidatemidabout .divider {
  border: 1px solid rgba(97, 209, 128, 0.1);
  margin: 30px 0;
}
.candidatemidleft .candidatemidabout .progressOuter {
  margin-bottom: 15px;
}
.candidatemidleft .candidatemidabout .progressOuter p {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  margin-bottom: 10px;
}
.candidatemidleft .candidatemidabout .progressOuter .progress {
  height: 3px;
}
.candidatemidleft .candidatemidabout .progressOuter .progress .progress-bar {
  background-color: #61D180;
}
.candidatemidleft .candidatemidabout .tags {
  word-break: break-all;
}
.candidatemidleft .candidatemidabout .tags span {
  display: inline-block;
  border-radius: 8px;
  background: rgba(97, 209, 128, 0.1);
  padding: 5px 10px;
  color: #61D180;
  margin: 0 5px 5px 0;
}
.candidatemidleft .candidatereviews h3 {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 30px;
  font-weight: 700;
}
@media (max-width: 575px) {
  .candidatemidleft .candidatereviews h3 {
    align-items: flex-start;
  }
}
.candidatemidleft .candidatereviews .reviewOuter {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}
@media (max-width: 575px) {
  .candidatemidleft .candidatereviews .reviewOuter {
    align-items: center;
  }
}
.candidatemidleft .candidatereviews .reviewOuter .reviewImg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #fff;
  object-fit: cover;
  object-position: center;
  -o-object-position: center;
}
.candidatemidleft .candidatereviews .reviewOuter .reviewName h4 {
  color: #fff;
  font-size: 20px;
}
.candidatemidleft .candidatereviews .reviewOuter .reviewName h4 span {
  font-weight: 400;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
}
.candidatemidleft .candidatereviews .rating {
  text-align: right;
}
.candidatemidleft .candidatereviews .reviewContent {
  margin-top: 25px;
  position: relative;
}
.candidatemidleft .candidatereviews .reviewContent p {
  color: rgba(255, 255, 255, 0.7);
}
.candidatemidleft .candidatereviews .divider {
  border: 1px solid rgba(97, 209, 128, 0.1);
  margin: 30px 0;
}
.candidatemidleft .candidatereviews .addTestimonialBtn {
  position: absolute;
  top: 30px;
  right: 15px;
}
@media (max-width: 575px) {
  .candidatemidleft .candidatereviews .addTestimonialBtn {
    position: static;
    margin-bottom: 30px;
    text-align: right;
  }
}
.candidatemidleft .candidatereviews .addTestimonialBtn .btn {
  background: none;
  border: none;
  padding: 0;
  margin-left: 5px;
}
@media (max-width: 575px) {
  .candidatemidleft .candidatereviews .addTestimonialBtn .btn img {
    width: 100%;
    max-width: 30px;
  }
}
.candidatemidleft .candidatereviews .editTestimonialBtn {
  position: absolute;
  background: none;
  border: none;
  top: -45px;
  right: -15px;
}
@media (max-width: 1199px) {
  .candidatemidleft .candidatereviews .editTestimonialBtn {
    top: 0;
  }
}
@media (max-width: 575px) {
  .candidatemidleft .candidatereviews .editTestimonialBtn {
    top: -25px;
  }
}
@media (max-width: 575px) {
  .candidatemidleft .candidatereviews .editTestimonialBtn img {
    width: 100%;
    max-width: 25px;
  }
}
.candidatemidleft .detailText * {
  color: rgba(255, 255, 255, 0.7);
}
.candidatemidleft .detailText ul,
.candidatemidleft .detailText ol {
  margin-bottom: 30px;
}
.candidatemidleft .detailText ul li,
.candidatemidleft .detailText ol li {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 10px;
  list-style-type: none;
  position: relative;
  font-weight: 500;
}
.candidatemidleft .detailText ul li::after,
.candidatemidleft .detailText ol li::after {
  position: absolute;
  content: " ";
  width: 20px;
  height: 20px;
  background-image: url(../imgs/tickGreendark.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  left: -30px;
  top: 3px;
}
.candidatemidleft.experienceSection .candidatemidabout .candidatemidabout1 {
  margin-bottom: 0;
}
.candidatemidleft.experienceSection .candidatemidabout .candidatemidabout1 h3 {
  margin: 0;
}
@media (max-width: 575px) {
  .candidatemidleft.experienceSection .candidatemidabout .candidatemidabout1 .actionBtns {
    top: 20px !important;
  }
}
.candidatemidleft.experienceSection .candidatemidabout .divider:last-child {
  display: none;
}
.candidatemidleft.experienceSection .candidatemidabout .eduDetail {
  margin-top: 20px;
}
.candidatemidleft.experienceSection .candidatemidabout .eduDetail p:last-of-type {
  margin: 0;
}
.candidatemidleft.customPromptSection .candidatemidabout .candidatemidabout1 {
  margin-bottom: 0;
}
.candidatemidleft.customPromptSection .candidatemidabout .candidatemidabout1 h3 {
  margin: 0;
}
.candidatemidleft.customPromptSection .candidatemidabout .eduDetail {
  margin: 20px 0 0 0;
}
.candidatemidleft.customPromptSection .candidatemidabout .eduDetail .promtHead {
  font-size: 20px;
  font-weight: 600;
  color: #61D180;
}
.candidatemidleft.customPromptSection .candidatemidabout .eduDetail p {
  margin: 0;
}
.candidatemidleft.customPromptSection .candidatemidabout .eduDetail p.promptAnswer {
  font-weight: 500;
}
.candidatemidleft.aboutSection .candidatemidabout .candidatemidabout1 {
  margin-bottom: 0;
}
.candidatemidleft.aboutSection .candidatemidabout .candidatemidabout1 h3 {
  margin: 0;
}
.candidatemidleft.aboutSection .candidatemidabout p {
  margin-top: 20px;
}
.candidatemidleft.reviewsSection .candidatereviews .divider:last-child {
  display: none;
}
.candidatemidleft.reviewsSection .candidatereviews .reviewContent p:last-child {
  margin-bottom: 0;
}
.candidatemidleft.empty {
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: rgba(97, 209, 128, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
}

.setupProfile {
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: rgba(97, 209, 128, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 30px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media (max-width: 575px) {
  .setupProfile {
    padding: 20px;
  }
}
.setupProfile h3 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 700;
}
@media (max-width: 575px) {
  .setupProfile h3 {
    font-size: 20px;
  }
}
@media (max-width: 360px) {
  .setupProfile h3 {
    font-size: 18px;
  }
}
.setupProfile button {
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 15px;
  top: 30px;
}
@media (max-width: 575px) {
  .setupProfile button {
    top: 20px;
  }
}
@media (max-width: 1440px) {
  .setupProfile button img {
    max-width: 35px;
  }
}
@media (max-width: 575px) {
  .setupProfile button img {
    max-width: 30px;
  }
}

.candidateProfileRight {
  background-color: #191A1E;
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  padding: 30px 30px;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  margin-bottom: 30px;
}
.candidateProfileRight .candidatemidright1 h3 {
  display: flex;
  justify-content: space-between;
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  border-bottom: 1px solid rgba(97, 209, 128, 0.16);
  padding: 0 0 20px 0;
}
@media (max-width: 1440px) {
  .candidateProfileRight .candidatemidright1 h3 {
    font-size: 20px;
  }
}
.candidateProfileRight .candidatemidright1 h3.contactHeading {
  justify-content: flex-start;
  gap: 10px;
}
.candidateProfileRight .candidatemidright1 h3 .fa {
  font-size: 18px;
}
@media (max-width: 1440px) {
  .candidateProfileRight .candidatemidright1 h3 .fa {
    font-size: 14px;
  }
}
.candidateProfileRight .candidatemidright2 p {
  color: #fff;
  font-size: 20px;
  margin: 30px 0 0 0;
}
.candidateProfileRight .candidatemidright2 p span {
  color: #61D180;
  font-weight: 700;
  padding-left: 30px;
  padding-top: 5px;
  display: inline-block;
}
.candidateProfileRight .candidatemidright2 p.contactAddress {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  align-items: flex-start;
}
.candidateProfileRight .candidatemidright2 .sendmessage {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  padding: 10px 20px;
  font-weight: 600;
  display: inline-block;
  border-radius: 8px;
  margin-top: 20px;
}
.candidateProfileRight .candidatemidright2 .input-group {
  gap: 10px;
  margin-top: 20px;
}
.candidateProfileRight .candidatemidright2 .input-group .form-control {
  background-color: rgba(71, 72, 75, 0.5);
  border-radius: 8px !important;
  border-color: rgba(71, 72, 75, 0.5);
  box-shadow: none;
  color: #fff;
}
.candidateProfileRight .candidatemidright2 .input-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.2);
}
.candidateProfileRight .candidatemidright2 .input-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.2);
}
.candidateProfileRight .candidatemidright2 .input-group .btn {
  background-color: #61D180;
  border-radius: 8px !important;
}

.candidateFullImg img {
  width: 100%;
  border-radius: 8px;
}

.candidatemidright .candidatemidright1 h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(97, 209, 128, 0.16);
  padding: 0 0 20px 0;
}
.candidatemidright .candidatemidright1 h3 > div span {
  font-size: 16px;
}
.candidatemidright .candidatemidright2 {
  display: flex;
  align-items: flex-start;
}
.candidatemidright .candidatemidright2 div {
  margin: 0px 13px;
}
.candidatemidright .candidatemidright2 div p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0px;
}
.candidatemidright .candidatemidright2 div span {
  color: #61D180;
  font-size: clamp(1rem, 4vw, 1.125rem);
  font-weight: 600;
}
.candidatemidright .candidatemidright2 div span a {
  color: #61D180;
  word-break: break-all;
}
.candidatemidright .candidatemidright2 img {
  margin-top: 5px;
}
.candidatemidright .candidatemidright3 h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  border-bottom: 1px solid rgba(97, 209, 128, 0.16);
  padding: 0 0 20px 0;
}
.candidatemidright .candidatemidright4 {
  display: flex;
  align-items: flex-start;
}
.candidatemidright .candidatemidright4 p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 400;
  margin: 0px 13px;
}
.candidatemidright .sendmessage {
  display: block;
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 8px;
  background: #61D180;
  max-width: 251px;
  text-align: center;
  padding: 17px 0px;
  margin: 74px 0px;
}
.candidatemidright .maskimgcontainer {
  margin: 40px 0px;
  background-color: #f2f6fd;
  border-radius: 8px;
  background-image: url("../imgs/mask.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 460px;
  padding: 20px;
}
.candidatemidright .maskimgcontainer img {
  border-radius: 8px;
}
.candidatemidright .maskimgcontainer h3 {
  color: #000;
  font-size: 30px;
  font-weight: 700;
}
.candidatemidright .maskimgcontainer p {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  font-weight: 400;
}
.candidatemidright .maskimgcontainer a {
  display: block;
  color: #fff;
  font-size: 16px;
  font-weight: 700;
  border-radius: 8px;
  background: #61D180;
  max-width: 118px;
  text-align: center;
  padding: 14px 0px;
}
.candidatemidright .maskimg {
  margin: 40px 0px;
  border-radius: 8px;
}

.companyHiring {
  background-image: url(../imgs/hiringBg.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 20px;
  height: 400px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  background-blend-mode: overlay;
}
.companyHiring h3 {
  color: #fff;
  font-size: 30px;
}
.companyHiring p {
  color: #fff;
  font-weight: 500;
}
.companyHiring a {
  background-color: #000;
  color: #fff;
  font-weight: 600;
  padding: 10px 30px;
  display: inline-block;
  border-radius: 8px;
}

.fa {
  color: grey;
}

.checked {
  color: orange;
}

.jobssection > p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
  font-weight: 400;
}
.jobssection .jobssectiontop {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.jobssection .jobssectiontop .jobssectiontop1 {
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .jobssection .jobssectiontop .jobssectiontop1 {
    align-items: flex-start;
    flex-direction: column;
  }
}
.jobssection .jobssectiontop .jobssectiontop1 div {
  margin-left: 10px;
}
@media (max-width: 575px) {
  .jobssection .jobssectiontop .jobssectiontop1 div {
    margin: 10px 0 0 0;
  }
}
@media (max-width: 575px) {
  .jobssection .jobssectiontop .jobssectiontop1 div h4,
  .jobssection .jobssectiontop .jobssectiontop1 div .postTime {
    margin: 0 !important;
  }
}
.jobssection .jobssectiontop .jobssectiontop1 div h4 {
  color: #fff;
  font-size: 20px;
  font-weight: 700;
  margin: 0px 13px;
}
@media (max-width: 575px) {
  .jobssection .jobssectiontop .jobssectiontop1 div h4 {
    font-size: 15px;
  }
}
.jobssection .jobssectiontop .jobssectiontop1 div h4 span {
  font-weight: 400;
  font-size: 14px;
  color: #61D180;
  margin-left: 10px;
}
@media (max-width: 575px) {
  .jobssection .jobssectiontop .jobssectiontop1 div h4 span {
    display: block;
    margin-left: 0;
  }
}
.jobssection .jobssectiontop .jobssectiontop1 div h4 span img {
  margin-right: 5px;
}
.jobssection .jobssectiontop .jobssectiontop1 div .postTime {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin: 0 13px;
}
.jobssection .jobssectiontop .jobssectiontop1 div p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
  margin: 7px 13px;
}
.jobssection .jobssectiontop .jobssectiontop1 .jobImgs {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}
.jobssection .jobssectiontop .jobssectiontop2 {
  display: flex;
  align-items: center;
}
.jobssection .jobssectiontop .jobssectiontop2 span {
  color: #61D180;
  font-size: 14px;
  font-weight: 700;
  margin: 7px 20px;
}
.jobssection .pricingplans {
  margin: 15px 0px;
  color: #61D180;
  font-size: 20px;
  font-weight: 700;
}
.jobssection .pricingplans span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  font-weight: 400;
}
.jobssection .jobssectionbottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.jobssection .jobssectionbottom .tags {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.jobssection .jobssectionbottom .tags span {
  color: #61D180;
  font-size: 14px;
  font-weight: 400;
  border-radius: 8px;
  background: rgba(97, 209, 128, 0.1);
  padding: 10px 20px;
}
.jobssection .jobssectionbottom a {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
  border-radius: 8px;
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  width: 186px;
  padding: 13px 0px;
  text-align: center;
}
@media (max-width: 991px) {
  .jobssection .jobssectionbottom a {
    margin-top: 16px;
  }
}

.cppagination {
  margin: 40px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.cppagination a {
  color: #61D180;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 46px;
  height: 40px;
  background: rgba(97, 209, 128, 0.1);
  color: #61D180;
  margin-right: 10px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.cppagination a:hover {
  background-color: #61D180;
  color: #fff;
}
@media (max-width: 575px) {
  .cppagination a {
    font-size: 12px;
    width: 36px;
    height: 35px;
  }
}
.cppagination a.paginationarrowright {
  margin-right: 0;
}
.cppagination a.active {
  background-color: #61D180;
  color: #fff;
  font-weight: 500;
}

.jobListing {
  min-height: 100vh;
  background-color: #0D0D0D;
  padding: 30px 0 0;
}
.jobListing h3 {
  color: #fff;
  font-size: 40px;
  font-weight: 500;
}
.jobListing h3.preferencesHead {
  font-size: 26px;
}
.jobListing h3.preferencesHead .preferencesText {
  font-size: 17px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.jobListing__form form {
  border-radius: 8px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: #141414;
  box-shadow: 8px 8px 20px 0px rgba(113, 113, 113, 0.15);
}
.jobListing__form form .form-group {
  display: flex;
  position: relative;
}
.jobListing__form form .form-group label {
  color: #fff;
  white-space: nowrap;
  padding: 20px 0 0 20px;
  color: rgba(255, 255, 255, 0.7);
}
@media (max-width: 1366px) {
  .jobListing__form form .form-group label {
    padding: 15px 0 0 20px;
  }
}
.jobListing__form form .form-group .form-control {
  height: 65px;
  background-color: transparent;
  border: none;
  color: #fff;
  box-shadow: none;
}
.jobListing__form form .form-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.2);
}
.jobListing__form form .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.2);
}
@media (max-width: 1366px) {
  .jobListing__form form .form-group .form-control {
    height: 55px;
  }
}
.jobListing__form form .form-group span {
  position: absolute;
  cursor: pointer;
  top: 20px;
  right: 0;
}
.jobListing__form form .form-group span i {
  color: rgba(255, 255, 255, 0.2);
}
@media (max-width: 1366px) {
  .jobListing__form form .form-group span {
    top: 15px;
  }
}
@media (max-width: 991px) {
  .jobListing__form form .form-group span {
    right: 10px;
  }
}
.jobListing__form form .form-group.first::after {
  position: absolute;
  content: " ";
  width: 1px;
  height: 40px;
  background-color: rgba(255, 255, 255, 0.8);
  right: -22px;
  top: 14px;
}
@media (max-width: 1366px) {
  .jobListing__form form .form-group.first::after {
    top: 9px;
  }
}
@media (max-width: 991px) {
  .jobListing__form form .form-group.first::after {
    display: none;
  }
}
.jobListing__form form .btn-search {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  font-weight: 600;
  border: 0;
  width: 100%;
  height: 65px;
  border-radius: 8px;
}
@media (max-width: 1366px) {
  .jobListing__form form .btn-search {
    height: 55px;
  }
}
.jobListing__form .filters {
  display: flex;
  margin-top: 10px;
  gap: 5px;
}
@media (max-width: 1280px) {
  .jobListing__form .filters {
    flex-wrap: wrap;
  }
}
.jobListing__form .filters select {
  background-color: rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  font-size: 14px;
  padding: 5px 7px;
  border-color: transparent;
  outline: none;
  cursor: pointer;
}
.jobListing__form .filters .btn-clear {
  background-color: #61D180;
  color: #fff;
  border-radius: 8px;
  border-color: #61D180;
  padding: 0px 10px;
}
.jobListing .candidateTags {
  margin-top: 20px;
}
.jobListing .candidateTags span {
  color: #61D180;
  border-radius: 8px;
  background: rgba(97, 209, 128, 0.1);
  font-size: 14px;
  display: inline-block;
  padding: 5px 10px;
  margin-bottom: 8px;
  border: 1px solid rgba(97, 209, 128, 0.1);
  cursor: pointer;
  margin: 0 3px;
}
.jobListing .candidateTags span:hover {
  border-color: #61D180;
}
@media (max-width: 1440px) {
  .jobListing .candidateTags span {
    padding: 5px 4px;
    font-size: 13px;
  }
}
.jobListing__List {
  margin-top: 30px;
}
.jobListing__List h3 {
  color: #fff;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.jobListing__List h3 a {
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  width: 42px;
  height: 38px;
  border: 1px solid #61D180;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  margin: 0 5px;
}
.jobListing__List h3 a svg path {
  fill: rgba(255, 255, 255, 0.2);
}
.jobListing__List h3 a.active, .jobListing__List h3 a:hover {
  background-color: #61D180;
}
.jobListing__List h3 a.active svg path, .jobListing__List h3 a:hover svg path {
  fill: #fff;
}
.jobListing__Result {
  background-color: #191A1E;
  border: 1px solid rgba(97, 209, 128, 0.16);
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}
.jobListing__Result .resultImg img {
  width: 100%;
}
@media (max-width: 575px) {
  .jobListing__Result .resultImg img {
    max-width: 80px;
    margin-bottom: 10px;
  }
}
.jobListing__Result .resultText h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 700;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 575px) {
  .jobListing__Result .resultText h3 {
    font-size: 19px;
  }
}
.jobListing__Result .resultText h3 span {
  display: flex;
  align-items: center;
}
.jobListing__Result .resultText h3 span .dropdown .dropdown-toggle {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.jobListing__Result .resultText h3 span .dropdown .dropdown-toggle::after {
  display: none;
}
.jobListing__Result .resultText h3 span .dropdown .dropdown-menu {
  padding: 0;
  background-color: #141414;
}
.jobListing__Result .resultText h3 span .dropdown .dropdown-menu li {
  margin: 0;
}
.jobListing__Result .resultText h3 span .dropdown .dropdown-menu li a {
  color: #fff;
  padding: 15px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.jobListing__Result .resultText h3 span .dropdown .dropdown-menu li a:hover {
  background-color: transparent;
}
@media (max-width: 575px) {
  .jobListing__Result .resultText h3 span .dropdown .dropdown-menu li a {
    font-size: 14px;
  }
}
.jobListing__Result .resultText h3 span .dropdown .dropdown-menu li a img {
  margin-right: 5px;
}
.jobListing__Result .resultText h3 span .dropdown .dropdown-menu li:last-child a {
  border: 0;
}
.jobListing__Result .resultText h3 span img.premiumLogo {
  width: 100%;
  max-width: 50px;
  margin-left: 20px;
}
@media (max-width: 575px) {
  .jobListing__Result .resultText h3 span img.premiumLogo {
    max-width: 45px;
    margin-left: 0px;
  }
}
.jobListing__Result .resultText p {
  color: rgba(255, 255, 255, 0.7);
}
.jobListing__Result .resultText p span {
  display: inline-flex;
  align-items: center;
  margin-right: 10px;
  gap: 3px;
}
.jobListing__Result .resultText p span img {
  width: 100%;
  max-width: 20px;
}
.jobListing__Result .resultText ul {
  padding-left: 20px;
}
.jobListing__Result .resultText ul li {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 10px;
}
.jobListing__Result .resultText .hourlyRate {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 40px;
}
.jobListing__Result .resultText .hourlyRate h4 {
  color: #61D180;
  font-size: 18px;
  margin: 0;
}
.jobListing__Result .resultText .hourlyRate h4 span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}
@media (max-width: 575px) {
  .jobListing__Result .resultText .hourlyRate h4 {
    margin-bottom: 10px;
  }
}
.jobListing__Result .resultText .hourlyRate .btn {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  font-size: 18px;
  font-weight: 600;
  border: 0;
  padding: 10px 20px;
}
.jobListing__Result.premium {
  background-color: none;
  background-image: url(../imgs/primium.png);
  border-color: #FDC500;
}
.jobListing__Result.premium .resultText .hourlyRate h4 {
  color: #FDC500;
}
.jobListing__Candidate {
  background-color: #191A1E;
  border: 1px solid rgba(97, 209, 128, 0.5);
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 25px;
  border-radius: 8px;
  margin-bottom: 30px;
}
.jobListing__Candidate .candidate__preference {
  display: flex;
  gap: 5px;
}
.jobListing__Candidate .candidate__preference span {
  color: #61D180;
  border-radius: 8px;
  background-color: rgba(97, 209, 128, 0.16);
  display: inline-flex;
  align-items: center;
  padding: 5px 15px;
  font-size: 12px;
  min-height: 23px;
}
.jobListing__Candidate .candidateAboutText {
  color: rgba(255, 255, 255, 0.7);
  margin-top: 20px;
  font-size: 14px;
}
.jobListing__Candidate .candidateAboutText a {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
}
.jobListing__Candidate .hourlyRate {
  display: flex;
  height: 100%;
  align-items: center;
}
.jobListing__Candidate .hourlyRate h4 {
  color: #61D180;
  font-size: 18px;
  margin: 0;
}
.jobListing__Candidate .hourlyRate h4 span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}
.jobListing__Candidate .candidate__Btns {
  text-align: right;
}
.jobListing__Candidate .candidate__Btns a {
  border-radius: 8px;
  color: #fff;
  padding: 7px 20px;
  display: inline-block;
  font-size: 14px;
}
.jobListing__Candidate .candidate__Btns a.viewProfile {
  background-color: #3D3D3D;
  font-weight: 400;
}
.jobListing__Candidate .candidate__Btns a.inviteBtn {
  font-weight: 600;
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  margin-left: 10px;
}
@media (max-width: 575px) {
  .jobListing__Candidate .candidate__Btns a {
    width: 100%;
    text-align: center;
    margin: 10px 0 0 0 !important;
  }
}
.jobListing__Candidate .candidateDetail {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}
.jobListing__Candidate .candidateDetail img {
  width: 65px;
  height: 65px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #fff;
}
.jobListing__Candidate .candidateDetail h3 {
  color: #979797;
  font-size: 28px;
  font-weight: 600;
}
.jobListing__Candidate .candidateDetail .currentCompany {
  display: flex;
  gap: 10px;
}
.jobListing__Candidate .candidateDetail .currentCompany img {
  width: 32px;
  height: 32px;
}
.jobListing__Candidate .candidateDetail .currentCompany p {
  font-size: 10px;
  color: #fff;
}
.jobListing__Candidate .candidateDetail .currentCompany p span {
  color: rgba(255, 255, 255, 0.7);
  display: block;
}
.jobListing__Candidate .chartSection {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}
@media (max-width: 767px) {
  .jobListing__Candidate .chartSection {
    text-align: left;
  }
}
@media (max-width: 575px) {
  .jobListing__Candidate .chartSection {
    text-align: center;
  }
}
.jobListing__Candidate .chartSection svg {
  width: 120px;
  height: 120px;
}
.jobListing__Candidate .chartSection svg text {
  fill: #61D180;
  font-size: 5px;
  font-weight: 500;
}
.jobListing__Candidate .chartSection .matchPercentage {
  position: absolute;
  color: #61D180;
  text-align: center;
  font-weight: 500;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
@media (max-width: 1366px) {
  .jobListing__Candidate.Candidategrid .candidateDetail img {
    width: 50px;
    height: 50px;
  }
}
.jobListing__Candidate.Candidategrid .candidateDetail h3 {
  margin: 0;
}
@media (max-width: 1366px) {
  .jobListing__Candidate.Candidategrid .candidateDetail h3 {
    font-size: 25px;
  }
}
@media (max-width: 1280px) {
  .jobListing__Candidate.Candidategrid .candidateDetail h3 {
    font-size: 23px;
  }
}
.jobListing__Candidate.Candidategrid .currentCompanyImg {
  height: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
}
.jobListing__Candidate.Candidategrid .currentCompanyImg img {
  width: 40px;
  height: 40px;
  border: 0;
}
@media (max-width: 1366px) {
  .jobListing__Candidate.Candidategrid .currentCompanyImg img {
    width: 32px;
    height: 32px;
  }
}
.jobListing__Candidate.Candidategrid .currentCompanyImg .dropdown .dropdown-toggle {
  background-color: transparent;
  border: 0;
  padding: 0;
}
.jobListing__Candidate.Candidategrid .currentCompanyImg .dropdown .dropdown-toggle img {
  width: 26px;
  height: 26px;
}
.jobListing__Candidate.Candidategrid .currentCompanyImg .dropdown .dropdown-toggle::after {
  display: none;
}
.jobListing__Candidate.Candidategrid .currentCompanyImg .dropdown .dropdown-menu {
  padding: 0;
  background-color: #141414;
}
.jobListing__Candidate.Candidategrid .currentCompanyImg .dropdown .dropdown-menu li {
  margin: 0;
}
.jobListing__Candidate.Candidategrid .currentCompanyImg .dropdown .dropdown-menu li a {
  color: #fff;
  padding: 15px 15px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}
.jobListing__Candidate.Candidategrid .currentCompanyImg .dropdown .dropdown-menu li a:hover {
  background-color: transparent;
}
@media (max-width: 575px) {
  .jobListing__Candidate.Candidategrid .currentCompanyImg .dropdown .dropdown-menu li a {
    font-size: 14px;
  }
}
.jobListing__Candidate.Candidategrid .currentCompanyImg .dropdown .dropdown-menu li a img {
  height: auto;
  width: 16px;
  margin-right: 5px;
}
.jobListing__Candidate.Candidategrid .candidate__preference {
  margin: 20px 0;
}
@media (max-width: 1366px) {
  .jobListing__Candidate.Candidategrid .candidate__preference span {
    padding: 5px 10px;
    font-size: 11px;
  }
}
.jobListing__Candidate.Candidategrid .aboutCandidate {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0 0 20px 0;
}
.jobListing__Candidate.Candidategrid .aboutCandidate a {
  font-weight: 600;
  color: rgba(255, 255, 255, 0.7);
}
.jobListing__Candidate.Candidategrid .chartSection {
  text-align: left;
  margin: 0;
}
@media (max-width: 575px) {
  .jobListing__Candidate.Candidategrid .chartSection {
    text-align: center;
  }
}
.jobListing__Candidate.Candidategrid .chartSection svg {
  width: 90px;
  height: 90px;
}
@media (max-width: 1440px) {
  .jobListing__Candidate.Candidategrid .chartSection svg {
    width: 70px;
    height: 70px;
  }
}
@media (max-width: 1280px) {
  .jobListing__Candidate.Candidategrid .chartSection svg {
    width: 60px;
    height: 60px;
  }
}
.jobListing__Candidate.Candidategrid .chartSection svg text {
  font-size: 6px;
}
.jobListing__Candidate.Candidategrid .candidate__Btns {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
}
@media (max-width: 575px) {
  .jobListing__Candidate.Candidategrid .candidate__Btns {
    display: block;
  }
}
.jobListing__Candidate.Candidategrid .candidate__Btns a {
  padding: 14px 25px;
}
@media (max-width: 1600px) {
  .jobListing__Candidate.Candidategrid .candidate__Btns a {
    padding: 12px 19px;
  }
}
@media (max-width: 1440px) {
  .jobListing__Candidate.Candidategrid .candidate__Btns a {
    padding: 10px 10px;
  }
}
@media (max-width: 1366px) {
  .jobListing__Candidate.Candidategrid .candidate__Btns a {
    padding: 10px 7px;
    font-size: 13px;
  }
}
@media (max-width: 1280px) {
  .jobListing__Candidate.Candidategrid .candidate__Btns a {
    padding: 10px 6px;
    font-size: 12px;
  }
}
.jobListing__Candidate.Candidategrid .resultPreference {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-top: 20px;
}
.jobListing__Candidate.Candidategrid .resultPreference span {
  display: inline-flex;
  align-items: center;
  margin-right: 30px;
  gap: 3px;
}
@media (max-width: 1440px) {
  .jobListing__Candidate.Candidategrid .resultPreference span {
    margin-right: 10px;
  }
}
.jobListing__Candidate.Candidategrid ul {
  padding-left: 20px;
}
.jobListing__Candidate.Candidategrid ul li {
  color: rgba(255, 255, 255, 0.7);
}
.jobListing .sectionActivate {
  background-color: #191A1E;
  border: 1px solid rgba(97, 209, 128, 0.16);
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  border-radius: 8px;
  padding: 30px;
}
.jobListing .sectionActivate h3 {
  color: #fff;
  font-size: 18px;
}
.jobListing .sectionActivate .form-group {
  position: relative;
  margin: 20px 0 30px 0;
}
.jobListing .sectionActivate .form-group .form-control {
  background-color: #1D1D1D;
  border-color: rgba(97, 209, 128, 0.16);
  box-shadow: none;
  height: 50px;
  color: #fff;
  padding-left: 60px;
}
.jobListing .sectionActivate .form-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.2);
}
.jobListing .sectionActivate .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.2);
}
.jobListing .sectionActivate .form-group span {
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-right: 1px solid rgba(67, 67, 67, 0.5);
}
.jobListing .sectionActivate p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.jobListing .sectionActivate .btn {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  border: 0;
  color: #fff;
  font-weight: 700;
  padding: 10px 30px;
  margin-bottom: 20px;
}
.jobListing .searchableTags {
  margin-top: 30px;
}
.jobListing .searchableTags h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}
@media (max-width: 575px) {
  .jobListing .searchableTags h3 {
    font-size: 24px;
  }
}
.jobListing .searchableTags .tags {
  margin-top: 30px;
}
.jobListing .searchableTags .tags span {
  color: #61D180;
  background: rgba(97, 209, 128, 0.1);
  font-size: 14px;
  padding: 7px 10px;
  display: inline-block;
  margin: 0 5px 10px 0;
  border-radius: 8px;
}
.jobListing .premiumCandidate {
  background-image: url(../imgs/premium-candidate.png);
  background-size: cover;
  border-color: #FDC500;
}
.jobListing .premiumCandidate .candidateAvatar {
  position: relative;
}
.jobListing .premiumCandidate .candidateAvatar span {
  position: absolute;
  top: -10px;
  right: -10px;
}
.jobListing .premiumCandidate .candidateAvatar span img {
  border: none;
  width: 100%;
  max-width: 23px;
  height: auto;
}
.jobListing .premiumCandidate .chartSection svg path {
  stroke: #FDC500;
}
.jobListing .premiumCandidate .chartSection .matchPercentage {
  color: #FDC500;
}
.jobListing .premiumCandidate .hourlyRate h4 {
  color: #FDC500;
}
.jobListing__noResult {
  text-align: center;
  padding: 70px 0 0;
}
.jobListing__noResult img {
  width: 100%;
  max-width: 25%;
  margin-bottom: 20px;
}
.jobListing__noResult h4 {
  color: #fff;
  font-size: 36px;
}
.jobListing__noResult p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
}
.jobListing .jobDetails {
  background-color: #191A1E;
  border: 1px solid rgba(97, 209, 128, 0.16);
  border-radius: 8px;
  padding: 30px 30px;
  margin-bottom: 30px;
}
.jobListing .jobDetails__Header {
  display: flex;
  gap: 15px;
}
.jobListing .jobDetails__Header .jobImg img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 575px) {
  .jobListing .jobDetails__Header .jobImg img {
    width: 50px;
    height: 50px;
  }
}
.jobListing .jobDetails__Header .heading h2 {
  color: #fff;
  font-weight: 600;
}
@media (max-width: 1366px) {
  .jobListing .jobDetails__Header .heading h2 {
    font-size: 30px;
  }
}
@media (max-width: 575px) {
  .jobListing .jobDetails__Header .heading h2 {
    font-size: 20px;
  }
}
.jobListing .jobDetails__Header .heading h4 {
  color: #61D180;
  font-weight: 600;
}
.jobListing .jobDetails__Header .heading h4 span {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 300;
}
.jobListing .jobDetails__Header .heading p {
  color: rgba(255, 255, 255, 0.7);
}
.jobListing .jobDetails__Header .heading p.preference {
  font-weight: 300;
}
.jobListing .jobDetails__Header .heading p:last-of-type {
  margin: 0;
}
.jobListing .jobDetails .jobBtns {
  text-align: right;
}
.jobListing .jobDetails .jobBtns .btn {
  padding: 10px;
  border-radius: 8px;
  min-width: 180px;
  max-width: 180px;
  height: 50px;
  border: 0;
  margin: 0 5px;
  font-weight: 600;
}
@media (max-width: 1440px) {
  .jobListing .jobDetails .jobBtns .btn {
    min-width: 120px;
    max-width: 120px;
  }
}
@media (max-width: 575px) {
  .jobListing .jobDetails .jobBtns .btn {
    margin-bottom: 10px;
  }
}
.jobListing .jobDetails .jobBtns .btn.btn-cancel {
  background-color: #3D3D3D;
  color: rgba(255, 255, 255, 0.3);
  max-width: none;
  min-width: auto;
}
.jobListing .jobDetails .jobBtns .btn.btn-save {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
}
.jobListing .jobDetails hr {
  border-color: rgba(97, 209, 128, 0.16);
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
}
.jobListing .jobDetails .detailsContent .detail {
  font-size: 30px;
}
.jobListing .jobDetails .detailsContent p {
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
}
.jobListing .jobDetails .detailsContent h5 {
  font-size: 16px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.7);
  margin: 30px 0 15px 0;
}
.jobListing .jobDetails .detailsContent ul li {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 10px;
  list-style-type: none;
  position: relative;
}
.jobListing .jobDetails .detailsContent ul li::before {
  position: absolute;
  content: " ";
  width: 20px;
  height: 20px;
  background-image: url(../imgs/greentick.svg);
  background-size: 15px;
  background-repeat: no-repeat;
  left: -25px;
  top: 5px;
}
.jobListing .jobDetails .detailsContent .btns .btn {
  border-radius: 8px;
  background: rgba(61, 61, 61, 0.5);
  color: rgba(255, 255, 255, 0.2);
  padding: 10px 15px;
  margin: 0 5px;
  font-size: 18px;
}
.jobListing .jobDetails .detailsContent .btns .btn img {
  max-width: 25px;
}
.jobListing .jobDetails.premium .jobDetailsInner {
  background-image: url(../imgs/premium-candidate.png);
  background-size: cover;
  background-repeat: no-repeat;
  padding: 20px 20px;
  border-radius: 8px;
  position: relative;
  margin-bottom: 20px;
}
.jobListing .jobDetails.premium .jobDetailsInner .premiumIcon {
  position: absolute;
  top: 20px;
  right: 20px;
}
@media (max-width: 575px) {
  .jobListing .jobDetails.premium .jobDetailsInner .premiumIcon {
    top: -10px;
    right: -10px;
  }
}
@media (max-width: 575px) {
  .jobListing .jobDetails.premium .jobDetailsInner .premiumIcon img {
    width: 100%;
    max-width: 40px;
  }
}
.jobListing .jobDetails.premium .jobDetailsInner .jobDetails__Header .heading h2 {
  width: 80%;
}
@media (max-width: 575px) {
  .jobListing .jobDetails.premium .jobDetailsInner .jobDetails__Header .heading h2 {
    width: 100%;
  }
}
.jobListing .jobDetails.premium .video {
  position: relative;
  border-radius: 8px;
  overflow: hidden;
}
.jobListing .jobDetails.premium .video .thumbnail img {
  width: 100%;
  height: 350px;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 575px) {
  .jobListing .jobDetails.premium .video .thumbnail img {
    height: 250px;
  }
}
.jobListing .jobDetails.premium .video .playBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}
@media (max-width: 575px) {
  .jobListing .jobDetails.premium .video .playBtn img {
    width: 100%;
    max-width: 60px;
  }
}

@media (max-width: 1440px) {
  .commonModal .modal-dialog {
    max-width: 40%;
  }
}
@media (max-width: 1024px) {
  .commonModal .modal-dialog {
    max-width: 60%;
  }
}
@media (max-width: 767px) {
  .commonModal .modal-dialog {
    max-width: 90%;
    margin: 0 auto;
  }
}
.commonModal .modal-dialog .modal-content {
  border-radius: 8px;
  overflow: hidden;
  border: 1px solid rgba(97, 209, 128, 0.16);
  box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.1);
}
.commonModal .modal-dialog .modal-content .modal-header {
  background-color: #191A1E;
  border: 0;
}
.commonModal .modal-dialog .modal-content .modal-header .btn-close {
  background-image: url(../imgs/crossBlack.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  opacity: 1;
  box-shadow: none;
}
.commonModal .modal-dialog .modal-content .modal-body {
  background-color: #191A1E;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent {
  text-align: center;
  padding: 0 30px;
}
@media (max-width: 1600px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent {
    padding: 0 20px;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .ModalHeading {
  font-size: 30px;
  color: #fff;
  margin-bottom: 50px;
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .ModalHeading {
    font-size: 22px;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent h5 {
  font-size: 36px;
  color: #fff;
  font-weight: 300;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent h4.inviteSubText {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent h4.inviteSubText span {
  color: #FDC500;
  text-align: center;
  font-weight: 500;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent p {
  color: #fff;
  font-size: 19px;
  font-weight: 500;
  margin: 30px 0 30px 0;
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent p {
    font-size: 14px;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent p.experienceText {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.4);
  font-weight: 400;
  margin-top: 0;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent p.confirmText {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
}
@media (max-width: 1199px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent p br {
    display: none;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent a {
  border-radius: 8px;
  width: 190px;
  height: 48px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
}
@media (max-width: 1199px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent a {
    width: 170px;
    font-size: 14px;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent a.cancelBtn {
  background: #3D3D3D;
  color: rgba(255, 255, 255, 0.3);
  margin-right: 10px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent a.inviteBtn {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group {
  margin-bottom: 20px;
  position: relative;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group .form-control {
  color: rgba(255, 255, 255, 0.8);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group .form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group .form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.4);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group .form-control:disabled {
  background-color: #131313;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group ul {
  padding: 0;
  background-color: #1D1D1D;
  border: 1px solid rgba(97, 209, 128, 0.16);
  max-height: 200px;
  overflow-x: auto;
  border-radius: 8px;
  position: absolute;
  width: 100%;
  z-index: 11;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group ul::-webkit-scrollbar {
  width: 6px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group ul::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group ul::-webkit-scrollbar-thumb {
  background-color: rgba(97, 209, 128, 0.1);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group ul li {
  list-style-type: none;
  text-align: left;
  color: rgba(255, 255, 255, 0.7);
  padding: 8px 10px;
  transition: all 0.2s ease-in-out;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group select {
  background-image: url(../imgs/arrowBottom.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 98% center;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group textarea {
  min-height: 150px;
  resize: none;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group textarea::-webkit-scrollbar {
  width: 6px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group textarea::-webkit-scrollbar-thumb {
  background-color: rgba(97, 209, 128, 0.2);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group .appentBtn {
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.1);
  background: #0D0D0D;
  margin-top: 15px;
  color: rgba(255, 255, 255, 0.3);
  font-weight: 500;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group .iconSkillDelete {
  margin: 0 0 0 15px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group #half-stars-example .rating-group label .star {
  width: 20px;
  height: 20px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group #half-stars-example .rating-group label .activeStar {
  width: 20px;
  height: 20px;
  filter: invert(80%) sepia(59%) saturate(2087%) hue-rotate(352deg) brightness(397%) contrast(89%);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-check .form-check-label {
  color: rgba(255, 255, 255, 0.8);
  font-weight: 500;
  cursor: pointer;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-check .form-check-input {
  background-color: #191A1E;
  border-color: rgba(97, 209, 128, 0.16);
  box-shadow: none;
  cursor: pointer;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-check .form-check-input:checked {
  background-color: #61D180;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .tagSuggesion {
  text-align: left;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .tagSuggesion p {
  color: rgba(255, 255, 255, 0.4);
  margin: 0 0 10px 0;
  font-size: 18px;
  font-weight: 500;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .tagSuggesion span {
  border-radius: 8px;
  background: rgba(97, 209, 128, 0.1);
  color: #61D180;
  display: inline-block;
  font-size: 14px;
  padding: 5px 10px;
  margin: 0 5px 5px 0;
  cursor: pointer;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .formBtns {
  margin-top: 70px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .formBtns .btn {
  border-radius: 8px;
  width: 190px;
  height: 48px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  border: 0;
}
@media (max-width: 1199px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .formBtns .btn {
    width: 170px;
    font-size: 14px;
  }
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .formBtns .btn {
    width: 160px;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .formBtns .btn.cancelBtn {
  background: #3D3D3D;
  color: rgba(255, 255, 255, 0.3);
  margin-right: 10px;
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .formBtns .btn.cancelBtn {
    margin-left: 5px;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .formBtns .btn.cancelBtn:hover {
  box-shadow: 0 0 20px rgba(255, 255, 255, 0.1);
  transform: scale(1.02);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .formBtns .btn.inviteBtn {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  transition: all 0.2s ease-in-out;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .formBtns .btn.inviteBtn:hover {
  box-shadow: 0 0 20px rgba(97, 209, 128, 0.2);
  transform: scale(1.02);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .formBtns .btn.AppStatusBtn {
  width: 260px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .boostContent p {
  font-weight: 500;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .boostContent .inviteBtn {
  width: auto;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .boostContent .formBtns button {
  margin: 0;
}
@media (max-width: 991px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .boostContent .formBtns button {
    margin-bottom: 10px;
  }
}
@media (max-width: 1024px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .boostContent .formBtns button {
    width: 100%;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .boostContent .formBtns .tooltipOuter {
  cursor: pointer;
  display: inline-block;
  margin-left: 10px;
  position: relative;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .boostContent .formBtns .tooltipOuter .tooltipText {
  opacity: 0;
  position: absolute;
  background-color: #61D180;
  color: #fff;
  font-size: 15px;
  width: 260px;
  top: -130px;
  left: -210px;
  padding: 10px 10px;
  border-radius: 8px;
  transition: all 0.1s ease-in-out;
  font-weight: 500;
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .boostContent .formBtns .tooltipOuter .tooltipText {
    left: -113px;
    display: none;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .boostContent .formBtns .tooltipOuter .tooltipText::after {
  position: absolute;
  content: " ";
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid #61D180;
  bottom: -10px;
  right: 30px;
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .boostContent .formBtns .tooltipOuter .tooltipText::after {
    right: 50%;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .boostContent .formBtns .tooltipOuter:hover .tooltipText {
  opacity: 1;
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .boostContent .formBtns .tooltipOuter:hover .tooltipText {
    display: inline-block;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .stepProgressBar p {
  display: none;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .suggesion {
  text-align: left;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .suggesion h3 {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.4);
  font-weight: 500;
  margin-bottom: 15px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .suggesion span {
  border-radius: 8px;
  background: rgba(97, 209, 128, 0.1);
  color: #61D180;
  padding: 3px 10px;
  display: inline-block;
  font-size: 14px;
  margin-right: 5px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .candidatemidabout .divider:last-of-type {
  display: none;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent form .form-group.delereRow {
  margin: 6px 0 0 0;
  text-align: left;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .waitImg {
  width: 100%;
  max-width: 50%;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .waitText {
  color: #fff;
  font-weight: 500;
  font-size: 23px;
}
@media (max-width: 1440px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .waitText {
    font-size: 22px;
  }
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .waitText {
    font-size: 15px;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .waitText span {
  color: #61D180;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .deleteJobImg {
  width: 100%;
  max-width: 130px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .jobPosted__List {
  background-color: #1D1D1D;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .jobPosted__List .postHeader .postImg .img {
  position: absolute;
  right: -10px;
  top: -20px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .jobPosted__List .postHeader .postImg .img img {
  width: 45px;
  height: 45px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .jobPosted__List .postBodyContent p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .jobPosted__List .postBodyContent h4 {
  font-size: 15px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .btns {
  margin-top: 30px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .btns .btn {
  border: 0;
  border-radius: 8px;
  padding: 0;
  height: 44px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .btns .btn.googleBtn {
  background-color: #EA4335;
  color: #fff;
  padding: 0 30px;
  font-weight: 600;
  margin-right: 10px;
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .btns .btn.googleBtn {
    margin-bottom: 10px;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .btns .btn.linkedBtn img {
  height: 44px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .btns .linkedApplyBtn {
  margin-bottom: 0;
  height: 44px;
  width: auto;
  display: inline-block;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .btns .linkedApplyBtn img {
  width: 100%;
  height: 44px;
  border-radius: 8px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup p {
  width: 70%;
  position: relative;
  border-top: 1px solid rgba(97, 209, 128, 0.1);
  margin: 40px auto;
}
@media (max-width: 1366px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup p {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup p {
    width: 80%;
  }
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup p {
    width: 100%;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup p span {
  position: absolute;
  background-color: #191A1E;
  left: 50%;
  transform: translate(-50%, -50%);
  color: rgba(255, 255, 255, 0.7);
  padding: 0 10px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .form-group {
  position: relative;
  width: 70%;
  margin: 0 auto 30px;
}
@media (max-width: 991px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .form-group {
    width: 80%;
  }
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .form-group {
    width: 100%;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .form-group .form-control {
  padding-left: 55px;
  color: rgba(255, 255, 255, 0.5);
  height: 50px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .form-group span {
  position: absolute;
  color: rgba(255, 255, 255, 0.2);
  font-size: 18px;
  width: 40px;
  height: 50px;
  top: 0;
  left: 5px;
  padding: 0 10px;
  border-right: 1px solid rgba(74, 74, 74, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .form-group.signinEmail {
  display: flex;
  gap: 10px;
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .form-group.signinEmail {
    flex-wrap: wrap;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .postSignup .form-group.signinEmail .btn {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  width: 120px;
  border: 0;
  font-weight: 600;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .checkSection {
  text-align: left;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .checkSection .form-check {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .checkSection .form-check .form-check-label {
  color: rgba(255, 255, 255, 0.7);
  font-size: 18px;
  font-weight: 500;
  margin-left: 10px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .checkSection .form-check .form-check-input {
  width: 30px;
  height: 30px;
  background-color: #1D1D1D;
  border: 2px solid rgba(113, 113, 113, 0.1);
  box-shadow: none;
  overflow: visible;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .checkSection .form-check .form-check-input:checked {
  background-image: url(../imgs/checkMarkLarge.svg);
  background-size: 20px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .shareContent .input-group .input-group-text {
  border: 1px solid #61D180;
  background-color: rgba(97, 209, 128, 0.1);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .shareContent .input-group .form-control {
  color: #fff;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .shareContent .input-group .form-control:disabled {
  background-color: rgba(97, 209, 128, 0.1);
  color: rgba(255, 255, 255, 0.3);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .shareContent .shareBtns {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 30px 0;
}
@media (max-width: 575px) {
  .commonModal .modal-dialog .modal-content .modal-body .commonModalContent .shareContent .shareBtns {
    flex-direction: column;
  }
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .shareContent .shareBtns > div {
  display: flex;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .shareContent .shareBtns > div .btn {
  background-color: #1D1D1D;
  color: rgba(255, 255, 255, 0.7);
  padding: 10px 40px;
  font-weight: 500;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo p {
  margin: 0 0 20px 0;
  font-size: 16px;
  color: #61D180;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo p span {
  width: 150px;
  display: inline-block;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.8);
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo p.description {
  color: #fff;
  font-size: 14px;
  margin: 0;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo p.customPrompt {
  vertical-align: top;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .ActEduDtls {
  display: flex;
  margin-bottom: 5px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .ActEduDtls:last-of-type {
  margin-bottom: 0;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .ActEduDtls .eduDyImg {
  margin-right: 10px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .ActEduDtls .eduDyImg img {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
  object-position: center;
  -o-object-position: center;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .ActEduDtls .eduDyText h3 {
  font-size: 16px;
  color: #61D180;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .ActEduDtls .eduDyText p {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .eduDetail .experienceHead {
  font-size: 16px;
  color: #61D180;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .eduDetail p {
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .eduDetail p.description {
  margin-bottom: 10px;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .divider {
  height: 1px;
  background-color: rgba(97, 209, 128, 0.1);
  margin: 10px 0;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .divider:last-of-type {
  display: none;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .tags span {
  background-color: rgba(97, 209, 128, 0.3);
  color: #fff;
  padding: 3px 3px;
  border-radius: 8px;
  font-size: 13px;
  margin: 0 5px 5px 0;
  min-width: 20px;
  text-align: center;
  display: inline-block;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .skillListing {
  padding: 0;
}
.commonModal .modal-dialog .modal-content .modal-body .commonModalContent .teamInfo .skillListing li {
  background-color: rgba(97, 209, 128, 0.3);
  color: #fff;
  padding: 3px 3px;
  border-radius: 8px;
  font-size: 13px;
  margin: 0 5px 5px 0;
  min-width: 20px;
  text-align: center;
  display: inline-block;
}
.commonModal .modal-dialog .modal-content .modal-body .jobProgress .stepProgressBar {
  margin-top: 0;
}
.commonModal .modal-dialog .modal-content .modal-body .jobProgress .stepProgressBar p {
  display: none;
}
.commonModal.editEducationModal .candidatemidleft {
  text-align: left;
  background-color: transparent;
  border: 0;
  padding: 0;
  box-shadow: none;
}
.commonModal.editEducationModal .candidatemidleft .eduDetail {
  position: relative;
}
@media (max-width: 1440px) {
  .commonModal.editEducationModal .candidatemidleft .eduDetail img {
    height: 65px;
  }
}
@media (max-width: 575px) {
  .commonModal.editEducationModal .candidatemidleft .eduDetail img {
    height: 157px;
    object-fit: cover;
    object-position: center;
  }
}
.commonModal.editEducationModal .candidatemidleft .eduDetail h3 {
  font-size: 20px;
}
.commonModal.editEducationModal .candidatemidleft .eduDetail p {
  font-size: 14px !important;
  margin-bottom: 0 !important;
  margin-top: 10px !important;
  color: rgba(255, 255, 255, 0.7) !important;
}
.commonModal.editEducationModal .candidatemidleft .eduDetail .actionBtns {
  position: absolute;
  right: 0;
  top: 0;
}
.commonModal.editEducationModal .candidatemidleft .eduDetail .actionBtns .btn img {
  width: 100%;
  max-width: 50px;
  height: auto;
}
.commonModal.editEducationModal .candidatemidleft .eduDetail.reviewDetail .userIcon {
  border-radius: 50%;
  width: 100px;
  height: 100px;
}
.commonModal.editEducationModal .candidatemidleft .eduDetail.reviewDetail .rating {
  width: 100%;
  max-width: 100px;
  height: auto;
}
.commonModal.editEducationModal .candidatemidleft .eduDetail.reviewDetail .divider:last-child {
  display: none;
}
.commonModal.editEducationModal .candidatemidleft .eduDetail.reviewDetail .starRating label .star {
  width: 20px;
  height: 20px;
}
.commonModal.editEducationModal .candidatemidleft .eduDetail.reviewDetail .starRating label .activeStar {
  width: 20px;
  height: 20px;
  filter: invert(80%) sepia(59%) saturate(2087%) hue-rotate(352deg) brightness(397%) contrast(89%);
}
@media (max-width: 1440px) {
  .commonModal.boostModal .modal-dialog {
    max-width: 50%;
  }
}
@media (max-width: 767px) {
  .commonModal.boostModal .modal-dialog {
    max-width: 100%;
  }
}
.commonModal.uploadImageModal .modal-dialog .modal-content .modal-body .commonModalContent .dragDrop p {
  color: rgba(255, 255, 255, 0.4);
  margin-bottom: 0;
}
.commonModal.uploadImageModal .modal-dialog .modal-content .modal-body .commonModalContent .dragDrop p:last-of-type {
  margin-top: 10px;
}
.commonModal.confirmModal .modal-dialog {
  max-width: 30%;
}
@media (max-width: 1600px) {
  .commonModal.confirmModal .modal-dialog {
    max-width: 40%;
  }
}
@media (max-width: 991px) {
  .commonModal.confirmModal .modal-dialog {
    max-width: 60%;
  }
}
@media (max-width: 575px) {
  .commonModal.confirmModal .modal-dialog {
    max-width: 100%;
  }
}
.commonModal.profileModal .modal-dialog {
  max-width: 50%;
}
@media (max-width: 1600px) {
  .commonModal.profileModal .modal-dialog {
    max-width: 50%;
  }
}
@media (max-width: 991px) {
  .commonModal.profileModal .modal-dialog {
    max-width: 80%;
  }
}
@media (max-width: 575px) {
  .commonModal.profileModal .modal-dialog {
    max-width: 90%;
  }
}
.commonModal.companyProfileImg .commonModalContent .dragDrop p {
  color: rgba(255, 255, 255, 0.4) !important;
  font-size: 16px !important;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content {
  border: none;
  box-shadow: none;
  background-color: transparent;
  border-radius: 4px;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-header {
  padding: 0;
  text-align: center;
  background-color: transparent;
  margin-bottom: 20px;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-header .logo {
  width: 100%;
  max-width: 100px;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-body {
  position: relative;
  border: 1px solid #1D1D1D;
  box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.1);
  border-radius: 4px;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-body .commonModalContent .ModalHeading {
  margin-top: 50px;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-body .commonModalContent form {
  padding: 0 25px;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-body .commonModalContent form .previewUpload {
  display: inline-block;
  margin-top: 20px;
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 3px solid #fff;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-body .commonModalContent form .previewUpload img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-body .commonModalContent form .previewUpload .crossIcon {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-body .commonModalContent form .previewUpload .crossIcon img {
  max-width: 23px;
  height: auto;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-body .commonModalContent .stepProgressBar {
  margin-top: 0;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-body .commonModalContent .stepProgressBar p {
  display: none;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-body .commonModalContent .stepProgressBar .progress {
  background-color: #1D1D1D;
}
.commonModal.candidateOnboarding .modal-dialog .modal-content .modal-body .btn-close {
  position: absolute;
  top: 20px;
  right: 20px;
  background-image: url(../imgs/crossBlack.svg);
  background-size: 20px;
  background-repeat: no-repeat;
}
.commonModal.postingModal .modal-dialog .modal-content .modal-body .commonModalContent p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 16px;
}
.commonModal.postingModal .modal-dialog .modal-content .modal-body .commonModalContent h4.inviteSubText span small {
  color: rgba(253, 197, 0, 0.34);
  font-size: 16px;
}
.commonModal.postingModal .modal-dialog .modal-content .modal-body .commonModalContent .questionsList {
  padding: 0;
  height: 500px;
  overflow-y: auto;
}
.commonModal.postingModal .modal-dialog .modal-content .modal-body .commonModalContent .questionsList::-webkit-scrollbar {
  width: 6px;
}
.commonModal.postingModal .modal-dialog .modal-content .modal-body .commonModalContent .questionsList::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.commonModal.postingModal .modal-dialog .modal-content .modal-body .commonModalContent .questionsList::-webkit-scrollbar-thumb {
  background-color: rgba(97, 209, 128, 0.2);
}
.commonModal.postingModal .modal-dialog .modal-content .modal-body .commonModalContent .questionsList li {
  list-style-type: none;
  text-align: left;
}
.commonModal.postingModal .modal-dialog .modal-content .modal-body .commonModalContent .questionsList li h3 {
  font-size: 20px;
  color: #fff;
  font-weight: 300;
  display: flex;
  justify-content: space-between;
  margin: 0;
}
.commonModal.postingModal .modal-dialog .modal-content .modal-body .commonModalContent .questionsList li p {
  margin-top: 0;
  font-weight: 300;
}
.commonModal.postingModal .modal-dialog .modal-content .modal-body .commonModalContent .questionsList li button {
  background: transparent;
  border: 0;
}
.commonModal#educationhistoryModal .modal-body .commonModalContent form .row, .commonModal#experiencehistoryModal .modal-body .commonModalContent form .row, .commonModal#oneTimeEducationhistoryModal .modal-body .commonModalContent form .row {
  margin-bottom: 40px;
  position: relative;
}
.commonModal#educationhistoryModal .modal-body .commonModalContent form .row .deleteEduBtn, .commonModal#experiencehistoryModal .modal-body .commonModalContent form .row .deleteEduBtn, .commonModal#oneTimeEducationhistoryModal .modal-body .commonModalContent form .row .deleteEduBtn {
  position: absolute;
  right: 0;
  width: 50px;
  height: 40px;
  top: -50px;
  background-color: rgba(255, 27, 27, 0.2);
  color: #FF1B1B;
  border: none;
  border-radius: 8px;
}
@media (max-width: 575px) {
  .commonModal#educationhistoryModal .modal-body .commonModalContent form .row .deleteEduBtn, .commonModal#experiencehistoryModal .modal-body .commonModalContent form .row .deleteEduBtn, .commonModal#oneTimeEducationhistoryModal .modal-body .commonModalContent form .row .deleteEduBtn {
    width: 40px;
    height: 36px;
    top: -45px;
  }
}
.commonModal#educationhistoryModal .modal-body .commonModalContent form .row .deleteEduBtn i, .commonModal#experiencehistoryModal .modal-body .commonModalContent form .row .deleteEduBtn i, .commonModal#oneTimeEducationhistoryModal .modal-body .commonModalContent form .row .deleteEduBtn i {
  font-size: 18px;
}
@media (max-width: 575px) {
  .commonModal#educationhistoryModal .modal-body .commonModalContent form .row .deleteEduBtn i, .commonModal#experiencehistoryModal .modal-body .commonModalContent form .row .deleteEduBtn i, .commonModal#oneTimeEducationhistoryModal .modal-body .commonModalContent form .row .deleteEduBtn i {
    font-size: 15px;
  }
}
.commonModal#educationhistoryModal .modal-body .commonModalContent form .row:last-child, .commonModal#experiencehistoryModal .modal-body .commonModalContent form .row:last-child, .commonModal#oneTimeEducationhistoryModal .modal-body .commonModalContent form .row:last-child {
  margin-bottom: 0;
}
.commonModal#questionModal .modal-dialog .modal-content .modal-body .commonModalContent .questionsList li p {
  margin-bottom: 10px;
}

.commonTabs nav .nav-tabs {
  justify-content: space-between;
  border-bottom: 5px solid #47484B;
}
.commonTabs nav .nav-tabs .nav-link {
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  width: 20%;
  padding: 0 0 20px;
  border: 0;
  border-bottom: 5px solid transparent;
  margin-bottom: -5px;
}
.commonTabs nav .nav-tabs .nav-link.active, .commonTabs nav .nav-tabs .nav-link:hover {
  background-color: transparent;
  border-color: #61D180;
}
@media (max-width: 1366px) {
  .commonTabs nav .nav-tabs .nav-link {
    font-size: 18px;
  }
}
@media (max-width: 1280px) {
  .commonTabs nav .nav-tabs .nav-link {
    font-size: 16px;
  }
}
@media (max-width: 1199px) {
  .commonTabs nav .nav-tabs .nav-link {
    width: auto;
  }
}
@media (max-width: 575px) {
  .commonTabs nav .nav-tabs .nav-link {
    font-size: 13px;
    font-weight: 500;
  }
}
.commonTabs .tab-content .innerTabContent {
  padding: 50px 0;
}
.commonTabs .tab-content .innerTabContent h4 {
  color: #fff;
  font-size: clamp(1.2rem, 4vw, 1.625rem);
  font-weight: 700;
}
.commonTabs .tab-content .innerTabContent p {
  color: rgba(255, 255, 255, 0.7);
  font-size: clamp(14px, 4vw, 18px);
}
.commonTabs .tab-content .innerTabContent hr {
  border-color: rgba(255, 255, 255, 0.2);
}
.commonTabs .tab-content .innerTabContent .update {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 575px) {
  .commonTabs .tab-content .innerTabContent .update {
    justify-content: flex-start;
  }
}
.commonTabs .tab-content .innerTabContent .update a {
  color: #61D180;
  font-weight: 500;
}
.commonTabs .tab-content .innerTabContent .update .form-check-input {
  width: 45px;
  height: 25px;
  box-shadow: none;
  cursor: pointer;
}
.commonTabs .tab-content .innerTabContent .update .form-check-input:checked {
  background-color: #61D180;
  border-color: #61D180;
}
.commonTabs .tab-content .innerTabContent .commonForm .form-group label {
  color: rgba(255, 255, 255, 0.7);
  font-size: clamp(15px, 4vw, 19px);
  font-weight: 600;
  margin-bottom: 20px;
}
.commonTabs .tab-content .innerTabContent .commonForm .form-group .form-control {
  color: rgba(255, 255, 255, 0.8);
}
@media (max-width: 1199px) {
  .commonTabs .tab-content .innerTabContent .commonForm .form-group .form-control {
    margin-bottom: 10px;
  }
}
.commonTabs .tab-content .innerTabContent .commonForm hr {
  margin: 40px 0 30px;
}
.commonTabs .tab-content .innerTabContent .commonForm .billingCard {
  width: 320px;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.1);
  margin: 0 0 25px;
  background: #1D1D1D;
  position: relative;
}
@media (max-width: 1600px) {
  .commonTabs .tab-content .innerTabContent .commonForm .billingCard {
    width: auto;
  }
}
@media (max-width: 575px) {
  .commonTabs .tab-content .innerTabContent .commonForm .billingCard {
    height: 180px;
  }
}
.commonTabs .tab-content .innerTabContent .commonForm .billingCard .form-check-label img {
  margin-bottom: 10px;
}
@media (max-width: 1440px) {
  .commonTabs .tab-content .innerTabContent .commonForm .billingCard .form-check-label img {
    width: 100%;
    max-width: 80px;
  }
}
.commonTabs .tab-content .innerTabContent .commonForm .billingCard .form-check-label p {
  margin: 0;
}
.commonTabs .tab-content .innerTabContent .commonForm .billingCard .form-check-input {
  position: absolute;
  width: 21px;
  height: 21px;
  top: 20px;
  right: 20px;
  border-radius: 50%;
  cursor: pointer;
  background-color: #1D1D1D;
  border-color: rgba(255, 255, 255, 0.2);
  box-shadow: none;
}
.commonTabs .tab-content .innerTabContent .commonForm .billingCard .form-check-input:checked {
  background-color: #61D180;
  border-color: #61D180;
}
.commonTabs .tab-content .innerTabContent .commonForm .thirdPatyPayment {
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: #1D1D1D;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 20px;
}
.commonTabs .tab-content .innerTabContent .commonForm .thirdPatyPayment .form-check .form-check-input {
  border-radius: 50%;
  background-color: transparent;
  box-shadow: none;
}
.commonTabs .tab-content .innerTabContent .commonForm .thirdPatyPayment .form-check .form-check-input:checked {
  background-color: #61D180;
  border-color: #61D180;
}
.commonTabs .tab-content .innerTabContent .commonForm .thirdPatyPayment .form-check .form-check-label {
  color: #fff;
  font-weight: 500;
}
.commonTabs .tab-content .innerTabContent .integrationCard {
  background-color: #191A1E;
  border: 1px solid rgba(97, 209, 128, 0.16);
  border-radius: 8px;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  margin-top: 30px;
}
.commonTabs .tab-content .innerTabContent .integrationCard__Body {
  padding: 20px;
  height: 210px;
}
@media (max-width: 1440px) {
  .commonTabs .tab-content .innerTabContent .integrationCard__Body {
    height: 240px;
  }
}
@media (max-width: 767px) {
  .commonTabs .tab-content .innerTabContent .integrationCard__Body {
    height: auto;
  }
}
.commonTabs .tab-content .innerTabContent .integrationCard__Body .name {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.commonTabs .tab-content .innerTabContent .integrationCard__Body .name img {
  width: 65px;
  height: 65px;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 8px;
}
.commonTabs .tab-content .innerTabContent .integrationCard__Body .name h3 {
  color: #fff;
  margin: 0;
  padding-left: 20px;
  font-size: 20px;
}
.commonTabs .tab-content .innerTabContent .integrationCard__Body p {
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 15px;
}
@media (max-width: 1366px) {
  .commonTabs .tab-content .innerTabContent .integrationCard__Body p {
    font-size: 14px;
  }
}
.commonTabs .tab-content .innerTabContent .integrationCard__Body .form-check {
  display: flex;
  justify-content: end;
}
.commonTabs .tab-content .innerTabContent .integrationCard__Body .form-check .form-check-input {
  width: 45px;
  height: 25px;
  box-shadow: none;
  cursor: pointer;
}
.commonTabs .tab-content .innerTabContent .integrationCard__Body .form-check .form-check-input:checked {
  background-color: #61D180;
  border-color: #61D180;
}
.commonTabs .tab-content .innerTabContent .integrationCard__Footer {
  padding: 20px;
  text-align: right;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.commonTabs .tab-content .innerTabContent .integrationCard__Footer a {
  color: #61D180;
  font-size: 18px;
}

.logoutModal .modal-dialog {
  max-width: 600px;
}
.logoutModal .modal-dialog .modal-content {
  background-color: #191A1E;
  padding: 20px 0;
  border-radius: 8px;
}
.logoutModal .modal-dialog .modal-content .modal-header {
  border: 0;
}
.logoutModal .modal-dialog .modal-content .modal-header .btn-close {
  background-image: url(../imgs/crossBlack.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  opacity: 1;
  box-shadow: none;
}
.logoutModal .modal-dialog .modal-content .modal-body {
  text-align: center;
}
.logoutModal .modal-dialog .modal-content .modal-body p {
  color: #fff;
  font-size: 18px;
}
.logoutModal .modal-dialog .modal-content .modal-body p span {
  font-weight: 500;
}
.logoutModal .modal-dialog .modal-content .modal-footer {
  border: 0;
  justify-content: center;
}
.logoutModal .modal-dialog .modal-content .modal-footer .btn {
  padding: 10px;
  border-radius: 8px;
  min-width: 180px;
  max-width: 180px;
  height: 50px;
  border: 0;
  margin: 0 5px;
  font-weight: 600;
}
@media (max-width: 575px) {
  .logoutModal .modal-dialog .modal-content .modal-footer .btn {
    margin-bottom: 10px;
  }
}
.logoutModal .modal-dialog .modal-content .modal-footer .btn.btn-secondary {
  background-color: #3D3D3D;
  color: rgba(255, 255, 255, 0.3);
}
.logoutModal .modal-dialog .modal-content .modal-footer .btn.btn-primary {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
}

@media (max-width: 575px) {
  .dashboard .text-end .btn {
    margin-bottom: 10px;
    font-size: 14px;
  }
}
.dashboard .commonCard {
  margin-bottom: 30px;
}
@media (max-width: 1024px) {
  .dashboard .commonCard {
    margin-bottom: 10px;
  }
}
.dashboard .card {
  border: 0;
  border-radius: 8px;
  overflow: hidden;
  margin-bottom: 30px;
}
.dashboard .card .card-header {
  background-color: #1D1D1D;
  border-bottom: #1D1D1D;
  display: flex;
  justify-content: space-between;
}
.dashboard .card .card-header h3 {
  font-size: 30px;
  color: #fff;
}
.dashboard .card .card-header .dropdown .dropdown-toggle {
  background-color: rgba(255, 255, 255, 0.2);
  border: 8px;
}
.dashboard .card .card-header .dropdown .dropdown-toggle::after {
  display: none;
}
.dashboard .card .card-header .dropdown .dropdown-menu {
  padding: 0;
  background-color: #1D1D1D;
  width: 250px;
  margin-top: 15px !important;
  box-shadow: 0px 0px 10px 0px rgba(97, 209, 128, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.dashboard .card .card-header .dropdown .dropdown-menu::after {
  position: absolute;
  content: " ";
  width: 15px;
  height: 15px;
  background-image: url(../imgs/UpArrowBlack.svg);
  background-size: 15px;
  background-repeat: no-repeat;
  top: -13px;
  right: 10px;
}
.dashboard .card .card-header .dropdown .dropdown-menu li {
  color: #fff;
  padding: 10px 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  cursor: pointer;
}
.dashboard .card .card-header .dropdown .dropdown-menu li:last-of-type {
  border: 0;
}
.dashboard .card .card-header .dropdown .dropdown-menu li .form-check .form-check-label {
  font-size: 14px;
}
.dashboard .card .card-header .dropdown .dropdown-menu li .form-check .form-check-input {
  background-color: #1D1D1D;
  width: 18px;
  height: 18px;
  box-shadow: none;
}
.dashboard .card .card-header .dropdown .dropdown-menu li .form-check .form-check-input:checked {
  border-color: #fff;
  box-shadow: none;
}
.dashboard .card .card-body {
  background-color: #1D1D1D;
  padding: 0;
}
.dashboard .card .card-body.dashboardCardBody {
  height: 480px;
  overflow: auto;
}
.dashboard .card .card-body.dashboardCardBody::-webkit-scrollbar {
  width: 6px;
}
.dashboard .card .card-body.dashboardCardBody::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.dashboard .card .card-body.dashboardCardBody::-webkit-scrollbar-thumb {
  background-color: rgba(97, 209, 128, 0.1);
}
.dashboard .card .card-body .table {
  margin: 0;
}
.dashboard .card .card-body .table tr th {
  background-color: #141414;
  border-bottom: 1px solid rgba(97, 209, 128, 0.16);
  color: #fff;
  padding: 15px 15px;
  font-weight: 500;
}
@media (max-width: 1440px) {
  .dashboard .card .card-body .table tr th {
    padding: 15px 20px;
  }
}
@media (max-width: 1440px) {
  .dashboard .card .card-body .table tr th {
    white-space: nowrap;
  }
}
.dashboard .card .card-body .table tbody {
  border: 1px solid rgba(97, 209, 128, 0.16);
}
.dashboard .card .card-body .table tbody tr td {
  background-color: #191A1E;
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(97, 209, 128, 0.16);
  padding: 15px 15px;
  vertical-align: middle;
}
@media (max-width: 1440px) {
  .dashboard .card .card-body .table tbody tr td {
    white-space: nowrap;
    padding: 15px 20px;
  }
}
@media (max-width: 1366px) {
  .dashboard .card .card-body .table tbody tr td {
    font-size: 14px;
  }
}
.dashboard .card .card-body .table tbody tr td span {
  display: inline-block;
  margin-right: 10px;
}
@media (max-width: 1440px) {
  .dashboard .card .card-body .table tbody tr td span {
    margin-right: 10px;
  }
}
.dashboard .card .card-body .table tbody tr td span img {
  width: 100%;
  max-width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  border: 1px solid #fff;
}
.dashboard .card .card-body .table tbody tr td button {
  background-color: transparent;
  border: 0;
}
@media (max-width: 1440px) {
  .dashboard .card .card-body .table tbody tr td .btns {
    white-space: nowrap;
  }
}
.dashboard .card .card-body .table tbody tr td .btns a {
  border-radius: 8px;
  background: rgba(97, 209, 128, 0.1);
  color: #61D180;
  display: inline-block;
  padding: 6px 20px;
  font-size: 14px;
  margin-right: 10px;
}
.dashboard .card .card-body .table tbody tr td .btns a:last-of-type {
  margin: 0;
}
.dashboard .card .card-body ul {
  padding: 0;
}
.dashboard .card .card-body ul li {
  list-style-type: none;
}
.dashboard .card .card-body ul li a {
  text-decoration: none;
  display: flex;
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(97, 209, 128, 0.16);
  padding: 10px 0;
}
@media (max-width: 1440px) {
  .dashboard .card .card-body ul li a {
    display: grid;
    grid-template-columns: 15% 85%;
  }
}
@media (max-width: 1199px) {
  .dashboard .card .card-body ul li a {
    grid-template-columns: 20% 80%;
  }
}
@media (max-width: 991px) {
  .dashboard .card .card-body ul li a {
    display: flex;
  }
}
.dashboard .card .card-body ul li a .userImg {
  width: 35px;
  height: 35px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.dashboard .card .card-body ul li a p {
  font-size: 12px;
  margin: 0;
}
.dashboard .card .card-body ul li a p .yellow {
  color: #FDC500;
}
.dashboard .card .card-body ul li a p .green {
  color: #61D180;
}
.dashboard .card.activityCard .card-body {
  padding: 0 20px;
}
.dashboard .messageSection {
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: #191A1E;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 20px;
}
.dashboard .messageSection h3 {
  color: #fff;
}
@media (max-width: 1366px) {
  .dashboard .messageSection h3 {
    font-size: 24px;
  }
}
@media (max-width: 1280px) {
  .dashboard .messageSection h3 {
    font-size: 21px;
  }
}
.dashboard .messageSection .messages {
  border-bottom: 1px solid rgba(97, 209, 128, 0.16);
  padding: 15px 10px;
}
.dashboard .messageSection .messages .messageInner {
  display: flex;
  align-items: center;
}
.dashboard .messageSection .messages .messageInner .img {
  display: inline-block;
  margin-right: 10px;
}
.dashboard .messageSection .messages .messageInner .img img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  border: 1px solid #fff;
}
.dashboard .messageSection .messages .messageInner .listIcons {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 4px;
  width: 35px;
  height: 35px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.dashboard .messageSection .messages .messageInner .listIcons.text {
  display: block;
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 13px;
  padding: 5px 0;
}
.dashboard .messageSection .messages .messageInner .listIcons.text .green {
  color: #61D180;
  font-weight: 500;
}
.dashboard .messageSection .messages .messageInner p {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin: 0;
}
.dashboard .messageSection .messages .messageInner p span {
  font-weight: 500;
}
.dashboard .messageSection .messages p {
  font-size: 14px;
}
.dashboard .messageSection .messages p.purple {
  color: #A9ACFF;
}
.dashboard .messageSection .messages p.green {
  color: #61D180;
}
.dashboard .messageSection .messages p.yellow {
  color: #FDC500;
}
.dashboard .messageSection .messages p.red {
  color: #FF5C5C;
}

.profile {
  padding: 30px 10px 50px 10px;
  background: linear-gradient(90deg, #202020 0.14%, #212121 17.54%, #31523e 50.97%, #232a26 81.51%, #212121 99.9%);
  backdrop-filter: blur(2px);
}
.profile .candidateHeading {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 1024px) {
  .profile .candidateHeading {
    flex-wrap: wrap;
  }
}
@media (max-width: 575px) {
  .profile .candidateHeading {
    flex-direction: column;
    align-items: start;
  }
}
.profile .candidateHeading .release p span {
  font-size: 16px;
}
@media (max-width: 575px) {
  .profile .candidateHeading .release p span {
    font-size: 14px;
  }
}
@media (max-width: 1280px) {
  .profile .candidateHeading h2 {
    font-size: 2.2rem;
  }
}
@media (max-width: 1199px) {
  .profile .candidateHeading h2 {
    font-size: 2rem;
    order: 2;
  }
}
@media (max-width: 991px) {
  .profile .candidateHeading h2 {
    order: 1;
  }
}
@media (max-width: 575px) {
  .profile .candidateHeading h2 {
    font-size: 1.5rem;
    order: 2;
  }
}
.profile .btn-add {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  font-weight: 500;
  border: 0;
  border-radius: 8px;
  padding: 12px 20px;
}
.profile .btn-add img {
  width: 100%;
  max-width: 20px;
  margin-right: 5px;
}
.profile .commonTabs nav .nav-tabs {
  justify-content: flex-start;
}
.profile .commonTabs nav .nav-tabs .nav-link {
  width: 15%;
}
.profile .tab-content .candidateuser .candidateuserleft .candidateImg {
  position: relative;
}
@media (max-width: 991px) {
  .profile .tab-content .candidateuser .candidateuserleft .candidateImg {
    margin-right: 10px;
  }
}
.profile .tab-content .candidateuser .candidateuserleft .candidateImg .btn {
  position: absolute;
  bottom: -5px;
  border: 0;
  right: 0;
}
.profile .tab-content .candidateuser .candidateuserleft .candidateImg .btn:focus, .profile .tab-content .candidateuser .candidateuserleft .candidateImg .btn:active {
  outline: none;
}
.profile .tab-content .candidateuser .candidateuserleft .candidateImg .btn img {
  width: 100%;
  max-width: 30px;
  height: auto;
}
.profile .tab-content .candidateuser .candidateuserleft .candidateImg .btn input {
  position: absolute;
  left: 12px;
  width: 30px;
  height: 30px;
  opacity: 0;
  cursor: pointer;
}
.profile .tab-content .candidatemidleft {
  position: relative;
}
.profile .tab-content .candidatemidleft .candidatemidabout .candidatemidabout1 .actionBtns {
  position: absolute;
  right: 20px;
  top: 30px;
}
@media (max-width: 575px) {
  .profile .tab-content .candidatemidleft .candidatemidabout .candidatemidabout1 .actionBtns {
    top: 30px;
  }
}
.profile .tab-content .candidatemidleft .candidatemidabout .candidatemidabout1 .actionBtns .btn {
  padding: 0;
  margin-right: 10px;
  border: 0;
  transition: all 0.2s ease-in-out;
}
.profile .tab-content .candidatemidleft .candidatemidabout .candidatemidabout1 .actionBtns .btn:last-of-type {
  margin: 0;
}
@media (max-width: 1440px) {
  .profile .tab-content .candidatemidleft .candidatemidabout .candidatemidabout1 .actionBtns .btn img {
    max-width: 35px;
  }
}
@media (max-width: 575px) {
  .profile .tab-content .candidatemidleft .candidatemidabout .candidatemidabout1 .actionBtns .btn img {
    width: 100%;
    max-width: 30px;
  }
}
.profile .tab-content .candidatemidleft .candidatemidabout .candidatemidabout1 .actionBtns .btn:hover {
  box-shadow: 0 0 20px rgba(97, 209, 128, 0.2);
  transform: scale(1.03);
}
.profile .tab-content .candidatemidleft .eduDetail .form-control {
  background-color: rgba(97, 209, 128, 0.16);
  color: #61D180;
  font-size: 14px;
  background-image: url(../imgs/arrowBottomGreen.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 99% center;
  border: none;
  padding: 10px 10px;
  width: 50%;
  margin-bottom: 30px;
  box-shadow: none;
  cursor: pointer;
}
@media (max-width: 1366px) {
  .profile .tab-content .candidatemidleft .eduDetail .form-control {
    width: 100%;
  }
}
.profile .tab-content .candidatemidleft .eduDetail select option {
  background-color: #191A1E;
  color: rgba(255, 255, 255, 0.8);
}
.profile .profileRightOuter {
  margin-top: 40px;
}
@media (max-width: 1199px) {
  .profile .profileRightOuter {
    margin-top: 0;
  }
}
.profile .profileRightOuter .profileSkills .candidatemidabout .candidatemidabout1 {
  margin-bottom: 0;
}
.profile .profileRightOuter .profileSkills .candidatemidabout .candidatemidabout1 h3 {
  margin: 0;
}
@media (max-width: 1440px) {
  .profile .profileRightOuter .profileSkills .candidatemidabout .candidatemidabout1 h3 .actionBtns .btn img {
    width: 100%;
    max-width: 35px;
  }
}
@media (max-width: 1440px) {
  .profile .profileRightOuter .profileSkills .candidatemidabout .candidatemidabout1 .actionBtns {
    top: 26px;
  }
}
.profile .photoUploadSection {
  margin-bottom: 30px;
}
.profile .photoUploadSection p {
  color: rgba(255, 255, 255, 0.7);
}
.profile .photoUploadSection .dragDrop {
  background-color: #191A1E;
  border: 1px dashed rgba(97, 209, 128, 0.16);
  border-radius: 8px;
  text-align: center;
  padding: 40px;
  position: relative;
}
.profile .photoUploadSection .dragDrop input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: pointer;
  opacity: 0;
}
.profile .photoUploadSection .dragDrop p {
  color: rgba(255, 255, 255, 0.4);
}
.profile .photoUploadSection .dragDrop p .green {
  color: #61D180;
}
.profile .photoUploadSection .dragDrop p .white {
  color: #fff;
}
.profile .photoUploadSection .uploadPreview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 20px;
  position: relative;
}
.profile .photoUploadSection .uploadPreview img {
  border: 3px solid #fff;
}
.profile .photoUploadSection .uploadPreview .crossIcon {
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  border: 0;
  max-width: 23px;
  right: 10px;
}
.profile .profileForm {
  margin-top: 40px;
}
.profile .profileForm form .form-group {
  margin-bottom: 15px;
}
.profile .profileForm form .form-group label {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 8px;
}
.profile .profileForm form .form-group .form-control {
  color: rgba(255, 255, 255, 0.8);
}
.profile .profileForm form .form-group textarea {
  resize: none;
}
.profile .profileForm form .form-group textarea.description {
  height: 100px !important;
}
.profile .profileForm form .form-group textarea.about {
  height: 150px !important;
}
.profile .profileForm form .preview {
  width: 100px;
  margin-top: 50px;
  position: relative;
}
@media (max-width: 991px) {
  .profile .profileForm form .preview {
    margin-top: 20px;
  }
}
.profile .profileForm form .preview .uploadImg {
  width: 100px;
  height: 100px;
  object-fit: cover;
  object-position: center;
  border-radius: 50%;
}
.profile .profileForm form .preview span {
  position: absolute;
  right: 0;
  cursor: pointer;
}
.profile .profileForm form .preview span img {
  width: 25px;
}
.profile .hiringAlert {
  background-color: #191A1E;
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  padding: 30px 30px;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
}
.profile .hiringAlert h4 {
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  border-bottom: 1px solid rgba(97, 209, 128, 0.1);
  padding: 0 0 15px 0;
}
.profile .hiringAlert a {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  margin-top: 20px;
  padding: 10px 30px;
  font-weight: 700;
  border-radius: 8px;
  display: inline-block;
}
@media (max-width: 1440px) {
  .profile .hiringAlert a {
    padding: 10px 20px;
  }
}
.profile .hiringAlert a:hover {
  box-shadow: 0 10px 20px rgba(97, 209, 128, 0.2);
}
.profile .btnSection .btn {
  min-width: 150px;
  max-width: 150px;
}

.createJob {
  padding: 30px 10px 50px 10px;
  background: linear-gradient(90deg, #202020 0.14%, #212121 17.54%, #31523e 50.97%, #232a26 81.51%, #212121 99.9%);
  backdrop-filter: blur(2px);
}
.createJob__form {
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: #191A1E;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 30px;
}
.createJob__form form .form-group {
  margin-bottom: 20px;
}
.createJob__form form .form-group.monthlySelector > div > div {
  background-color: #1D1D1D;
  border: 1px solid rgba(97, 209, 128, 0.1);
  height: 50px;
  border-radius: 8px;
  box-shadow: none;
}
.createJob__form form .form-group.monthlySelector > div > div > div > div {
  color: #fff;
  padding: 8px 10px;
}
.createJob__form form .form-group.monthlySelector > div > div:nth-child(4) {
  background-color: #1D1D1D;
  border: 0;
  height: auto;
}
.createJob__form form .form-group.monthlySelector > div .css-1u9des2-indicatorSeparator {
  background-color: rgba(97, 209, 128, 0.1);
}
.createJob__form form .form-group.monthlySelector .css-1nmdiq5-menu {
  background-color: #1D1D1D !important;
  color: rgba(255, 255, 255, 0.8);
}
.createJob__form form .form-group.monthlySelector .css-1nmdiq5-menu > div {
  background-color: #1D1D1D;
}
.createJob__form form .form-group.monthlySelector .css-1nmdiq5-menu > div > div:hover {
  background-color: #141414;
}
.createJob__form form .form-group.monthlySelector .col-lg-5 {
  border: 0;
  background-color: transparent;
}
.createJob__form form .form-group .editorOuter .ck-editor .ck-editor__top .ck-reset_all {
  background: #1D1D1D;
}
.createJob__form form .form-group .editorOuter .ck-toolbar {
  background-color: #1D1D1D;
  border-color: rgba(97, 209, 128, 0.16);
  border-radius: 8px 8px 0 0 !important;
}
.createJob__form form .form-group .editorOuter .ck-toolbar__items .ck-button {
  color: #fff;
  box-shadow: none;
}
.createJob__form form .form-group .editorOuter .ck-toolbar__items .ck-button:hover, .createJob__form form .form-group .editorOuter .ck-toolbar__items .ck-button:focus {
  background: #1D1D1D;
  color: #fff;
}
.createJob__form form .form-group .editorOuter .ck-toolbar__items .ck-dropdown__panel ul {
  background: #1D1D1D;
}
.createJob__form form .form-group .editorOuter .ck-content {
  background-color: #1D1D1D;
  min-height: 300px;
  color: #fff;
  border-color: rgba(97, 209, 128, 0.16);
  border-radius: 0 0 8px 8px;
}
.createJob__form form .form-group .editorOuter .ck-content.ck-focused {
  border-color: rgba(97, 209, 128, 0.16);
}
.createJob__form form .form-group .form-control {
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background-color: #1D1D1D;
  height: 50px;
  box-shadow: none;
  color: rgba(255, 255, 255, 0.8);
}
.createJob__form form .form-group .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.4);
}
.createJob__form form .form-group .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.4);
}
.createJob__form form .form-group textarea {
  min-height: 150px;
}
.createJob__form form .form-group select {
  background-image: url(../imgs/arrowBottom.svg);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 99% center;
}
.createJob__form .salaryRange {
  border-radius: 100px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: linear-gradient(92deg, rgba(97, 209, 128, 0.1) 0.24%, rgba(2, 48, 32, 0.1) 139.73%);
  padding: 15px;
  text-align: center;
}
.createJob__form .salaryRange p {
  color: #fff;
  margin: 0;
}
.createJob__form .salaryRange p .green {
  color: #61D180;
}
.createJob__form .selectJob {
  margin-top: 50px;
}
.createJob__form .selectJob p {
  font-weight: 300;
  color: #fff;
  font-size: 20px;
}
.createJob__form .selectJob .selectJobTiles {
  display: flex;
  gap: 50px;
}
@media (max-width: 991px) {
  .createJob__form .selectJob .selectJobTiles {
    flex-wrap: wrap;
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .createJob__form .selectJob .selectJobTiles {
    gap: 10px;
  }
}
.createJob__form .selectJob .selectJobTiles .tile {
  background-color: #1D1D1D;
  border: 1px solid rgba(97, 209, 128, 0.1);
  border-radius: 8px;
  width: 250px;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 575px) {
  .createJob__form .selectJob .selectJobTiles .tile {
    width: 130px;
    height: 130px;
  }
}
.createJob__form .selectJob .selectJobTiles .tile p {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
  font-size: 18px;
  transition: all 0.2s ease-in-out;
}
.createJob__form .selectJob .selectJobTiles .tile svg {
  transition: all 0.2s ease-in-out;
}
@media (max-width: 575px) {
  .createJob__form .selectJob .selectJobTiles .tile svg {
    width: 50px;
    height: 50px;
  }
}
.createJob__form .selectJob .selectJobTiles .tile .checkMark {
  opacity: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  transition: all 0.2s ease-in-out;
}
.createJob__form .selectJob .selectJobTiles .tile:hover, .createJob__form .selectJob .selectJobTiles .tile.active {
  border-color: #61D180;
}
.createJob__form .selectJob .selectJobTiles .tile:hover p, .createJob__form .selectJob .selectJobTiles .tile.active p {
  color: #61D180;
}
.createJob__form .selectJob .selectJobTiles .tile:hover svg path, .createJob__form .selectJob .selectJobTiles .tile.active svg path {
  fill: #61D180;
}
.createJob__form .selectJob .selectJobTiles .tile:hover .checkMark, .createJob__form .selectJob .selectJobTiles .tile.active .checkMark {
  opacity: 1;
}
.createJob__form .selectJob .departmentSelector > div > div {
  background-color: #1D1D1D;
  border: 1px solid rgba(97, 209, 128, 0.1);
  height: 50px;
  border-radius: 8px;
}
.createJob__form .selectJob .departmentSelector > div > div:nth-child(2) {
  border: 0;
}
.createJob__form .selectJob .departmentSelector > div > div:hover, .createJob__form .selectJob .departmentSelector > div > div:focus {
  box-shadow: none;
}
.createJob__form .selectJob .departmentSelector > div > div > div > div {
  color: #fff;
}
.createJob__form .selectJob .departmentSelector > div > div > div > div.css-d7l1ni-option {
  background-color: #1D1D1D;
}
.createJob__form .selectJob .departmentSelector > div > div:nth-child(4) {
  background-color: transparent;
  border: 0;
  height: auto;
}
.createJob__form .selectJob .departmentSelector > div > div > div {
  background-color: #1D1D1D;
}
.createJob__form .selectJob .departmentSelector > div .css-1u9des2-indicatorSeparator {
  background-color: rgba(97, 209, 128, 0.1);
}
.createJob__form .selectJob .departmentSelector .css-1nmdiq5-menu {
  background-color: #1D1D1D !important;
  color: rgba(255, 255, 255, 0.8);
}
.createJob__form .selectJob .departmentSelector .css-1nmdiq5-menu > div {
  background-color: #1D1D1D;
}
.createJob__form .selectJob .departmentSelector .css-1nmdiq5-menu > div > div:hover {
  background-color: #141414;
}
.createJob__form .selectJob .departmentSelector .otherOptions {
  display: flex;
  margin-top: 15px;
}
.createJob__form .selectJob .departmentSelector .otherOptions button {
  border: 0;
  background: none;
  margin-left: 10px;
}
.createJob__form .selectJob .departmentSelector .otherOptions .form-control {
  color: rgba(255, 255, 255, 0.8);
}
.createJob__form .uploader p {
  font-weight: 300;
  font-size: 18px;
  color: #fff;
}
.createJob__form .uploader .dragDrop {
  text-align: center;
  border: 1px dashed #FDC500;
  border-radius: 8px;
  padding: 50px;
  background-color: #1D1D1D;
  position: relative;
  margin-bottom: 30px;
}
.createJob__form .uploader .dragDrop .premium {
  position: absolute;
  right: 10px;
  top: 10px;
}
.createJob__form .uploader .dragDrop .premium img {
  width: 100%;
  max-width: 60px;
}
.createJob__form .uploader .dragDrop input {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  cursor: pointer;
}
.createJob__form .uploader .dragDrop p {
  color: rgba(255, 255, 255, 0.4);
}
.createJob__form .uploader .dragDrop p .white {
  color: #fff;
}
.createJob__form .uploader .dragDrop p .green {
  color: #61D180;
}
.createJob__form .skillsGrid {
  position: relative;
}
.createJob__form .skillsGrid .form-group {
  width: 100%;
  margin: 0;
}
@media (max-width: 575px) {
  .createJob__form .skillsGrid .form-group {
    width: 100%;
    margin-bottom: 5px;
  }
}
.createJob__form .skillsGrid button {
  background: transparent;
  border: none;
  position: absolute;
  top: 6px;
  right: 3px;
}
.createJob__form .skillListing {
  padding: 0;
  margin: 15px 0;
}
.createJob__form .skillListing li {
  list-style-type: none;
  display: inline-block;
  color: #61D180;
  border-radius: 8px;
  background: rgba(97, 209, 128, 0.1);
  font-size: 14px;
  padding: 5px 15px;
  margin: 0 10px 10px 0;
  text-transform: capitalize;
}
.createJob__form .skillListing .crossIcon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}
.createJob__form .skillListingli:hover .crossIcon {
  opacity: 1;
  cursor: pointer;
}

.commonTable {
  border-radius: 8px;
  overflow: hidden;
}
.commonTable .searchBar {
  position: relative;
}
.commonTable .searchBar .form-control {
  padding-left: 40px;
}
.commonTable .searchBar .form-control::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: rgba(255, 255, 255, 0.4);
}
.commonTable .searchBar .form-control::-moz-placeholder {
  /* Firefox 19+ */
  color: rgba(255, 255, 255, 0.4);
}
.commonTable .searchBar span {
  position: absolute;
  top: 12px;
  left: 10px;
}
.commonTable .tableOuter {
  border: 1px solid #1D1D1D;
  border-radius: 8px;
  overflow: hidden;
}
.commonTable .tableOuter .table {
  margin-bottom: 0;
}
.commonTable .tableOuter .table thead tr th {
  background-color: #0D0D0D;
  color: #fff;
  border-color: #0D0D0D;
  padding: 15px 10px;
  font-weight: 500;
}
.commonTable .tableOuter .table tbody tr td {
  background-color: #191A1E;
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(113, 113, 113, 0.1);
  vertical-align: middle;
  padding: 8px 10px;
}
.commonTable .tableOuter .table tbody tr td .compatibe {
  border-radius: 8px;
  padding: 8px 10px;
  font-size: 14px;
}
.commonTable .tableOuter .table tbody tr td .compatibe.green {
  color: #61D180;
  background: rgba(97, 209, 128, 0.1);
}
.commonTable .tableOuter .table tbody tr td .compatibe.orange {
  color: #F28900;
  background: rgba(202, 97, 0, 0.23);
}
.commonTable .tableOuter .table tbody tr td .compatibe.yellow {
  color: #FDC500;
  background: rgba(253, 197, 0, 0.16);
}
.commonTable .tableOuter .table tbody tr td .status {
  font-size: 14px;
  border-radius: 8px;
  padding: 8px 10px;
  min-width: 130px;
  max-width: 130px;
  display: inline-block;
  text-align: center;
}
.commonTable .tableOuter .table tbody tr td .status.hire {
  color: #61D180;
  background-color: #023020;
}
.commonTable .tableOuter .table tbody tr td .status.message {
  color: #61D180;
  background-color: rgba(97, 209, 128, 0.2);
}
.commonTable .tableOuter .table tbody tr td .status.sentOffer {
  color: #61D180;
  background-color: rgba(97, 209, 128, 0.05);
}
.commonTable .tableOuter .pagination {
  padding: 20px;
}
.commonTable .tableOuter .pagination .enteries p {
  color: #fff;
  margin: 10px 0 0 0;
}
.commonTable .tableOuter .pagination .cppagination {
  margin: 0;
  justify-content: end;
}

.budget__Content {
  background-color: #191A1E;
  border: 1px solid rgba(97, 209, 128, 0.16);
  border-radius: 8px;
  padding: 20px;
}
.budget__Content h3 {
  font-size: 30px;
  font-weight: 300;
  color: #fff;
}
.budget__Content p {
  color: #fff;
  font-weight: 300;
  margin-bottom: 30px;
}
.budget__Content .rangeSection {
  margin-bottom: 30px;
}
.budget__Content .rangeSection h5 {
  font-size: 20px;
  font-weight: 300;
  color: #fff;
}
.budget__Content .rangeSection p {
  text-align: center;
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.4);
}
.budget__Content .rangeSection .rc-slider .rc-slider-rail {
  background: rgba(97, 209, 128, 0.16);
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  height: 8px;
}
.budget__Content .rangeSection .rc-slider .rc-slider-track {
  background-color: #61D180;
  height: 8px;
}
.budget__Content .rangeSection .rc-slider .rc-slider-handle {
  width: 30px;
  height: 30px;
  background-color: #61D180;
  border-color: #61D180;
  opacity: 1;
  box-shadow: none;
  top: 0;
}
.budget__Content .rangeSection .rc-slider .rc-slider-handle.rc-slider-handle-dragging {
  border-color: #61D180;
  box-shadow: none;
}

.forYou__Content {
  background-color: #191A1E;
  border: 1px solid #1D1D1D;
  padding: 20px;
  border-radius: 8px;
}
.forYou__Content .forYouCover {
  background-image: url(../imgs/primium.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 40px 20px;
  border-radius: 8px;
}
.forYou__Content .forYouCover .userImg {
  text-align: center;
}
.forYou__Content .forYouCover .userImg img {
  width: 100%;
  max-width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  -o-object-position: center;
}
@media (max-width: 1366px) {
  .forYou__Content .forYouCover .userImg img {
    max-width: 80px;
    height: 80px;
  }
}
@media (max-width: 1280px) {
  .forYou__Content .forYouCover .userImg img {
    max-width: 70px;
    height: 70px;
  }
}
.forYou__Content .forYouCover h3 {
  color: #fff;
  font-size: 30px;
}
.forYou__Content .forYouCover p {
  color: #fff;
  font-size: 14px;
}
.forYou__Content .forYouCover h5 {
  font-size: 24px;
  color: #61D180;
  font-weight: 500;
}
.forYou__Content .forYouCover h5 span {
  color: rgba(255, 255, 255, 0.5);
  font-size: 14px;
}
.forYou__Content .forYouDetail {
  margin-top: 30px;
}
.forYou__Content .forYouDetail h3 {
  color: #61D180;
  font-size: 32px;
  font-weight: 600;
}
.forYou__Content .forYouDetail h5 {
  font-weight: 300;
  font-size: 22px;
  color: #fff;
}
.forYou__Content .forYouDetail p {
  color: #fff;
  font-weight: 600;
  font-size: 14px;
}
.forYou__Content .forYouDetail h4 {
  color: #fff;
  font-size: 22px;
  margin: 30px 0 15px 0;
  font-weight: 600;
}
.forYou__Content .forYouDetail ul li {
  list-style-type: none;
  color: rgba(255, 255, 255, 0.7);
  position: relative;
  margin-bottom: 10px;
}
.forYou__Content .forYouDetail ul li::after {
  position: absolute;
  content: " ";
  background-image: url(../imgs/greentick.svg);
  background-size: 15px;
  background-repeat: no-repeat;
  width: 15px;
  height: 15px;
  left: -25px;
  top: 5px;
}
.forYou__Content .forYouDetail .donut {
  position: relative;
  text-align: center;
}
.forYou__Content .forYouDetail .donut svg {
  height: 120px;
  width: 120px;
}
.forYou__Content .forYouDetail .donut p {
  color: #61D180;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.forYou__Content .forYouBtn {
  display: flex;
  justify-content: space-between;
  margin: 30px 0;
}
.forYou__Content .forYouBtn .btn {
  padding: 0;
}
.forYou__Content .forYouBtn .btn img {
  width: 100%;
  max-width: 55px;
}

.messages .listSection {
  padding-right: 0;
}
.messages__Contact {
  border-right: 1px solid rgba(97, 209, 128, 0.16);
}
.messages__Contact .searchContact {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #191A1E;
  padding: 20px;
}
.messages__Contact .searchContact h4 {
  color: #fff;
  margin: 0;
}
.messages__Contact .searchContact .form-group {
  width: 65%;
  position: relative;
}
.messages__Contact .searchContact .form-group .form-control {
  border-radius: 100px;
  color: rgba(255, 255, 255, 0.4);
  padding-left: 40px;
}
.messages__Contact .searchContact .form-group span {
  position: absolute;
  top: 0;
  color: #61D180;
  top: 13px;
  left: 13px;
}
.messages__Contact .contactOuter {
  height: 69vh;
  overflow: auto;
}
.messages__Contact .contactOuter::-webkit-scrollbar {
  width: 6px;
}
.messages__Contact .contactOuter::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.messages__Contact .contactOuter::-webkit-scrollbar-thumb {
  background-color: rgba(97, 209, 128, 0.2);
}
.messages__Contact .contactOuter .contactList {
  padding: 20px 20px;
  background-color: #1F1D1F;
  border-bottom: 1px solid rgba(97, 209, 128, 0.16);
  cursor: pointer;
}
.messages__Contact .contactOuter .contactList .col-xl-9 {
  padding-left: 0;
}
.messages__Contact .contactOuter .contactList .contactImg {
  text-align: center;
  position: relative;
}
@media (max-width: 575px) {
  .messages__Contact .contactOuter .contactList .contactImg {
    text-align: left;
  }
}
.messages__Contact .contactOuter .contactList .contactImg span {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 4px solid #fff;
  position: absolute;
  background-color: #cccccc;
  top: 0;
  left: 10px;
}
.messages__Contact .contactOuter .contactList .contactImg span.active {
  background-color: #61D180;
}
@media (max-width: 991px) {
  .messages__Contact .contactOuter .contactList .contactImg span {
    left: 0;
  }
}
.messages__Contact .contactOuter .contactList .contactImg img {
  width: 100%;
  max-width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
@media (max-width: 1199px) {
  .messages__Contact .contactOuter .contactList .contactImg img {
    width: 40px;
    height: 40px;
  }
}
.messages__Contact .contactOuter .contactList .contactName {
  position: relative;
}
.messages__Contact .contactOuter .contactList .contactName h4 {
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 991px) {
  .messages__Contact .contactOuter .contactList .contactName h4 {
    display: block;
  }
}
.messages__Contact .contactOuter .contactList .contactName h4 span {
  font-size: 12px;
}
.messages__Contact .contactOuter .contactList .contactName h4 span.lastSeen {
  font-size: 10px;
  color: #fff;
}
@media (max-width: 991px) {
  .messages__Contact .contactOuter .contactList .contactName h4 span.lastSeen {
    display: block;
    text-align: right;
  }
}
.messages__Contact .contactOuter .contactList .contactName p {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 14px;
  color: #fff;
  width: 80%;
  margin: 0;
}
.messages__Contact .contactOuter .contactList .contactName .messageCounter {
  background-color: #61D180;
  color: #fff;
  font-size: 12px;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.messages__Contact .contactOuter .contactList.active {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
}
.messages__Contact .contactOuter .contactList.active .messageCounter {
  display: none;
}
.messages .chatSection {
  padding-left: 0;
}
.messages__Chat {
  height: 100%;
}
@media (max-width: 575px) {
  .messages__Chat {
    margin-top: 10px;
  }
}
.messages__Chat .chatOuter {
  background-color: #191A1E;
  padding: 17px;
  border-bottom: 1px solid rgba(112, 124, 151, 0.1);
}
.messages__Chat .chatOuter .NameImg {
  display: flex;
  align-items: center;
}
.messages__Chat .chatOuter .NameImg .imgSection {
  margin-right: 20px;
}
.messages__Chat .chatOuter .NameImg .imgSection img {
  width: 55px;
  height: 55px;
  border: 1px solid #fff;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}
.messages__Chat .chatOuter .NameImg .nameSection h4 {
  color: #fff;
  font-size: 20px;
  margin-bottom: 0;
}
.messages__Chat .chatOuter .NameImg .nameSection p {
  color: #61D180;
  margin-bottom: 0;
  font-size: 15px;
}
.messages__Chat .chatOuter .sideBtns {
  text-align: right;
}
.messages__Chat .chatOuter .sideBtns .btn {
  padding: 15px;
  border: 0;
}
@media (max-width: 575px) {
  .messages__Chat .chatOuter .sideBtns .btn {
    padding: 10px;
  }
}
.messages__Chat .chatOuter .sideBtns .btn.sendOffer {
  color: #fff;
  font-weight: 500;
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  margin-right: 20px;
}
@media (max-width: 575px) {
  .messages__Chat .chatOuter .sideBtns .btn.sendOffer {
    margin-right: 10px;
  }
}
.messages__Chat .chatOuter .sideBtns .btn.deleteBtn {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 14px;
}
@media (max-width: 575px) {
  .messages__Chat .chatOuter .sideBtns .btn.deleteBtn {
    padding: 9px;
  }
}
.messages__Chat .chatInner {
  padding: 15px;
  height: 60vh;
  overflow: auto;
}
.messages__Chat .chatInner::-webkit-scrollbar {
  display: none;
}
@media (max-width: 1600px) {
  .messages__Chat .chatInner {
    height: 52vh;
  }
}
.messages__Chat .chatInner .myChat {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 20px;
}
.messages__Chat .chatInner .myChat .messages {
  max-width: 40%;
  background-color: #191A1E;
  border: 1px solid rgba(97, 209, 128, 0.16);
  border-radius: 0px 10px 10px 10px;
  padding: 10px 10px;
  margin-left: 10px;
}
@media (max-width: 575px) {
  .messages__Chat .chatInner .myChat .messages {
    max-width: 100%;
  }
}
.messages__Chat .chatInner .myChat .messages p {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}
@media (max-width: 1199px) {
  .messages__Chat .chatInner .myChat .messages p {
    font-size: 14px;
  }
}
.messages__Chat .chatInner .myChat span {
  cursor: pointer;
}
.messages__Chat .chatInner .otherChat {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.messages__Chat .chatInner .otherChat .messageOuter {
  max-width: 40%;
  position: relative;
}
@media (max-width: 575px) {
  .messages__Chat .chatInner .otherChat .messageOuter {
    max-width: 100%;
  }
}
.messages__Chat .chatInner .otherChat .messageOuter .messages {
  border-radius: 10px 10px 0 10px;
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  box-shadow: 10px 10px 25px rgba(97, 209, 128, 0.1);
  padding: 10px 10px;
  margin-left: 10px;
  cursor: pointer;
}
@media (max-width: 1199px) {
  .messages__Chat .chatInner .otherChat .messageOuter .messages {
    font-size: 14px;
  }
}
.messages__Chat .chatInner .otherChat .messageOuter .messages p {
  color: rgba(255, 255, 255, 0.7);
  margin: 0;
}
.messages__Chat .chatInner .otherChat .messageOuter .messageTimer {
  margin: 0;
  text-align: right;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.5);
}
.messages__Chat .chatInner .otherChat .messageOuter span {
  margin-left: 10px;
  position: absolute;
  top: 20%;
  right: -30px;
}
@media (max-width: 575px) {
  .messages__Chat .chatInner .otherChat .messageOuter span {
    top: auto;
    right: auto;
    bottom: 0;
    left: 0;
  }
}
.messages__Chat .messageWrite {
  position: relative;
}
.messages__Chat .messageWrite textarea {
  border-radius: 0;
  background-color: transparent;
  border: 0;
  border-top: 2px solid rgba(112, 124, 151, 0.15);
  padding: 10px 130px 10px 70px;
  color: rgba(255, 255, 255, 0.5);
}
.messages__Chat .messageWrite textarea::-webkit-scrollbar {
  width: 6px;
}
.messages__Chat .messageWrite textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.messages__Chat .messageWrite textarea::-webkit-scrollbar-thumb {
  background-color: rgba(97, 209, 128, 0.2);
}
.messages__Chat .messageWrite .attachIcon {
  position: absolute;
  top: 10px;
  left: 10px;
  cursor: pointer;
}
.messages__Chat .messageWrite .attachIcon img {
  width: 100%;
  max-width: 40px;
}
.messages__Chat .messageWrite .attachIcon .attachments {
  position: absolute;
  top: -190px;
  display: none;
}
.messages__Chat .messageWrite .attachIcon .attachments span {
  width: 40px;
  height: 40px;
  background-color: #61D180;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
}
.messages__Chat .messageWrite .attachIcon .attachments span img {
  width: 100%;
  max-width: 25px;
}
.messages__Chat .messageWrite .attachIcon:hover .attachments {
  display: block;
}
.messages__Chat .messageWrite .sideIcon {
  position: absolute;
  top: 5px;
  right: 0;
}
.messages__Chat .messageWrite .sideIcon .emoji {
  margin-right: 5px;
}
.messages__Chat .messageWrite .sideIcon .btn {
  background: #61D180;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 5px;
}

.jobPosted {
  min-height: 80vh;
  padding: 30px 10px 50px 10px;
}
.jobPosted__List {
  background-color: #191A1E;
  border: 1px solid rgba(97, 209, 128, 0.16);
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 20px;
}
.jobPosted__List:last-of-type {
  margin-bottom: 0;
}
.jobPosted__List .postHeader {
  display: flex;
  gap: 15px;
  align-items: center;
  position: relative;
}
.jobPosted__List .postHeader .headBtns {
  position: absolute;
  right: 0;
  top: 0;
}
.jobPosted__List .postHeader .headBtns .btn {
  padding: 0;
  margin-right: 10px;
}
.jobPosted__List .postHeader .headBtns .btn:last-of-type {
  margin: 0;
}
.jobPosted__List .postHeader .postImg {
  width: 70px;
  position: relative;
}
.jobPosted__List .postHeader .postImg img {
  width: 65px;
  height: 65px;
  object-fit: cover;
  object-position: center;
  border-radius: 8px;
}
.jobPosted__List .postHeader .postText h4 {
  font-size: 20px;
  margin-bottom: 0;
  font-weight: 600;
}
.jobPosted__List .postHeader .postText h4 span {
  font-size: 14px;
  font-weight: 300;
  margin-left: 5px;
}
.jobPosted__List .postHeader .postText h4 span img {
  width: 100%;
  max-width: 10px;
  margin-right: 5px;
}
.jobPosted__List .postHeader .postText .postTime {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
}
.jobPosted__List .postHeader .postText .jobLoc {
  display: flex;
  align-items: center;
  margin-top: 10px;
}
@media (max-width: 575px) {
  .jobPosted__List .postHeader .postText .jobLoc {
    flex-wrap: wrap;
  }
}
.jobPosted__List .postHeader .postText .jobLoc span {
  margin-right: 10px;
  display: flex;
  align-items: center;
}
@media (max-width: 575px) {
  .jobPosted__List .postHeader .postText .jobLoc span {
    margin-right: 7px;
  }
}
.jobPosted__List .postHeader .postText .jobLoc span img {
  margin-right: 5px;
  width: 100%;
  max-width: 20px;
}
.jobPosted__List .postHeader .postText p {
  font-size: 14px;
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 0;
}
.jobPosted__List .postHeader .applyBtns {
  position: absolute;
  right: 0;
  top: 0;
}
@media (max-width: 575px) {
  .jobPosted__List .postHeader .applyBtns {
    position: static;
    margin-top: 10px;
  }
}
.jobPosted__List .postHeader .applyBtns .btn {
  height: 44px;
}
.jobPosted__List .postHeader .applyBtns .btn.btn-apply {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  border: 0;
  padding: 10px 30px;
  font-weight: 600;
  margin-right: 10px;
}
.jobPosted__List .postHeader .applyBtns .btn.btn-save {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 10px 15px;
}
.jobPosted__List .postHeader .applyBtns .btn.btn-save svg {
  width: 20px;
  height: 20px;
}
.jobPosted__List .postBodyContent {
  margin-top: 20px;
}
.jobPosted__List .postBodyContent h4 {
  color: #61D180;
}
.jobPosted__List .postBodyContent h4 span {
  color: rgba(255, 255, 255, 0.7);
  font-weight: 400;
  font-size: 16px;
}
.jobPosted__List .postBodyContent p {
  color: rgba(255, 255, 255, 0.7);
  margin-top: 10px;
}
.jobPosted__List .postBodyContent .btnSeemore {
  font-style: italic;
  text-decoration: underline;
  font-size: 14px;
  padding: 0;
  color: #61D180;
}
.jobPosted__List .postBodyContent .detailText {
  margin-top: 20px;
}
.jobPosted__List .postBodyContent .detailText h2,
.jobPosted__List .postBodyContent .detailText h3,
.jobPosted__List .postBodyContent .detailText h4,
.jobPosted__List .postBodyContent .detailText h5,
.jobPosted__List .postBodyContent .detailText h6 {
  color: #fff;
  font-size: 18px;
}
.jobPosted__List .postBodyContent .detailText h2 strong,
.jobPosted__List .postBodyContent .detailText h3 strong,
.jobPosted__List .postBodyContent .detailText h4 strong,
.jobPosted__List .postBodyContent .detailText h5 strong,
.jobPosted__List .postBodyContent .detailText h6 strong {
  font-weight: 600;
}
.jobPosted__List .postBodyContent .detailText ul li,
.jobPosted__List .postBodyContent .detailText ol li {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 10px;
  position: relative;
  list-style-type: none;
}
.jobPosted__List .postBodyContent .detailText ul li::before,
.jobPosted__List .postBodyContent .detailText ol li::before {
  position: absolute;
  content: " ";
  background-image: url(../imgs/checkMarkIcon.svg);
  width: 18px;
  height: 18px;
  background-size: 17px;
  background-repeat: no-repeat;
  top: 5px;
  left: -25px;
}
.jobPosted__List .postFooter {
  margin-top: 20px;
}
.jobPosted__List .postFooter .tags span {
  color: #61D180;
  border-radius: 8px;
  background: rgba(97, 209, 128, 0.1);
  font-size: 14px;
  padding: 5px 10px;
  margin-right: 10px;
  display: inline-block;
  text-transform: capitalize;
}
@media (max-width: 575px) {
  .jobPosted__List .postFooter .tags span {
    margin-bottom: 10px;
  }
}
.jobPosted__List .postFooter .btnSection a {
  padding: 12px;
  display: inline-block;
  min-width: none;
}
.jobPosted__List .postFooter .btnSection a.btn-share {
  min-width: 200px;
  max-width: 200px;
}

.analytics {
  background: linear-gradient(90deg, #202020 0.14%, #212121 17.54%, #31523e 50.97%, #232a26 81.51%, #212121 99.9%);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 50px 30px;
}
.analytics__Filter {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.analytics__Filter .btn-group .btn {
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  padding: 10px 20px;
  font-weight: 500;
  transition: all 0.2s ease-in-out;
}
.analytics__Filter .btn-group .btn:hover {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
}
.analytics__Filter .dropdown .dropdown-toggle {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  padding: 11px 20px;
  font-weight: 500;
  width: 300px;
  position: relative;
  border: 0;
}
.analytics__Filter .dropdown .dropdown-toggle::after {
  position: absolute;
  content: " ";
  width: 20px;
  height: 20px;
  background-image: url(../imgs/filterGreen.svg);
  background-size: 20px;
  right: 10px;
  border: 0;
}
.analytics .card {
  background-color: #191A1E;
  border: 1px solid #1D1D1D;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
}
.analytics .card .card-header {
  display: flex;
  justify-content: space-between;
  background-color: #1D1D1D;
  padding: 20px;
}
@media (max-width: 1366px) {
  .analytics .card .card-header {
    align-items: center;
  }
}
@media (max-width: 575px) {
  .analytics .card .card-header {
    flex-direction: column;
  }
}
.analytics .card .card-header h3 {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  margin: 0;
}
@media (max-width: 1440px) {
  .analytics .card .card-header h3 {
    font-size: 20px;
  }
}
@media (max-width: 1280px) {
  .analytics .card .card-header h3 {
    font-size: 20px;
  }
}
.analytics .card .card-header .dropdown .dropdown-toggle {
  background-image: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  border: 1px solid #61D180;
  color: #fff;
  font-weight: 500;
  padding: 9px 30px;
  border-radius: 8px;
}
@media (max-width: 1440px) {
  .analytics .card .card-header .dropdown .dropdown-toggle {
    padding: 9px 25px 9px 10px;
  }
}
.analytics .card .card-header .dropdown .dropdown-toggle::after {
  position: absolute;
  content: " ";
  background-image: url(../imgs/curveShape.svg);
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  background-size: 18px;
  background-position-y: center;
  border: 0;
}
.analytics .card .card-header .dropdown .dropdown-menu {
  background-color: #1D1D1D;
  padding: 0;
  overflow: hidden;
}
.analytics .card .card-header .dropdown .dropdown-menu li .dropdown-item {
  color: #fff;
}
.analytics .card .card-header .dropdown .dropdown-menu li .dropdown-item:hover {
  background-color: rgba(97, 209, 128, 0.3);
}
.analytics .card .card-header .dropdown.newUserDropdown {
  text-align: right;
}
.analytics .card .card-header .filterOuter {
  display: flex;
  justify-content: end;
}
@media (max-width: 575px) {
  .analytics .card .card-header .filterOuter {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
}
.analytics .card .card-header .filterOuter .customDate {
  display: flex;
  margin-right: 10px;
}
@media (max-width: 575px) {
  .analytics .card .card-header .filterOuter .customDate {
    flex-wrap: wrap;
    margin-top: 10px;
  }
}
.analytics .card .card-header .filterOuter .customDate .react-datepicker-wrapper {
  width: 150px;
  margin-right: 10px;
}
@media (max-width: 575px) {
  .analytics .card .card-header .filterOuter .customDate .react-datepicker-wrapper {
    width: 110px;
  }
}
.analytics .card .card-header .filterOuter .customDate .react-datepicker-wrapper input {
  height: 44px;
}
.analytics .card .card-header .filterOuter .customDate .btn {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  border: 1px solid #61D180;
  padding: 10px 40px;
  font-weight: 500;
  height: 44px;
  border-radius: 8px;
}
.analytics .card .card-body .chart-container .apexcharts-toolbar {
  display: none;
}
.analytics .card .card-body .chart-container svg text {
  fill: #fff;
}
.analytics .card .card-body .chart-container .recharts-wrapper svg {
  width: 100%;
}
.analytics .card .card-body .chart-container .recharts-wrapper .recharts-legend-wrapper {
  display: none;
}
.analytics .ticketsCard-body {
  background-color: #1D1D1D;
  padding: 10px 20px;
  height: 625px;
  overflow: auto;
}
@media (max-width: 1199px) {
  .analytics .ticketsCard-body {
    height: auto;
  }
}
.analytics .ticketsCard-body::-webkit-scrollbar {
  width: 6px;
}
.analytics .ticketsCard-body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
.analytics .ticketsCard-body::-webkit-scrollbar-thumb {
  background-color: rgba(97, 209, 128, 0.2);
}
.analytics .ticketsCard-body .ticketList {
  display: flex;
  background-color: #3E4240;
  border-radius: 8px;
  padding: 10px 15px;
  margin-bottom: 10px;
}
.analytics .ticketsCard-body .ticketList .candidateImg {
  margin-right: 10px;
}
.analytics .ticketsCard-body .ticketList .candidateImg img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  -o-object-position: center;
  object-position: center;
  border-radius: 50%;
}
.analytics .ticketsCard-body .ticketList .candidateuserleft2 h2 {
  font-size: 16px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.analytics .ticketsCard-body .ticketList .candidateuserleft2 p {
  color: #fff;
  font-size: 14px;
  margin-bottom: 0;
}
@media (max-width: 1199px) {
  .analytics .invitation.px-5 {
    padding: 0 !important;
    margin-bottom: 10px;
  }
}
.analytics .invitation h3 {
  margin-bottom: 30px;
}
.analytics .invitation .invitationCard {
  background-color: #1D1D1D;
  padding: 20px;
  border-radius: 8px;
  height: 180px;
}
@media (max-width: 1440px) {
  .analytics .invitation .invitationCard {
    padding: 20px;
  }
}
@media (max-width: 1199px) {
  .analytics .invitation .invitationCard {
    margin-bottom: 10px;
  }
}
.analytics .invitation .invitationCard .inviteHeader {
  display: flex;
  gap: 10px;
  align-items: center;
}
.analytics .invitation .invitationCard .inviteHeader span {
  background-color: #61D180;
  display: inline-flex;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
}
.analytics .invitation .invitationCard .inviteHeader span.activeAcount {
  background-color: transparent;
}
.analytics .invitation .invitationCard .inviteHeader p {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin: 0;
}
@media (max-width: 1440px) {
  .analytics .invitation .invitationCard .inviteHeader p {
    font-size: 21px;
  }
}
@media (max-width: 1280px) {
  .analytics .invitation .invitationCard .inviteHeader p {
    font-size: 20px;
  }
}
.analytics .invitation .invitationCard .inviteHeader h3 {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  margin: 0 0 20px 0;
}
@media (max-width: 1366px) {
  .analytics .invitation .invitationCard .inviteHeader h3 {
    font-size: 22px;
  }
}
@media (max-width: 1280px) {
  .analytics .invitation .invitationCard .inviteHeader h3 {
    font-size: 20px;
  }
}
.analytics .invitation .invitationCard .inviteHeader.employerHeader {
  justify-content: space-between;
  align-items: start;
}
.analytics .invitation .invitationCard .inviteHeader.employerHeader h3 {
  margin: 0;
}
.analytics .invitation .invitationCard .inviteHeader.employerHeader .dropdown .btn {
  padding: 6px 20px;
  border-radius: 8px;
}
.analytics .invitation .invitationCard .inviteHeader.employerHeader .dropdown .btn.viewAllButton:hover {
  background-color: #61D180;
  color: #fff;
  border: 1px solid #61D180;
}
.analytics .invitation .invitationCard .inviteHeader.employerHeader .dropdown .btn.dropdown-toggle {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  border: 1px solid #61D180;
  padding: 6px 30px;
}
.analytics .invitation .invitationCard .inviteHeader.employerHeader .dropdown .btn.dropdown-toggle::after {
  position: absolute;
  content: " ";
  background-image: url(../imgs/curveShape.svg);
  background-repeat: no-repeat;
  width: 18px;
  height: 18px;
  background-size: 18px;
  background-position-y: center;
  border: 0;
}
.analytics .invitation .invitationCard .inviteHeader.employerHeader .dropdown .dropdown-menu {
  background-color: #1D1D1D;
  padding: 0;
  overflow: hidden;
}
.analytics .invitation .invitationCard .inviteHeader.employerHeader .dropdown .dropdown-menu li .dropdown-item {
  color: #fff;
}
.analytics .invitation .invitationCard .inviteHeader.employerHeader .dropdown .dropdown-menu li .dropdown-item:hover {
  background-color: rgba(97, 209, 128, 0.3);
}
.analytics .invitation .invitationCard .inviteBody h4 {
  font-size: 26px;
  font-weight: 600;
  margin: 10px 0;
  padding-left: 40px;
}
.analytics .invitation .invitationCard .inviteBody p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  padding-left: 35px;
  font-weight: 600;
  margin-bottom: 0;
}
.analytics .invitation .invitationCard .inviteBody p span {
  color: #fff;
  margin-left: 20px;
  font-size: 20px;
  font-weight: 300;
  position: relative;
}
.analytics .invitation .invitationCard .inviteBody p span svg {
  height: 10px;
  position: absolute;
  top: 1px;
}
@media (max-width: 1440px) {
  .analytics .invitation .invitationCard .inviteBody .manageTeamImage {
    margin-bottom: 5px;
    width: 50px;
    height: 50px;
  }
}
@media (max-width: 1366px) {
  .analytics .invitation .invitationCard .inviteBody .manageTeamImage {
    width: 45px;
    height: 45px;
  }
}
.analytics .invitation .invitationCard .inviteBody .employeeCardOuter {
  display: flex;
  overflow: auto;
  margin-top: 10px;
}
@media (max-width: 1280px) {
  .analytics .invitation .invitationCard .inviteBody .employeeCardOuter {
    white-space: nowrap;
  }
}
.analytics .invitation .invitationCard .inviteBody .employeeCardOuter .newEmployeeCard {
  display: flex;
  background-color: #3E4240;
  padding: 10px;
  border-radius: 8px;
  width: 310px;
  margin-right: 10px;
}
.analytics .invitation .invitationCard .inviteBody .employeeCardOuter .newEmployeeCard .candidateImg {
  margin-right: 10px;
}
.analytics .invitation .invitationCard .inviteBody .employeeCardOuter .newEmployeeCard .candidateImg img {
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: center;
  -o-object-position: center;
  border-radius: 50%;
}
.analytics .invitation .invitationCard .inviteBody .employeeCardOuter .newEmployeeCard .candidateuserleft2 h2 {
  font-size: 14px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.analytics .invitation .invitationCard .inviteBody .employeeCardOuter .newEmployeeCard .candidateuserleft2 p {
  font-size: 12px;
  color: #fff;
  padding: 0;
  font-weight: 500;
  margin-bottom: 0;
  white-space: normal;
  word-break: break-all;
}
.analytics .invitation .invitationCard .inviteBody .manageTeamImage {
  width: 48px;
  height: 48px;
}
.analytics .invitation .card .card-header .searchSection {
  display: flex;
}
.analytics .invitation .card .card-header .searchSection .btn {
  background: linear-gradient(to right, rgba(97, 209, 128, 0.2), rgba(2, 48, 32, 0.2));
  color: rgba(255, 255, 255, 0.7);
  border-color: rgba(97, 209, 128, 0.3);
  transition: all 0.5s ease-in-out;
}
.analytics .invitation .card .card-header .searchSection .btn:hover {
  box-shadow: inset 600px 0 0 0 rgba(97, 209, 128, 0.8);
  color: #fff;
}
.analytics .invitation .card .card-header .searchSection .searchBar {
  position: relative;
  display: flex;
}
.analytics .invitation .card .card-header .searchSection .searchBar .form-control {
  color: rgba(255, 255, 255, 0.7);
  background-color: #141414;
  padding-left: 30px;
  border-color: rgba(255, 255, 255, 0.2);
}
.analytics .invitation .card .card-header .searchSection .searchBar span {
  position: absolute;
  top: 10px;
  left: 5px;
}
.analytics .invitation .card .card-header .customerSearch {
  display: flex;
}
@media (max-width: 575px) {
  .analytics .invitation .card .card-header .customerSearch {
    margin-top: 10px;
  }
}
.analytics .invitation .card .card-header .customerSearch .searchBar {
  position: relative;
}
.analytics .invitation .card .card-header .customerSearch .searchBar .form-control {
  border-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  padding-left: 30px;
  height: 44px;
}
.analytics .invitation .card .card-header .customerSearch .searchBar span {
  position: absolute;
  top: 9px;
  left: 5px;
}
.analytics .invitation .card .card-header .customerSearch .dropdown .dropdown-toggle {
  margin-left: 10px;
  border-color: rgba(255, 255, 255, 0.5);
  background: transparent;
}
.analytics .invitation .card .card-header .customerSearch .dropdown .dropdown-toggle::after {
  background-image: url(../imgs/arrowBottomWhite.svg);
  top: 15px;
}
.analytics .invitation .card .card-header .customerSearch .dropdown .dropdown-toggle span {
  color: rgba(255, 255, 255, 0.7);
}
.analytics .invitation .card .card-body {
  background: #191A1E;
}
.analytics .invitation .card .card-body .editableTeamMember {
  background-color: #47484B;
  align-items: center;
}
.analytics .invitation .card .card-body .editableTeamMember .leadRight .btn {
  width: 35px;
  height: 35px;
  background-color: rgba(97, 209, 128, 0.3);
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-right: 10px;
}
.analytics .invitation .card .card-body .editableTeamMember .leadRight .btn:last-of-type {
  margin: 0;
}
.analytics .invitation .card .card-body .editableTeamMember .leadRight .btn img {
  width: 15px;
}
.analytics .invitation .card .card-body .table-responsive .customerTable thead tr th {
  color: #61D180;
  font-weight: 500;
  background-color: #191A1E;
  border-color: rgba(255, 255, 255, 0.7);
  padding: 10px 10px;
}
.analytics .invitation .card .card-body .table-responsive .customerTable tbody tr td {
  background-color: #191A1E;
  color: rgba(255, 255, 255, 0.8);
  padding: 15px 10px;
}
.analytics .invitation .card .card-body .table-responsive .customerTable tbody tr td .status {
  padding: 7px 20px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
  text-align: center;
}
.analytics .invitation .card .card-body .table-responsive .customerTable tbody tr td .status.status-won {
  color: #61D180;
  background-color: #023020;
  border: 1px solid #094e36;
}
.analytics .invitation .card .card-body .table-responsive .customerTable tbody tr td .status.status-lost {
  background-color: #BB1B16;
  border: 1px solid #C48787;
  color: #C48787;
}
.analytics .invitation .card .card-body .table-responsive .customerTable tbody tr td .status.status-inactive {
  background-color: #FFC5C5;
  color: #BB1B16;
  border: 1px solid #FFC5C5;
}
.analytics .invitation .card .card-body .table-responsive .tableEntry {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.analytics .invitation .card .card-body .table-responsive .tableEntry p {
  margin: 0;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 500;
}
.analytics .invitation .card .card-body .activitySection .activityContent {
  display: flex;
  justify-content: space-between;
  background-color: #3E4240;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
}
.analytics .invitation .card .card-body .activitySection .activityContent:last-of-type {
  margin-bottom: 0;
}
.analytics .invitation .card .card-body .activitySection .activityContent .leftContent {
  display: flex;
  align-items: center;
}
.analytics .invitation .card .card-body .activitySection .activityContent .leftContent img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
  -o-object-position: center;
  margin-right: 10px;
}
.analytics .invitation .card .card-body .activitySection .activityContent .leftContent p {
  color: #fff;
  font-weight: 500;
  margin: 0;
  font-size: 14px;
}
.analytics .invitation .card .card-body .activitySection .activityContent .leftContent p span {
  font-weight: 300;
}
.analytics .invitation .card .card-body .activitySection .activityContent .rightContent p {
  color: #fff;
  font-size: 13px;
  font-weight: 500;
}
.analytics .invitation .productionCard {
  background-color: #1D1D1D;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
}
.analytics .invitation .productionCard h3 {
  color: #fff;
  font-size: 24px;
  margin-bottom: 0;
}
.analytics .invitation .productionCard h4 {
  padding-left: 50px;
  margin: 10px 0;
}
.analytics .invitation .productionCard p {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  padding-left: 50px;
}
.analytics .invitation .productionCard p span {
  color: #fff;
  font-size: 16px;
  padding-left: 20px;
}
.analytics .invitation .productionCard p span img {
  margin-top: -15px;
}
.analytics .invitation.teamDirectory {
  display: flex;
  flex-direction: column;
  height: 100%;
}
@media (max-width: 991px) {
  .analytics .invitation.teamDirectory {
    margin-top: 20px;
  }
}
.analytics .invitation.teamDirectory .card {
  display: flex;
  height: 100%;
  flex-direction: column;
}
.analytics .invitation .listing {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: #1D1D1D;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 10px 10px;
  margin-bottom: 15px;
}
.analytics .invitation .listing > div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.analytics .invitation .listing span {
  width: 44px;
  height: 44px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
}
.analytics .invitation .listing span.green {
  background-color: rgba(97, 209, 128, 0.1);
}
.analytics .invitation .listing span.red {
  background-color: rgba(255, 27, 27, 0.1);
}
.analytics .invitation .listing h5 {
  font-size: 20px;
  color: #fff;
  font-weight: 700;
  margin: 0;
}
.analytics .invitation .listing p {
  font-size: 12px;
  margin-bottom: 0;
}
.analytics .ticketOuter {
  padding-left: 30px;
}
.analytics .ticketOuter .table-resposnive .table tr th span img {
  margin-left: 5px;
}
.analytics.teamView {
  min-height: 100vh;
}

.users {
  background: linear-gradient(90deg, #202020 0.14%, #212121 17.54%, #31523e 50.97%, #232a26 81.51%, #212121 99.9%);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  padding: 50px 30px;
  border-radius: 0;
}
.users .card {
  background-color: #191A1E;
  margin-left: 20px;
}
@media (max-width: 991px) {
  .users .card {
    margin-left: 0;
  }
}
.users .card .card-header {
  background-color: #1D1D1D;
  padding: 20px;
}
.users .card .card-header h2 {
  color: #fff;
  font-size: 30px;
  font-weight: 600;
}
.users .card .card-header .exportBtn {
  display: flex;
  height: 100%;
  align-items: center;
}
.users .card .card-header .exportBtn a {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  border: 1px solid #61D180;
  padding: 9px 20px;
  border-radius: 6px;
  font-weight: 500;
}
.users .card .card-header .exportBtn p {
  color: #fff;
  margin-bottom: 0;
  margin-right: 10px;
  font-weight: 500;
}
.users .card .card-header .dropdown {
  text-align: right;
}
@media (max-width: 575px) {
  .users .card .card-header .dropdown {
    margin-top: 10px;
    text-align: left;
  }
}
.users .card .card-header .dropdown .userButton {
  position: relative;
}
.users .card .card-header .dropdown .userButton::after {
  position: absolute;
  content: " ";
  background-image: url(../imgs/curveShape.svg);
  width: 18px;
  height: 18px;
  background-size: 16px;
  background-repeat: no-repeat;
  background-position-y: center;
  border: 0;
  top: 14px;
  right: 8px;
}
.users .card .card-header .dropdown .userButton:hover, .users .card .card-header .dropdown .userButton:focus {
  color: #fff;
  font-weight: 500;
  border: 1px solid #61D180;
}
.users .card .card-header .dropdown .dropdown-menu {
  background-color: #1D1D1D;
  padding: 0;
  overflow: hidden;
}
.users .card .card-header .dropdown .dropdown-menu li .dropdown-item {
  color: #fff;
  cursor: pointer;
}
.users .card .card-header .dropdown .dropdown-menu li .dropdown-item:hover {
  background-color: rgba(97, 209, 128, 0.3);
  color: #fff;
}
.users .card .card-body {
  padding: 30px 50px;
}
.users .card .card-body .tableOuter .table tr th {
  background-color: #1D1D1D;
  border-color: rgba(113, 113, 113, 0.1);
}
.users .card .card-body .tableOuter .table tr th .form-check .form-check-input {
  box-shadow: none;
  border: 1px solid #61D180;
  background-color: rgba(97, 209, 128, 0.3);
}
.users .card .card-body .tableOuter .table tr td {
  border-color: rgba(113, 113, 113, 0.1);
}
.users .card .card-body .tableOuter .table tr td .form-check .form-check-input {
  box-shadow: none;
  border: 1px solid #61D180;
  background-color: rgba(97, 209, 128, 0.3);
}
.users .card .card-body .tableOuter .table tr td .skillListing {
  padding: 0;
}
.users .card .card-body .tableOuter .table tr td .skillListing li {
  display: inline-block;
  background-color: rgba(97, 209, 128, 0.3);
  color: #fff;
  padding: 3px 3px;
  border-radius: 8px;
  font-size: 13px;
  margin: 0 5px 5px 0;
  min-width: 20px;
  text-align: center;
}
.users .card .card-body .tableOuter .table tr td .actionBtns {
  display: flex;
}
.users .card .card-body .tableOuter .table tr td .actionBtns .btn {
  padding: 0;
  margin-right: 5px;
}
.users .card .card-body .tableOuter .table tr td .actionBtns .btn:last-of-type {
  margin: 0;
}
.users .card .card-body .tableOuter .pagination {
  margin-top: 30px;
}

.affilate {
  padding: 50px 10px;
}
.affilate .commonHeading * {
  margin-bottom: 15px;
}
.affilate .sectionSubheading h3 {
  font-weight: 600;
}
.affilate .sectionSubheading p {
  color: rgba(255, 255, 255, 0.7);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  padding-bottom: 15px;
}
.affilate .commonHeading hr {
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
}
.affilate__Content {
  margin: 0 auto;
  background-color: #191A1E;
  border: 1px solid rgba(97, 209, 128, 0.16);
  padding: 30px 15px;
  text-align: center;
}
@media (max-width: 575px) {
  .affilate__Content {
    width: 100%;
  }
}
.affilate__Content h4 {
  color: #fff;
}
.affilate__Content p {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 50px;
}
.affilate__Content p span {
  color: #FDC500;
}
.affilate__Content .qrCode__Outer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 50px;
  border: 2px solid #61D180;
  border-radius: 8px;
  width: 35%;
  margin: 0 auto;
  padding: 35px 30px;
}
@media (max-width: 1600px) {
  .affilate__Content .qrCode__Outer {
    width: 45%;
  }
}
@media (max-width: 1440px) {
  .affilate__Content .qrCode__Outer {
    width: 50%;
  }
}
@media (max-width: 1366px) {
  .affilate__Content .qrCode__Outer {
    width: 55%;
  }
}
@media (max-width: 1366px) {
  .affilate__Content .qrCode__Outer {
    width: 60%;
  }
}
@media (max-width: 991px) {
  .affilate__Content .qrCode__Outer {
    width: 75%;
  }
}
@media (max-width: 575px) {
  .affilate__Content .qrCode__Outer {
    width: 100%;
    flex-wrap: wrap;
  }
}
.affilate__Content .qrCode {
  width: 200px;
  height: 200px;
  border-radius: 8px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  padding: 5px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.affilate__Content .qrCode img {
  width: 100%;
  max-width: 75%;
  border: 1px solid rgba(97, 209, 128, 0.16);
  border-radius: 8px;
  background-color: #191A1E;
  padding: 5px;
}
.affilate__Content .qrCode canvas {
  background-color: #fff;
  padding: 6px;
  width: 170px !important;
  height: 170px !important;
}
.affilate__Content .shareBtns {
  margin: 50px 0 20px;
}
.affilate__Content .shareBtns button {
  background-color: transparent;
  border: 0;
}
.affilate__Content .shareBtn {
  color: #fff;
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  border-radius: 8px;
  border: 0;
  margin-top: 30px;
  width: 280px;
  padding: 10px 0;
  font-weight: 500;
}
@media (max-width: 575px) {
  .affilate__Content .shareBtn {
    width: 100%;
  }
}
.affilate__Content .divider {
  border: 1px solid rgba(255, 255, 255, 0.04);
  background: rgba(0, 0, 0, 0.1);
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  margin: 60px 0 50px;
}
.affilate__Content h4 {
  font-size: 26px;
  font-weight: 600;
  width: 85%;
  margin: 0 auto 50px;
}
@media (max-width: 991px) {
  .affilate__Content h4 {
    width: 100%;
  }
}
.affilate__Content h4 span {
  color: #FDC500;
}
.affilate__Content .form-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 35%;
  margin: 50px auto 0;
}
@media (max-width: 1600px) {
  .affilate__Content .form-section {
    width: 45%;
  }
}
@media (max-width: 1440px) {
  .affilate__Content .form-section {
    width: 50%;
  }
}
@media (max-width: 1366px) {
  .affilate__Content .form-section {
    width: 55%;
  }
}
@media (max-width: 1366px) {
  .affilate__Content .form-section {
    width: 60%;
  }
}
@media (max-width: 991px) {
  .affilate__Content .form-section {
    width: 75%;
  }
}
@media (max-width: 575px) {
  .affilate__Content .form-section {
    width: 100%;
  }
}
.affilate__Content .form-section .form-group {
  position: relative;
}
.affilate__Content .form-section .form-group span {
  position: absolute;
  top: 10px;
  right: 10px;
}
.affilate__Content .form-section .form-group .form-control {
  color: rgba(255, 255, 255, 0.7);
  padding-right: 30px;
}
.affilate__Content .entryCounter h3 {
  font-size: 26px;
  margin-bottom: 50px;
}
.affilate__Content .entryCounter__Content {
  position: relative;
}
.affilate__Content .entryCounter__Content svg {
  position: relative;
  width: 190px;
  height: 190px;
  z-index: 1000;
  box-shadow: 0px 0px 24px rgba(255, 255, 255, 0.2);
  border-radius: 100%;
}
.affilate__Content .entryCounter__Content svg circle {
  width: 100%;
  height: 100%;
  fill: none;
  box-shadow: 0px 0px 24px rgba(255, 255, 255, 0.2);
  stroke: #191919;
  stroke-width: 10;
  stroke-linecap: round;
  transform: translate(5px, 5px);
}
.affilate__Content .entryCounter__Content svg circle:nth-child(2) {
  stroke-dashoffset: 570;
  stroke: #61D180;
}
.affilate__Content .entryCounter__Content .number {
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 70%);
}
@media (max-width: 575px) {
  .affilate__Content .entryCounter__Content .number {
    transform: translate(-50%, 100%);
  }
}
.affilate__Content .entryCounter__Content .number h2 {
  color: #fff;
  font-weight: 600;
  font-size: 60px;
  line-height: 25px;
}
.affilate__Content .entryCounter__Content .number h2 span {
  font-size: 20px;
}
.affilate__Content .entryCounter__Content ul {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  margin-top: 30px;
}
.affilate__Content .entryCounter__Content ul li {
  position: relative;
  text-align: left;
  list-style-type: none;
  color: #fff;
  margin-bottom: 15px;
  font-size: 18px;
}
.affilate__Content .entryCounter__Content ul li::after {
  position: absolute;
  content: " ";
  width: 20px;
  height: 20px;
  background-image: url(../imgs/tickGreendark.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  left: -25px;
  top: 5px;
}

.terms__Content h3 {
  color: #fff;
  margin: 30px 0 15px;
  font-weight: 600;
}
.terms__Content h5 {
  color: #fff;
  font-weight: 600;
  margin-top: 30px;
}
.terms__Content p {
  color: rgba(255, 255, 255, 0.7);
}
.terms__Content p a {
  color: #61D180;
}
@media (max-width: 575px) {
  .terms__Content p {
    word-wrap: break-all;
  }
}
.terms__Content ul li {
  color: rgba(255, 255, 255, 0.7);
  margin-bottom: 8px;
  list-style-type: none;
  position: relative;
}
.terms__Content ul li::after {
  position: absolute;
  content: " ";
  width: 20px;
  height: 20px;
  background-image: url(../imgs/tickGreendark.svg);
  background-size: 18px;
  background-repeat: no-repeat;
  left: -25px;
  top: 3px;
}
.terms__Content ul li a {
  color: #61D180;
}
.terms__Content .table-responsive .table tr th {
  background-color: #1D1D1D;
  color: #fff;
  padding: 15px;
  font-size: 14px;
  border-color: #47484B;
}
.terms__Content .table-responsive .table tr td {
  background-color: #191A1E;
  color: #fff;
  padding: 15px;
  font-size: 14px;
  border-color: #47484B;
}
.terms__Content .table-responsive .table tr td a {
  color: #61D180;
}

#half-stars-example {
  position: relative;
  /* use display:inline-flex to prevent whitespace issues. alternatively, you can put all the children of .rating-group on a single line */
  /* make hover effect work properly in IE */
  /* hide radio inputs */
  /* set icon padding and size */
  /* add padding and positioning to half star labels */
  /* set default star color */
  /* set color of none icon when unchecked */
  /* if none icon is checked, make it red */
  /* if any input is checked, make its following siblings grey */
  /* make all stars orange on rating group hover */
  /* make hovered input's following siblings grey on hover */
  /* make none icon grey on rating group hover */
  /* make none icon red on hover */
}
#half-stars-example .mainLabel {
  display: block;
  color: rgba(255, 255, 255, 0.5);
  text-align: left;
  font-weight: 500;
  font-size: 14px;
}
#half-stars-example .rating-group {
  text-align: left;
}
#half-stars-example .rating__icon {
  pointer-events: none;
}
#half-stars-example .rating__input {
  position: absolute !important;
  left: -9999px !important;
}
#half-stars-example .rating__label {
  cursor: pointer;
  /* if you change the left/right padding, update the margin-right property of .rating__label--half as well. */
  padding: 0 0.1em;
  font-size: 1.2rem;
}
#half-stars-example .rating__label--half {
  padding-right: 0;
  margin-right: -0.6em;
  z-index: 2;
  position: absolute;
}
#half-stars-example .rating__icon--star {
  color: orange;
}
#half-stars-example .rating__icon--none {
  color: #eee;
}
#half-stars-example .rating__input--none:checked + .rating__label .rating__icon--none {
  color: red;
}
#half-stars-example .rating__input:checked ~ .rating__label .rating__icon--star {
  color: #ddd;
}
#half-stars-example .rating-group:hover .rating__label .rating__icon--star,
#half-stars-example .rating-group:hover .rating__label--half .rating__icon--star {
  color: orange;
}
#half-stars-example .rating__input:hover ~ .rating__label .rating__icon--star,
#half-stars-example .rating__input:hover ~ .rating__label--half .rating__icon--star {
  color: #ddd;
}
#half-stars-example .rating-group:hover .rating__input--none:not(:hover) + .rating__label .rating__icon--none {
  color: #eee;
}
#half-stars-example .rating__input--none:hover + .rating__label .rating__icon--none {
  color: red;
}

.contactSection {
  padding: 110px 0;
}
@media (max-width: 991px) {
  .contactSection__Img {
    display: none;
  }
}
.contactSection__Img img {
  width: 80%;
}
@media (max-width: 1199px) {
  .contactSection__Img img {
    width: 100%;
  }
}
.contactSection__Form form {
  background-color: #191A1E;
  padding: 20px;
  border-radius: 8px;
}
.contactSection__Form form h3 {
  color: #fff;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 30px;
}
.contactSection__Form form .form-group {
  margin-bottom: 20px;
}
.contactSection__Form form .form-group .form-control {
  color: rgba(255, 255, 255, 0.8);
}
.contactSection__Form form .form-group textarea {
  height: 150px;
  resize: none;
}
.contactSection__Form form .form-group .btn-send {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  color: #fff;
  border: none;
  font-weight: 500;
  font-size: 18px;
  padding: 10px 30px;
  margin-top: 30px;
  width: 100%;
}

.publicQr {
  text-align: center;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(../imgs/featuresBg.svg);
  background-size: cover;
}
.publicQr__Content {
  margin-bottom: 50px;
}
.publicQr__Content h3 {
  font-size: 40px;
  color: #fff;
}
.publicQr__Content img {
  width: 100%;
  max-width: 120px;
}
.publicQr__Img {
  width: 200px;
  height: 200px;
  background-image: url(../imgs/qrOuter.svg);
  background-size: 98%;
  background-repeat: no-repeat;
  background-position: center;
  text-align: center;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.publicQr__Img canvas {
  background-color: #fff;
  padding: 3px;
}

@media (max-width: 575px) {
  .order2 {
    order: 2;
  }
}

@media (max-width: 575px) {
  .order1 {
    order: 1;
    margin-bottom: 20px;
  }
}

.errorPage {
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.errorPage__Content {
  text-align: center;
}
.errorPage__Content h3 {
  color: #fff;
  font-weight: 700;
  font-size: 48px;
}
@media (max-width: 991px) {
  .errorPage__Content h3 {
    font-size: 36px;
  }
}
@media (max-width: 575px) {
  .errorPage__Content h3 {
    font-size: 30px;
  }
}
.errorPage__Content h1 {
  color: #61D180;
  font-size: 250px;
  font-weight: 600;
}
@media (max-width: 575px) {
  .errorPage__Content h1 {
    font-size: 120px;
  }
}
.errorPage__Content p {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
}
@media (max-width: 991px) {
  .errorPage__Content p {
    font-size: 20px;
  }
}
@media (max-width: 575px) {
  .errorPage__Content p {
    font-size: 16px;
  }
}

.imageUploaderSection .uploaderInner .crop-container {
  background: none;
  background-image: url(../imgs/qrOuter.svg);
  background-repeat: no-repeat;
  background-size: cover;
  height: 242px;
}
.imageUploaderSection .uploaderInner .uploaderFileSection {
  display: flex;
  justify-content: center;
}
@media (max-width: 1199px) {
  .imageUploaderSection .uploaderInner .uploaderFileSection {
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 767px) {
  .imageUploaderSection .uploaderInner .uploaderFileSection {
    margin-top: 20px;
  }
}
.imageUploaderSection .uploaderInner .uploaderFileSection p {
  margin: 0 !important;
  border: 1px solid #47484B;
  border-radius: 8px;
  padding: 7px 10px;
  text-align: left;
  position: relative;
  width: 60%;
}
@media (max-width: 1440px) {
  .imageUploaderSection .uploaderInner .uploaderFileSection p {
    font-size: 14px !important;
  }
}
@media (max-width: 575px) {
  .imageUploaderSection .uploaderInner .uploaderFileSection p {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row-reverse;
  }
}
.imageUploaderSection .uploaderInner .uploaderFileSection p img {
  width: 100%;
  max-width: 30px;
  float: right;
}
.imageUploaderSection .uploaderInner .uploaderFileSection p input {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}
.imageUploaderSection .uploaderInner .uploaderFileSection button {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
  border: none;
  color: #fff;
  font-weight: 600;
  border-radius: 8px;
  margin-left: 20px;
}
@media (max-width: 1440px) {
  .imageUploaderSection .uploaderInner .uploaderFileSection button {
    font-size: 14px;
    padding: 0 10px !important;
  }
}
@media (max-width: 1199px) {
  .imageUploaderSection .uploaderInner .uploaderFileSection button {
    padding: 5px 10px !important;
    margin-top: 20px;
  }
}
.imageUploaderSection .cropperArea {
  height: 280px;
  width: 280px;
  margin: 0 auto;
  position: relative;
}
.imageUploaderSection .cropperArea h6 {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.5);
  text-align: left;
}
.imageUploaderSection .cropperArea .reactEasyCrop_Container {
  top: 30px;
  left: 0;
}
.imageUploaderSection .cropperBtns {
  margin: 30px 0;
}
.imageUploaderSection .cropperBtns button {
  background-color: rgba(97, 209, 128, 0.2);
  border: 0;
  padding: 10px 12px;
  border-radius: 8px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0 3px;
}
.imageUploaderSection .cropperBtns button img {
  width: 100%;
  max-width: 14px;
}
.imageUploaderSection .cropperBtns button.first img {
  transform: rotateY(180deg);
}
.imageUploaderSection .cropperBtns input[type=range] {
  -webkit-appearance: none;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3; /* Set the background color */
  outline: none;
  margin: 10px 0; /* Optional: Add some spacing */
}
.imageUploaderSection .cropperBtns input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #61D180; /* Set the thumb color */
  cursor: pointer;
  margin-top: -5px; /* Center the thumb vertically */
}
.imageUploaderSection .cropperBtns input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  border-radius: 5px;
  background: #d3d3d3; /* Set the track color */
}

.adminTopRowSetting {
  display: flex;
  align-items: flex-start;
}
.adminTopRowSetting div {
  margin: 0px 13px;
}
.adminTopRowSetting div p {
  color: #fff;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 0px;
}
.adminTopRowSetting div span {
  color: #61D180;
}
.adminTopRowSetting div span.newUserData {
  font-size: 40px;
  font-weight: 600;
  font-family: manrope;
}
.adminTopRowSetting div span a {
  color: #61D180;
  word-break: break-all;
}
.adminTopRowSetting img {
  margin-top: 5px;
  size: 40px;
}
.adminTopRowSetting .todayData {
  font-weight: 10;
}
.adminTopRowSetting .topArrow {
  height: 25px;
  width: 25px;
}

.manageTeamImage {
  width: 60px;
  height: 60px;
  border-radius: 30px;
  object-fit: cover;
  object-position: center;
  margin-left: 5px;
  margin-right: 5px;
}

.imageGroupTeamMember {
  display: block;
}

.newUserData {
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: #191a1e;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 15px;
}

.viewAllButton {
  border: 1px solid #61d180;
  color: white;
  font-weight: 500;
  padding: 9px 30px;
  background-color: #1d1d1d;
  margin-right: 12px;
}

.hiringStatisticsBody {
  font-size: 27px;
  margin-left: 30px;
  margin-right: 30px;
}
.hiringStatisticsBody .divider {
  background-color: rgba(97, 209, 128, 0.15);
  height: 2px;
  margin: 25px 0;
}

.hiringStatistics {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.hiringStatistics .hiringStatisticsInner {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.hiringStatistics .hiringStatisticsInner img {
  margin-right: 10px;
}
.hiringStatistics .hiringStatisticsInner p {
  font-size: 16px;
  color: #fff;
  margin: 0;
}
.hiringStatistics .count {
  font-weight: 600;
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
}

.ticketMain {
  display: flex;
  padding: 34px;
}

.ticketBox {
  border-radius: 10px;
  border: 1px solid rgba(97, 209, 128, 0.16);
  background: #191a1e;
  box-shadow: 0px 4px 10px 0px rgba(113, 113, 113, 0.1);
  padding: 30px 40px;
  margin: 0 0 15px 0;
}
.ticketBox .ticketData h3 {
  font-size: 28px;
  color: #fff;
  font-weight: 600;
}
.ticketBox .ticketData p {
  color: #c9c9c9;
  font-size: 15px;
  font-weight: 500;
}

.userButton {
  background-image: linear-gradient(91deg, #61d180 0.07%, #023020 112.02%);
  border: 1px solid #61d180;
  color: #fff;
  font-weight: 500;
  padding: 9px 30px;
}
.userButton::after {
  position: absolute;
  content: " ";
  background-image: url(../imgs/arrowBottomWhite.svg);
  width: 18px;
  height: 18px;
  background-image: 18px;
  border: 0;
}

.adminButtonGroup .adminTeamButton {
  background: linear-gradient(91deg, #202825 0.07%, #023020 112.02%);
  border: 1px solid #61D180;
  color: #fff;
  font-weight: 500;
  padding: 12px 30px;
  margin: 10px 0;
  border-radius: 8px;
  width: 160px;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 1199px) {
  .adminButtonGroup .adminTeamButton {
    width: 100%;
  }
}
@media (max-width: 991px) {
  .adminButtonGroup .adminTeamButton {
    width: 160px;
  }
}
.adminButtonGroup .adminTeamButton.activeButton, .adminButtonGroup .adminTeamButton:hover {
  background: linear-gradient(91deg, #61D180 0.07%, #023020 112.02%);
}

.TopPerformers {
  margin-bottom: 12px;
  padding: 10px 10px;
  justify-content: space-between;
  display: flex;
  color: #fff;
  border-radius: 8px;
  background-color: #1D1D1D;
}
@media (max-width: 575px) {
  .TopPerformers {
    flex-direction: column;
  }
}
.TopPerformers.firstPosition {
  background-color: rgba(253, 197, 0, 0.38);
}
.TopPerformers.secondPosition {
  background-color: #3E4240;
}
.TopPerformers.thirdPosition {
  background-color: rgba(61, 45, 14, 0.8);
}
.TopPerformers .leadLeft {
  display: flex;
  align-items: center;
  gap: 10px;
}
.TopPerformers .leadLeft .leadPosition {
  font-size: 20px;
  margin: 0;
}
.TopPerformers .leadLeft .leadHeading {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.TopPerformers .leadLeft .leadImage {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  object-fit: cover;
  object-position: center;
  -o-object-position: center;
}
.TopPerformers .leadLeft p {
  color: #fff;
  font-size: 12px;
  margin: 0;
}
.TopPerformers .leadRight {
  display: flex;
  justify-content: space-around;
}
@media (max-width: 575px) {
  .TopPerformers .leadRight {
    margin: 20px 0 0;
  }
}
.TopPerformers .leadRight .leadCounter {
  text-align: center;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 8px;
  padding: 7px;
  width: 80px;
  margin-right: 10px;
}
.TopPerformers .leadRight .leadCounter:last-of-type {
  margin: 0;
}
.TopPerformers .leadRight .leadCounter h4 {
  font-size: 24px;
  margin: 0;
}
.TopPerformers .leadRight .leadCounter p {
  font-size: 10px;
  margin: 0;
}

.sectionShareJob {
  padding: 50px 0;
}
@media (max-width: 575px) {
  .sectionShareJob .jobPosted__List .postHeader {
    flex-direction: column;
  }
}
@media (max-width: 575px) {
  .sectionShareJob .jobPosted__List .postBodyContent h4 {
    word-break: break-all;
  }
}

